
import { globalTableStyles } from 'styles/globalTableStyles'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    margin: 'auto',
    color: theme.palette.common.second3,
    fontWeight: '500',
  },
  text: {
    color: theme.palette.common.second3,
    lineHeight: 'normal',
    fontWeight: '300',
  },
}))

export const tableStyles = {
  ...globalTableStyles,
}

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  & .icon {
    cursor: pointer;
  }
`
