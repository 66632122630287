//  libraries
import React, { useEffect, useState, useRef } from 'react'
import ReactGA from 'react-ga'
import { useHistory, Link } from 'react-router-dom'
import MaterialTable, { MTableBody } from 'material-table'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import TablePagination from '@material-ui/core/TablePagination'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { quoteListFetch, cleanState } from 'redux/ducks/quoteList'

//  tools
import { nameAbbreviation, dateFormat } from 'tools'

// styles
import { tableStyles, useStyles } from './styles'
import { tableIcons } from 'styles/tableIcons'
import { globalTableOptions } from 'styles/globalTableStyles'
import { IoIosArrowRoundUp, IoIosArrowRoundDown, IoIosEye } from 'react-icons/io'

// components
import FilterBar from 'components/common/filterBar'
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'
import ContainerTable from 'components/common/tableFragments/containerTable'
import FormattedCell from 'components/common/tableFragments/cells/formattedCell'
import Pagination from 'components/common/tableFragments/pagination'
import TableLoading from 'components/common/tableFragments/tableLoading'
import CellRepresentatives from 'components/common/tableFragments/cellRepresentatives'
import SortingHeader from 'components/common/tableFragments/sortingHeader'
import Assign from './assign'

export default function QuoteListSalesRep () {
  const classes = useStyles()
  const tableRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const { data: dataTable = [], loading, totalCount = 0, page = 1 } = useSelector(state => state.entities.quoteList.tl)
  const filters = useSelector(state => state.ui.filters)
  const defaultFilters = { limit, page, ...filters }
  const setPage = page - 1 < 0 ? 0 : page - 1 // Avoid to assign negative value.

  useEffect(() => {
    dispatch(quoteListFetch('Tl', { params: defaultFilters }))
    return () => {
      dispatch(cleanState())
    }
    //  eslint-disable-next-line
  }, [dispatch])

  return (
    <>
      <FilterBar
        filters={['searchBar', 'status', 'transportType', 'creationDate', 'priceRange', 'pickupDate', 'deliveryDate']}
        thunkAction={(data, callback) => quoteListFetch('Tl', data, null, callback)}
        propFilters={defaultFilters}
      />
      <Pagination
        count={totalCount}
        page={setPage}
        rowsPerPage={limit}
        onChangePage={(evt, page) => {
          dispatch(quoteListFetch('Tl', { params: { ...defaultFilters, page: page + 1, limit } }))
        }}
        onChangeRowsPerPage={(evt, arg) => {
          setLimit(evt.target.value)
          tableRef.current.onChangeRowsPerPage(evt)
          dispatch(quoteListFetch('Tl', { params: { ...defaultFilters, limit: evt.target.value, page } }))
        }}
      />
      <MaterialTable
        tableRef={tableRef}
        isLoading={loading}
        data={dataTable}
        icons={tableIcons}
        columns={[
          {
            title: 'Company',
            sortKeyValue: 'company',
            render: (rowData) => (
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Avatar className={classes.companyAvatar} src={`${rowData.company?.image}?${Math.random()}`}>
                  {nameAbbreviation(rowData.company?.name)}
                </Avatar>
                <Box display='flex' flexDirection='column' alignItems='center' width='100%'>
                  <b>{rowData.company?.name}</b>
                </Box>
              </Box>
            ),
          },
          {
            title: 'Reference',
            sortKeyValue: 'reference',
            render: rowData => (
              <>
                {rowData.mlQuoteId && <Typography>{rowData.mlQuoteId}</Typography>}
                <Link to={`/sales/list/${rowData.refId}`} className={classes.link}> <b> #{rowData.refId} </b> </Link>
              </>
            ),
          },
          {
            title: 'Pickup date/time',
            sortKeyValue: 'pickup',
            render: (rowData) => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.pickup?.startDate)}
                time={rowData.shipping?.pickup?.startTime}
              />
            ),
          },
          {
            title: 'Origin',
            sortKeyValue: 'origin',
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundUp className={classes.originIcon} />}
                mainText={rowData.origin?.city}
                secondMainText={rowData.origin?.state}
                secondaryText={rowData.origin?.postalCode}
              />
            ),
          },
          {
            title: 'Destination',
            sortKeyValue: 'destination',
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundDown className={classes.destinationIcon} />}
                mainText={rowData.destination?.city}
                secondMainText={rowData.destination?.state}
                secondaryText={rowData.destination?.postalCode}
              />
            ),
          },
          {
            title: 'Creation Date',
            sortKeyValue: 'creation',
            render: ({ quoteDate }) => <QuoteDateTD date={dateFormat(quoteDate?.date)} time={dateFormat(quoteDate?.date, 'HH:mm')} />,
          },
          {
            title: 'Estimated Delivery Date',
            sortKeyValue: 'delivery',
            render: (rowData) => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.delivery?.startDate)}
                time={rowData.shipping?.delivery?.startTime}
              />
            ),
          },
          {
            title: 'Customer Code',
            sorting: false,
            render: rowData => (
              <Typography>
                {rowData.company.mlCompanyId || '...'}
              </Typography>
            ),
          },
          {
            title: 'Representatives',
            sorting: false,
            render: rowData => <CellRepresentatives representatives={rowData.company?.representatives} />,
          },
          {
            title: 'Status',
            sortKeyValue: 'status',
            render: ({ status }) => <Typography className={classes.textStatus}>{status}</Typography>,
          },
          {
            title: 'Assign To Me',
            sorting: false,
            render: ({ salesAssigned, assignedToMe, refId }) => (
              <Assign
                salesAssigned={salesAssigned}
                assignedToMe={assignedToMe}
                refId={refId}
              />
            ),
          },
        ]}
        actions={[rowData => {
          return ({
            icon: () => <IoIosEye className={classes.icon} />,
            tooltip: 'Go to detail',
            onClick: () => {
              ReactGA.event({
                category: 'Detail',
                action: '/sales/list/',
                label: rowData.refId,
              })
              history.push(`/sales/list/${rowData.refId}`)
            },
          })
        }]}
        components={{
          Pagination: (componentsProps) => {
            return (
              <TablePagination
                {...componentsProps}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(quoteListFetch('Tl', { params: { ...defaultFilters, page: page + 1, limit } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  componentsProps.onChangeRowsPerPage(evt)
                  dispatch(quoteListFetch('Tl', { params: { ...defaultFilters, limit: evt.target.value, page } }))
                }}
              />
            )
          },
          Body: (props) => (
            <>
              {loading && totalCount > 0 && <TableLoading />}
              <MTableBody {...props} />
            </>
          ),
          Container: props => (
            <ContainerTable>
              {props.children}
            </ContainerTable>
          ),
          Header: (props) => <SortingHeader tableProps={props} limit={limit} listOf='quote' />,
        }}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          sorting: true,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No quotes to display',
          },
        }}
      />
    </>
  )
}
