import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  InputAdornment,
  ClickAwayListener,
  Box,
  Typography,
  IconButton,
  TextField,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'

// tools
import { dateFormat } from 'tools'
import moment from 'moment'

//  styles
import { useStyles } from './styles'
import { IoIosClose, IoMdCalendar } from 'react-icons/io'

function DateRange ({ filtersValues = {}, cbHandler, gteKey, lteKey, labelText, placeholder, style }) {
  const formatString = 'MM/DD/YYYY'
  const classes = useStyles()
  const gteValue = filtersValues[gteKey]
  const lteValue = filtersValues[lteKey]
  const [values, setValues] = useState({
    open: false,
    range: gteValue && lteValue ? `${dateFormat(gteValue, formatString)} - ${dateFormat(lteValue, formatString)}` : '',
    sinceDate: gteValue || moment().hours(0).minutes(0).seconds(0).subtract(2, 'days')._d,
    toDate: lteValue || moment().hours(23).minutes(59).seconds(59)._d,
  })

  useEffect(() => {
    setValues({
      ...values,
      range: gteValue && lteValue ? `${dateFormat(gteValue, formatString)} - ${dateFormat(lteValue, formatString)}` : '',
      sinceDate: gteValue || moment().hours(0).minutes(0).seconds(0).subtract(2, 'days')._d,
      toDate: lteValue || moment().hours(23).minutes(59).seconds(59)._d,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gteValue, lteValue])

  const updateFilters = (gte = null, lte = null) => {
    cbHandler({
      key: gteKey,
      value: gte,
    })
    cbHandler({
      key: lteKey,
      value: lte,
    })
  }

  const onClick = event => { // Right Icon button
    event.preventDefault()
    if (values.open) { // USE-CASE: opened calendar: close it & clean date
      setValues({
        ...values,
        open: false,
        range: '',
      })
      updateFilters()
    } else if (values.range === '') { // USE-CASE: closed calendar and Empty date: show it & set date
      setValues({
        ...values,
        open: true,
        range: `${dateFormat(values.sinceDate, formatString)} - ${dateFormat(values.toDate, formatString)}`,
      })
      updateFilters(values.sinceDate, values.toDate)
    } else { // USE-CASE: closed calendar and added date: clean date
      setValues({
        ...values, // ALWAYS is open = false
        range: '',
      })
      updateFilters()
    }
  }

  const onClickAway = () => {
    setValues({
      ...values,
      open: false,
    })
  }

  const onFocus = () => {
    const sinceDate = dateFormat(values.sinceDate, formatString)
    const toDate = dateFormat(values.toDate, formatString)
    updateFilters(values.sinceDate, values.toDate)
    setValues({
      ...values,
      open: true,
      range: `${sinceDate} - ${toDate}`,
    })
  }

  const onChangeDatePickerSince = (dateObj) => {
    const sinceDateFormatted = dateFormat(dateObj._d, formatString)
    const toDateFormatted = dateFormat(values.toDate, formatString)
    const sinceDateObject = moment(dateObj._d).hours(0).minutes(0).seconds(0)
    setValues({
      ...values,
      sinceDate: sinceDateObject._id,
      range: `${sinceDateFormatted} - ${toDateFormatted}`,
    })
    updateFilters(sinceDateObject._d, values.toDate)
  }
  //  Example: createdAt[lte]=2020-04-10&createdAt[gte]=2020-03-26
  const onChangeDatePickerTo = (dateObj) => {
    const sinceDateFormatted = dateFormat(values.sinceDate, formatString)
    const toDateFormatted = dateFormat(dateObj._d, formatString)
    const toDateObject = moment(dateObj._d).hours(23).minutes(59).seconds(59)
    setValues({
      ...values,
      toDate: toDateObject._d,
      range: `${sinceDateFormatted} - ${toDateFormatted}`,
    })
    updateFilters(values.sinceDate, toDateObject._d)
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        position='relative'
        minWidth='9.25rem'
        width={`${(values.range.length * 0.68)}rem`}
        mt={labelText ? '1rem' : 0}
        style={style}
      >
        {labelText && (
          <Typography id='range-slider' variant='body1' className={classes.subtitleAdvancedOption}>
            {labelText}
          </Typography>
        )}
        <TextField
          fullWidth
          onFocus={onFocus}
          value={values.range}
          className={classes.dateRangeInput}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton onClick={onClick} edge='end'>
                  {values.open || !!values.range
                    ? <IoIosClose />
                    : <IoMdCalendar style={{ color: '#637280' }} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          placeholder={placeholder || formatString}
        />
        {values.open && (
          <Box
            className={classes.paper}
            display={['block', 'block', 'block', 'flex']}
            flexWrap='wrap'
            boxShadow={3}
          >
            <Box mx={2}>
              <Typography className={classes.indicatorText}>Since:</Typography>
              <DatePicker
                variant='static'
                value={values.sinceDate}
                onChange={onChangeDatePickerSince}
                maxDate={values.toDate}
                disableFuture={gteKey.includes('createdAt')}
              />
            </Box>
            <Box mx={2}>
              <Typography className={classes.indicatorText}>To:</Typography>
              <DatePicker
                variant='static'
                value={values.toDate}
                onChange={onChangeDatePickerTo}
                minDate={values.sinceDate}
                disableFuture={gteKey.includes('createdAt')}
              />
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default DateRange

DateRange.propTypes = {
  filtersValues: PropTypes.object.isRequired,
  cbHandler: PropTypes.func.isRequired,
  gteKey: PropTypes.string.isRequired,
  lteKey: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
}
