//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PRIMARY_TAB.UPDATE:
      return model.updateTab(action.payload)
    default:
      return state
  }
}
