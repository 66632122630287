function buildFormData (formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data
    formData.append(parentKey, value)
  }
}

const convertToFormatData = (payload) => {
  const fd = new FormData()
  buildFormData(fd, payload)
  return fd
}

export default convertToFormatData
