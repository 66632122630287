//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import InfoTl from './infoTl'
import InfoLtl from './infoLtl'
import Reference from './reference'

//  styles
import { listIcon } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'

export default function ShippingInformationShipment ({ type }) {
  const classes = useStyles()

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={listIcon} width='35px' />
        <Typography variant='h1' className={classes.heading}>
          Additional Shipping information
        </Typography>
      </HeadingContainer>
      {type === 'ltl' ? <InfoLtl /> : <InfoTl />}
      <Reference type={type} />
    </Root>
  )
}

ShippingInformationShipment.propTypes = {
  type: PropTypes.string.isRequired,
}
