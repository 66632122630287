import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  colorText: {
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.common.second1,
    margin: 'auto',
  },
  titleValue: {
    lineHeight: 'normal',
    color: theme.palette.common.second3,
    opacity: 0.6,
  },
  buttonValue: {
    margin: '0 25px',
    minWidth: '40px',
    height: '40px',
    padding: 0,
    backgroundColor: theme.palette.common.second6,
    '&:hover': {
      backgroundColor: '#637280',
    },
  },
  value: {
    fontSize: '28px',
    fontWeight: 'normal',
    color: theme.palette.common.second1,
  },
  addButton: {
    width: '140px',
    height: '40px',
    marginTop: '23px',
    marginLeft: '30px',
    backgroundColor: theme.palette.common.second6,
    '&:hover': {
      backgroundColor: '#637280',
    },
    color: theme.palette.common.second8,
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
}))

export const Container = styled.div`
  ${({ theme, selected }) => css`
    width: 100%;
    height: 120px;
    border: ${selected ? `solid 2px ${theme.palette.primary.main}` : `solid 1px ${theme.palette.common.second6}`}
    background: ${selected ? '#CECECE' : '#FFFFFF'}
    border-radius: 4px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-content: center;
    padding: 0 33px;
  `}
`

export const Actions = styled.div`
  display: flex;
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Selector = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;
  .plus {
    color: #FFFFFF;
  }
  .minus {
    color: #FFFFFF;
  }
`
