//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// components
import FormattedCell from 'components/common/tableFragments/cells/formattedCell'
import StatusTD from 'components/common/tableFragments/cells/statusTD'
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'

// tools
import { dateFormat, timeFormatUtc } from 'tools'

//  styles
import { useStyles, Root, Header, Content } from './styles'
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io'

export default function DetailsForm1 ({ data }) {
  const classes = useStyles()
  const { refId, origin, destination, quoteDate, shipping, status } = data

  return (
    <Root>
      <Header>
        <Typography variant='h5' className={classes.title} align='center'>Reference</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Origin</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Destination</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Pickup Date</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Creation Date</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Status</Typography>
      </Header>
      <Content>
        <div>
          <Typography variant='h4' className={classNames(classes.text, classes.bold)} align='center'>
            {data.mlQuoteId}
          </Typography>
          <Typography align='center'>
            #{refId}
          </Typography>
        </div>
        <FormattedCell
          icon={<IoIosArrowRoundUp className={classes.originIcon} />}
          mainText={origin?.city}
          secondMainText={origin?.state}
          secondaryText={origin?.postalCode}
        />
        <FormattedCell
          icon={<IoIosArrowRoundDown className={classes.destinationIcon} />}
          mainText={destination?.city}
          secondMainText={destination?.state}
          secondaryText={destination?.postalCode}
        />
        <QuoteDateTD
          date={dateFormat(shipping.pickup?.startDate)}
          time={shipping.pickup?.startTime}
        />
        <QuoteDateTD
          date={dateFormat(quoteDate?.date)}
          time={dateFormat(quoteDate?.date, 'HH:mm')}
        />
        <StatusTD status={status} />
      </Content>
    </Root>
  )
}

DetailsForm1.propTypes = {
  data: PropTypes.shape({
    refId: PropTypes.string,
    origin: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    destination: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    transportType: PropTypes.string,
    quoteDate: PropTypes.shape({
      date: PropTypes.string,
    }),
    shipping: PropTypes.shape({
      pickup: PropTypes.shape({
        startDate: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
  }),
}
