import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    cursor: 'pointer',
  },
  icon: {
    justifyContent: 'flex-end',
  },
  unCheckedIcon: {
    color: theme.palette.common.second6,
    width: '35px',
    height: '35px',
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    width: '35px',
    height: '35px',
    borderRadius: '50%',
  },
  image: {
    margin: '0 auto',
    width: '100%',
    padding: '0 33px',
  },
  text: props => ({
    lineHeight: 'normal',
    color: props.validation ? theme.palette.primary.main : theme.palette.common.second6,
    marginBottom: '27px',
  }),
}))

export const ContainerType = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 5px;
    border: solid 1px ${theme.palette.common.second6};
    display: grid;
    align-items: center;
    grid-auto-rows: auto 1fr auto;
    @media (min-width: 960px) {
      height: 300px;
    }
  `}
`
