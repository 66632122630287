import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  colorText: {
    color: theme.palette.common.second1,
    marginLeft: 18,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
  },
  onlyText: {
    color: theme.palette.common.second1,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
    marginLeft: '5px',
    marginTop: '15px',
  },
  datePicker: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
      paddingRight: '0px',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
      margin: '12px 12px 12px 0px',
    },
  },
  modal: {
    '& .MuiButton-root': {
      fontSize: '15px',
    },
  },
  checkbox: {
    width: '50px',
    margin: 'auto 0',
  },
  checked: {
    color: theme.palette.common.second6,
  },
}))

export const Title = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: center;
  min-height: 50px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`
