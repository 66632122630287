//  libraries
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'

//  styles
import { useStyles, AvatarContainer, Upload } from './styles'
import { MdAddAPhoto } from 'react-icons/md'

export default function ImageSelector ({ image, handleSaveImage }) {
  const classes = useStyles()
  const inputRef = useRef(null)
  let value = image

  if (value instanceof File) {
    value = URL.createObjectURL(value)
  } else if (typeof value === 'object') {
    value = undefined
  }

  return (
    <>
      <input
        accept='image/*'
        type='file'
        onChange={handleSaveImage}
        ref={inputRef}
        className={classes.inputFile}
      />
      <AvatarContainer>
        <Avatar
          alt='User Photo'
          src={value?.search('blob:') === -1 ? `${value}?${Math.random()}` : value}
          className={classes.avatar}
        />
        <Upload onClick={() => inputRef.current.click()}>
          <IconButton>
            <MdAddAPhoto className='icon' />
          </IconButton>
        </Upload>
      </AvatarContainer>
    </>
  )
}

ImageSelector.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  handleSaveImage: PropTypes.func.isRequired,
}
