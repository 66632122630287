import { FETCH } from 'API/fetch'
import TYPES from './types'

export const UserListRequest = (payload, callback = () => {}) => ({
  type: TYPES.USER_LIST.FETCH.REQUEST,
  payload,
  callback,
})

export const UserListSucceeded = payload => ({
  type: TYPES.USER_LIST.FETCH.SUCCEEDED,
  payload,
})

export const UserListFailed = payload => ({
  type: TYPES.USER_LIST.FETCH.FAILED,
  payload,
})

export const UserListUpdateStatus = payload => ({
  type: TYPES.USER_LIST.UPDATE.STATUS,
  payload,
})

export const TuckerUsersRequest = (payload) => ({
  type: TYPES.TUCKER_USERS.FETCH.REQUEST,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.USER_LIST.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.USER_LIST.CLEAN.STATE,
})

export const deleteItemList =  (id) => {
  FETCH('delete', `/api/v1/admin/user/${id}`);
  return{
    type: TYPES.USER_LIST.DELETE,
    payload: id
    }
}