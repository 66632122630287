import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  QuoteDetailsSucceeded,
  QuoteDetailsFailed,
  QuoteDetailsSetMessageSucceeded,
  QuoteDetailsSetMessageFailed,
  QuoteDetailsMarketRatesSucceeded,
  QuoteDetailsMarketRatesFailed,
  QuoteDetailsDeclineQuoteSucceeded,
  QuoteDetailsDeclineQuoteFailed,
} from './actions'
import API from 'API'

function * fetchQuoteDetails (action) {
  try {
    const { data } = yield call(API.quoteService.getTl, action.payload)
    yield put(QuoteDetailsSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(QuoteDetailsFailed(newError))
  }
}

function * fetchSetMessage (action) {
  try {
    const { data } = yield call(API.quoteService.setTlMessage, action.payload, action.quoteId)
    yield put(QuoteDetailsSetMessageSucceeded(data.data, action.payload.commentType))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(QuoteDetailsSetMessageFailed(newError))
    action.errorCallback()
  }
}

function * fetchDeclineQuote (action) {
  try {
    const { data } = yield call(API.quoteService.setRejectSurvey, action.payload)
    yield put(QuoteDetailsDeclineQuoteSucceeded(data.data, action.payload))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(QuoteDetailsDeclineQuoteFailed(newError))
    action.errorCallback()
  }
}

function * fetchUpdateMarketRates (action) {
  try {
    const { data } = yield call(API.quoteService.updateTlMarket, action.payload)
    yield put(QuoteDetailsMarketRatesSucceeded(data.data, action.payload.commentType))
    action.callBack()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(QuoteDetailsMarketRatesFailed(newError))
    action.errorCallback()
  }
}

export default function * rootQuoteDetails () {
  yield takeEvery(TYPES.QUOTE_DETAILS.FETCH.REQUEST, fetchQuoteDetails)
  yield takeEvery(TYPES.QUOTE_DETAILS.SET_MESSAGE.FETCH.REQUEST, fetchSetMessage)
  yield takeEvery(TYPES.QUOTE_DETAILS.DECLINE.FETCH.REQUEST, fetchDeclineQuote)
  yield takeEvery(TYPES.QUOTE_DETAILS.UPDATE_RATES.FETCH.REQUEST, fetchUpdateMarketRates)
}
