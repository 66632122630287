import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    borderBottom: `2px solid ${theme.palette.common.second6}`,
  },
  indicator: {
    height: '6px',
  },
  tab: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: '500',
    textTransform: 'none',
    paddingBottom: '20px',
    fontSize: '22px',
    color: '#637280',
    marginTop: 12,
  },
  activeTab: {
    fontWeight: '900',
    fontSize: '24px',
    '& .MuiTab-wrapper': {
      alignItems: 'start',
    },
  },
  closeButton: {
    width: '9%',
    alignSelf: 'flex-end',
    marginTop: '.2vh',
    marginRight: '.1vw'
  },
  emptyCompanyErrorMessage: {
    padding: '5vh 5vw',
    marginBottom: '1vw'
  }
}))

export const Form = styled.form`
  ${({ theme }) => css`
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding-left: 40px;
    margin-left: 10px;
    height: 100%;
    width: 700px;
    border-left: 2px solid ${theme.palette.common.second6};
    @media (max-width: 1270px) {
      border-left: none;
    }
  `}
`
