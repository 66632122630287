//  libraries
import React, { useEffect } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

//  components
import CustomInput from './customInput'
import Dimensions from './dimensions'
import Checkboxes from './checkboxes'
import Tarping from './tarping'

//  hooks
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

//  styles
import { FiDollarSign } from 'react-icons/fi'
import { Item, Title, useStyles, Range, RangeText } from './customInput/styles'

const weightCondition = {
  'dry-van': 45000,
  reefer: 42000,
  flatbed: 44000,
}

export default function LoadInformation () {
  const { type: modality, action } = useParams()
  const { register, errors, control, setValue, unregister, watch, getValues, trigger } = useFormContext()
  const { products: [product] = [{}], transport = 'dry-van', fromMcLeod } = useSelector(state => state.newForm)

  const classes = useStyles()

  useEffect(() => {
    register('products[0].overWeight')
    register('products[0].overSize')
    register('products[0].isHigh')
    setValue('products[0].overWeight', product.overWeight || false)
    setValue('products[0].overSize', product.overSize || false)
    setValue('products[0].isHigh', product.isHigh || false)
    if (action === 'edit') trigger()
    return () => {
      unregister('products[0].overWeight')
      unregister('products[0].overSize')
      unregister('products[0].isHigh')
    }// eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (modality === 'tl') {
      if (watch('products[0].value') > 100000) {
        setValue('products[0].isHigh', true)
      } else {
        setValue('products[0].isHigh', false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modality, watch('products[0].value')])

  return (
    <>
      <CustomInput
        title='Pieces'
        name='products[0].pieces'
        mask='999'
        error={errors.products?.[0]?.pieces}
        defaultValue={product.pieces || ''}
        control={control}
      />
      <CustomInput
        title='Total weight (lbs)'
        name='products[0].weight'
        error={errors.products?.[0]?.weight}
        control={control}
        defaultValue={product.weight || ''}
        condition={transport ? weightCondition[transport] : 42000}
        field='products[0].overWeight'
        customRules={{ required: { value: !fromMcLeod ?? true, message: 'This field is required' } }}
        message='The weight of your shipment may require special attention and handling. In order to quote your shipment, a member of our team will reach out to you for more details.'
      />
      {transport === 'flatbed' && (
        <Dimensions
          length={product.length}
          height={product.height}
          width={product.width}
        />
      )}
      <CustomInput
        title='Declared Value'
        name='products[0].value'
        error={errors.products?.[0]?.value}
        control={control}
        defaultValue={product.value || ''}
        condition={100000}
        field='products[0].isHigh'
        customRules={{ required: { value: !fromMcLeod ?? true, message: 'This field is required' } }}
        message='The value of your shipment may require special attention and handling. In order to quote your shipment, a member of our team will reach out to you for more details.'
        InputProps={{
          startAdornment: <InputAdornment position='start'><FiDollarSign color='#CBD6E2' /></InputAdornment>,
        }}
      />
      <Checkboxes
        isNew={!!product.isNew}
        hazmat={!!product.hazmat}
        hazmatValue={product.hazmatValue}
      />
      {transport === 'reefer' && (
        <Item>
          <Title>
            <Typography className={classes.colorText} variant='h4' align='center' component='label'>Temperature Range</Typography>
          </Title>
          <Range>
            <CustomInput
              name='products[0].tempMin'
              error={errors.products?.[0]?.tempMin}
              control={control}
              defaultValue={product.tempMin || ''}
              rules={{
                required: { value: !((fromMcLeod && product.tempMax && !product.tempMin)), message: 'This field is required' },
                max: { value: 140, message: '140 is the max allowed value' },
              }}
            />
            <RangeText>
              <Typography variant='h4' align='center' component='label'>to</Typography>
            </RangeText>
            <CustomInput
              name='products[0].tempMax'
              error={errors.products?.[0]?.tempMax}
              control={control}
              defaultValue={product.tempMax || ''}
              rules={{
                required: { value: !((fromMcLeod && product.tempMin && !product.tempMax)), message: 'This field is required' },
                max: { value: 140, message: '140 is the max allowed value' },
                validate: value => {
                  const min = getValues('products[0].tempMin')
                  if (fromMcLeod) return
                  return Number(value) <= Number(min) && 'Temp. value must be higher' || true
                },
              }}
            />
          </Range>
        </Item>
      )}
      {transport === 'flatbed' && (
        <Tarping
          isTarping={!!product.isTarping}
          tarpingValue={product.tarpingValue || '4ft'}
        />
      )}
    </>
  )
}
