import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles((theme) => ({
  button: {
    height: 50,
    fontSize: 18,
    color: '#ffffff',
    backgroundColor: '#4b2367',
    marginBottom: '2rem',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  twoButtonsInline: {
    width: '45%',
  },
  spinner: {
    position: 'absolute',
    margin: 0,
  },
}))

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 60% 40%;
    column-gap: 10px;
    width: 100%;
    padding: 30px 10px 10px 10px;
    max-width: 1200px;
    margin: 0 auto;
  `}
`
