//  libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// component
import ImageNotDraggable from 'components/common/imageNotDraggable'
import EditModal from '../editModal'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { useStyles } from './styles'
import { alert, comments } from 'assets'
import { FiMoreVertical } from 'react-icons/fi'

const CardAddedProduct = ({
  addedProduct,
  index,
  selectedArrayOfProducts,
  setSelectedArrayOfProducts,
  choosenArrayOfProducts,
  setChoosenArrayOfProducts,
}) => {
  const classes = useStyles()
  const {
    itemName,
    isOverWeight,
    isOverSize,
    pieces,
    freightClass,
    width,
    length,
    height,
    quantity,
    totalWeight,
  } = addedProduct
  const overWeightText = isOverWeight ? 'The weight of your shipment may require special attention and handling. In order to quote your shipment, a member of our team will reach out to you for more details.' : ''
  const overSizeText = isOverSize ? 'The size of your shipment may require special attention and handling. In order to quote your shipment, a member of our team will reach out to you for more details.' : ''
  const [tooltipIsOpen, setTooltipIsOpen] = useState(true)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const disabled = useSelector(state => state.newForm.disabled)

  const handleClickModal = () => {
    setOpen(!open)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseEdit = () => {
    handleClose()
    handleClickModal()
  }

  const handleCloseDelete = () => {
    choosenArrayOfProducts = choosenArrayOfProducts.filter((element, indexArray) => {
      return !(index === indexArray)
    })
    setChoosenArrayOfProducts(choosenArrayOfProducts)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const printTitleValue = (title, value, isHighlight = false) => {
    return (
      <div className={classes.duoContainer}>
        <Typography
          className={classes.subtitle}
          variant='subtitle2'
          gutterBottom
          align='left'
        >
          {title}
        </Typography>
        <Typography
          className={isHighlight ? classes.isHighlight : classes.value}
          variant='body2'
          gutterBottom
          align='center'
        >
          {value}
        </Typography>
      </div>
    )
  }

  return (
    <Grid className={`${classes.styledCard}`} item xs={5} container justify='space-between'>
      <Grid container item xs={12} alignItems='center' className={classes.styledCardAddedProduct}>
        <Grid container alignItems='center' item xs={10}>
          <Typography
            className={classes.selectedProductStyle}
            gutterBottom
          >
            {itemName}
          </Typography>
          {isOverWeight && <ImageNotDraggable externalImage={alert} width='25px' />}
        </Grid>
        <Grid container justify='flex-end' item xs={2}>
          <IconButton onClick={handleClick} className={classes.styledIcon} size='medium' disabled={disabled}>
            <FiMoreVertical />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container alignItems='center' item xs={12} className={classes.styledCardAddedProduct}>
        <Grid container item xs={10} justify='space-between'>
          {printTitleValue('Pieces', pieces.toLocaleString('en-US'))}
          {printTitleValue('Class', freightClass)}
          {printTitleValue('Dimensions', `${width} x ${length} x ${height}`)}
          {printTitleValue('Quantity', quantity.toLocaleString('en-US'))}
          {printTitleValue('Total Weight', totalWeight.toLocaleString('en-US'), true)}
        </Grid>
        <Grid container justify='flex-end' item xs={2}>
          {(isOverWeight || isOverSize) &&
            (
              <Tooltip
                onOpen={() => setTooltipIsOpen(true)}
                onClose={() => setTooltipIsOpen(false)}
                open={tooltipIsOpen}
                title={`${overWeightText} ${overSizeText}`}
              >
                <Button
                  onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                  className={classes.styledButton}
                >
                  <ImageNotDraggable externalImage={comments} width='30px' />
                </Button>
              </Tooltip>
            )}
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCloseEdit}>Edit</MenuItem>
        <MenuItem onClick={handleCloseDelete}>Delete</MenuItem>
      </Menu>
      <EditModal
        index={index}
        open={open}
        handleClick={handleClickModal}
        addedProduct={addedProduct}
        choosenArrayOfProducts={choosenArrayOfProducts}
        setChoosenArrayOfProducts={setChoosenArrayOfProducts}
        selectedArrayOfProducts={selectedArrayOfProducts}
        setSelectedArrayOfProducts={setSelectedArrayOfProducts}
      />
    </Grid>
  )
}

CardAddedProduct.propTypes = {
  addedProduct: PropTypes.object,
  setSelectedArrayOfProducts: PropTypes.func,
  selectedArrayOfProducts: PropTypes.array,
  index: PropTypes.number,
  choosenArrayOfProducts: PropTypes.array,
  setChoosenArrayOfProducts: PropTypes.func,
}

export default CardAddedProduct
