import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Link as LinkRouter } from 'react-router-dom'

export const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: '10px',
    height: '45px',
    width: '350px',
    background: theme.palette.background.paper,
    borderRadius: '6px',
    '& .MuiInputLabel-filled': {
      marginTop: '-8px',
    },
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    '&:hover': {
      backgroundColor: '#ebf1f5',
    },
    '&:disabled': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#4B2367',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    border: '1px solid',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,

    },
    '&:hover': {
      backgroundColor: '#4B2367',
    },
  },
  inputBase: {
    height: '45px',
    background: `${theme.palette.background.paper} !important`,
    color: theme.palette.common.second2,
    '& .MuiFilledInput-input': {
      padding: '15px 0px 10px 12px',
    },
  },
  submitButton: {
    marginTop: '20px',
    display: 'block',
    margin: '0 auto',
    textTransform: 'none',
    width: '350px',
    height: '45px',
    fontWeight: '400',
    fontSize: '16px',
    color: 'white',
    background: '#D57800',
    '&:hover': {
      background: '#CC5500',
    },
  },
  text: {
    fontStretch: 'normal',
    fontStyle: 'normal',
    margin: 'auto 0',
  },
  errorForm: {
    fontSize: 16,
    width: '60%',
  },

}))

export const Link = styled(LinkRouter)`
  display: block;
  margin: 10px 0;
  text-align: right;
  width: 118px;
  height: 22px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
  color: white;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
`

export const Form = styled.form`
  text-align: end;
`
