//  libraries
import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

//  redux
import { stepperUpdate } from 'redux/ducks/stepper'

//  hooks
import { useDispatch } from 'react-redux'

//  styles
import { useStyles, Container } from './styles'

export default function ButtonStepper ({
  activeStep,
  internalStep,
  setInternalStep,
  isLastStep,
  action,
  handleSave,
  disabledNextButton = false,
  disabledSaveButton = true,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleBack = () => {
    if (internalStep > 0) {
      setInternalStep(internalStep - 1)
    } else {
      dispatch(stepperUpdate({ index: activeStep - 1 }))
    }
  }

  const renderSaveButton = () => {
    return (
      <Button
        variant='contained'
        onClick={handleSave}
        className={`${classes.button} ${classes.orange}`}
        classes={{ disabled: classes.disabled }}
        disabled={disabledSaveButton}
      >
        Save Changes
      </Button>
    )
  }

  const renderNextButton = () => (
    <Button
      variant='contained'
      type='submit'
      classes={{ root: classes.button, disabled: classes.disabled }}      
      disabled={disabledNextButton}
    >
      Next
    </Button>
  )

  const renderCreateButton = () => (
    <Button
      variant='contained'
      type='submit'
      classes={{ root: classes.button, disabled: classes.disabled }}
      className={`${classes.button} ${classes.orange}`}
      disabled={disabledNextButton}
    >
      Create
    </Button>
  )

  const renderBackButton = () => {
    if (activeStep !== 0 || internalStep !== 0) {
      return (
        <Button
          variant='contained'
          onClick={handleBack}
          className={classes.button}
        >
          Back
        </Button>
      )
    }
  }

  if (action === 'edit') {
    return (
      <Container>
        {!isLastStep && renderSaveButton()}
        {renderBackButton()}
        {isLastStep ? renderSaveButton() : renderNextButton()}
      </Container>
    )
  }

  return ( // new
    <Container>
      {renderBackButton()}      
      {isLastStep ? renderCreateButton() : renderNextButton()}
    </Container>
  )
}

ButtonStepper.propTypes = {
  complex: PropTypes.bool,
  activeStep: PropTypes.number.isRequired,
  internalStep: PropTypes.number,
  setInternalStep: PropTypes.func,
  action: PropTypes.string,
  handleSave: PropTypes.func,
  disabledNextButton: PropTypes.bool,
  disabledSaveButton: PropTypes.bool,
}
