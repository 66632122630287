import { FETCH } from '../fetch'
import { quoteRoutes } from '../routes/quote'

export class Quote {
  async createLtl (payload) {
    const response = await FETCH('post', quoteRoutes.ltl, payload)
    return response
  }

  async createTl (payload) {
    const response = await FETCH('post', quoteRoutes.tl, payload)
    return response
  }

  async saveRate (payload) {
    const response = await FETCH('put', quoteRoutes.saveRate, payload)
    return response
  }

  async getTl (data) {
    let response
    const { quoteId, ...payload } = data || {}
    if (quoteId) {
      response = await FETCH('get', quoteRoutes.getTlById.replace(':quoteId', quoteId), payload)
    } else {
      response = await FETCH('get', quoteRoutes.tl, payload)
    }
    return response
  }

  async getAll (payload) {
    const response = await FETCH('get', quoteRoutes.getAll, payload)
    return response
  }

  async getProducts () {
    const response = await FETCH('get', quoteRoutes.ltlProducts)
    return response
  }

  async setTlRate (data) {
    const { quoteId, ...payload } = data
    const response = await FETCH('put', quoteRoutes.setTlRate.replace(':quoteId', quoteId), payload)
    return response
  }

  async setTlMessage (payload, quoteId) {
    const response = await FETCH('post', quoteRoutes.setTlMessage.replace(':quoteId', quoteId), payload)
    return response
  }

  async reQuote (quoteId) {
    const response = await FETCH('put', quoteRoutes.updateRatesListLtl.replace(':quoteId', quoteId))
    return response
  }

  async setRejectSurvey (payload) {
    const response = await FETCH('patch', quoteRoutes.setRejectSurvey, payload)
    return response
  }

  async updateProductLtl (data) {
    const { _id, payload } = data
    const response = await FETCH('put', quoteRoutes.updateProductLtl.replace(':productId', _id), payload)
    return response
  }

  async updateTlMarket (data) {
    const response = await FETCH('put', quoteRoutes.updateTlMarket, data)
    return response
  }

  async reSendTl (quoteId) {
    const response = await FETCH('get', quoteRoutes.resend.replace(':quoteId', quoteId))
    return response
  }

  async returnTl (quoteId) {
    const response = await FETCH('get', quoteRoutes.return.replace(':quoteId', quoteId))
    return response
  }

  async putTl (data) {
    const { quoteId, ...payload } = data || {}
    const response = await FETCH('put', quoteRoutes.getTlById.replace(':quoteId', quoteId), payload)
    return response
  }

  async assignQuoteToASales (quoteId) {
    const response = await FETCH('PUT', quoteRoutes.assignQuoteToASales.replace(':quoteId', quoteId))
    return response
  }
}
