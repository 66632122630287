//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

//  styles
import { useStyles } from './styles'

function DialogModal ({ open, title, body, okText, cancelText, onOk, onCancel }) {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.paper }}>
      {title && (
        <DialogTitle>
          <Typography className={classes.dialogTitle}>{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText classes={{ root: classes.textColor }}>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button onClick={onCancel} color='primary' variant='contained' className={classes.cancelButton}>
            {cancelText}
          </Button>
        )}
        <Button onClick={onOk} color='primary' variant='contained' className={classes.okButton} autoFocus>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  body: PropTypes.node.isRequired,
  okText: PropTypes.string.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  cancelText: PropTypes.string,
}

export default DialogModal
