import TYPES from './types'

export const loginRequest = (payload, callback) => ({
  type: TYPES.USER.LOGIN.FETCH.REQUEST,
  payload,
  callback,
})

export const loginSucceeded = payload => ({
  type: TYPES.USER.LOGIN.FETCH.SUCCEEDED,
  payload,
})

export const loginFailed = payload => ({
  type: TYPES.USER.LOGIN.FETCH.FAILED,
  payload,
})

export const afterFirstLoginRequest = (payload) => ({
  type: TYPES.USER.AFTER_FIRST_LOGIN.FETCH.REQUEST,
  payload,
})

export const afterFirstLoginSucceeded = payload => ({
  type: TYPES.USER.AFTER_FIRST_LOGIN.FETCH.SUCCEEDED,
  payload,
})

export const afterFirstLoginFailed = payload => ({
  type: TYPES.USER.AFTER_FIRST_LOGIN.FETCH.FAILED,
  payload,
})

export const forgotPasswordRequest = (payload, callback) => ({
  type: TYPES.USER.FORGOT_PASSWORD.FETCH.REQUEST,
  payload,
  callback,
})

export const forgotPasswordSucceeded = payload => ({
  type: TYPES.USER.FORGOT_PASSWORD.FETCH.SUCCEEDED,
  payload,
})

export const forgotPasswordFailed = payload => ({
  type: TYPES.USER.FORGOT_PASSWORD.FETCH.FAILED,
  payload,
})

export const resetPasswordRequest = (payload, callback) => ({
  type: TYPES.USER.RESET_PASSWORD.FETCH.REQUEST,
  payload,
  callback,
})

export const resetPasswordSucceeded = payload => ({
  type: TYPES.USER.RESET_PASSWORD.FETCH.SUCCEEDED,
  payload,
})

export const resetPasswordFailed = payload => ({
  type: TYPES.USER.RESET_PASSWORD.FETCH.FAILED,
  payload,
})

export const activeRequest = (payload, callback) => ({
  type: TYPES.USER.ACTIVATE.FETCH.REQUEST,
  payload,
  callback,
})

export const activeSucceeded = payload => ({
  type: TYPES.USER.ACTIVATE.FETCH.SUCCEEDED,
  payload,
})

export const activeFailed = payload => ({
  type: TYPES.USER.ACTIVATE.FETCH.FAILED,
  payload,
})

export const companiesRequest = () => ({
  type: TYPES.USER.COMPANIES.FETCH.REQUEST,
})

export const resendActivationEmailSucceeded = payload => ({
  type: TYPES.USER.RESEND_ACTIVATION_EMAIL.FETCH.SUCCEEDED,
  payload,
})

export const resendActivationEmailFailed = payload => ({
  type: TYPES.USER.RESEND_ACTIVATION_EMAIL.FETCH.FAILED,
  payload,
})

export const resendActivationEmailRequest = (payload, callback) => ({
  type: TYPES.USER.RESEND_ACTIVATION_EMAIL.FETCH.REQUEST,
  payload,
  callback,
})

export const companiesSucceeded = payload => ({
  type: TYPES.USER.COMPANIES.FETCH.SUCCEEDED,
  payload,
})

export const companiesFailed = payload => ({
  type: TYPES.USER.COMPANIES.FETCH.FAILED,
  payload,
})

export const getKillSwitch = () => ({
  type: TYPES.USER.GET_KILLSWITCH.FETCH.REQUEST
})

export const getKillSwitchSucceded = payload => ({
  type: TYPES.USER.GET_KILLSWITCH.FETCH.SUCCEEDED,
  payload
})

export const getKillSwitchFailed = payload => ({
  type: TYPES.USER.GET_KILLSWITCH.FETCH.FAILED,
  payload
})

export const updateKillSwitch = () => ({
  type: TYPES.USER.UPDATE_KILLSWITCH.FETCH.REQUEST
})

export const updateKillSwitchSucceded = payload => ({
  type: TYPES.USER.UPDATE_KILLSWITCH.FETCH.SUCCEEDED,
  payload
})

export const updateKillSwitchFailed = payload => ({
  type: TYPES.USER.UPDATE_KILLSWITCH.FETCH.FAILED,
  payload
})


/**
 * GENERAL
 */

export const userUpdate = payload => ({
  type: TYPES.USER.UPDATE,
  payload,
})

export const cleanErrors = () => ({
  type: TYPES.USER.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.USER.CLEAN.STATE,
})
