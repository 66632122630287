import styled, { css } from 'styled-components'

export const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 31px 64px 34px 28px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid ${theme.palette.common.second6};
    border-radius: 4px;
  `}
`
