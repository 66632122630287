import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '90%',
    position: 'absolute',
    height: '88%',
    maxWidth: '1050px',
  },
  styledTab: {
    '& span': {
      height: 10,
    },
    backgroundColor: 'white',
  },
  styledTabItem: {
    height: 74,

  },
  styledIcon: {
    position: 'absolute',
    right: 0,
    '&:hover': {
      backgroundColor: 'white',
    },
    fontSize: 30,
    padding: 0,
  },
  textEdit: {
    padding: '29px 263px 27px 263px',
    fontSize: 26,
    fontWeight: 500,
    borderBottom: 'solid 2px rgb(203, 214, 226, 0.7) ',
  },
  formContainer: {
    height: 'calc( 100% - 100px )',
  },
}))
