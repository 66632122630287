//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'

//  components
import FormInputs from 'components/common/formInputs'
import CustomToolbar from 'components/common/customToolbar'

//  hooks
import { useFormContext, Controller } from 'react-hook-form'

//  styles
import { useStyles, Root, Header, Content, Item, Row } from './styles'
import { AiFillClockCircle } from 'react-icons/ai'

export default function EstimationForm1 ({ disabled, miles }) {
  const classes = useStyles()
  const { errors, control } = useFormContext()

  return (
    <Root>
      <Header>
        <Typography variant='h5' className={classes.title} align='center'>
          Scheduled Departure Date
        </Typography>
        <Typography variant='h5' className={classes.title} align='center'>
          Days in Transit
        </Typography>
        <Typography variant='h5' className={classes.title} align='center'>
          Expiration Date
        </Typography>
      </Header>
      <Content>
        <Row>
          <Item>
            <Controller
              as={
                <KeyboardDatePicker
                  autoOk
                  disablePast={!disabled}
                  disabled={disabled}
                  style={{ width: '9rem' }}
                  variant='inline'
                  inputVariant='outlined'
                  format='MM-DD-YYYY'
                  placeholder='MM-DD-YYYY'
                  className={classNames(classes.picker, classes.marginRight10)}
                  KeyboardButtonProps={{
                    className: classes.pickerButton,
                  }}
                />
              }
              control={control}
              name='pickupDate'
              error={!!errors.pickupDate}
            />
            <FormHelperText className={classes.error} error={!!errors.pickupDate}>
              {errors.pickupDate?.message}
            </FormHelperText>
          </Item>
          <Item>
            <Controller
              as={
                <KeyboardTimePicker
                  autoOk
                  disabled={disabled}
                  style={{ width: '8rem' }}
                  variant='inline'
                  inputVariant='outlined'
                  placeholder='HH:MM'
                  className={classes.picker}
                  KeyboardButtonProps={{
                    className: classes.pickerButton,
                  }}
                  keyboardIcon={<AiFillClockCircle />}
                  ampm={false}
                  ToolbarComponent={CustomToolbar}
                />
              }
              control={control}
              name='pickupTime'
              error={!!errors.pickupTime}
            />
            <FormHelperText className={classes.error} error={!!errors.pickupTime}>
              {errors.pickupTime?.message}
            </FormHelperText>
          </Item>
        </Row>
        <Item>
          <FormInputs.InputMask
            disabled={disabled}
            name='daysInTransit'
            isControlled
            control={control}
            variant='outlined'
            mask='99'
            showError
            error={errors.daysInTransit}
            classNameError={classes.error}
          />
          {miles && <span>Distance: <b>{Number(miles).toLocaleString('en-US')}</b> miles</span>}
        </Item>
        <Item>
          <Controller
            as={
              <KeyboardDatePicker
                autoOk
                disablePast={!disabled}
                disabled={disabled}
                style={{ width: '9rem' }}
                variant='inline'
                inputVariant='outlined'
                format='MM-DD-YYYY'
                placeholder='MM-DD-YYYY'
                className={classes.picker}
                KeyboardButtonProps={{
                  className: classes.pickerButton,
                }}
              />
            }
            control={control}
            name='expirationDate'
            error={!!errors.expirationDate}
          />
        </Item>
      </Content>
    </Root>
  )
}

EstimationForm1.propTypes = {
  disabled: PropTypes.bool,
  miles: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
