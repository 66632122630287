import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  styledGrid: {
    borderColor: '#bdbdbd',
    border: '1px solid',
    borderRadius: 4,
    marginBottom: 10,
    padding: theme.spacing(2),
    maxHeight: '11rem',
  },
  selected: {
    background: '#CECECE',
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  styledGridItem: {
    padding: theme.spacing(1),
  },
  styledTitle: {
    color: '#4B2367',
    fontWeight: 'bold',
    padding: 0,
  },
  styledTitleUnselected: {
    color: '#222222',
    fontWeight: 'bold',
    padding: 0,
  },
  totalWeight: {
    fontSize: 14,
    marginBottom: 2,
    color: theme.palette.common.second3,
    opacity: '60%',
  },
  weight: {
    fontSize: 20,
    color: '#4B2367',
  },
  weightUnselected: {
    fontSize: 20,
    color: theme.palette.common.second1,
  },
  styledGridFirstItem: {
    float: 'left',
  },
  descriptionItems: {
    fontSize: 16,
    color: theme.palette.common.second3,
    opacity: '60%',
  },
  styledGridFirstItemValue: {
    clear: 'left',
    width: 48.03,
  },
  styledGridItemValue: {
    fontSize: 18,
    color: theme.palette.common.second3,
    opacity: '60%',
  },
  styledButtonProducts: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: '2px',
    backgroundColor: '#cbd6e2',
    '&:hover': {
      background: '#4b2367',
    },
    '& .MuiButton-label': {
      width: '164%',
    },
  },
  quantity: {
    margin: '0px 26px',
    color: '#4B2367',
    fontSize: 20,
  },
  quantityUnselected: {
    margin: '0px 26px',
    color: theme.palette.common.second1,
    fontSize: 20,
  },
  icon: {
    '& path': {
      color: 'white',
      width: '100%',
    },
  },
}))
