import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles((theme) => {
  return ({
    container: {
      display: 'grid',
      justifyItems: 'center',
      alignItems: 'center',
      height: '100vh',
      gridTemplateRows: '40% 60%',
      width: '100%',
    },
  })
})

export const ImageContainer = styled.div`
  margin-top: 7%;
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    width: 100%;
    height: 100%;
    justify-items: center;
    padding-top: 50px;
    background: ${theme.palette.primary.main};
  `}

`
