import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  label: {
    color: '#637280',
    fontSize: 18,
    fontWeight: 'normal',
    marginRight: '15px',
  },
}))

export const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`

export const Company = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
`

export const customStyles = {
  menu: (base) => ({
    ...base,
    zIndex: '1200',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => {
    return {
      ...base,
      width: '320px',
      borderWith: '2px',
      borderStyle: 'solid',
      borderColor: state.isFocused ? '#8D8E90' : '#979797',
      boxShadow: 'none',
      '&:hover': {
        border: '2px solid #8D8E90',
      },
      height: '40px',
    }
  },
}
