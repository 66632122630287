import TYPES from './types'
import { resolve } from 'tools'

export function fetchPost (fetchPath, data, statePath = '', callback = () => {}, additionalData = {}) {
  return (dispatch, getState, api) => {
    dispatch(fetchRequest())
    let payload = data || resolve(statePath, getState())
    payload = Object.keys(additionalData).length > 0 ? { ...additionalData, ...payload } : payload
    return resolve(fetchPath, api)(payload).then(
      ({ data }) => {
        dispatch(fetchSucceeded(data))
        callback(data)
      },
      (error) => {
        const newError = error.response?.data || { error: error.message }
        dispatch(fetchFailed(newError))
      },
    )
  }
}

export const fetchRequest = payload => ({
  type: TYPES.POST_DATA.FETCH.REQUEST,
  payload,
})

export const fetchSucceeded = payload => ({
  type: TYPES.POST_DATA.FETCH.SUCCEEDED,
  payload,
})

export const fetchFailed = payload => ({
  type: TYPES.POST_DATA.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.POST_DATA.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.POST_DATA.CLEAN.STATE,
})
