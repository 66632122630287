//  libraries
import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'

// constants
import { filters as filtersList, advancedFilters } from './constants/filters'
import TypeFilter from './components/typeFilter'
import AdvancedFilters from './components/dropDownFilters'

// hooks
import { useDispatch, useSelector } from 'react-redux'

//  redux
import { filtersUpdate } from 'redux/ducks/filters'

//  styles
import { Root, FiltersContainer, FilterElement, useStyles } from './styles'
import { globalTableOptions } from 'styles/globalTableStyles'

function FilterBar ({ filters = [], thunkAction = null, searchHandler = null, propFilters = {}, addTypeFilter = false }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const reduxFilters = useSelector(state => state.ui.filters)
  const [isLoading, setIsLoading] = useState(false)
  const [filtersValues, setFiltersValues] = useState({ ...propFilters, ...reduxFilters })
  const addAdvancedFilters = filters.some(filterText => advancedFilters[filterText] !== undefined)

  const filterHandler = ({ key, value }) => {
    setFiltersValues((currentValues) => ({ ...currentValues, [key]: value }))
  }

  const onSearch = () => {
    setIsLoading(true)
    const paginationParams = { limit: globalTableOptions.pageSize, page: 1 }
    dispatch(filtersUpdate(filtersValues))
    const filterParams = {
      params: { ...paginationParams, ...filtersValues },
    }
    console.log(filterParams)
    ReactGA.event({
      category: 'Search',
      action: window.location.pathname,
      label: filterParams.params.searchTerm || filterParams.params.status,
    })
    if (thunkAction) {
      dispatch(thunkAction(
        filterParams,
        () => setIsLoading(false),
      ))
    }
    if (searchHandler) {
      searchHandler(filterParams, setIsLoading)
    }
  }

  useEffect(() => {
    setFiltersValues((currentValues) => ({ ...currentValues, ...reduxFilters }))
  }, [reduxFilters])

  if (!filters.length) return null

  return (
    <Root>
      {/* {addTypeFilter && <TypeFilter />} */}
      <FiltersContainer>
        {filters.map(filterType => {
          const { Component, name } = filtersList[filterType] || {}
          if (!Component) return null
          return (
            <FilterElement key={filterType}>
              <Component
                value={filtersValues[name]}
                filtersValues={filtersValues}
                cbHandler={filterHandler}
                onSearchEvent={onSearch}
              />
            </FilterElement>
          )
        })}
        {addAdvancedFilters && (
          <AdvancedFilters
            filtersList={filters}
            cbHandler={filterHandler}
            filtersValuesState={filtersValues}
            setFiltersValuesState={setFiltersValues}
          />
        )}
      </FiltersContainer>
      <Button
        disabled={isLoading}
        className={classes.button}
        variant='contained'
        onClick={onSearch}
      >
        Apply
        {isLoading && <CircularProgress size={30} color='primary' className={classes.spinner} />}
      </Button>
    </Root>
  )
}

FilterBar.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  thunkAction: PropTypes.func,
  propFilters: PropTypes.object,
  addTypeFilter: PropTypes.bool,
}

export default FilterBar
