//  libraries
import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { quoteListFetch, cleanState } from 'redux/ducks/quoteList'

// tools
import { FETCH } from 'API/fetch'
import { quoteRoutes } from 'API/routes/quote'
import { dateFormat } from 'tools'

// styles
import { tableStyles, useStyles } from './styles'
import { globalTableOptions } from 'styles/globalTableStyles'
import { tableIcons } from 'styles/tableIcons'
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

// components
import FilterBar from 'components/common/filterBar'
import LtlQuoteDetail from 'components/quote/common/ltlDetail'
import FormattedCell from 'components/common/tableFragments/cells/formattedCell'
import StatusTD from 'components/common/tableFragments/cells/statusTD'
import ContainerTable from 'components/common/tableFragments/containerTable'
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'

function LtlQuoteList () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const role = useSelector(state => state.session.data.role)
  const isAdmin = role.toLowerCase() === 'tucker'
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const { data = [], page = 0, totalCount = 0, loading = true } = useSelector(state => state.entities.quoteList.ltl)
  const filters = useSelector(state => state.ui.filters)

  useEffect(() => {
    dispatch(quoteListFetch('Ltl', { params: { limit, page: 1, ...filters } }))
    return () => {
      dispatch(cleanState())
    }
    //  eslint-disable-next-line
  }, [])

  return (
    <>
      <FilterBar
        filters={['searchBar', 'status', 'creationDate', 'priceRange']}
        module='quote'
        thunkAction={(data, callback) => quoteListFetch('Ltl', data, null, callback)}
        addTypeFilter
      />
      <MaterialTable
        isLoading={loading}
        data={data}
        icons={tableIcons}
        columns={[
          {
            title: 'Reference',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <Typography align='center' className={classes.reference}>#{rowData.refId}</Typography>,
          },
          {
            title: 'Status',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <StatusTD status={rowData.status} remainingTime={rowData.remainingTime} />,
          },
          {
            title: 'Creation Date',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.quoteDate?.date)}
                time={dateFormat(rowData.quoteDate?.date, 'HH:mm')}
              />
            ),
          },
          {
            title: 'Origin',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundUp className={classes.originIcon} />}
                mainText={rowData.origin?.city}
                secondMainText={rowData.origin?.state}
                secondaryText={rowData.origin?.postalCode}
              />
            ),
          },
          {
            title: 'Destination',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundDown className={classes.destinationIcon} />}
                mainText={rowData.destination?.city}
                secondMainText={rowData.destination?.state}
                secondaryText={rowData.destination?.postalCode}
              />
            ),
          },
          {
            title: 'Quantity',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <Typography align='center' className={classes.primaryColor}>{rowData.totalQuantity} Units</Typography>,
          },
          {
            title: 'Weight',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <Typography align='center' className={classes.primaryColor}>{rowData.totalWeight} Lb</Typography>,
          },
          {
            title: 'Carrier Price',
            hidden: !isAdmin,
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <Typography align='center' className={classes.primaryColor}>$ {rowData.carrierPrice} USD</Typography>,
          },
          {
            title: 'Customer Price',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <Typography align='center' className={classes.customerPrice}>${rowData.customerPrice}</Typography>,
          },
        ]}
        detailPanel={[{
          icon: () => <MdKeyboardArrowDown fontSize='1em' />,
          openIcon: () => <MdKeyboardArrowUp fontSize='1em' />,
          render: rowData => <LtlQuoteDetail rowData={rowData} />,
        }]}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              FETCH('DELETE', quoteRoutes.delete.replace(':quoteId', oldData.refId))
                .then(() => {
                  resolve(dispatch(quoteListFetch('Ltl', { params: { limit, page, ...filters } })))
                })
                .catch(error => {
                  reject(error)
                })
            }),
        }}
        components={{
          Pagination: (componentsProps) => {
            const setPage = page - 1 < 0 ? 0 : page - 1 // Avoid to assign negative value.
            return (
              <TablePagination
                {...componentsProps}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(quoteListFetch('Ltl', { params: { limit, page: page + 1, ...filters } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  componentsProps.onChangeRowsPerPage(evt)
                  dispatch(quoteListFetch('Ltl', { params: { limit: evt.target.value, page, ...filters } }))
                }}
              />
            )
          },
          Container: props => (
            <ContainerTable>
              {props.children}
            </ContainerTable>
          ),
        }}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No quotes to display',
          },
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </>
  )
}

export default LtlQuoteList
