import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core'

export const useStyles = makeStyles(() => (
  {
    expandButton: {
      width: '2rem',
      height: '2rem',
    },
  }
))

export const IndicatorTypo = withStyles(theme => ({
  root: {
    color: theme.palette.common.second3,
    fontSize: 16,
    margin: 3,
  },
}))(Typography)

export const StyledExpansionPanel = withStyles({
  root: {
    border: '1px solid #cbd6e2',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(ExpansionPanel)

export const StyledExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .01)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(ExpansionPanelSummary)

export const StyledExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}))(ExpansionPanelDetails)
