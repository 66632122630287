// libraries
import React from 'react'
import { TextField } from '@material-ui/core'

// components
import InputElement from './inputElement'

// styles
import { Mask } from './styles'

/**
 * Inputs dictionary
 */
export default {
  Input: ({ error, name = 'textInput', ...rest }) => (
    <InputElement
      error={error}
      name={name}
      render={props => (
        <TextField
          error={!!error}
          variant='outlined'
          {...props}
        />
      )}
      {...rest}
    />
  ),
  InputMask: ({ error, name = 'maskInput', cbHandler = () => {}, ...rest }) => (
    <InputElement
      error={error}
      name={name}
      render={({ value, disabled = false, mask = '9999999', maskChar = '', ...props }) => (
        <Mask
          onChange={function (e) { return cbHandler({ value: e.target.value }, ...arguments) }}
          value={value}
          mask={mask}
          maskChar={maskChar}
          alwaysShowMask={false}
          disabled={disabled}
        >
          {() => <TextField error={!!error} disabled={disabled} {...props} />}
        </Mask>
      )}
      {...rest}
    />
  ),
}
