import { globalTableStyles } from 'styles/globalTableStyles'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.second1,
    fontWeight: 400,
  },
  text2: {
    color: theme.palette.common.second3,
    fontWeight: 400,
  },
  document: {
    color: theme.palette.common.second1,
    fontWeight: 400,
    cursor: 'pointer',
  },
}))

export const tableStyles = {
  ...globalTableStyles,
}

export const Container = styled.div`
  width: 80%;
  max-width: 600px;
  height: 80%;
  background-color: #FFFFFF;
  border-radius: 6px;
  margin: 90px auto;
`
