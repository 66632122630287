import baseModel from 'redux/baseModel'

export default {
  ...baseModel,

  setLocationList: (state, data = []) => {
    return {
      ...state,
      loading: false,
      errors: [],
      data: data.map((location) => ({
        value: location.contactInfo.companyName,
        label: location.contactInfo.companyName,
        ...location,
      })),
    }
  },

  deleteLocation: (state, locationId) => {
    return {
      ...state,
      loading: false,
      errors: [],
      data: state.data.filter(location => location._id !== locationId),
    }
  },

  updateLocation: (state, updatedLocation) => {
    const data = state.data.slice()
    const index = data.findIndex(location => location._id === updatedLocation._id)
    data[index] = updatedLocation
    return {
      ...state,
      loading: false,
      errors: [],
      data: data,
    }
  },
}
