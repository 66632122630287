import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  heading: {
    marginLeft: 24,
    color: theme.palette.primary.main,
    fontWeight: 400,
    lineHeight: 'normal',
    fontSize: 24,
  },
  text: {
    color: theme.palette.common.second3,
    fontWeight: 400,
    lineHeight: 'normal',
    fontSize: 24,
    margin: '20px 0px',
  },
}))

export const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding-top: 31px;
    display: flex;
    flex-flow: column;
    border: 1px solid ${theme.palette.common.second6};
    border-radius: 4px;
  `}
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  padding-left: 28px;
`
