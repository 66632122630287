const nameAbbreviation = (text = '') => {
  let result = text.toUpperCase()
  const regex = '\\b[a-zA-Z0-9]'
  const letters = Array.from(result.matchAll(regex), m => m[0])

  if (letters.length > 1) result = letters
  return result.slice(0, 3)
}

export default nameAbbreviation
