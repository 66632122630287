//  libraries
import React from 'react'
import PropTypes from 'prop-types'

//  styles
import { Image } from './styles'

const ImageNotDraggable = ({ className, externalImage, imageKey, alt, ...rest }) => {
  return (
    <Image
      className={className || ''}
      src={externalImage || imageKey}
      alt={alt}
      draggable={false}
      {...rest}
    />
  )
}

ImageNotDraggable.propTypes = {
  className: PropTypes.string,
  externalImage: PropTypes.string,
  imageKey: PropTypes.string,
  alt: PropTypes.string,
}

export default ImageNotDraggable
