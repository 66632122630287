//  libraries
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

//  components
import Loader from 'components/common/loader'
import NotFound from 'components/common/notFound'
import Details from 'components/shipment/common/details'
import BackButton from 'components/common/backButton'
import { Email } from 'components/common/forms'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

//  redux
import { ShipmentDetailsTlRequest, ShipmentDetailsLtlRequest, cleanState } from 'redux/ducks/shipmentDetails'
import { newFormLoadFromShipmentLtl, newFormLoadFromShipmentTl } from 'redux/ducks/newForm'
import { companySelectorUpdate } from 'redux/ducks/companySelector'

//  styles
import { useStyles, Container, Top, Buttons, Title, Content } from './styles'

export default function ShipmentDetails () {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { removeToast } = useToasts()
  const { type, id: shipmentId } = useParams()
  const { companyParent, companyChild, ...data } = useSelector(state => state.entities.shipmentDetails.data)
  const { loading, success } = useSelector(state => state.entities.shipmentDetails)
  const [open, setOpen] = useState(false)
  
  useEffect(() => { 
    if (type === 'tl') {
      dispatch(ShipmentDetailsTlRequest({ shipmentId }))
    } else {
      dispatch(ShipmentDetailsLtlRequest({ shipmentId }))
    }
    return () => {
      dispatch(cleanState())
      removeToast('success-update-shipment')
    }
    //  eslint-disable-next-line
  }, [])

  const editShipment = () => {
    if (type === 'tl') {
      dispatch(newFormLoadFromShipmentTl(data))
    } else {
      dispatch(newFormLoadFromShipmentLtl(data))
    }
    dispatch(companySelectorUpdate({ companyParent, companyChild: companyChild || companyParent }))
    history.push(`/shipment/edit/${type}`)
  }

  const handleModal = (value) => () => {
    setOpen(value)
  }

  if (loading) return <Loader />

  if (!success) return <NotFound />

  return (
    <Container>
      <Content>
        <Top>
          <BackButton to={`/shipment/list/${type}`} />
          <Buttons>
            <Button
              variant='contained'
              classes={{ root: classes.button, disabled: classes.disabled }}
              onClick={editShipment}
              disabled={data.general?.status !== 'In Review'}
            >
              Edit
            </Button>
            <Button
              variant='contained'
              classes={{ root: classes.button, disabled: classes.disabled }}
              onClick={handleModal(true)}
            >
              Support
            </Button>
          </Buttons>
        </Top>
        <Title>
          <Typography variant='subtitle1' className={classes.title}>
            Shipment PRO ( {data.mlShipmentId} )
          </Typography>
        </Title>
        <Details />
      </Content>
      <Email
        open={open}
        handleModal={handleModal}
        email={data.contactInfo.email}
        subject={`PRO # ${data.mlShipmentId}`}
        mlShipmentId={data.mlShipmentId}
        shipmentId={shipmentId}
      />
    </Container>
  )
}
