import TYPES from './types'

export const CompanyInfoRequest = (payload, callback) => ({
  type: TYPES.COMPANY_INFO.FETCH.REQUEST,
  payload,
  callback,
})

export const CompanyInfoSucceeded = payload => ({
  type: TYPES.COMPANY_INFO.FETCH.SUCCEEDED,
  payload,
})

export const CompanyInfoFailed = payload => ({
  type: TYPES.COMPANY_INFO.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.COMPANY_INFO.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.COMPANY_INFO.CLEAN.STATE,
})
