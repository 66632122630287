//  libraries
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { locationButtonsUpdate } from 'redux/ducks/locationButtons'
import { locationDeleteRequest, locationSaveRequest, locationUpdateRequest } from 'redux/ducks/locations'

//  styles
import { useStyles } from './styles'

function LocationButtons ({ location }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addToast, removeToast } = useToasts()
  const { trigger, getValues, setValue, clearErrors } = useFormContext()
  const companyId = useSelector(state => state.ui.companySelector.companyChild.value)
  const disabledForm = useSelector(state => state.newForm.disabled)
  const {
    showSave,
    showUpdate,
    showDelete,
    idLocation,
  } = useSelector(state => state.ui.locationButtons[location])
  const [locationLoading, setLocationLoading] = useState(false)

  useEffect(() => {
    return () => {
      removeToast('error-location')
      removeToast('success-location')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLocationValues = () => {
    const { city, contact, country, state, zip: postalCode } = getValues({ nest: true })[location]
    const {
      address,
      address2,
      company: companyName,
      mail: email,
      name: contactName,
      phone: phoneNumber,
    } = contact

    const locationValues = {
      postalCode,
      city,
      state,
      country,
      company: companyId,
      contactInfo: {
        companyName,
        contactName,
        addressLines: [address, address2],
        phoneNumber,
        email,
      },
    }

    return locationValues
  }

  const locationFormIsValid = async () => {
    const formElements = [
      `${location}.zip`,
      `${location}.city`,
      `${location}.state`,
      `${location}.country`,
      `${location}.contact.company`,
      `${location}.contact.address`,
      `${location}.contact.address2`,
      `${location}.contact.name`,
      `${location}.contact.phone`,
      `${location}.contact.mail`,
    ]
    const validatedElements = await trigger(formElements)
    return validatedElements
  }

  const errorCallback = () => {
    errorToastFeedback()
    setLocationLoading(false)
  }

  // ---------- toasts notifications ----------
  const errorToastFeedback = () => {
    const message = 'In this moment this function is not available. Please try later or contact us.'
    addToast(message, {
      appearance: 'error',
      autoDismiss: true,
      id: 'error-location',
    })
  }

  const successToastFeedback = (message) => {
    addToast(message, {
      appearance: 'success',
      autoDismiss: true,
      id: 'success-location',
    })
  }

  // ---------- handles ----------
  const handleSave = async () => {
    if (!await locationFormIsValid()) return
    setLocationLoading(true)
    const payload = getLocationValues()
    dispatch(locationSaveRequest(
      payload,
      (newLocationId) => { // success callback
        successToastFeedback('Location saved!')
        dispatch(locationButtonsUpdate({
          [location]: {
            idLocation: newLocationId,
            showSave: false,
            showUpdate: true,
            showDelete: true,
          },
        }))
        setLocationLoading(false)
      },
      errorCallback,
    ))
  }

  const handleUpdate = async () => {
    if (!await locationFormIsValid()) return
    setLocationLoading(true)
    const payload = getLocationValues()
    dispatch(locationUpdateRequest(
      idLocation,
      payload,
      () => { // success callback
        successToastFeedback('Location updated!')
        setLocationLoading(false)
        dispatch(locationButtonsUpdate({
          [location]: {
            idLocation: idLocation,
            showSave: false,
            showUpdate: false,
            showDelete: true,
          },
        }))
      },
      errorCallback,
    ))
  }

  const handleDelete = () => {
    setLocationLoading(true)
    dispatch(locationDeleteRequest(
      idLocation,
      () => { // success callback
        successToastFeedback('Location deleted!')
        dispatch(locationButtonsUpdate({
          [location]: {
            idLocation: null,
            showSave: true,
            showUpdate: false,
            showDelete: false,
          },
        }))
        clearInputs()
        setLocationLoading(false)
      },
      errorCallback,
    ))
  }

  const clearInputs = () => {
    if (!disabledForm) {
      setValue(`${location}.zip`, '')
      setValue(`${location}.country`, '')
      setValue(`${location}.state`, '')
      setValue(`${location}.city`, '')
    }
    setValue(`${location}.contact.company`, '')
    setValue(`${location}.contact.address`, '')
    setValue(`${location}.contact.address2`, '')
    setValue(`${location}.contact.name`, '')
    setValue(`${location}.contact.phone`, '')
    setValue(`${location}.contact.mail`, '')

    clearErrors([
      `${location}.zip`,
      `${location}.country`,
      `${location}.state`,
      `${location}.city`,
      `${location}.contact.company`,
      `${location}.contact.address`,
      `${location}.contact.address2`,
      `${location}.contact.name`,
      `${location}.contact.phone`,
      `${location}.contact.mail`,
    ])
  }

  return (
    <div className={classes.root}>
      {locationLoading && <LinearProgress className={classes.linearProgress} />}
      {showSave && (
        <Button
          onClick={handleSave}
          className={classes.locationButton}
          disabled={locationLoading}
          variant='contained'
          color='primary'
        >
          Save Location
        </Button>
      )}
      {showUpdate && (
        <Button
          onClick={handleUpdate}
          className={classes.locationButton}
          disabled={locationLoading}
          variant='contained'
          color='primary'
        >
          Update Location
        </Button>
      )}
      {showDelete && (
        <Button
          onClick={handleDelete}
          className={classes.locationButton}
          disabled={locationLoading}
          variant='contained'
          color='primary'
        >
          Delete Location
        </Button>
      )}
    </div>
  )
}

LocationButtons.propTypes = {
  location: PropTypes.string.isRequired,
}

export default LocationButtons
