import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  styledCardAddedProduct: {
    margin: 20,
    marginLeft: 0,
    marginTop: 0,
  },
  selectedProductStyle: {
    fontSize: 20,
    color: theme.palette.secondary1,
    margin: 0,
    display: 'inline-block',
    marginRight: 17,
  },
  styledIcon: {
    '&:hover': {
      backgroundColor: 'white',
    },
    fontSize: 30,
    padding: 0,
  },
  styledCard: {
    boxSizing: 'border-box',
    padding: '2%',
    border: '1px solid #cbd6e2',
    borderRadius: 4,
    maxWidth: 640,
    marginBottom: 50,
    marginRight: '4rem',
    height: 'max-content',
    minWidth: '27rem',
  },
  styledButton: {
    padding: 0,
    minWidth: 'unset',
  },
  subtitle: {
    fontSize: 13,
    color: '#979797',
    textAlign: 'center',
  },
  value: {
    fontSize: 16,
    color: '#979797',
  },
  isHighlight: {
    fontSize: 20,
    color: '#222222',
  },
  duoContainer: {
    maxHeight: '4rem',
    textAlign: 'center',
  },
}))
