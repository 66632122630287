import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'none',
    width: 140,
    height: 50,
    marginLeft: 31,
    marginTop: 15,
    color: theme.palette.common.second8,
    backgroundColor: '#290c3d',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  orange: {
    backgroundColor: theme.palette.common.orange,
    '&:hover': {
      backgroundColor: '#CC5500',
    },
  },
  disabled: {
    color: '#FFFFFF !important',
  },
}))

export const Container = styled.div`
  justify-self: end;
`
