import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    width: '100px',
    justifySelf: 'center',
    background: '#FFFFFF',
  },
  dots: {
    width: '100%',
    justifyContent: 'space-around',
  },
})
