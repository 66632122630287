import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'none',
    width: 140,
    height: 50,
    color: theme.palette.common.second8,
    backgroundColor: '#290c3d',
    marginLeft: 31,
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  backButton: {
    width: 192,
  },
  convertButton: {
    width: 200,
  },
  errorText: {
    fontSize: 12,
    color: '#fa2e2e',
    textAlign: 'right',
    marginTop: '0.5rem',
  },
}))

export const Root = styled.div`
  display: flex;
  justify-content: flex-end;
`
