//  libraries
import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'

//  components
import Type from './type'
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useFormContext, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  styles
import { dryVanIconActive } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'

export default function TransportType () {
  const classes = useStyles()
  const { control, watch } = useFormContext()
  const transport = useSelector(state => state.newForm.transport || 'dry-van')
  const watchTransport = watch('transport', transport)

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={dryVanIconActive} width='80px' />
        <Typography variant='h1' className={classes.heading}>
          Transport Type
        </Typography>
      </HeadingContainer>
      <Controller
        as={
          <RadioGroup className={classes.radioGroup}>
            <Type text='Dry Van' value='dry-van' selected={watchTransport} />
            <Type text='Reefer' value='reefer' selected={watchTransport} />
            <Type text='Flatbed' value='flatbed' selected={watchTransport} />
          </RadioGroup>
        }
        name='transport'
        defaultValue={transport}
        control={control}
        disabled
      />
    </Root>
  )
}
