//  libraries
import React from 'react'
import PropTypes from 'prop-types'

//  styles
import { useStyles, IconContainer, Circle } from './styles'
import { MdFiberManualRecord } from 'react-icons/md'

export default function CustomIcon ({ active, completed, delivered }) {
  const classes = useStyles({ delivered })

  return (
    <IconContainer>
      {
        active ? (
          <Circle>
            <MdFiberManualRecord className={classes.activeIcon} />
          </Circle>
        ) : (
          <MdFiberManualRecord className={completed ? classes.completeIcon : classes.icon} />
        )
      }
    </IconContainer>
  )
}

CustomIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
}
