export const customSelectStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => ({
    ...base,
    minWidth: '10rem',
    boxShadow: state.isFocused ? '0 0 0 1px #979797' : 'none',
    border: `1px solid ${state.isFocused ? '#979797' : '#CBD6E2'}`,
    '&:hover': {
      border: '1px solid #979797',
    },
  }),
  container: (base) => ({
    ...base,
    zIndex: 20,
  }),
}
