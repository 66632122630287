import keyMirror from 'keymirror-nested'

export default keyMirror({
  NEW_FORM: {
    UPDATE: null,
    DELETE: null,
    LOAD: {
      QUOTE: {
        LTL: null,
        TL: null,
      },
      RATE_LIST: null,
      SHIPMENT: {
        LTL: null,
        TL: null,
      },
      USER_LIST: null,
      COMPANY_LIST: null,
      SALES_DETAILS: null,
    },
  },
})
