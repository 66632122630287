//  libraries
import React from 'react'

//  components
import Info from './info'

//  styles
import { Root } from './styles'

export default function ShipperConsignee () {
  return (
    <Root>
      <Info name='shipper' />
      <Info name='consignee' />
    </Root>
  )
}
