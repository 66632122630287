//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  styles
import { useStyles } from './styles'
import { FaCircle } from 'react-icons/fa'

const statusColor = {
  Submitted: '#3C3737',
  'In Review': '#3C3737',
  Accepted: '#ffd062',
  Assigned: ' #b14eb5',
  'At Pick': '#2e9da3',
  'In Transit': '#8b84d7',
  'At Delivery': '#d77900',
  Delivered: '#482366',
  // Rated: 'gold', // removed by client request. See TMCP-758 ticket in Jira
  Canceled: 'black',
  Rejected: '#FFCC00',
}

export default function CustomizedSelect ({ status }) {
  const classes = useStyles()
  return (
    <Typography className={classes.typography}>
      <FaCircle color={statusColor[status]} className={classes.icon} /> {status}
    </Typography>
  )
}

CustomizedSelect.defaultProps = {
  status: 'inTransit',
}
