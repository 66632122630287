//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import AdditionalForm from './additionalForm'
import CheckBoxes from './checkboxes'

//  styles
import { listIcon } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'

export default function Step2 ({ name }) {
  const classes = useStyles()

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={listIcon} width='35px' />
        <Typography variant='h1' className={classes.heading}>
          Additional Shipping Information
        </Typography>
      </HeadingContainer>
      {name === 'pickup' && <CheckBoxes />}
      <AdditionalForm name={name} />
    </Root>
  )
}

Step2.propTypes = {
  name: PropTypes.string.isRequired,
}
