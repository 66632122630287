//  libraries
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

//  reducers
import primaryTab from 'redux/ducks/primaryTab'
import stepper from 'redux/ducks/stepper'
import user from 'redux/ducks/user'
import products from 'redux/ducks/products'
import carrierList from 'redux/ducks/carrierList'
import zipCodes from 'redux/ducks/zipCodes'
import modal from 'redux/ducks/modal'
import newForm from 'redux/ducks/newForm'
import filters from 'redux/ducks/filters'
import postData from 'redux/ducks/postData'
import quoteList from 'redux/ducks/quoteList'
import quoteDetails from 'redux/ducks/quoteDetails'
import shipmentList from 'redux/ducks/shipmentList'
import shipmentDetails from 'redux/ducks/shipmentDetails'
import companySelector from 'redux/ducks/companySelector'
import companyInfo from 'redux/ducks/companyInfo'
import accountingList from 'redux/ducks/accountingList'
import accountingInfo from 'redux/ducks/accountingInfo'
import locationButtons from 'redux/ducks/locationButtons'
import userList from 'redux/ducks/userList'
import companyList from 'redux/ducks/companyList'
import accounts from 'redux/ducks/accounts'
import notifications from 'redux/ducks/notifications'
import bol from 'redux/ducks/bol'
import mcLeodCompanies from 'redux/ducks/mcLeodCompanies'
import locations from 'redux/ducks/locations'

const persist = {
  key: 'app-root',
  storage,
  whitelist: ['newForm'],
}

const carrierListPersist = {
  key: 'app-carrierList',
  storage,
  blacklist: [],
}

const primaryTabPersist = {
  key: 'app-primaryTab',
  storage,
  blacklist: [],
}

const companySelectorPersist = {
  key: 'app-companySelector',
  storage,
  blacklist: [],
}

const bolPersist = {
  key: 'app-bol',
  storage,
  blacklist: [],
}

const locationButtonsPersist = {
  key: 'app-locationButtons',
  storage,
  blacklist: [],
}

const companyListPersist = {
  key: 'app-companyList',
  storage,
  blacklist: [],
}

const rootReducer = combineReducers({
  entities: combineReducers({
    products,
    carrierList: persistReducer(carrierListPersist, carrierList),
    zipCodes,
    postData,
    quoteList,
    quoteDetails,
    shipmentList,
    shipmentDetails,
    companyInfo,
    accountingList,
    accountingInfo,
    userList,
    companyList: (persistReducer(companyListPersist, companyList)),
    accounts,
    notifications,
    mcLeodCompanies,
    locations,
  }),
  newForm,
  ui: combineReducers({
    filters,
    primaryTab: persistReducer(primaryTabPersist, primaryTab),
    stepper,
    modal,
    companySelector: persistReducer(companySelectorPersist, companySelector),
    locationButtons: persistReducer(locationButtonsPersist, locationButtons),
    bol: persistReducer(bolPersist, bol),
  }),
  session: user,
})

export default persistReducer(persist, rootReducer)
