import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  subtitleAdvancedOption: {
    fontSize: 14,
    color: '#979797',
    marginBottom: 5,
  },
  priceLabel: {
    color: theme.palette.common.second1,
    fontSize: 16,
    margin: 0,
    fontWeight: 900,
  },
  markLabel: {
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 1,
  },
  input: {
    width: 107,
    margin: 0,
  },
}))
