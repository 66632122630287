import TYPES from './types'

export const carrierListQuoteRequest = (callback) => ({
  type: TYPES.CARRIER_LIST.FETCH.QUOTE_REQUEST,
  callback,
})

export const carrierListShipmentRequest = (callback) => ({
  type: TYPES.CARRIER_LIST.FETCH.SHIPMENT_REQUEST,
  callback,
})

export const carrierListSucceeded = payload => ({
  type: TYPES.CARRIER_LIST.FETCH.SUCCEEDED,
  payload,
})

export const carrierListFailed = payload => ({
  type: TYPES.CARRIER_LIST.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.CARRIER_LIST.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.CARRIER_LIST.CLEAN.STATE,
})
