import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'

// hooks
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// redux
import { newFormLoadFromQuoteTl } from 'redux/ducks/newForm'
import { quoteListSurveyFetch } from 'redux/ducks/quoteList'
import { companySelectorUpdate } from 'redux/ducks/companySelector'
import { modalCreatingShipment } from 'redux/ducks/modal'

// styles
import { useStyles } from './styles'

// components
import ShipmentRateDetail from 'components/common/tableFragments/shipmentRateDetail'
import ShipmentItemsTable from 'components/common/tableFragments/shipmentItemsTable'
import RejectSurvey from 'components/quote/common/rejectSurvey'
export default function TlDetail ({ rowData }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast, removeAllToasts } = useToasts()
  const { refId, status, rate, references = [], shipping, transportType, company } = rowData
  const disableAcceptButton = status.toLowerCase() !== 'active' || rate?.customerPrice === undefined
  const disableRejectButton = status.toLowerCase() !== 'expired' && disableAcceptButton
  const listReferences = references.map((item, ind) => `${item.ref}: ${item.value}`).join(', ')
  const [openSurvey, setOpenSurvey] = useState(false)

  useEffect(() => {
    return () => { removeAllToasts() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const OnConvertToShipment = () => {
    const { companyParent, companyChild, ...data } = rowData
    dispatch(modalCreatingShipment(true))
    dispatch(newFormLoadFromQuoteTl(data))
    dispatch(companySelectorUpdate({ companyParent, companyChild: companyChild || companyParent }))
    history.push('/shipment/convert/tl')
  }

  const onDeclineConfirmation = (refId, surveyData) => {
    const payload = {
      quoteId: refId,
      status: 'Rejected',
      survey: surveyData,
    }
    const successCallback = () => {
      addToast('Quote was declined', {
        appearance: 'success',
        autoDismiss: true,
        id: 'success-decline-quote-list',
      })
    }
    const errorCallback = () => {
      addToast('Fail declining quote', {
        appearance: 'error',
        autoDismiss: true,
        id: 'error-decline-quote-list',
      })
    }
    dispatch(quoteListSurveyFetch(payload, successCallback, errorCallback))
  }

  return (
    <div className={classes.container}>
      <div className={classes.firstRow}>
        <div>
          <Typography display='inline' className={classes.subtitle}>
            Company: <span className={classes.whiteBox}>{company?.name || '--'}</span>
          </Typography>
          <Typography display='inline' className={classes.subtitle}>
            Driver Type: <span className={classes.whiteBox}>{shipping?.driver.toUpperCase() || '--'}</span>
          </Typography>
          <Typography display='inline' className={classes.subtitle}>
            References: <span className={classes.whiteBox}>{listReferences || '--'}</span>
          </Typography>
          <Typography display='inline' className={classes.subtitle}>
            Equipment Type: <span className={classes.whiteBox}>{transportType?.replace(/-/, ' ') || '--'}</span>
          </Typography>
        </div>
        <div className={classes.contentButtons}>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='contained'
              size='small'
              disabled={disableRejectButton}
              onClick={disableRejectButton ? null : OnConvertToShipment}
              className={classes.accept}
            >
              Accept
            </Button>
            <Button
              size='small'
              onClick={disableAcceptButton ? null : () => setOpenSurvey(true)}
              disabled={disableAcceptButton}
              variant='contained'
              className={classes.decline}
            >
              Decline
            </Button>
          </Box>
        </div>
      </div>
      <div className={classes.mainContent}>
        <Card className={classes.card} style={{ width: '45%' }}>
          <CardContent className={classes.headTable}>Quote Details</CardContent>
          <ShipmentItemsTable itemsData={rowData} />
        </Card>
        <Card className={classes.card} style={{ width: '53%' }}>
          <CardContent className={classes.headTable}>Response</CardContent>
          <ShipmentRateDetail itemsData={rowData} />
        </Card>
      </div>
      <RejectSurvey
        onClose={() => setOpenSurvey(false)}
        open={openSurvey}
        onDeclineConfirmation={onDeclineConfirmation}
        refId={refId}
      />
    </div>
  )
}

TlDetail.propTypes = {
  rowData: PropTypes.shape({
    origin: PropTypes.object.isRequired,
    destination: PropTypes.object.isRequired,
    company: PropTypes.object,
    transportType: PropTypes.string.isRequired,
    rate: PropTypes.object,
  }),
}
