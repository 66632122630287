//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.NOTIFICATIONS.GET_LIST.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.NOTIFICATIONS.GET_LIST.FETCH.SUCCEEDED:
      return model.setData(state, action.payload)
    case TYPES.NOTIFICATIONS.GET_LIST.FETCH.FAILED:
      return model.error(state, action.payload)
    case TYPES.NOTIFICATIONS.ADD:
      return model.addNewNotification(state, action.payload)

    case TYPES.NOTIFICATIONS.UPDATE_READ.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.NOTIFICATIONS.UPDATE_READ.FETCH.SUCCEEDED:
      return model.updateReadNotifications(state, action.readNotifications)
    case TYPES.NOTIFICATIONS.UPDATE_READ.FETCH.FAILED:
      return model.error(state, action.payload)

    case TYPES.NOTIFICATIONS.UPDATE_DELETE.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.NOTIFICATIONS.UPDATE_DELETE.FETCH.SUCCEEDED:
      return model.deleteNotification(state, action.idNotification)
    case TYPES.NOTIFICATIONS.UPDATE_DELETE.FETCH.FAILED:
      return model.error(state, action.payload)

    case TYPES.NOTIFICATIONS.CLEAN.STATE:
      return { ...initialState }

    default:
      return state
  }
}
