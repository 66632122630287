//  libraries
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// hooks
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

// redux
import { fetchPost } from 'redux/ducks/postData'
import { newFormLoadFromRatesList } from 'redux/ducks/newForm'

// styles
import { useStyles } from './styles'
import { FaArrowLeft } from 'react-icons/fa'
import { documentIcon } from 'assets'

// components
import RatesTable from 'components/quote/common/ratesTable'
import ImageNotDraggable from 'components/common/imageNotDraggable'
import SummaryInformationLTLQuote from 'components/common/summaryInformationLTLQuote'
import MessageModal from 'components/common/messageModal'
import Loader from 'components/common/loader'

function RatesList () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const loading = useSelector(state => state.entities.postData.loading)
  const response = useSelector(state => state.entities.carrierList.data) || {}
  const newForm = useSelector(state => state.newForm)
  const { from, to, products, accessorials } = newForm
  if (from === undefined || to === undefined || products === undefined) {
    history.push('/quote/new/ltl')
  }
  const ratesData = response.successCarriers || []
  const totalWeight = products.reduce((prev, next) => {
    return (prev + (Number(next.weight) || Number(next.totalWeight))) || 0
  }, 0)

  const handleSaveQuote = () => {
    const data = {
      rateId: ratesData[selectedIndex]._id,
      quoteId: response.quoteId.toString(),
    }
    dispatch(fetchPost('quoteService.saveRate', data, null, () => {
      history.push('/quote/list/ltl')
    }))
  }

  const handleCreateShipment = () => {
    const data = { ...newForm, refId: response.refId }
    dispatch(newFormLoadFromRatesList(data))
    history.push('/shipment/new/ltl')
  }

  return (
    <Box p={4} px={7} bgcolor='white'>
      <Grid container justify='center' spacing={3}>
        <Grid item md={12}>
          <Link to='/quote/new/ltl'>
            <FaArrowLeft className={classes.backArrowLink} />
          </Link>
        </Grid>
        <Grid item container md={11} justify='flex-start'>
          <Typography variant='h1' className={classes.title}>Quote LTL #{response.refId || '...'}</Typography>
          <hr className={classes.hr} />
        </Grid>
        <Grid item md={10} className={classes.title}>
          <Box display='flex'>
            <ImageNotDraggable externalImage={documentIcon} width='23px' />
            <Typography variant='h3' className={classes.h3}>Summary information</Typography>
          </Box>
        </Grid>
        <Grid item md={9}>
          <SummaryInformationLTLQuote from={from} to={to} products={products} accessorials={accessorials} totalWeight={totalWeight} />
        </Grid>
        <Grid item md={10} className={classes.title}>
          <Box display='flex'>
            <ImageNotDraggable externalImage={documentIcon} width='23px' />
            <Typography variant='h3' className={classes.h3}>Select Rate</Typography>
          </Box>
        </Grid>
        <Grid item md={9}>
          <RatesTable ratesData={response.successCarriers} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </Grid>
        <Grid item md={10} className={classes.title}>
          <Box display='flex'>
            <ImageNotDraggable externalImage={documentIcon} width='23px' />
            <Typography variant='h3' className={classes.h3}>Error Rate</Typography>
          </Box>
        </Grid>
        <Grid item md={9}>
          <RatesTable ratesData={response.errorCarriers} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </Grid>
        <Grid item container md={11} justify='space-between' style={{ margin: '4rem 0' }}>
          <Link to='/quote/new/ltl' style={{ textDecoration: 'none' }}>
            <Button className={classes.backButton} variant='contained' color='primary'>
              Back to form
            </Button>
          </Link>
          <Box display='flex' justifyContent='space-between' width='25rem'>
            <Button
              className={classes.saveQuote}
              variant='contained'
              color='primary'
              onClick={handleSaveQuote}
              disabled={ratesData.length === 0}
            >
              Save Quote
            </Button>
            <Button
              className={classes.createShipment}
              variant='contained'
              color='primary'
              onClick={handleCreateShipment}
              disabled={ratesData.length === 0}
            >
              Create Shipment
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Loader open={loading} />
      <MessageModal path='entities.postData.errors' />
    </Box>
  )
}

export default RatesList
