import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  city: {
    color: '#222222',
    fontSize: 16,
  },
  state: {
    color: '#979797',
    fontSize: 16,
    marginTop: 3,
  },
  zipCode: {
    color: '#2b2b2b',
    fontSize: 14,
    fontStyle: 'italic',
    marginTop: 8,
  },
  // Lane styles
  icon: {
    color: theme.palette.primary.main,
    width: 42,
    height: 62,
    marginTop: '1rem, 1rem',
  },
}))
