import keyMirror from 'keymirror-nested'

export default keyMirror({
  LOCATIONS: {
    GET: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    DELETE: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    SAVE: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    UPDATE: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
  },
})
