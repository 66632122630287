//  libraries
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import Zoom from '@material-ui/core/Zoom'
import classNames from 'classnames'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { modalUpdate } from 'redux/ducks/modal'

//  utils
import { resolve } from 'tools'

//  styles
import { MdClear } from 'react-icons/md'
import { FiCheckCircle } from 'react-icons/fi'
import { useStyles } from './styles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function MessageModal({
  hideTitle,
  path = '',
  title = '',
  body,
  submitText,
  cancelText,
  errorText,
  handleCancelButton,
  handleSubmitButton,
  handleErrorButton,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const duck = path.split('.').slice(-2)[0]
  const errors = useSelector(state => resolve(path, state) || [])
  const open = useSelector(state => state.ui.modal.open)
  const foundError = errors.length > 0

  useEffect(() => {
    dispatch(modalUpdate(foundError))
  }, [foundError, dispatch])

  const handleClean = async () => {
    if (foundError) {
      const { cleanErrors } = await import(`redux/ducks/${duck === 'session' ? 'user' : duck}`)
      dispatch(cleanErrors())
    }
    dispatch(modalUpdate(false))
  }

  const handleOpen = () => {
    handleClean()
    if (handleSubmitButton) handleSubmitButton()
  }

  const handleCancel = () => {
    handleClean()
    if (foundError) {
      if (handleErrorButton) handleErrorButton()
    } else {
      if (handleCancelButton) handleCancelButton()
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={handleCancel}
    >
      {hideTitle ? null :
        <DialogTitle className={classes.root}>
          <DialogContentText className={classes.contentTextPreDialog}>
            {foundError && open
              ? ''
              : (
                <>
                  <Zoom in={open} timeout={2000}><FiCheckCircle className={classes.successIcon} /></Zoom>
                  {title}
                </>
              )}
          </DialogContentText>
          <IconButton className={classes.closeButton} onClick={handleCancel}>
            <MdClear />
          </IconButton>
        </DialogTitle>
      }
      <DialogContent className={classes.content}>
        {
          hideTitle &&
          <IconButton className={classes.contentCloseButton} onClick={handleCancel}>
            <MdClear />
          </IconButton>
        }
        <DialogContentText className={classes.contentText}>
          {open && foundError ? errors.slice(-1)[0] : body}
        </DialogContentText>
      </DialogContent>
      {submitText || errorText && (
        <DialogActions disableSpacing>
          {foundError && open && errorText && (
            <Button
              onClick={handleCancel}
              color='primary'
              className={classes.button}
            >
              {errorText}
            </Button>
          )}
          {!foundError && open && cancelText && (
            <Button
              onClick={handleCancel}
              color='primary'
              className={classNames(classes.button, classes.cancelButton)}
            >
              {cancelText}
            </Button>
          )}
          {!foundError && open && submitText && (
            <Button
              onClick={handleOpen}
              color='primary'
              className={classNames(classes.button, classes.submitButton)}
            >
              {submitText}
            </Button>
          )}
        </DialogActions>
      )}

    </Dialog>
  )
}

MessageModal.propTypes = {
  handleCancelButton: PropTypes.func,
  handleSubmitButton: PropTypes.func,
  handleErrorButton: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  errorText: PropTypes.string,
}
