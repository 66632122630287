import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: 12,
    color: theme.palette.common.second3,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 400,
  },
}))

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`

export const FirstItem = styled(Item)`
  grid-column-start: 2;
`

export const ImageItem = styled.div`
  align-self: center;
  justify-self: center;
  grid-column: 1 / 2;
  grid-row: 2 / 5;
`

export const customStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => {
    return {
      ...base,
      borderWith: '2px',
      borderStyle: 'solid',
      borderColor: !state.isFocused ? state.selectProps.error ? 'red' : '#979797' : '#8D8E90',
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#F6F8FA' : '#FFFFFF',
      '&:hover': {
        border: '2px solid #8D8E90',
      },
    }
  },
}
