// libraries
import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'
import Box from '@material-ui/core/Box'

// utils
import { FETCH } from 'API/fetch'
import { quoteRoutes } from 'API/routes/quote'
import { dateFormat } from 'tools'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { quoteListFetch, cleanState } from 'redux/ducks/quoteList'

// styles
import { useStyles, tableStyles } from './styles'
import { tableIcons } from 'styles/tableIcons'
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io'
import { globalTableOptions } from 'styles/globalTableStyles'
import { MdWidgets, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'

// components
import StatusTD from 'components/common/tableFragments/cells/statusTD'
import FormattedCell from 'components/common/tableFragments/cells/formattedCell'
import FilterBar from 'components/common/filterBar'
import ContainerTable from 'components/common/tableFragments/containerTable'
import LTLDetail from '../common/ltlDetail'
import TlDetail from '../common/tlDetail'

function AllTable () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const { data = [], page = 0, totalCount = 0, loading = true } = useSelector(state => state.entities.quoteList.all)
  const filters = useSelector(state => state.ui.filters)

  useEffect(() => {
    dispatch(quoteListFetch('All', { params: { limit, page: 1 } }))
    return () => {
      dispatch(cleanState())
    }
    //  eslint-disable-next-line
  }, [])

  return (
    <>
      <FilterBar
        filters={['searchBar', 'status', 'creationDate', 'priceRange', 'transportType']}
        thunkAction={(data, callback) => quoteListFetch('All', data, null, callback)}
        module='quote'
        addTypeFilter
      />
      <MaterialTable
        isLoading={loading}
        data={data}
        icons={tableIcons}
        columns={[
          {
            title: 'Reference',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <Typography align='center' className={classes.reference}>#{rowData.refId}</Typography>,
          },
          {
            title: 'Status',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,

            render: rowData => <StatusTD status={rowData.status} />,
          },
          {
            title: 'Creation Date',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <FormattedCell mainText={dateFormat(rowData.quoteDate?.date)} secondaryText={dateFormat(rowData.quoteDate?.date, 'HH:MM')} />,
          },
          {
            title: 'Origin',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundUp className={classes.originIcon} />}
                mainText={rowData.origin.city}
                secondMainText={rowData.origin.state}
                secondaryText={rowData.origin.postalCode}
              />
            ),
          },
          {
            title: 'Destination',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundDown className={classes.destinationIcon} />}
                mainText={rowData.destination.city}
                secondMainText={rowData.destination.state}
                secondaryText={rowData.destination.postalCode}
              />
            ),
          },
          {
            title: 'Shipment Type',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData =>
              <Box display='flex' justifyContent='center'>
                <div className={classes.shipmentType}><MdWidgets style={{ fontSize: '1em', marginRight: 5 }} /> {rowData.shipmentType}</div>
              </Box>,
          },
          {
            title: 'Equipment Type',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData =>
              <Typography align='center' className={classes.equipmentType}>{rowData.transportType?.replace(/-/, ' ') || '...'}</Typography>,
          },
          {
            title: 'Quoted Rate',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <Typography align='center'>{rowData.customerPrice ? `$${rowData.customerPrice}` : '--'}</Typography>,
          },
        ]}
        detailPanel={[{
          icon: () => <MdKeyboardArrowDown fontSize='1em' />,
          openIcon: () => <MdKeyboardArrowUp fontSize='1em' />,
          render: subRowData => (subRowData.shipmentType === 'LTL' ? <LTLDetail rowData={subRowData} /> : <TlDetail rowData={subRowData} />),
        }]}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              FETCH('PATCH', quoteRoutes.delete, { quoteId: oldData.refId })
                .then(() => {
                  resolve(dispatch(quoteListFetch('All', { params: { limit, page, ...filters } })))
                  // updateData()
                })
                .catch(error => {
                  console.log(error)
                  reject(error)
                })
            }),
        }}
        components={{
          Pagination: (componentsProps) => {
            const setPage = page - 1 < 0 ? 0 : page - 1 // Avoid to assign negative value.
            return (
              <TablePagination
                {...componentsProps}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(quoteListFetch('All', { params: { limit, page: page + 1, ...filters } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  componentsProps.onChangeRowsPerPage(evt)
                  dispatch(quoteListFetch('All', { params: { limit: evt.target.value, page, ...filters } }))
                }}
              />
            )
          },
          Container: props => (
            <ContainerTable>
              {props.children}
            </ContainerTable>
          ),
        }}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          bodyStyle: {
            width: 200,
          },
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No quotes to display',
          },
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </>
  )
}

export default AllTable
