//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import ContactForm from './contactForm'
import MultiLocationCheckbox from './multiLocationCheckbox'
import LocationButtons from './locationButtons'

//  hooks
import { useParams } from 'react-router-dom'

//  styles
import { truckIconOrigin, truckIconDestination } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'

export default function Step1 ({ title, location }) {
  const classes = useStyles()
  const modality = useParams().type.toLowerCase()
  const showMultiLocationCheckbox = location.toLowerCase() === 'from' && modality === 'tl'

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={location === 'from' ? truckIconOrigin : truckIconDestination} width='35px' />
        <Typography variant='h1' className={classes.heading}>
          {title}
        </Typography>
      </HeadingContainer>
      <ContactForm location={location} />
      {showMultiLocationCheckbox && <MultiLocationCheckbox />}
      <LocationButtons location={location} />
    </Root>
  )
}

Step1.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
}
