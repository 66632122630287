import { makeStyles } from '@material-ui/core/styles'
import { globalTableStyles } from 'styles/globalTableStyles'

export const useStyles = makeStyles((theme) => ({
  reference: {
    fontSize: '1em',
    fontWeight: 600,
    fontStyle: 'bold',
  },
  originIcon: {
    fontSize: 'inherit',
    color: '#2CB556',
  },
  destinationIcon: {
    fontSize: 'inherit',
    color: '#EA5D5D',
  },
  primaryColor: {
    color: '#4b2367',
  },
  customerPrice: {
    fontSize: '1em',
  },

}))

export const tableStyles = {
  ...globalTableStyles,
}
