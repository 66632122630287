// libraries
import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

//  styles
import { useStyles } from './styles'
import {
  dryVanIcon,
  dryVanIconActive,
  flatbedIcon,
  flatbedIconActive,
  reeferIcon,
  reeferIconActive,
} from 'assets'

// components
import ImageNotDraggable from 'components/common/imageNotDraggable'

function TransportType ({ value, cbHandler }) {
  const classes = useStyles()
  const [selected, setSelected] = useState(value)
  const icon = {
    'dry-van': selected === 'dry-van' ? dryVanIconActive : dryVanIcon,
    reefer: selected === 'reefer' ? reeferIconActive : reeferIcon,
    flatbed: selected === 'flatbed' ? flatbedIconActive : flatbedIcon,
  }
  useEffect(() => {
    setSelected(value)
  }, [value])

  const onChange = (newValue) => {
    cbHandler({
      key: 'transportType',
      value: newValue,
    })
    setSelected(newValue)
  }

  return (
    <>
      <Typography variant='body1' className={classes.subtitleAdvancedOption}>
        Equipment type
      </Typography>
      <Box display='flex' justifyContent='space-around' mt='1.5rem'>
        <Box width='25%' onClick={() => onChange('dry-van')}>
          <ImageNotDraggable externalImage={icon['dry-van']} className={classes.transportImage} />
          <Typography variant='body1' className={classes.subtitleAdvancedOption}>
            Dry van
          </Typography>
        </Box>
        <Box width='25%' onClick={() => onChange('reefer')}>
          <ImageNotDraggable externalImage={icon.reefer} className={classes.transportImage} />
          <Typography variant='body1' className={classes.subtitleAdvancedOption}>
            Reefer
          </Typography>
        </Box>
        <Box width='25%' onClick={() => onChange('flatbed')}>
          <ImageNotDraggable externalImage={icon.flatbed} className={classes.transportImage} />
          <Typography variant='body1' className={classes.subtitleAdvancedOption}>
            Flatbed
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default TransportType
