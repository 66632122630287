//  libraries
import React, { useEffect } from 'react'

//  components
import NavStepper from 'components/common/navStepper'
import Loader from 'components/common/loader'
import MessageModal from 'components/common/messageModal'
import {
  OriginDestinationShipment,
  ProductInformation,
  VehicleAccessorials,
  ShippingInformationShipment,
} from 'components/common/forms'

//  redux
import { productsRequest } from 'redux/ducks/products'
import { fetchPost } from 'redux/ducks/postData'
import { carrierListShipmentRequest } from 'redux/ducks/carrierList'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

//  styles
import { Content } from './styles'

export default function LtlForm () {
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector(state => state.entities.carrierList.loading)
  const companies = useSelector(state => state.ui.companySelector)
  const quoteAction = useParams().action

  useEffect(() => {
    dispatch(productsRequest())
  }, [dispatch])

  const getStepContent = {
    0: {
      0: <OriginDestinationShipment location='from' position={0} />,
      1: <OriginDestinationShipment location='from' position={1} />,
      isComplex: true,
    },
    1: {
      0: <OriginDestinationShipment location='to' position={0} />,
      1: <OriginDestinationShipment location='to' position={1} />,
      isComplex: true,
    },
    2: <ProductInformation />,
    3: <VehicleAccessorials />,
    4: <ShippingInformationShipment type='ltl' />,
  }

  const handleSent = () => {
    if (quoteAction === 'edit') {
      dispatch(fetchPost('shipmentService.putLtl', null, 'newForm', () => {
        history.goBack()
      }, companies))
    } else {
      dispatch(carrierListShipmentRequest(() => history.push('/shipment/rates/list')))
    }
  }
  return (
    <Content>
      <NavStepper getStepContent={getStepContent} handleSent={handleSent} companySelector />
      <MessageModal path={quoteAction === 'edit' ? 'entities.postData.errors' : 'entities.carrierList.errors'} />
      <Loader open={loading} />
    </Content>
  )
}
