import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'none',
    width: 140,
    height: 50,
    color: theme.palette.common.second8,
    backgroundColor: '#290c3d',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  margin: {
    marginRight: 10,
  },
}))
