//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import LocationForm from 'components/common/forms/locationForm'
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  styles
import { truckIconOrigin, truckIconDestination } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'

export default function Desktop () {
  const classes = useStyles()
  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={truckIconOrigin} width='35px' />
        <Typography variant='h1' className={classes.heading}>
          Origin
        </Typography>
      </HeadingContainer>
      <LocationForm location='from' />
      <HeadingContainer>
        <ImageNotDraggable externalImage={truckIconDestination} width='35px' />
        <Typography variant='h1' className={classes.heading}>
          Destination
        </Typography>
      </HeadingContainer>
      <LocationForm location='to' />
    </Root>
  )
}
