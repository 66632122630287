//  libraries
import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'

import API from 'API'

//  hooks
import { useDispatch, useSelector } from 'react-redux'

//  redux actions
import { quoteListAssignSucceeded } from 'redux/ducks/quoteList'

//  styles
import { AiOutlineStop } from 'react-icons/ai'

export default function Assign ({ salesAssigned, assignedToMe, refId }) {
  const dispatch = useDispatch()
  const currentUserId = useSelector(state => state.session.data.userId)
  const [loading, setLoading] = useState(false)

  const handleSwitch = () => {
    setLoading(true)
    API.quoteService.assignQuoteToASales(refId)
      .then(({ data }) => {
        if (data.success) {
          dispatch(quoteListAssignSucceeded(refId, {
            salesAssigned: data.data.salesAssigned,
            assignedToMe: currentUserId === data.data.salesAssigned,
          }))
        }
      })
      .catch((e) => {
        console.log('error:::', e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (salesAssigned === null || assignedToMe) {
    return (
      <Fragment key={refId}>
        <Switch
          checked={assignedToMe}
          color='primary'
          disabled={loading}
          onChange={handleSwitch}
        />
        {loading && <CircularProgress color='primary' size={20} />}
      </Fragment>
    )
  }
  return (
    <Tooltip title='Assigned to another Sales Rep.'>
      <IconButton>
        <AiOutlineStop color='red' />
      </IconButton>
    </Tooltip>
  )
}

Assign.propTypes = {
  salesAssigned: PropTypes.string,
  assignedToMe: PropTypes.bool,
  refId: PropTypes.string.isRequired,

}
