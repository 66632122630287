import { FETCH } from 'API/fetch'
import { authRoutes } from 'API/routes/auth'

export class Auth {
  async login (payload) {
    const response = await FETCH('post', authRoutes.login, payload)
    return response
  }

  async forgotPassword (payload) {
    const response = await FETCH('post', authRoutes.forgotPassword, payload)
    return response
  }

  async resetPassword (data) {
    const { token, ...payload } = data
    const response = await FETCH('post', authRoutes.resetPassword.replace(':token', token), payload)
    return response
  }

  async checkUser () {
    const response = await FETCH('get', authRoutes.check)
    return response
  }

  async activateAccount (data) {
    const { token, ...payload } = data
    const response = await FETCH('post', authRoutes.activateAccount.replace(':token', token), payload)
    return response
  }

  async companiesByUser () {
    const response = await FETCH('get', authRoutes.companiesByUser)
    return response
  }

  async usersByCompany (data) {
    const { companyId, ...payload } = data
    const response = await FETCH('get', authRoutes.usersByCompany.replace(':companyId', companyId), payload)
    return response
  }

  async isFirstLogin (data) {
    const response = await FETCH('put', authRoutes.isFirstLogin, data)
    return response
  }

  async resendActivationEmail (data) {
    const response = await FETCH('post', authRoutes.resendActivationEmail, data)
    return response
  }
}
