import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    backgroundColor: '#FAFAFA',
  },

  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0',
  },

  contentButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },

  whiteBox: {
    backgroundColor: 'white',
    borderRadius: '5px',
    marginLeft: '.2em',
    padding: '.2em .5em',
    color: theme.palette.common.orange,
    textTransform: 'capitalize',
  },

  mainContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  card: {
    height: '100%',
    minHeight: 192,
    backgroundColor: theme.palette.common.white,
  },

  headTable: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.5em 1em',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '12pt',
    fontWeight: 600,
  },

  decline: {
    backgroundColor: theme.palette.common.second3,
    color: 'white',
    width: 210,
    height: 40,
    fontSize: '1em',
    textTransform: 'none',
    marginLeft: '2em',
    '&:hover': {
      backgroundColor: theme.palette.common.second2,
      borderColor: '#a25c01',
    },
  },

  accept: {
    color: 'white',
    backgroundColor: theme.palette.common.orange,
    width: 188,
    fontSize: '1rem',
    textTransform: 'none',
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.common.deepOrange,
    },
  },

  subtitle: {
    marginLeft: '1rem',
    fontWeight: 600,
  },
}))
