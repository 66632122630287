import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    margin: 'auto 5px',
    color: theme.palette.common.second3,
    fontWeight: '500',
  },
  picker: {
    '& .MuiInputBase-root': {
      paddingRight: '5px',
    },
    '& input': {
      '&::placeholder': {
        fontSize: 13,
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
      fontSize: 12,
      color: '#fa2e2e',
    },
  },
  marginRight10: {
    marginRight: '10px',
  },
  pickerButton: {
    padding: 0,
  },
}))

export const Root = styled.div`
  margin-bottom: 2rem;
`

export const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 285px 150px 150px;
  column-gap: 10px;
  justify-content: center;
  background-color: #CBD6E233;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px ${({ theme }) => theme.palette.common.second6};
  border-bottom: none;
`

export const Content = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 285px 150px 150px;
  column-gap: 10px;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.palette.common.second6};
  height: 90px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 25px;
`

export const Item = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
`
