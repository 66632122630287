import keyMirror from 'keymirror-nested'

export default keyMirror({
  QUOTE_DETAILS: {
    FETCH: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
    SET_MESSAGE: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    UPDATE_RATES: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    DECLINE: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
  },
})
