//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

//  hooks
import { useFormContext } from 'react-hook-form'

//  styles
import { useStyles, Root } from './styles'
import { FaCheck } from 'react-icons/fa'

export default function MultiLocationCheckbox () {
  const classes = useStyles()
  const { register } = useFormContext()

  return (
    <Root>
      <Typography>
        Are there any additional pick-ups or deliveries for this load?
      </Typography>
      <FormControlLabel
        className={classes.radio}
        control={
          <Checkbox
            icon={<FaCheck className={classes.unCheckedIcon} />}
            checkedIcon={<FaCheck className={classes.checkedIcon} />}
          />
        }
        label='Yes'
        name='from.withAdditionalPickupsDeliveries'
        inputRef={register}
      />
    </Root>
  )
}
