//  libraries
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { yupResolver } from '@hookform/resolvers'

//  components
import FormInputs from 'components/common/formInputs'
import ImageSelector from 'components/admin/common/imageSelector'
import MessageModal from 'components/common/messageModal'
import CircularProgress from '@material-ui/core/CircularProgress'
//  hooks
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

//  redux
import { fetchPost } from 'redux/ducks/postData'
import { modalUpdate } from 'redux/ducks/modal'
import { userUpdate } from 'redux/ducks/user'

//  tools
import { profileValidation, convertToFormatData } from 'tools'

//  styles
import { useStyles, Form, Element } from './styles'

export default function ProfileForm () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, setValue, unregister, errors, handleSubmit } = useForm({ provider: yupResolver(profileValidation) })
  const { name, email, image: defaultImage } = useSelector(state => state.session.data)
  const loading = useSelector(state => state.entities.postData.loading)
  const [image, setImage] = useState(defaultImage)
  const [selectedTimeZone, setSelectedTimeZone] = useState(localStorage.getItem('timeZone'))

  useEffect(() => {
    register('image')
    setValue('image', defaultImage)
    return () => {
      unregister('image')
    }
  //  eslint-disable-next-line
  }, [])

  const handleSaveImage = (event) => {
    const file = event.target.files[0]
    const fileURL = URL.createObjectURL(file)
    setImage(fileURL)
    setValue('image', file)
  }

  const onSubmit = (values) => {
    const form = convertToFormatData(values)
    dispatch(fetchPost('profileService.update', form, '', () => {
      dispatch(modalUpdate(true))
      dispatch(userUpdate(values))
    }))
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ImageSelector
        image={image}
        handleSaveImage={handleSaveImage}
      />
      <Box
        display='flex'
        flexWrap='wrap'
        alignContent='flex-start'
        justifyContent='center'
        mb='1rem'
      >
        <Element>
          <Typography variant='h4' className={classes.text} component='label'>Name</Typography>
          <FormInputs.Input
            name='name'
            inputRef={register}
            showError
            error={errors.name}
            defaultValue={name}
          />
        </Element>
        <Element>
          <Typography variant='h4' className={classes.text} component='label'>Email</Typography>
          <FormInputs.Input
            name='email'
            inputRef={register}
            showError
            error={errors.email}
            defaultValue={email}
          />
        </Element>
      </Box>
      <Button
        variant='contained'
        type='submit'
        className={classes.button}
        disabled={loading}
      >
        Save
        {loading && <CircularProgress size={30} color='primary' className={classes.spinner} />}
      </Button>
      <MessageModal
        title='SUCCESSFULLY UPDATED!'
        path='entities.postData.errors'
        body='Your profile was updated correctly.'
      />
    </Form>
  )
}
