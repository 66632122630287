//  libraries
import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  components
import FormInputs from 'components/common/formInputs'

//  hooks
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

//  tools
import { floatNumberValidator } from 'tools'

//  styles
import { useStyles, Title, Item, CustomTooltip } from './styles'

export default function CustomInput ({ title, name, condition, field, message, openTooltipValue = false, customRules, ...rest }) {
  const classes = useStyles()
  const { addToast, removeToast } = useToasts()
  const { control, watch, setValue } = useFormContext()
  const value = watch(name)
  const disabled = useSelector(state => state.newForm.disabled)

  useEffect(() => {
    if (condition) {
      if (value > condition) {
        setValue(field, true)
        addToast(message, {
          appearance: 'warning',
          autoDismiss: false,
          id: `warning-toast-${title}`,
        })
      } else {
        setValue(field, false)
        removeToast(`warning-toast-${title}`)
      }
    }
    return () => { removeToast(`warning-toast-${title}`) }
    //  eslint-disable-next-line
  }, [value])

  return (
    <CustomTooltip open={openTooltipValue} title='High value!' arrow>
      <Item>
        <Title>
          <Typography variant='h4' className={classes.colorText} align='center' component='label'>{title}</Typography>
        </Title>
        <FormInputs.Input
          name={name}
          variant='outlined'
          rules={customRules ?? floatNumberValidator}
          disabled={disabled}
          control={control}
          isControlled
          showError
          classNameError={classes.error}
          inputProps={{
            lang: 'en',
            type: 'number',
            title: 'Please enter a number.',
          }}
          {...rest}
        />
      </Item>
    </CustomTooltip>
  )
}

CustomInput.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  condition: PropTypes.number,
  field: PropTypes.string,
  message: PropTypes.string,
  openTooltip: PropTypes.bool,
}
