import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  head: {
    fontSize: '35px',
    fontWeight: 900,
    color: theme.palette.primary.main,
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    width: '50%',
    height: 76,
  },
  title: {
    marginBottom: 12,
    color: theme.palette.common.second3,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  button: {
    justifySelf: 'center',
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'none',
    width: 140,
    height: 50,
    color: theme.palette.common.second8,
    backgroundColor: '#290c3d',
    marginTop: '10px',
    marginRight: '7%',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  disabled: {
    color: '#FFFFFF !important',
  },
  loading: {
    position: 'absolute',
  },
}))

export const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    background: #FFFFFF;
    width: 80%;
    max-width: 700px;
    height: 80%;
    max-height: 800px;
    margin: 80px auto;
    padding: 50px;
    overflow: auto;
    &:focus {
      outline: none;
    } 
  `}
`

export const Content = styled.form`
  ${({ theme }) => css`
    flex-grow: 1;
    display: grid;
    grid-template: 110px 110px 1fr 50px / 100%;
    margin-top: 20px;
  `}
`

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`

export const Title = styled.div`
  border-bottom: 2px solid #CBD6E2;
  width: 100%;
  height: 76px;
`
