// commented items was deleted because of a client request but there are in mcleod system
export default [
  // { value: 'WB', label: 'American Petroleum Institute (API) Well' },
  { value: 'BM', label: 'Bill of Lading Number' },
  { value: 'CG', label: "Consignee's Order Number" },
  { value: 'KK', label: 'Delivery Reference' },
  { value: 'QL', label: 'Line Quanitity' },
  { value: 'MB', label: 'Master Bill of Lading' },
  // { value: 'ZZ', label: 'Mutally Defined' },
  { value: 'OR', label: 'Order Number' },
  { value: 'OI', label: 'Original Invoice Number' },
  { value: 'PS', label: 'Pickset Number' },
  { value: 'PU', label: 'Pickup Number' },
  { value: 'PO', label: 'Purchase Order Number' },
  { value: 'SL', label: 'Sales/Territory Code' },
  { value: 'SN', label: 'Seal Number' },
  { value: 'SI', label: "Shipper's Identifying Number (SID)" },
  { value: 'SO', label: "Shipper's Order (Invoice Number)" },
  // { value: 'SBN', label: 'Surety Bond Number' },
  // { value: 'OU', label: 'Tariff Supplement Number' },
  // { value: 'UP', label: 'Upstream Shipper Contract Number' },
  { value: 'WO', label: 'Work Order Number ' },
]
