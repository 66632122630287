import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
} from '@material-ui/core'

// styles
import {
  useStyles,
  StyledExpansionPanel,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  IndicatorTypo,
} from './styles'
import { MdExpandMore } from 'react-icons/md'

// components
import Lane from 'components/common/tableFragments/quoteInfo/lane'
import Items from 'components/common/tableFragments/quoteInfo/items'
import Accessorials from 'components/common/tableFragments/quoteInfo/accessorials'

const DuoTitleValue = ({ indicator, value, isPrimaryColor = false }) => {
  const classes = useStyles()
  const classTypo = isPrimaryColor ? classes.valueTypoMainColor : classes.valueTypo
  return (
    <Box m={2} textAlign='center'>
      <IndicatorTypo>{indicator}</IndicatorTypo>
      <Typography className={classTypo}>{value}</Typography>
    </Box>
  )
}

DuoTitleValue.propTypes = {
  indicator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPrimaryColor: PropTypes.bool,
}

function SummaryInformationLTLQuote ({ from = '', to = '', products = [], accessorials = [], totalWeight = '' }) {
  const classes = useStyles()
  return (
    <StyledExpansionPanel>
      <StyledExpansionPanelSummary expandIcon={<MdExpandMore className={classes.expandButton} />}>
        <Lane from={from} to={to} />
        <Box display='flex' flexWrap='wrap' mx={4}>
          <DuoTitleValue indicator='Products' value={products.length} isPrimaryColor />
          <DuoTitleValue indicator='Accessorials' value={accessorials.length || 0} isPrimaryColor />
          <DuoTitleValue indicator='Total Weight' value={`${totalWeight} lbs`} />
        </Box>
      </StyledExpansionPanelSummary>
      <StyledExpansionPanelDetails>
        <Box width='30rem'>
          <Items itemsData={products} />
          <Accessorials accessorialList={accessorials} />
        </Box>
      </StyledExpansionPanelDetails>
    </StyledExpansionPanel>
  )
}

SummaryInformationLTLQuote.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  accessorials: PropTypes.array.isRequired,
  totalWeight: PropTypes.number.isRequired,
}

export default SummaryInformationLTLQuote
