//  libraries
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

//  components
import Loader from 'components/common/loader'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

//  redux
import { forgotPasswordRequest } from 'redux/ducks/user'
import { modalUpdate } from 'redux/ducks/modal'

//  tools
import { emailValidator } from 'tools'

//  styles
import { useStyles, Link, Form } from './styles'
import { FaUserAlt } from 'react-icons/fa'
import { MdKeyboardBackspace } from 'react-icons/md'

export default function FormForgotPassword () {
  const { handleSubmit, register, errors } = useForm()
  const classes = useStyles()
  const dispatch = useDispatch()
  const loading = useSelector(state => state.session.loading)

  const onSubmit = ({ emailInput }) => {
    dispatch(forgotPasswordRequest({ email: emailInput.toLowerCase() }, () => {
      dispatch(modalUpdate(true))
    }))
  }

  return (
    <Form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.messageText}>
        To reset your password, please enter your email address or username.
        You will receive a link to create a new password
      </Typography>

      <div className={classes.container}>
        <TextField
          fullWidth
          name='emailInput'
          className={classes.input}
          label='Email'
          variant='filled'
          error={!!errors.emailInput}
          inputRef={register(emailValidator)}
          InputProps={{
            className: classes.inputBase,
            endAdornment: (
              <InputAdornment position='end'>
                <FaUserAlt color='#4b2367' />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText className={classes.errorForm} error={!!errors.emailInput}>
          {errors?.emailInput?.message}
        </FormHelperText>
      </div>

      <Button
        type='submit'
        className={classes.submitButton}
        variant='contained'
        disabled={loading}
      >
        Send
      </Button>
      <Link to='/login'>
        <MdKeyboardBackspace className={classes.leftArrow} /> LOG IN
      </Link>
      <Loader open={loading} />
    </Form>
  )
}
