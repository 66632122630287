//  libraries
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// mocks
import { options } from 'mocks/optionsRejectSurvey'

// styles
import { useStyles, CustomRadio } from './styles'

function RejectSurvey ({ onClose, open, onDeclineConfirmation, refId, ...other }) {
  const classes = useStyles()
  const radioGroupRef = useRef(null)
  const [value, setValue] = useState('')
  const [textArea, setTextArea] = useState('')
  const [disable, setDisable] = useState(true)
  const headText = 'Can you spare a minute to tell us why you decided not to ship with us today?'
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    const surveyData = value === options[options.length - 1] ? `${value} ${textArea}` : value
    onDeclineConfirmation(refId, surveyData)
    onClose(value)
  }

  const handleChange = ({ target }) => {
    setDisable(() => {
      if (target.value === options[options.length - 1] && textArea.length < 3) {
        return true
      }
      return false
    })
    setValue(target.value)
  }

  const handleTextArea = ({ target }) => {
    const value = target.value
    setDisable(value.length < 3)
    setTextArea(value)
  }

  return (
    <Dialog
      id='reject-options'
      keepMounted
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      onEntering={handleEntering}
      aria-labelledby='confirmation-dialog-title'
      open={open}
      classes={{
        paper: classes.paper,
      }}
      {...other}
    >
      <Typography variant='h3' gutterBottom className={classes.modalTitle}>{headText}</Typography>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label='ringtone'
          name='ringtone'
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel value={option} key={option} control={<CustomRadio />} label={option} />
          ))}
        </RadioGroup>
        {
          (value === options[options.length - 1]) && (
            <Box>
              <TextField
                id='outlined-multiline-static'
                label='Give your answer'
                multiline
                variant='filled'
                style={{ width: '88%' }}
                inputProps={{
                  maxLength: 200,
                }}
                value={textArea}
                onChange={handleTextArea}
              />
            </Box>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant='contained' color='primary' className={classes.button}>
          Cancel
        </Button>
        <Button onClick={handleOk} disabled={disable} variant='contained' color='primary' className={classes.button}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RejectSurvey.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onDeclineConfirmation: PropTypes.func.isRequired,
  refId: PropTypes.string.isRequired,
}

export default RejectSurvey
