import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 3.3rem',
    paddingTop: 29,
    paddingBottom: 10,
    display: 'grid',
    gridTemplateColumns: 'minmax(90%, 1500px)',
    gridAutoRows: 'min-content',
    justifyContent: 'center',
  },
  h1: {
    marginTop: 12,
    fontSize: 21,
    color: theme.palette.common.second1,
    marginRight: 5,
    width: '100%',
    maxWidth: 1500,
    justifyContent: 'flex-start',
  },
  button: {
    marginTop: 24,
    marginLeft: 10,
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: '#D57800',
    '&:hover': {
      backgroundColor: '#D57800',
    },
  },
}))
