import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `}
`

export const Content = styled.div`
  max-width: 1200px;
  margin-top: 2rem;
  margin-bottom: 20px;
  width: 100%;
`
