export default {
  customLoading: (state, location) => {
    return {
      ...state,
      [location]: {
        options: [],
        loading: true,
      },
    }
  },
  saveData: (state, data, location) => {
    return {
      ...state,
      [location]: {
        options: data.map(item => ({
          ...item.Location.Address,
          DisplayPosition: item.Location.DisplayPosition,
        })),
        loading: false,
      },
    }
  },
  customError: (state, data = {}) => {
    return {
      ...state,
      errors: [...state.errors, data.error],
    }
  },
}
