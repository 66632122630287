//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import FormInputs from 'components/common/formInputs'

//  hooks
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { instructionsIcon } from 'assets'
import { useStyles, Item, Title } from './styles'

export default function AdditionalInformation () {
  const classes = useStyles()
  const { register, errors } = useFormContext()
  const [product] = useSelector(state => state.newForm.products || [{}])

  return (
    <Item>
      <Title>
        <ImageNotDraggable externalImage={instructionsIcon} width='23px' />
        <Typography variant='h5' className={classes.colorText} component='label'> Commodity </Typography>
      </Title>
      <FormInputs.Input
        name='products[0].commodity'
        inputRef={register(requiredValidator)}
        showError
        error={errors.products?.[0]?.commodity}
        defaultValue={product.commodity}
        classNameError={classes.error}
        rows={5}
        multiline
        InputProps={{
          className: classes.inputBase,
        }}
      />
    </Item>
  )
}
