import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  AccountsSucceeded,
  AccountsFailed,
} from './actions'
import API from 'API'

function * fetchAccounts (action) {
  try {
    const { data } = yield call(API.adminService.Accounts, action.payload)
    yield put(AccountsSucceeded(data))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(AccountsFailed(newError))
  }
}

export default function * rootAccounts () {
  yield takeEvery(TYPES.ACCOUNTS.FETCH.REQUEST, fetchAccounts)
}
