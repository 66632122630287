//  libraries
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { createSelector } from 'reselect'
import PropTypes from 'prop-types'

//  components
import CompanySelector from 'components/common/companySelector'

// hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { newFormUpdate } from 'redux/ducks/newForm'

//  styles
import { useStyles, Header, Po } from './styles'
import { TiInfoLarge } from 'react-icons/ti'

const dataNewForm = createSelector(
  state => state.newForm.references?.filter((reference) => reference.ref === 'PO NUMBER'),
  ([PO] = [{}]) => {
    return (PO?.value || '')
  },
)

export default function TopBar ({ po, companySelector }) {
  const dispatch = useDispatch()
  const PO = useSelector(dataNewForm)
  const classes = useStyles()

  const handleChangePo = (event) => {
    dispatch(newFormUpdate({
      references: [{
        ref: 'PO NUMBER',
        value: event.target.value,
      }],
    }))
  }

  return (
    <Header>
      <Po>
        {po && (
          <>
            <Typography variant='h4' className={classes.label} component='label'>PO #</Typography>
            <TextField
              variant='outlined'
              value={PO}
              onChange={handleChangePo}
              className={classes.PoText}
            />
            <TiInfoLarge className='icon' />
          </>
        )}
      </Po>
      {companySelector && <CompanySelector />}
    </Header>
  )
}

TopBar.propTypes = {
  po: PropTypes.bool,
  companySelector: PropTypes.bool,
}
