import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 400px;
  background: grey;
  && .H_ib_body {
    background: #4B2367;
  }
  && .H_btn.H_ib_close svg{
    fill: #FFFFFF;
  }
  && .mapContent {
    display: flex;
    flex-flow: column;
    align-items: center;
    color: #FFFFFF;
  }
  && .title {
    border-bottom: 2px solid #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }
  && .date {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
`
