//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

//  styles
import { useStyles } from './styles'
import { iconSizeStyle } from 'styles/tableIcons'
import {
  MdChevronRight,
  MdKeyboardArrowLeft,
  MdFirstPage,
  MdLastPage,
} from 'react-icons/md'

export default function PaginationActions ({ count, page, rowsPerPage, onChangePage }) {
  const classes = useStyles()
  const totalPages = Math.ceil(count / rowsPerPage)

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  const handlePageButtonClick = (newPage) => (event) => {
    onChangePage(event, newPage)
  }

  const PageButton = ({ children, disabled }) => {
    return (
      <Button
        size='small'
        className={classes.pageButton}
        disabled={disabled}
        variant={disabled ? 'contained' : 'text'}
        onClick={handlePageButtonClick(Number(children) - 1)}
      >
        {children}
      </Button>
    )
  }

  const renderPagesButtons = () => {
    if (count <= 0) return
    if (totalPages === 1) {
      return <PageButton disabled>1</PageButton>
    }

    let buttonLabel1 = null
    let buttonLabel2 = null
    let buttonLabel3 = null
    const realPage = page + 1

    if (realPage <= 1 || realPage === totalPages) {
      if (realPage === 1) {
        buttonLabel1 = realPage
        buttonLabel2 = realPage + 1
      } else {
        buttonLabel1 = realPage - 1
        buttonLabel2 = realPage
      }
      const disabled1 = realPage === buttonLabel1
      const disabled2 = realPage === buttonLabel2
      return (
        <Box display='flex'>
          <PageButton disabled={disabled1}>{buttonLabel1}</PageButton>
          <PageButton disabled={disabled2}>{buttonLabel2}</PageButton>
        </Box>
      )
    }

    buttonLabel1 = realPage - 1
    buttonLabel2 = realPage
    buttonLabel3 = realPage + 1
    return (
      <Box display='flex'>
        <PageButton>{buttonLabel1}</PageButton>
        <PageButton disabled>{buttonLabel2}</PageButton>
        <PageButton>{buttonLabel3}</PageButton>
      </Box>
    )
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <MdFirstPage style={iconSizeStyle} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <MdKeyboardArrowLeft style={iconSizeStyle} />
      </IconButton>
      {renderPagesButtons()}
      <IconButton onClick={handleNextButtonClick} disabled={page >= totalPages - 1}>
        <MdChevronRight style={iconSizeStyle} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= totalPages - 1}>
        <MdLastPage style={iconSizeStyle} />
      </IconButton>
    </div>
  )
}

PaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
