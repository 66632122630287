import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  fab: {
    width: 50,
    height: 50,
    position: 'absolute',
    right: 212,
    bottom: 183,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.second8,
    '&:hover': {
      backgroundColor: '#637280',
    },
  },
  icon: {
    height: 50,
    width: 50,
  },
  addItems: {
    marginLeft: '25%',
  },
  container: {
    height: 58,
    marginBottom: 33,
  },
  total: {
    display: 'inline-block',
    color: '#637280',
    fontSize: 18,
    opacity: '75%',
    marginRight: 12,
  },
  value: {
    display: 'inline-block',
    color: '#4B2367',
    fontSize: 26,
    fontWeight: 'bold',
    marginRight: 32,
  },
  totalContainer: {
    position: 'relative',
    top: 51,
  },
  oversize: {
    color: '#CBD6E2',
    fontSize: 18,
  },
  positionOversize: {
    position: 'absolute',
    top: 54,
    left: 0,
  },
  itemText: {
    color: '#4B2367',
    fontSize: 26,
    fontWeight: 500,
    position: 'relative',
    bottom: 8,
    left: 10,
  },

}))
