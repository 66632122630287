import TYPES from './types'

export const filtersUpdate = (payload) => ({
  type: TYPES.FILTERS.UPDATE,
  payload,
})

export const filtersDeleteAdvancedFilters = (payload) => ({
  type: TYPES.FILTERS.DELETE_ADVANCED,
  payload,
})

export const filtersDelete = () => ({
  type: TYPES.FILTERS.DELETE,
})
