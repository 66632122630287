// libraries
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

// styles
import { useStyles } from './styles'

function PriceRange ({ value, cbHandler }) {
  const classes = useStyles()
  const [sliderValue, setSliderValue] = useState(value || [3000, 5000])
  const step = 10
  const max = 10000
  const marks = [
    {
      value: 0,
      label: 0,
    },
    {
      value: max,
      label: '10000+',
    },
  ]

  useEffect(() => {
    setSliderValue(value || [2500, 5000])
  }, [value])

  const handleChange = (event, newValue) => {
    cbHandler({
      key: 'priceRange',
      value: newValue,
    })
    setSliderValue(newValue)
  }

  const handleFromInputChange = ({ target }) => { // FROM range
    // validations
    if (!Number(target.value)) return
    const newVal = Number(target.value)
    if (newVal >= sliderValue[1] || newVal > 10000) return
    // set values
    setSliderValue((prevValue) => {
      handleChange(null, [newVal, prevValue[1]])
      return [newVal, prevValue[1]]
    })
  }

  const handleToInputChange = ({ target }) => { // TO range
    // validations
    if (!Number(target.value)) return
    const newVal = Number(target.value)
    if (newVal < sliderValue[0] || newVal > 10000) return
    // set values
    setSliderValue((prevValue) => {
      handleChange(null, [prevValue[0], newVal])
      return [prevValue[0], newVal]
    })
  }

  return (
    <>
      <Typography variant='body1' className={classes.subtitleAdvancedOption}>
        Price (USD)
      </Typography>
      <Slider
        value={sliderValue}
        onChange={handleChange}
        max={max}
        step={step}
        marks={marks}
        getAriaValueText={(val) => val}
        classes={{ markLabel: classes.markLabel }}
        valueLabelDisplay='auto'
      />
      <Box display='flex' justifyContent='space-around' alignItems='center' mx='1rem'>
        <TextField
          className={classes.input}
          value={sliderValue[0]}
          onChange={handleFromInputChange}
          variant='outlined'
          inputProps={{
            step: step,
            min: 0,
            max: max,
            type: 'number',
          }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          onFocus={({ target }) => { target.select() }}
        />
        <Typography variant='caption' className={`${classes.subtitleAdvancedOption} ${classes.priceLabel}`}> - </Typography>
        <TextField
          className={classes.input}
          value={sliderValue[1]}
          onChange={handleToInputChange}
          variant='outlined'
          inputProps={{
            step: step,
            min: 0,
            max: max,
            type: 'number',
          }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          onFocus={({ target }) => { target.select() }}
        />
      </Box>
    </>
  )
}

PriceRange.propTypes = {
  value: PropTypes.array,
  cbHandler: PropTypes.func.isRequired,
}
export default PriceRange
