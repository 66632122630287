import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

// styles
import { useStyles } from './styles'

const Location = ({ icon, mainText, secondMainText, firstSecondaryText }) => {
  const classes = useStyles()
  return (
    <div className={classes.rowContainer}>
      <div className={classes.rowContent}>
        {mainText
          ? (
            <>
              <div className={classes.mainText}>{icon || null}<Typography className={classes.content}>{mainText}{secondMainText ? `, ${secondMainText}` : null}</Typography></div>
              <Typography component='div' className={classes.secondaryText}>{firstSecondaryText}</Typography>
            </>
          )
          : <Typography align='center'>...</Typography>}
      </div>
    </div>
  )
}

export default Location

Location.propTypes = {
  icon: PropTypes.element,
  secondMainText: PropTypes.string,
  mainText: PropTypes.string,
  firstSecondaryText: PropTypes.string,
  zipCode: PropTypes.string,
}
