// libraries
import React from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import { Box, Checkbox, Typography } from '@material-ui/core'

// styles
import { tableStyles, useStyles } from './styles'
import { tableIcons } from 'styles/tableIcons'
import { globalTableOptions } from 'styles/globalTableStyles'
import { MdRadioButtonUnchecked, MdCheckCircle } from 'react-icons/md'
import { FaTruckMoving } from 'react-icons/fa'

// redux
import { quoteLisUpdateLtlSelectedRate } from 'redux/ducks/quoteList'

// hooks
import { useSelector, useDispatch } from 'react-redux'

// components
import Detail from './detail'
import ContainerTable from 'components/common/tableFragments/containerTable'

const ErrorCard = ({ carrierName, errorMessage }) => {
  const classes = useStyles()
  return (
    <Box display='flex' justifyContent='end' className={classes.errorDetail}>
      <Typography variant='body1' style={{ color: '#b12323' }}>
        {`${carrierName} ${errorMessage}` || 'No carrier response'}
      </Typography>
    </Box>
  )
}

ErrorCard.propTypes = {
  carrierName: PropTypes.string,
  errorMessage: PropTypes.string,
}

function RatesTable ({ ratesData, selectedIndex, setSelectedIndex, refId = null }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const role = useSelector(state => state.session.data.role)
  const isAdmin = role.toLowerCase() === 'tucker'
  const isQuoteListView = useSelector(state => state.entities.quoteList.ltl.data?.length > 0)
  let showHeader = false

  if (!ratesData) return (<Typography>Empty data...</Typography>)

  ratesData.forEach(rate => {
    if (rate.carrierCharges === undefined) {
      rate.error = 'No rates in carrier response'
      showHeader = false
    } else {
      showHeader = true
    }
  })

  const handleSelectChange = (row) => {
    setSelectedIndex(row.tableData.id)
    if (isQuoteListView) {
      dispatch(quoteLisUpdateLtlSelectedRate(refId, row._id))
    }
  }

  return (
    <MaterialTable
      data={ratesData}
      icons={tableIcons}
      columns={[
        {
          title: 'Carrier',
          field: 'carrierName',
          headerStyle: tableStyles.textAlignCenter,
          width: 270,
          cellStyle: tableStyles.cellStyle,
          render: (rowData) => {
            return (
              <Box display='flex' flexDirection='row'>
                <Box ml='4rem'>
                  <FaTruckMoving className={classes.logo} />
                </Box>
                <Box ml={3} justifyContent='flex-start' textAlign='left'>
                  <span>{rowData.carrierName}</span>
                </Box>
              </Box>
            )
          },
        },
        {
          title: 'Service',
          field: 'serviceType',
          headerStyle: tableStyles.textAlignCenter,
          cellStyle: tableStyles.serviceCell,
        },
        {
          title: 'Days in Transit',
          field: 'daysInTransit',
          headerStyle: tableStyles.textAlignCenter,
          cellStyle: tableStyles.cellStyle,
          render: rowData => {
            return rowData.daysInTransit
              ? <span>{rowData.daysInTransit} Days</span>
              : null
          },
        },
        {
          title: 'Carrier price',
          field: 'carrierPrice',
          headerStyle: tableStyles.textAlignCenter,
          cellStyle: tableStyles.cellStyle,
          hidden: !isAdmin,
          render: rowData => {
            return rowData.carrierPrice
              ? <span>$ {rowData.carrierPrice} USD</span>
              : null
          },
        },
        {
          title: 'Customer price',
          field: 'customerPrice',
          headerStyle: tableStyles.textAlignCenter,
          cellStyle: tableStyles.cellStyle,
          render: rowData => {
            return rowData.customerPrice
              ? <span>$ {rowData.customerPrice}</span>
              : null
          },
        },
      ]}
      detailPanel={[{
        tooltip: 'See details',
        render: rowData => {
          return rowData.error
            ? <ErrorCard carrierName={rowData.carrierName} errorMessage={rowData.error} />
            : <Detail detailData={rowData.carrierCharges} isAdmin={isAdmin} />
        },
      }]}
      actions={[
        rowData => {
          if (rowData.carrierCharges === undefined) {
            rowData.error = 'No rates in carrier response'
          }
          const tooltip = rowData.error ? null : 'Select Rate'
          return ({
            icon: () => {
              return rowData.error
                ? <span className={classes.error}>Error</span>
                : (
                  <Checkbox
                    icon={<MdRadioButtonUnchecked className={classes.icon} />}
                    checkedIcon={<MdCheckCircle className={classes.icon} />}
                    checked={rowData.tableData.id === selectedIndex}
                  />
                )
            },
            onClick: () => {
              if (!rowData.error) {
                handleSelectChange(rowData)
              }
            },
            tooltip: tooltip,
          })
        },
      ]}
      components={{
        Container: props => (
          <ContainerTable>
            {props.children}
          </ContainerTable>
        ),
      }}
      options={{
        ...globalTableOptions,
        headerStyle: tableStyles.tHeader,
        rowStyle: (rowData) => { return rowData.error ? tableStyles.errorRow : tableStyles.successRow },
        actionsCellStyle: tableStyles.borderBottom,
        paging: ratesData.length > 5,
        pageSize: 5,
        header: showHeader,
      }}
      localization={{
        header: {
          actions: '',
        },
        body: {
          emptyDataSourceMessage: 'Please go and fill out the quote form to get rates info',
        },
      }}
    />
  )
}

export default RatesTable

RatesTable.propTypes = {
  ratesData: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  refId: PropTypes.string,
}
