import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  heading: {
    marginLeft: 24,
    color: theme.palette.primary.main,
    fontWeight: 400,
    lineHeight: 'normal',
    fontSize: 24,
  },
  title: {
    fontWeight: 400,
    color: theme.palette.common.second1,
  },
  text: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}))

export const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 31px 64px 34px 28px;
    display: flex;
    flex-flow: column;
    border: 1px solid ${theme.palette.common.second6};
    border-radius: 4px;
  `}
`

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  &.second {
    margin-top: 70px;
  }
`
