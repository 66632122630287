//  libraries
import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

//  hooks
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

// tools
import { formatToLocalString, IsRoleAllowed } from 'tools'

//  styles
import { useStyles, Root } from './styles'

export default function Carrier () {
  const classes = useStyles()
  const { type } = useParams()
  const {
    carrierName,
    serviceType,
    daysInTransit,
    carrierPrice,
    customerPrice,
    carrierCharges,
  } = useSelector(state => state.entities.shipmentDetails.data.rateInfo || {})
  const isTl = type === 'tl'

  return (
    <Root tl={isTl}>
      {!isTl && (
        <>
          <div>
            <Typography variant='h4' className={classes.title} align='center'>Carrier</Typography>
            <Typography variant='h4' className={classes.text1} align='center'>{carrierName || '...'}</Typography>
          </div>
          <div>
            <Typography variant='h4' className={classes.title} align='center'>Service </Typography>
            <Typography variant='h4' className={classes.text1} align='center'>{serviceType || '...'}</Typography>
          </div>
          <div>
            <Typography variant='h4' className={classes.title} align='center'>Days in transit</Typography>
            <Typography variant='h4' className={classes.text2} align='center'>{daysInTransit || '...'}</Typography>
          </div>
        </>
      )}
      {IsRoleAllowed('tucker', (
        <div>
          <Typography variant='h4' className={classes.title} align='center'>Carrier Price</Typography>
          <Typography variant='h4' className={classes.text2} align='center'>
            {carrierPrice ? `$${formatToLocalString(carrierPrice)}` : '...'}
          </Typography>
        </div>
      ))}
      <div>
        <Typography variant='h4' className={classes.title} align='center'>Customer Price</Typography>
        <Typography variant='h4' className={classes.text2} align='center'>
          {customerPrice ? `$${formatToLocalString(customerPrice)}` : '...'}
        </Typography>
      </div>
      {type === 'ltl' && (
        <>
          <Divider className='divider' />
          {IsRoleAllowed('tucker', (
            <div className='carrier'>
              <Typography variant='h5' className={classes.title2} align='center'>Carrier</Typography>
            </div>
          ))}
          <div className='customer'>
            <Typography variant='h5' className={classes.title2} align='center'>Customer</Typography>
          </div>
          {carrierCharges?.map((item) => (
            <Fragment key={item.description.replace(' ', '')}>
              <div>
                <Typography variant='h5' className={classes.title2}>{item.description || '...'}</Typography>
              </div>
              {IsRoleAllowed('tucker', (
                <div className='carrier'>
                  <Typography variant='h5' className={classes.text3} align='center'>
                    {formatToLocalString(item.carrierPrice) || '...'}
                  </Typography>
                </div>
              ))}
              <div className='customer'>
                <Typography variant='h5' className={classes.text3} align='center'>
                  {formatToLocalString(item.customerPrice) || '...'}
                </Typography>
              </div>
            </Fragment>
          ))}
        </>
      )}
    </Root>
  )
}
