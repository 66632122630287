import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles(theme => ({
  colorText: {
    color: theme.palette.common.second1,
    marginLeft: 18,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
  },
  inputBase: {
    height: 'auto',
    padding: '10.5px 14px',
  },
  button: {
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'none',
    width: 140,
    height: 50,
    color: theme.palette.common.second8,
    backgroundColor: '#290c3d',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  ref: {
    fontWeight: 400,
    color: theme.palette.common.second1,
    marginLeft: 10,
  },
}))

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
  grid-template-rows: 100%;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
`

export const Title = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: center;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemButton = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ReferenceContainer = styled.div`
  display: inline-flex;
  flex-flow: wrap row;
`

export const ItemRef = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 210px;
    height: 45px;
    border-radius: 4px;
    margin: .3em .3em;
    border: 1px solid ${theme.palette.common.second6}
    justify-content: space-between;
    align-items: center;
    .icon {
      width: 16px;
      height: 16px;
    }
  `}
`

export const customStyles = {
  option: (base) => ({
    ...base,
    fontSize: '10px',
    fontFamily: '"Roboto", sans-serif',
  }),
  control: (base, state) => {
    return {
      ...base,
      borderWith: '2px',
      borderStyle: 'solid',
      borderColor: state.selectProps.error ? 'red' : '#979797',
      boxShadow: 'none',
      '&:hover': {
        border: '2px solid #8D8E90',
      },
    }
  },
}
