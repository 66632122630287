//  libraries
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//  redux
import { cleanState, afterFirstLoginRequest, getKillSwitch } from 'redux/ducks/user'
import { primaryTabUpdate } from 'redux/ducks/primaryTab'
import { notificationCleanState as cleanNotifications } from 'redux/ducks/notifications'

//  tools
import { clearSession, clearStorage } from 'tools'

//  components
import NavBar from 'components/layouts/navBar'
import DialogModal from 'components/common/dialog'
import TermsAndConditionsLink from 'components/common/termsAndConditionsLink'

//  styles
import { useStyles } from './styles'

// idle-timer
import { useIdleTimer } from 'react-idle-timer'

import LogRocket from 'logrocket'

import API from 'API'

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('tucker-company-worldwide/customer-portal-ucker')
}

const Layout = ({ children }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const killSwitch = useSelector(state => state.session.data.killSwitch)
  const userRole = useSelector(state => state.session.data.role)
  const { isFirstLogin, userId, name, email } = useSelector(state => state.session.data)
  const [openModalDialog, setOpenModalDialog] = useState(false)

  if (process.env.NODE_ENV !== 'development') {
    LogRocket.identify(userId, {
      name: name,
      email: email,
    })
  }

  useEffect(() => {
    setOpenModalDialog(isFirstLogin || false)
  }, [isFirstLogin])

  const signOut = () => {
    clearSession()
    clearStorage()
    dispatch(cleanState())
    dispatch(cleanNotifications())
    dispatch(primaryTabUpdate('dashboard'))
    history.push('/login')
  }

  useEffect(() => {
    dispatch(getKillSwitch())
  }, [])

  useEffect(() => {
    if (killSwitch && userRole !== 'tucker') { signOut() }
  }, [killSwitch])

  const setCurActivity = () => {
    window.localStorage.setItem('lastActive', new Date())
  }

  // useEffect(() => {
  //   setCurActivity()
  //   const inteval = setInterval(() => {
  //     if (!window.localStorage.getItem('token')) {
  //       clearInterval(inteval)
  //       signOut()
  //     }
  //     API.adminService.getTimeout()
  //       .then((res) => {
  //         const timeout = res.data.timeout ? res.data.timeout * 60 * 1000 : 10 * 60 * 1000
  //         console.log(timeout)
  //         const lastTime = new Date(window.localStorage.getItem('lastActive'))
  //         const curTime = new Date()
  //         console.log(Number(curTime - lastTime))
  //         if (Number(curTime - lastTime) > timeout) {
  //           clearInterval(inteval)
  //           signOut()
  //         }
  //       })
  //   },
  //   5000)
  // }, [])

  useEffect(() => {
    let inteval
    // console.log('start timer')
    setCurActivity()
    API.adminService.getTimeout()
      .then((res) => {
        const timeout = res.data.timeout ? res.data.timeout * 60 * 1000 : 10 * 60 * 1000
        inteval = setInterval(() => {
          if (!window.localStorage.getItem('token')) {
            clearInterval(inteval)
            signOut()
          }
          // console.log(timeout)
          const lastTime = new Date(window.localStorage.getItem('lastActive'))
          const curTime = new Date()
          // console.log(Number(curTime - lastTime))
          if (Number(curTime - lastTime) > timeout) {
            clearInterval(inteval)
            signOut()
          }
        },
        5000)
      })

    return () => {
      // console.log('destroy page')
      clearInterval(inteval)
    }
  }, [])

  const onAction = () => {
    setCurActivity()
  }

  useIdleTimer({
    onAction,
    timeout: 5000,
    throttle: 500,
  })

  return (
    <Typography component='div' className={classes.container}>
      <NavBar />
      {children}
      <DialogModal
        open={openModalDialog}
        title='Terms and Conditions'
        okText='Accept'
        cancelText='Cancel'
        body={<TermsAndConditionsLink />}
        onCancel={() => {
          signOut()
          setOpenModalDialog(false)
        }}
        onOk={() => {
          dispatch(afterFirstLoginRequest({ isFirstLogin }))
        }}
      />
    </Typography>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Layout
