//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  styles
import { useStyles, Options } from './styles'
import { BsListUl } from 'react-icons/bs'
import { GoFileSymlinkFile } from 'react-icons/go'
import { FiFileText } from 'react-icons/fi'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

const listIcon = {
  shipment: <BsListUl color='#979797' className='icon' />,
  quote: <GoFileSymlinkFile color='#979797' className='icon' />,
  accounting: <FiFileText color='#979797' className='icon' />,
}

export default function PermissionsText ({ text, value, icon }) {
  const classes = useStyles()

  return (
    <Options>
      {
        listIcon[icon]
      }
      <Typography variant='h4' className={classes.text}>{text}</Typography>
      {
        value
          ? <MdCheckBox color='#4B2367' className='icon' />
          : <MdCheckBoxOutlineBlank color='#979797' className='icon' />
      }
    </Options>
  )
}

PermissionsText.propTypes = {
  text: PropTypes.string,
  value: PropTypes.bool,
  icon: PropTypes.string.isRequired,
}
