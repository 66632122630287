import keyMirror from 'keymirror-nested'

export default keyMirror({
  USER: {
    LOGIN: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    AFTER_FIRST_LOGIN: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    FORGOT_PASSWORD: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    RESET_PASSWORD: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    ACTIVATE: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    RESEND_ACTIVATION_EMAIL: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    COMPANIES: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    GET_KILLSWITCH: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      }
    },
    UPDATE_KILLSWITCH: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      }
    },
    UPDATE: null,
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
  },
})
