export const globalTableStyles = {
  tHeader: {
    color: '#979797',
    fontSize: 14,
    borderBottom: 'solid 2px #eaeff4',
    position: 'unset',
    textAlign: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  successRow: {
    borderBottom: 'solid 1px #eaeff4',
  },
  cellStyle: {
    textAlign: 'center',
    borderBottom: 'none',
  },
  borderBottom: {
    borderBottom: 'none',
  },
}

export const globalTableOptions = {
  toolbar: false,
  search: false,
  actionsColumnIndex: -1,
  sorting: false,
  emptyRowsWhenPaging: false,
  detailPanelType: 'single',
  pageSizeOptions: [10, 20, 30, 50],
  pageSize: 10,
  paginationType: 'stepped',
  loadingType: 'linear',
  detailPanelColumnAlignment: 'right',
}
