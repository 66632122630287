//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

//  components
import Loader from 'components/common/loader'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

// redux
import { modalUpdate } from 'redux/ducks/modal'

//  tools
import { passwordValidator } from 'tools'

//  styles
import { useStyles } from './styles'
import { FaKey } from 'react-icons/fa'

function FormChangePassword ({ customMessage, buttonText, action }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { handleSubmit, register, errors, setError } = useForm()
  const { id } = useParams()
  const loading = useSelector(state => state.session.loading)

  const onSubmit = ({ newPassword, confirmPassword }) => {
    if (newPassword !== confirmPassword) {
      setError('confirmPassword', 'notMatch', 'New password and confirm must be equal')
      return
    }

    dispatch(action({ password: newPassword, token: id }, () => {
      dispatch(modalUpdate(true))
    }))
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.message}>
        <Typography className={classes.messageText}>{customMessage}. </Typography>
        <Typography className={classes.messageText}>Your password must include at least one uppercase letter, one number and special character</Typography>
      </div>
      <div className={classes.textInputs}>
        <TextField
          fullWidth
          className={classes.input}
          label='Password'
          variant='filled'
          type='password'
          autoComplete='new-password'
          name='newPassword'
          inputRef={register(passwordValidator)}
          error={!!errors.newPassword}
          InputProps={{
            className: classes.inputBase,
            endAdornment: (
              <InputAdornment position='end'>
                <FaKey color='#4b2367' />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText className={classes.errorForm} error={!!errors.newPassword}>
          {errors?.newPassword?.message}
        </FormHelperText>
        <TextField
          className={classes.input}
          label='Confirm Password'
          variant='filled'
          fullWidth
          type='password'
          inputRef={register(passwordValidator)}
          name='confirmPassword'
          error={!!errors.confirmPassword}
          InputProps={{
            className: classes.inputBase,
            endAdornment: (
              <InputAdornment position='end'>
                <FaKey color='#4b2367' />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText className={classes.errorForm} error={!!errors.confirmPassword}>
          {errors?.confirmPassword?.message}
        </FormHelperText>
      </div>
      <Button
        type='submit'
        className={classes.submitButton}
        variant='contained'
        disabled={loading}
        style={{ position: 'relative' }}
      >
        {buttonText}
      </Button>
      <Loader open={loading} />
    </form>
  )
}

FormChangePassword.propTypes = {
  customMessage: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
}

export default FormChangePassword
