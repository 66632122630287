// BaseModel
import baseModel from 'redux/baseModel'
import { errorMessages } from 'mocks/errorMessages'

export default {
  ...baseModel,
  setMessage: (state, newMessage, commentType) => {
    const newDetails = state.data
    newDetails.comments[commentType] = [
      ...(newDetails.comments[commentType] || []),
      newMessage,
    ]
    newDetails.comments.loading = false
    return {
      ...state,
      loading: false,
      errors: [],
      data: newDetails,
    }
  },

  customLoadingMessage: (state) => {
    const newDetails = state.data
    newDetails.comments = {
      ...newDetails.comments,
      loading: true,
    }
    return {
      ...state,
      data: newDetails,
    }
  },

  customErrorComments: (state, data = {}) => {
    const newDetails = state.data
    newDetails.comments = {
      ...newDetails.comments,
      loading: false,
    }
    return {
      ...state,
      data: newDetails,
      errors: [...state.errors, errorMessages[data.error] || data.error],
    }
  },
  customClean: state => {
    return {
      ...state,
      errors: [],
      loading: true,
    }
  },
}
