import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 48,
    color: theme.palette.common.second3,
    fontSize: 15,
    fontWeight: 400,
  },
  text: {
    color: theme.palette.common.second2,
    fontWeight: 400,
  },
  checked: {
    color: theme.palette.common.second6,
  },
  icon: {
    marginRight: 20,
    fontSize: 25,
  },
}))

export const Content = styled.div`
  ${({ theme }) => css`
    margin-top: 59px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 50px 20px;
  `}
`

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 40px;
    padding: 0 20px;
    border-radius: 4px;
    border: 1px solid ${theme.palette.common.second6}
  `}
`
