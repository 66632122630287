//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

//  styles
import { useStyles } from './styles'

export default function CellRepresentatives ({ representatives = null }) {
  const classes = useStyles()
  if (representatives) {
    return (
      <div>
        {representatives?.salesRep &&
          <Typography align='center' className={classes.primaryColor}>
            {`SR: ${representatives?.salesRep}`}
          </Typography>}
        {representatives?.firstManager &&
          <Typography align='center' className={classes.primaryColor}>
            {`AM1: ${representatives?.firstManager}`}
          </Typography>}
        {representatives?.secondManager &&
          <Typography align='center' className={classes.primaryColor}>
            {`AM2: ${representatives?.secondManager}`}
          </Typography>}
      </div>
    )
  }
  return <Typography align='center'>...</Typography>
}

CellRepresentatives.propTypes = {
  representatives: PropTypes.object,
}
