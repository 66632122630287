//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

//  styles
import { useStyles, Root } from './styles'

export default function SimpleDataBox ({ data }) {
  const classes = useStyles()

  return (
    <Root>
      {data.map(({ title, text }) => (
        <Box m='1rem 0' key={title} display='flex'>
          <Typography variant='h5' className={classes.title} noWrap>{title}</Typography>
          <Typography variant='h4' className={classes.text}>{text}</Typography>
        </Box>
      ))}
    </Root>
  )
}

SimpleDataBox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
}
