//  libraries
import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

export default function TermsAndConditionsLink () {
  return (
    <Typography component='span'>
      {'I accept the Customer Portal '}
      <Link
        href='https://tuckerco.com/wp-content/uploads/2012/05/TCW-STOS-Web-Version-1.pdf'
        target='_blank'
        rel='noopener noreferrer'
      >
        Terms and Conditions.
      </Link>
    </Typography>
  )
}
