import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 400,
    color: theme.palette.common.second1,
  },
  title: {
    fontWeight: 400,
    color: theme.palette.common.second3,
  },
  text: {
    fontSize: 18,
    color: theme.palette.common.second2,
    fontWeight: 500,
  },
  text2: {
    color: theme.palette.common.second3,
    fontWeight: 500,
    fontStyle: 'italic',
    marginRight: '30px',
  },
}))

export const Root = styled.div`
  margin-left: 52px;
  margin-top: 27px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 500px);
  gap: 10px 60px;
  align-items: start;
`

export const Content = styled.div`
  ${({ theme }) => css`
    width: 500px;
    padding: 19px 19px 33px 26px;
    display: grid;
    row-gap: 25px;
    border: 1px solid ${theme.palette.common.second6};
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  `}
`

export const Row = styled.div`
  display: flex;
  .right {
    margin-right: 33px;
  }
`
