//  libraries
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

// Components
import CompanySelector from 'components/common/companySelector'
import FilterBar from 'components/common/filterBar/AccountFilter'
import BillingHistory from 'components/accounting/billingHistory'
import Info from 'components/accounting/info'
import AccountingList from 'components/accounting/accountList'

//  hooks
import { useDispatch, useSelector } from 'react-redux'

//  redux
import { AccountingInfoRequest } from 'redux/ducks/accountingInfo'
import { AccountingListRequest } from 'redux/ducks/accountingList'

//  styles
import { useStyles, ToolBar } from './styles'
import { IoMdDownload } from 'react-icons/io'

function Accounting () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const company = useSelector(state => state.ui.companySelector?.companyChild || '')
  const { data, loading } = useSelector(state => state.entities.accountingList || {})
  const [filteredData, setFilteredData] = useState(undefined)
  useEffect(() => {
    if (!company || company.value === '5f36d688fd875233200cead6') return
    dispatch(AccountingInfoRequest(company.mlCompanyId))
    dispatch(AccountingListRequest(company.mlCompanyId))
    //  eslint-disable-next-line
  }, [company.value])

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const handleReset = () => {
    setFilteredData(data)
  }

  return (
    <div className={classes.container}>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h3' className={classes.h1}>Billing History</Typography>
        <Box display='flex'>
          <CompanySelector />
          <Button
            variant='contained'
            className={classes.button}
            startIcon={<IoMdDownload />}
          >
            Download
          </Button>
        </Box>
      </Box>
      <ToolBar>
        <FilterBar
          filters={['searchBar', 'invoiceDate', 'dueDate']}
          data={data}
          setData={setFilteredData}
          onReset={handleReset}
        />
        <BillingHistory />
        <Info />
      </ToolBar>
      <AccountingList data={filteredData || data} loading={loading} />
    </div>
  )
}

export default Accounting
