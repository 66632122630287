import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  secondRow: {
    gridRow: 2,
    gridColumn: '1 / -1',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelAndItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unCheckedIcon: {
    padding: '1px',
    width: '21px',
    height: '21px',
    color: theme.palette.secondary.main,
    border: 'solid 3px #989898',
    borderRadius: '3px',
  },
  checkedIcon: {
    color: theme.palette.secondary.main,
    background: theme.palette.common.second6,
    borderRadius: '3px',
    padding: '3px',
    width: '21px',
    height: '21px',
  },
  radio: {
    margin: '0.5em 0.5em',
    '& .MuiTypography-root': {
      color: theme.palette.common.second1,
      paddingBottom: 8,
      paddingTop: 3.2833,
      fontSize: 18,
      fontWeight: 'normal',
      fontStretch: 'normal',
    },
  },
  conditionalDisplay: ({ displayValue }) => {
    return {
      display: displayValue ? 'flex' : 'none',
    }
  },
}))
