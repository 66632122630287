import { FETCH } from '../fetch'
import { shipmentRoutes } from '../routes/shipment'

export class Shipment {
  async createLtl (payload) {
    const response = await FETCH('post', shipmentRoutes.createLtl, payload)
    return response
  }

  async createTl (payload) {
    const response = await FETCH('post', shipmentRoutes.createTl, payload)
    return response
  }

  async createPdfLtlPreview (payload) {
    const response = await FETCH('post', shipmentRoutes.createPdfLtl, payload, null, true)
    return response
  }

  async createPdfTLPreview (payload) {
    const response = await FETCH('post', shipmentRoutes.createPdfTl, payload, null, true)
    return response
  }

  async getPdfTL (shipmentId) {
    const response = await FETCH('get', shipmentRoutes.getPdfTl.replace(':shipmentId', shipmentId), {}, null, true)
    return response
  }

  async getPdfLTL (shipmentId) {
    const response = await FETCH('get', shipmentRoutes.getPdfLtl.replace(':shipmentId', shipmentId), {}, null, true)
    return response
  }

  async getLtl (data) {
    let response
    const { shipmentId, ...payload } = data || {}
    if (shipmentId) {
      response = await FETCH('get', shipmentRoutes.getById.replace(':shipmentId', shipmentId), payload)
    } else {
      response = await FETCH('get', shipmentRoutes.getLtl, payload)
    }
    return response
  }

  async getTl (data) {
    let response
    const { shipmentId, ...payload } = data || {}
    if (shipmentId) {
      response = await FETCH('get', shipmentRoutes.getById.replace(':shipmentId', shipmentId), payload)
    } else {
      response = await FETCH('get', shipmentRoutes.getTl, payload)
    }

    return response
  }

  async putLtl (data) {
    const { shipmentId, ...payload } = data || {}
    const response = await FETCH('put', shipmentRoutes.getById.replace(':shipmentId', shipmentId), payload)
    return response
  }

  async putTl (data) {
    const { shipmentId, ...payload } = data || {}
    const response = await FETCH('put', shipmentRoutes.getById.replace(':shipmentId', shipmentId), payload)
    return response
  }

  async getLtlRates (payload) {
    const response = await FETCH('post', shipmentRoutes.getLtlRates, payload)
    return response
  }

  async getDocument (data) {
    const { shipmentId, documentId } = data || {}
    const response = await FETCH('get', shipmentRoutes.getDocument.replace(':shipmentId', shipmentId).replace(':documentId', documentId), {}, null, true)
    return response
  }

  async sendEmail (data) {
    const response = await FETCH('post', shipmentRoutes.sendEmail, data)
    return response
  }

  async updateStatus (data) {
    const response = await FETCH('get', shipmentRoutes.updateStatus.replace(':shipmentId', data))
    return response
  }

  async setTlMessage (payload, shipmentId) {
    const response = await FETCH('post', shipmentRoutes.setTlComment.replace(':shipmentId', shipmentId), payload)
    return response
  }
}
