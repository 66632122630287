import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  value: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.common.second1,
  },
}))

export const Content = styled.div`
  display: flex;
  grid-area: history;
  align-items: flex-start;
  margin-top: 1.5rem;
  & > .item {
    margin: 0 13px;
    min-width: 120px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`
