import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

const size = '200px'

export const useStyles = makeStyles(theme => ({
  avatar: {
    width: size,
    height: size,
    background: theme.palette.primary.main,
  },
  inputFile: {
    display: 'none',
  },
}))

export const AvatarContainer = styled.div`
  ${({ theme }) => css`
    width: ${size};
    height: ${size};
    border-radius: 50%;
    position: relative;
    overflow: hidden;
  `}
`

export const Upload = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 20%;
    background-color: #ECEDF4;
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      opacity: 0.8;
      transform: scale(1.3);
      transition: opacity 0.3s;
      cursor: pointer;
    }
    & .icon {
      font-size: 25px;
      color: ${theme.palette.primary.main}
    }
  `}
`
