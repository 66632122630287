import { call, takeEvery, put } from 'redux-saga/effects'
import API from 'API'
import TYPES from './types'
import {
  locationGetSucceeded,
  locationGetFailed,
  locationDeleteSucceeded,
  locationDeleteFailed,
  locationSaveSucceeded,
  locationSaveFailed,
  locationUpdateSucceeded,
  locationUpdateFailed,

} from './actions'

function * fetchGetLocations (action) {
  try {
    const { data } = yield call(API.location.getLocationByName, action.payload)
    yield put(locationGetSucceeded(data.data))
  } catch (error) {
    const newError = error.response.data || { error: error.message }
    yield put(locationGetFailed(newError))
  }
}

function * fetchDeleteLocations (action) {
  try {
    const { data } = yield call(API.location.deleteLocation, action.locationId)
    yield put(locationDeleteSucceeded(data.data))
    action.successCallback()
  } catch (error) {
    action.errorCallback()
    const newError = error.response.data || { error: error.message }
    yield put(locationDeleteFailed(newError))
  }
}

function * fetchSaveLocations (action) {
  try {
    const { data } = yield call(API.location.saveNewLocation, action.payload)
    yield put(locationSaveSucceeded(data.data))
    action.successCallback(data.data._id)
  } catch (error) {
    action.errorCallback()
    const newError = error.response.data || { error: error.message }
    yield put(locationSaveFailed(newError))
  }
}

function * fetchUpdateLocation (action) {
  const { locationId, payload, successCallback, errorCallback } = action
  try {
    const { data } = yield call(API.location.updateLocation, locationId, payload)
    locationUpdateSucceeded(data.data)
    successCallback()
  } catch (error) {
    errorCallback()
    const newError = error.response.data || { error: error.message }
    yield put(locationUpdateFailed(newError))
  }
}

export default function * rootLocation () {
  yield takeEvery(TYPES.LOCATIONS.GET.REQUEST, fetchGetLocations)
  yield takeEvery(TYPES.LOCATIONS.DELETE.REQUEST, fetchDeleteLocations)
  yield takeEvery(TYPES.LOCATIONS.SAVE.REQUEST, fetchSaveLocations)
  yield takeEvery(TYPES.LOCATIONS.UPDATE.REQUEST, fetchUpdateLocation)
}
