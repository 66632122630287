import TYPES from './types'

/**
 * Get locations by company name
 */
export const locationGetRequest = (payload) => ({
  type: TYPES.LOCATIONS.GET.REQUEST,
  payload,
})

export const locationGetSucceeded = (data) => ({
  type: TYPES.LOCATIONS.GET.SUCCEEDED,
  data,
})

export const locationGetFailed = (data) => ({
  type: TYPES.LOCATIONS.GET.FAILED,
  data,
})

/**
 * Delete location
 */
export const locationDeleteRequest = (locationId, successCallback, errorCallback) => ({
  type: TYPES.LOCATIONS.DELETE.REQUEST,
  locationId,
  successCallback,
  errorCallback,
})

export const locationDeleteSucceeded = (data) => ({
  type: TYPES.LOCATIONS.DELETE.SUCCEEDED,
  data,
})

export const locationDeleteFailed = (data) => ({
  type: TYPES.LOCATIONS.DELETE.FAILED,
  data,
})

/**
 * Save location
 */
export const locationSaveRequest = (payload, successCallback, errorCallback) => ({
  type: TYPES.LOCATIONS.SAVE.REQUEST,
  payload,
  successCallback,
  errorCallback,
})

export const locationSaveSucceeded = (data) => ({
  type: TYPES.LOCATIONS.SAVE.SUCCEEDED,
  data,
})

export const locationSaveFailed = (data) => ({
  type: TYPES.LOCATIONS.SAVE.FAILED,
  data,
})

/**
 * Save location
 */
export const locationUpdateRequest = (locationId, payload, successCallback, errorCallback) => ({
  type: TYPES.LOCATIONS.UPDATE.REQUEST,
  locationId,
  payload,
  successCallback,
  errorCallback,
})

export const locationUpdateSucceeded = (data) => ({
  type: TYPES.LOCATIONS.UPDATE.SUCCEEDED,
  data,
})

export const locationUpdateFailed = (data) => ({
  type: TYPES.LOCATIONS.UPDATE.FAILED,
  data,
})
