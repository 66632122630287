//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import McleodInput from './mcLeodInput'
import AdditionalInfo from './additionalInfo'
import GeneralForm from '../generalForm'

//  styles
import { useStyles, Root } from './styles'

export default function Company () {
  const classes = useStyles()

  return (
    <Root>
      <Typography variant='h1' className={classes.title}>
        General
      </Typography>
      <McleodInput />
      <AdditionalInfo />
      <GeneralForm location='general' start={2} />
    </Root>
  )
}
