//  libraries
import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers'

//  components
import FormInputs from 'components/common/formInputs'

//  hooks
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

//  tools
import { sendEmailValidation } from 'tools'
import API from 'API'

//  styles
import { useStyles, Root, Content, Title, Item } from './styles'

export default function Email ({ open, handleModal, email = 'leigh.sauter@tuckerco.com', subject, shipmentId }) {
  const classes = useStyles()
  const { addToast, removeAllToasts } = useToasts()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      to: email,
      subject,
    },
    provider: yupResolver(sendEmailValidation),
  })

  useEffect(() => {
    return () => {
      removeAllToasts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSend = (data) => {
    setLoading(true)
    const payload = { ...data, shipmentRefId: shipmentId }
    API.shipmentService.sendEmail(payload)
      .then(() => {
        addToast('Support email sent.', {
          appearance: 'success',
          autoDismiss: true,
          id: 'success-support-email',
        })
      })
      .catch(() => {
        addToast('Something went wrong. Please try later or contact to the admin', {
          appearance: 'error',
          autoDismiss: true,
          id: 'error-support-email',
        })
      })
      .finally(() => {
        handleModal(false)()
        setLoading(false)
      })
  }

  return (
    <Modal
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      onClose={handleModal(false)}
    >
      <Root>
        <Title>
          <Typography variant='subtitle1' className={classes.head}>
            Email Support
          </Typography>
        </Title>
        <Content onSubmit={handleSubmit(handleSend)}>
          <Item>
            <Typography variant='h4' className={classes.title} component='label'>To:</Typography>
            <FormInputs.Input
              name='to'
              inputRef={register({
                required: 'Required field',
              })}
              showError={!!errors.to?.message}
              error={errors.to}
              disabled
            />
          </Item>
          <Item>
            <Typography variant='h4' className={classes.title} component='label'>Subject:</Typography>
            <FormInputs.Input
              name='subject'
              inputRef={register({
                required: 'Required field',
              })}
              showError={!!errors.subject?.message}
              error={errors.subject}
            />
          </Item>
          <Item>
            <Typography variant='h4' className={classes.title} component='label'>Message:</Typography>
            <FormInputs.Input
              name='body'
              inputRef={register({
                required: 'Required field',
                maxLength: {
                  value: 1000,
                  message: 'The message is very long!',
                },
                minLength: {
                  value: 5,
                  message: 'Please add 5 letters or more.',
                },
              })}
              showError={!!errors.body?.message}
              error={errors.body}
              multiline
              rows={5}
            />
          </Item>
          <Button
            variant='contained'
            classes={{ root: classes.button, disabled: classes.disabled }}
            type='submit'
            disabled={loading}
          >
            Send
            {loading && <CircularProgress color='primary' size={20} className={classes.loading} />}
          </Button>
        </Content>
      </Root>
    </Modal>
  )
}

Email.propTypes = {
  open: PropTypes.bool,
  handleModal: PropTypes.func,
  email: PropTypes.string,
  subject: PropTypes.string,
  shipmentId: PropTypes.string,
}
