// BaseModel
import baseModel from 'redux/baseModel'

export default {
  ...baseModel,
  customLoading: (state, table) => {
    return {
      ...state,
      [table]: {
        ...state[table],
        loading: true,
      },
      errors: [],
    }
  },
  saveData: (state, payload, table) => {
    return {
      ...state,
      [table]: {
        ...payload,
        page: payload.page,
        loading: false,
      },
    }
  },

  updateLtlSelectedRate: (state, refId, newSelectedRate) => {
    const index = state.ltl.data.findIndex(row => row.refId === refId)
    const newRates = state.ltl.data.slice()
    newRates[index].selectedRate = newSelectedRate
    return {
      ...state,
      ltl: {
        data: newRates,
        loading: false,
      },
    }
  },

  updateStatus: (state, payload) => {
    const { _id, value } = payload
    const newData = [...state.tl.data]
    const index = newData.findIndex(row => row._id === _id)
    newData[index].general.status = value
    return {
      ...state,
      tl: {
        ...state.tl,
        data: newData,
      },
    }
  },

  customError: (state, data = {}) => {
    return {
      ...state,
      errors: [...state.errors, data.error],
      ltl: {
        loading: false,
      },
      tl: {
        loading: false,
      },
      all: {
        loading: false,
      },
    }
  },
}
