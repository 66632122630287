//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { useStyles, Content } from './styles'

export default function Info () {
  const classes = useStyles()
  const { name, address, city, state, zip } = useSelector(state => state.entities.accountingInfo.data || {})

  return (
    <Content>
      <Typography variant='h3' className={classes.text1} align='right'>
        {name}
      </Typography>
      <Typography variant='h3' className={classes.text1} align='right'>
        {address}
      </Typography>
      <Typography variant='h3' className={classes.text1} align='right'>
        {city && state && `${city}, ${state}`}
      </Typography>
      <Typography variant='h3' className={classes.text2} align='right'>
        {zip}
      </Typography>
    </Content>

  )
}
