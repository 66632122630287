import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {
    width: '110px',
    WebkitAppearance: 'none',
  },
  icon: {
    marginLeft: 8,
    width: 10,
  },
  typography: {
    minWidth: 'max-content',
  },
}))
