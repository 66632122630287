import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles(theme => ({
  heading: {
    margin: '12px 20px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  item: {
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: theme.palette.common.second1,
  },
  text: {
    fontSize: 22,
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.common.second6,
  },
  total: {
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: '637280',
    opacity: 0.75,
    marginRight: 5,
  },
  value: {
    fontSize: 26,
    lineHeight: 'normal',
    color: theme.palette.primary.main,
  },
  fab: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.second8,
    gridColumnStart: '2',
    justifySelf: 'flex-end',
    '&:hover': {
      backgroundColor: '#637280',
    },
  },
}))

export const Root = styled.div`
  width: 100%;
  padding: 33px 170px 33px 50px;
  display: grid;
  row-gap: 38px;
  column-gap: 15px;
  grid-template-rows: auto 1fr auto;
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 80px;
`

export const Item = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 90px;
    border: 1px solid ${theme.palette.common.second6};
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 29px;
    .icon {
      color: #c9d6e3;
      width: 25px;
      height: 25px;
    }
  `}
`

export const Optional = styled.div`
  align-self: center;
`

export const ElementImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ElementText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
`

export const ElementButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  .icon {
    width: 50px;
    height: 50px;
  }
`

export const Total = styled.div`
  display: flex;
  align-items: center;
`
