//  libraries
import React, { useState } from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import PropTypes from 'prop-types'

//  components
import Card from './card'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { useStyles } from './style'
import { IoIosSearch } from 'react-icons/io'

const SetOfProducts = ({
  selectedArrayOfProducts,
  setSelectedArrayOfProducts,
  closeModal,
  handleChoosenProducts,
}) => {
  const [search, setSearch] = useState('')
  const products = useSelector(state => state.entities.products.data)

  const { weight = 0, quantity = 0 } = selectedArrayOfProducts.reduce((acc, { quantity = 0, totalWeight = 0 }) => (
    {
      weight: acc.weight + totalWeight,
      quantity: acc.quantity + quantity,
    }
  ), { weight: 0, quantity: 0 })
  const classes = useStyles()

  const searchProduct = e => {
    setSearch(e.target.value)
  }

  const handleClickCancel = e => {
    closeModal()
  }

  const onClickAddItems = _ => {
    handleChoosenProducts()
    closeModal()
  }

  return (
    <Grid className={classes.setOfProducts}>
      <OutlinedInput
        value={search}
        fullWidth
        placeholder='Search'
        className={classes.styledInput}
        startAdornment={<> <IoIosSearch color='disabled' position='start' /> </>}
        onChange={searchProduct}
      />
      <div className={classes.productsContainer}>
        {products.filter(item => item.itemName.toLowerCase().search(search.toLowerCase()) !== -1).map((element, index) => {
          return (
            <Card
              key={index}
              element={element}
              selectedArrayOfProducts={selectedArrayOfProducts}
              setSelectedArrayOfProducts={setSelectedArrayOfProducts}
            />
          )
        })}
      </div>
      <Grid container className={classes.styledTotalItems} direction='row' justify='center' item>
        <Grid direction='row' item container xs={6}>
          <Grid direction='row' justify='flex-start' container alignItems='center' item xs={6}>
            <Typography className={classes.styledTotals} component='span' variant='body2' gutterBottom>
              Total items added
            </Typography>
            <Typography
              className={classes.styledTotals}
              component='span'
              variant='caption'
              gutterBottom
            >
              {quantity}
            </Typography>
          </Grid>
          <Grid item justify='flex-start' alignItems='center' container xs={6}>
            <Typography className={classes.styledTotals} component='span' variant='body2' gutterBottom>
              Total Weight
            </Typography>
            <Typography
              className={classes.styledTotals}
              component='span'
              variant='caption'
              gutterBottom
            >
              {weight}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item justify='flex-end' alignItems='flex-start' container xs={12}>
            <Button onClick={handleClickCancel} className={classes.productButtons} variant='contained'>Close</Button>
            <Button onClick={onClickAddItems} className={classNames(classes.productButtons, classes.styledCancelButton)} variant='contained'>Add Items</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

SetOfProducts.propTypes = {
  closeModal: PropTypes.func,
  setSelectedArrayOfProducts: PropTypes.func,
  selectedArrayOfProducts: PropTypes.array,
}

export default SetOfProducts
