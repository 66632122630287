//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import FormHelperText from '@material-ui/core/FormHelperText'

//  components
import ImageSelector from 'components/admin/common/imageSelector'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { stepperUpdate } from 'redux/ducks/stepper'

//  mocks
import companyTypes from 'mocks/companyTypes.json'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { useStyles, Item, FirstItem, ImageItem, customStyles } from './styles'

export default function AdditionalInfo () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, errors, setValue, unregister, clearErrors, watch } = useFormContext()
  const { type: defaultType = '', parent: defaultParent, image: defaultImage } = useSelector(state => state.newForm)
  const accountsOptions = useSelector(state => state.entities.accounts.data || [])
  const activeSaveButton = useSelector(state => state.ui.stepper.activeSaveButton)
  const [searchParent] = accountsOptions.filter((option) => option.value === defaultParent)
  const [company, setCompany] = useState({ value: defaultType, label: defaultType })
  const [parent, setParent] = useState(searchParent)
  const [image, setImage] = useState(defaultImage)

  useEffect(() => {
    register('image')
    register({ name: 'type' }, requiredValidator)
    setValue('type', defaultType)
    setValue('image', defaultImage)
    return () => {
      unregister('image')
      unregister('type')
    }
    //  eslint-disable-next-line
  }, [])

  useEffect(() => {
    setParent(searchParent)
    setValue('parent', defaultParent)
    //  eslint-disable-next-line
  }, [searchParent])

  useEffect(() => {
    if (watch('type') === 'Company') {
      register({ name: 'parent' }, requiredValidator)
    } else register('parent')
    setValue('parent', defaultParent)
    return () => {
      unregister('parent')
    }
    //  eslint-disable-next-line
  }, [watch('type')])

  const handleSaveImage = (event) => {
    const file = event.target.files[0]
    const fileURL = URL.createObjectURL(file)
    setImage(fileURL)
    setValue('image', file)
  }

  const handleChangeCompanyType = (data) => {
    setCompany(data)
    setValue('type', data.value)
    clearErrors('type')
    if (!activeSaveButton) dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  const handleChangeCompanyParent = (data) => {
    setParent(data)
    setValue('parent', data.value)
    clearErrors('parent')
    if (!activeSaveButton) dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  return (
    <>
      <ImageItem>
        <ImageSelector
          image={image}
          handleSaveImage={handleSaveImage}
        />
      </ImageItem>
      <FirstItem>
        <Typography variant='h4' className={classes.text} component='label'>Company Type</Typography>
        <Select
          name='type'
          isSearchable
          value={company}
          onChange={handleChangeCompanyType}
          options={companyTypes}
          styles={customStyles}
          classNamePrefix='select'
          error={errors.type}
        />
        <FormHelperText error={!!errors.type}>{errors.type?.message}</FormHelperText>
      </FirstItem>
      {
        watch('type') === 'Company' && (
          <Item>
            <Typography variant='h4' className={classes.text} component='label'>Company Parent</Typography>
            <Select
              name='parent'
              isSearchable
              value={parent}
              onChange={handleChangeCompanyParent}
              options={accountsOptions}
              styles={customStyles}
              classNamePrefix='select'
              error={errors.parent}
            />
            <FormHelperText error={!!errors.parent}>{errors.parent?.message}</FormHelperText>
          </Item>
        )
      }
    </>
  )
}
