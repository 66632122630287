import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 5px 10px;
  ${({ hovered, theme }) => hovered && css`
    background: ${theme.palette.common.second6};
  `}
  /* &:hover {
    background: #cbd6e2;
  } */

`
