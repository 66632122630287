//  libraries
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

// components
import CompanySelector from 'components/common/companySelector'
import FilterBar from 'components/common/filterBar'
import UserList from 'components/admin/user/userList'

//  hooks
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// redux
import { newFormDelete } from 'redux/ducks/newForm'
import { UserListRequest } from 'redux/ducks/userList'
import { getKillSwitch, updateKillSwitch } from 'redux/ducks/user'

// styles
import { useStyles } from './styles'
import { BsPlus } from 'react-icons/bs'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, TextField } from '@material-ui/core'
import styled from 'styled-components'

function Users () {
  const dispatch = useDispatch()
  const history = useHistory()
  const companyId = useSelector(state => state.ui.companySelector.companyChild.value)
  const killSwitch = useSelector(state => state.session.data.killSwitch)
  const userRole = useSelector(state => state.session.data.role)

  const classes = useStyles()

  const [killSwitchFlag, setKillSwitchFlag] = useState(false)
  const [killSwitchOpen, setKillSwitchOpen] = useState(false)
  const [killSwitchWord, setKillSwitchWord] = useState(false)

  const [killSwitchValue, setKillSwitchValue] = useState()
  const role = useSelector(state => state.session.data.role)

  const handleNewUser = () => {
    dispatch(newFormDelete())
    history.push('/admin/users/new')
  }

  const validateUserRole = () => {
    if (userRole !== 'tucker') { return }
    setKillSwitchFlag(true)
  }

  const killswitchModalOpen = () => {
    setKillSwitchWord(false)
    setKillSwitchOpen(true)
  }

  const validateWord = (e) => {
    if (e.target.value !== 'enable' && e.target.value !== 'disable') { return }
    setKillSwitchWord(true)
  }

  const killSwitchConfirmation = () => {
    if (!killSwitchWord) { return }
    killswitchModalClose()
    dispatch(updateKillSwitch())
  }

  const killswitchModalClose = () => {
    setKillSwitchOpen(false)
    setKillSwitchWord(false)
  }

  const workKillSwitchValue = () => {
    if (killSwitch) {
      setKillSwitchValue('on')
    } else {
      setKillSwitchValue('off')
    }
  }

  // Get killswitch value trigger
  useEffect(() => {
    dispatch(getKillSwitch())
  }, [])

  // validate role to shor or hide killswitch dialog
  useEffect(() => {
    validateUserRole()
  }, [userRole])

  useEffect(() => {
    workKillSwitchValue()
  }, [killSwitch])

  const KillswitchToggle = styled((props) => (
    <Switch color='primary' checked={killSwitch} />
  ))(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      padding: 0,
      transitionDuration: '300ms',
      '& .Mui-checked': {
        transform: 'translateX(16px)',
        color: '#4B2367',
        '& .MuiSwitch-track': {
          backgroundColor: '#4B2367',
          opacity: 0.5,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#4B2367',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: 'red',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      backgroundColor: '"000',
      opacity: 0.38,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }))
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Typography variant='h1' className={classes.h1}>User List</Typography>
        <CompanySelector tucker />
      </div>
      <div className={classes.row}>
        <FilterBar
          filters={['searchBar', 'statusUsers', 'rolesUsers']}
          thunkAction={(data, callback) => UserListRequest({ companyId, ...data }, callback)}
        />

        <div className={classes.row}>

          {/* <FormControlLabel labelPlacement='start' label='Disable User Login' onClick={killswitchModalOpen} control={<KillswitchToggle />} /> */}

          <Button
            variant='contained'
            className={classes.button}
            startIcon={<BsPlus />}
            onClick={handleNewUser}
          >
            New User
          </Button>
        </div>
      </div>

      <UserList />

      {killSwitchFlag
        ? !killSwitch
          ? <Dialog open={killSwitchOpen} onClose={killswitchModalClose}>
            <DialogTitle className={classes.dialogTitle}>Confirm disable user login</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogContent}>
                This will disable access for all non-Tucker user accounts. To confirm please type in the following word: 'disable'
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='word'
                label='Confirmation word'
                type='text'
                fullWidth
                variant='standard'
                onChange={validateWord}
              />
            </DialogContent>
            <DialogActions>
              <Button className={classes.dialogCancel} onClick={killswitchModalClose}>Cancel</Button>
              <Button className={classes.dialogConfirm} onClick={killSwitchConfirmation}>Confirm</Button>
            </DialogActions>
            </Dialog>
          : <Dialog open={killSwitchOpen} onClose={killswitchModalClose}>
            <DialogTitle className={classes.dialogTitle}>Confirm enable user login</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogContent}>
                This will enable access for all non-Tucker user accounts. To confirm please type in the following word: 'enable'
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='word'
                label='Confirmation word'
                type='text'
                fullWidth
                variant='standard'
                onChange={validateWord}
              />
            </DialogContent>
            <DialogActions>
              <Button className={classes.dialogCancel} onClick={killswitchModalClose}>Cancel</Button>
              <Button className={classes.dialogConfirm} onClick={killSwitchConfirmation}>Confirm</Button>
            </DialogActions>
            </Dialog>
        : <></>}
    </div>
  )
}

export default Users
