import { makeStyles, withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import StepConnector from '@material-ui/core/StepConnector'

export const useStyles = makeStyles(theme => ({
  stepper: {
    paddingBottom: 0,
    height: '55vh',
  },
  stepButton: {
    margin: 0,
    padding: 0,
  },
  stepLabel: {
    margin: '0 auto',
  },
  stepLabelIconContainer: {
    padding: 0,
  },
  position: {
    color: theme.palette.common.second1,
    lineHeight: 'normal',
    marginTop: '12px',
  },
  terms: {
    justifySelf: 'flex-end',
  },
  termsHelper: {
    justifySelf: 'flex-end',
    fontSize: 14,
    color: 'red',
  },
}))

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr 50px;
`

export const Content = styled.div`
  grid-row: 2 / 4;
  grid-column: 2 / 2;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
export const Form = styled.form`
  display: grid;
  align-content: space-between;
  width: 100%;
  flex-grow: 1;
  grid-template-rows: 1fr auto;
`

export const CustomStepperConnector = withStyles({
  line: {
    height: '100%',
  },
  active: {
    paddingBottom: 2,
  },
  vertical: {
    margin: '0 auto',
    marginTop: '-15px',
    marginBottom: '-17px',
  },
})(StepConnector)
