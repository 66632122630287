//  libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { FaBell } from 'react-icons/fa'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { notificationListRequest, notificationListUpdateRequest, notificationCleanState } from 'redux/ducks/notifications'

//  styles
import { useStyles } from './styles'
import { CircularProgress, Hidden } from '@material-ui/core'

//  components
import NotifyItems from './notifyItems'

function NotifyPanel ({ openNotification = false }) { // openNotification controls opening from externally
  const classes = useStyles()
  const dispatch = useDispatch()
  const { loading, data: { results = [], unread } } = useSelector(state => state.entities.notifications)
  const [open, setOpen] = useState(openNotification)
  const [page, setPage] = useState(1)
  const [itemIsLoading, setItemLoading] = useState(false)

  useEffect(() => {
    dispatch(notificationListRequest({ params: { limit: 6, page } }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (!open) setOpen(openNotification)
    return () => {
      dispatch(notificationCleanState())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNotification])

  useEffect(() => {
    const notifications = []
    if (open) {
      results.forEach(notification => {
        if (!notification.read) {
          notifications.push(notification._id)
        }
      })
    }
    return () => { // update read notifications
      if (notifications.length > 0) {
        dispatch(notificationListUpdateRequest({
          notifications,
        }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.length, open])

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  return (
    <>
      <IconButton
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        color='inherit'
        aria-label='show new notifications'
      >
        <Badge className={classes.badge} badgeContent={unread} color='primary'>
          <FaBell className={classes.bell} />
        </Badge>
      </IconButton>
      <Hidden mdUp> <Typography>Notifications</Typography> </Hidden>
      {open && (
        <ClickAwayListener onClickAway={() => { setOpen(false) }}>
          <Paper className={classes.paper}>
            <Typography variant='h3' className={classes.title}>Notifications</Typography>
            <MenuList
              className={classes.menuList}
              style={itemIsLoading ? { pointerEvents: 'none', opacity: '0.4' } : {}}
              autoFocusItem={open} id='menu-list-grow'
              onKeyDown={handleListKeyDown}
            >
              <NotifyItems items={results} setItemLoading={setItemLoading} />
              {loading && (
                <CircularProgress
                  className={classes.loader}
                  style={{
                    width: 25,
                    height: 25,
                  }}
                />
              )}
              {results.length > 0 || unread > 0
                ? (
                  <Button onClick={() => setPage(page + 1)} className={classes.button} variant='contained'>
                    Show older notifications
                  </Button>
                )
                : <Typography variant='h4' align='center'>No notifications.</Typography>}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      )}
    </>
  )
}

NotifyPanel.propTypes = {
  openNotification: PropTypes.bool,
}

export default NotifyPanel
