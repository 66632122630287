import React, { useState } from 'react'
import {
  Grid,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'

// styles
import { useStyles } from './styles'
import { FaArrowLeft } from 'react-icons/fa'
import { listIcon } from 'assets'

// hooks
import { useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

// components
import RatesTable from 'components/quote/common/ratesTable'
import ImageNotDraggable from 'components/common/imageNotDraggable'
import SummaryInformationLTLQuote from 'components/common/summaryInformationLTLQuote'

function ShipmentRatesList () {
  const classes = useStyles()
  const history = useHistory()
  const [getBOLButtonDisable, setGetBOLButtonDisable] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const response = useSelector(state => state.entities.carrierList.data) || {}
  const newForm = useSelector(state => state.newForm)
  const { from, to, products, accessorials } = newForm
  if (from === undefined || to === undefined || products === undefined) {
    history.push('/shipment/new/ltl')
  }
  const ratesData = [...response.successCarriers, ...response.errorCarriers]
  const totalWeight = products.reduce((prev, next) => {
    return (prev + (Number(next.weight) || Number(next.totalWeight))) || 0
  }, 0)

  const handleGetBOL = () => {
    setGetBOLButtonDisable(true)
    history.push('/shipment/bol/ltl')
  }

  return (
    <Box p={4} px={7} bgcolor='white'>
      <Grid container justify='center' spacing={3}>
        <Grid item md={12}>
          <Link to='/shipment/new/ltl'>
            <FaArrowLeft className={classes.backArrowLink} />
          </Link>
        </Grid>
        <Grid item container md={11} justify='flex-start'>
          <Typography variant='h1' className={classes.title}>Shipment Rate</Typography>
          <hr className={classes.hr} />
        </Grid>
        <Grid item md={10} className={classes.title}>
          <Box display='flex'>
            <ImageNotDraggable externalImage={listIcon} width='23px' />
            <Typography variant='h3' className={classes.h3}>Summary information</Typography>
          </Box>
        </Grid>
        <Grid item md={9}>
          <SummaryInformationLTLQuote from={from} to={to} products={products} accessorials={accessorials} totalWeight={totalWeight} />
        </Grid>
        <Grid item md={10} className={classes.title}>
          <Box display='flex'>
            <ImageNotDraggable externalImage={listIcon} width='23px' />
            <Typography variant='h3' className={classes.h3}>Select Rate</Typography>
          </Box>
        </Grid>
        <Grid item md={9}>
          <RatesTable ratesData={response.successCarriers} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </Grid>
        <Grid item container md={11} justify='flex-end' style={{ margin: '4rem 0' }}>
          <Box display='flex' justifyContent='space-between' width='25rem'>
            <Link to='/shipment/new/ltl'>
              <Button className={classes.backButton} variant='contained' color='primary'>
                Back to form
              </Button>
            </Link>
            <Button
              className={classes.createShipment}
              variant='contained'
              color='primary'
              onClick={handleGetBOL}
              disabled={ratesData.length === 0}
            >
              Preview BOL
              {getBOLButtonDisable && (
                <CircularProgress className={classes.spinner} color='secondary' />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ShipmentRatesList
