import TYPES from './types'
import model from './model'

const initialState = {
  loading: false,
  data: [],
  errors: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    /**
     * GET LOCATIONS
     */
    case TYPES.LOCATIONS.GET.REQUEST:
      return model.loading(state)
    case TYPES.LOCATIONS.GET.SUCCEEDED:
      return model.setLocationList(state, action.data)
    case TYPES.LOCATIONS.GET.FAILED:
      return model.error(state, action.data)
    /**
     * DELETE LOCATIONS
     */
    case TYPES.LOCATIONS.DELETE.REQUEST:
      return model.loading(state)
    case TYPES.LOCATIONS.DELETE.SUCCEEDED:
      return model.deleteLocation(state, action.data._id)
    case TYPES.LOCATIONS.DELETE.FAILED:
      return model.error(state, action.data)
    /**
     * SAVE LOCATIONS
     */
    case TYPES.LOCATIONS.SAVE.REQUEST:
      return model.loading(state)
    case TYPES.LOCATIONS.SAVE.SUCCEEDED:
      return {
        ...state,
        loading: false,
        errors: [],
      }
    case TYPES.LOCATIONS.SAVE.FAILED:
      return model.error(state, action.data)

    /**
     * UPDATE LOCATIONS
     */
    case TYPES.LOCATIONS.UPDATE.REQUEST:
      return model.loading(state)
    case TYPES.LOCATIONS.UPDATE.SUCCEEDED:
      return model.updateLocation(state, action.data)
    case TYPES.LOCATIONS.UPDATE.FAILED:
      return model.error(state, action.data)
    default:
      return { ...initialState }
  }
}
