import keyMirror from 'keymirror-nested'

export default keyMirror({
  ACCOUNTS: {
    FETCH: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
  },
})
