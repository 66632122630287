import { FETCH } from '../fetch'
import { accountingRoutes } from '../routes/accounting'

export class Accounting {
  async getInfo (data) {
    const response = await FETCH('get', accountingRoutes.getInfo.replace(':id', data), data)
    return response
  }

  async getInvoice (companyId, payload = {}) {
    const response = await FETCH('get', accountingRoutes.getInvoice.replace(':id', companyId), payload)
    return response
  }

  async getPdfInvoice (id) {
    const response = await FETCH('get', accountingRoutes.getPdfInvoice.replace(':id', id), {}, null, true)
    return response
  }

  async getPdfPOD (id) {
    const response = await FETCH('get', accountingRoutes.getPdfPOD.replace(':id', id), {}, null, true)
    return response
  }
}
