import React from 'react'
import { Box, Typography } from '@material-ui/core'

// styles
import { useStyles } from './styles'

const QuoteDateTD = ({ date = '...', time }) => {
  const classes = useStyles()
  return (
    <Box display='flex' width='100%' alignItems='center' flexDirection='column'>
      <Typography noWrap>{date}</Typography>
      {time && <Typography className={classes.miniSpan}>{time}</Typography>}
    </Box>
  )
}

export default QuoteDateTD
