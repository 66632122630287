//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ProfileForm from 'components/profile/profileForm'

//  styles
import { useStyles, Layout, Container, Title } from './styles'

export default function Profile () {
  const classes = useStyles()

  return (
    <Layout>
      <Container>
        <Title>
          <Typography variant='subtitle1' className={classes.title}>
            Profile
          </Typography>
        </Title>
        <ProfileForm />
      </Container>
    </Layout>
  )
}
