//  libraries
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'

import DateRange from '../components/dateRange'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { GoSearch } from 'react-icons/go'

//  styles
import { Root, FiltersContainer, useStyles } from '../styles'
import { useSelector } from 'react-redux'

function AccountFilter ({ filters = [], setData = null, data, propFilters = {}, onReset }) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [filtersValues, setFiltersValues] = useState({})
  const company = useSelector(state => state.ui.companySelector.companyParent)

  useEffect(() => {
    onReset()
    setFiltersValues({})
  }, [company.value])

  const filterHandler = ({ key, value }) => {
    setFiltersValues((currentValues) => ({ ...currentValues, [key]: value }))
  }

  const filterDataFunction = (dataArray = [], filters = {}) => {
    let filteredDataArray = dataArray
    const {
      searchTerm = '',
      'invoiceCreatedAt[gte]': invoiceFrom,
      'invoiceCreatedAt[lte]': invoiceTo,
      'dueDate[gte]': dueDateFrom,
      'dueDate[lte]': dueDateTo,
    } = filters

    const searchTermRegExp = searchTerm && new RegExp(searchTerm, 'i')

    const isDateBetween = ({ from, to, date }) => {
      const dateValue = new Date(date || null).getTime()
      const fromDate = new Date(from || null).getTime()
      const toDate = new Date(to || null).getTime()
      return dateValue >= fromDate && dateValue <= toDate
    }

    if (
      searchTerm ||
      (invoiceFrom && invoiceTo) ||
      (dueDateFrom && dueDateTo)
    ) {
      filteredDataArray = dataArray.filter((row) => {
        let isValid = false
        if (searchTerm) {
          const { billNumber, reference, orderId, invoiceNumber } = row
          const values = [billNumber, reference, orderId, invoiceNumber]
          isValid = values
            .map((value) => searchTermRegExp.test(value))
            .includes(true)
          if (!isValid) return isValid
        }
        if (invoiceFrom && invoiceTo) {
          const { billDate } = row
          isValid = isDateBetween({
            from: invoiceFrom,
            to: invoiceTo,
            date: billDate,
          })
          if (!isValid) return isValid
        }
        if (dueDateFrom && dueDateTo) {
          const { deliveryDate } = row
          isValid = isDateBetween({
            from: dueDateFrom,
            to: dueDateTo,
            date: deliveryDate,
          })
          if (!isValid) return isValid
        }
        return isValid
      })
    }
    return filteredDataArray
  }

  const onSearch = () => {
    setData(filterDataFunction(data, filtersValues))
  }

  // If no filters to render then return nothing
  if (!filters.length) return null

  return (
    <Root>
      <FiltersContainer>
        <TextField
          style={{ marginRight: '10px ' }}
          variant='outlined'
          value={filtersValues.searchTerm ?? ''}
          onChange={({ target }) => filterHandler({ key: 'searchTerm', value: target.value })}
          onKeyDown={(e) => { if (e.keyCode === 13) onSearch() }} // enter event handler
          placeholder='Search'
          InputProps={{
            startAdornment: <InputAdornment position='start'><GoSearch color='#CBD6E2' /></InputAdornment>,
          }}
        />
        <DateRange
          style={{ marginRight: '10px' }}
          filtersValues={filtersValues}
          cbHandler={filterHandler}
          gteKey='invoiceCreatedAt[gte]'
          lteKey='invoiceCreatedAt[lte]'
          placeholder='Invoice Date'
        />
        <DateRange
          style={{ marginRight: '10px' }}
          filtersValues={filtersValues}
          cbHandler={filterHandler}
          gteKey='dueDate[gte]'
          lteKey='dueDate[lte]'
          placeholder='Delivery Date'
        />
      </FiltersContainer>
      <Button
        style={{ marginRight: '10px ' }}
        disabled={isLoading}
        className={classes.button}
        variant='contained'
        onClick={onSearch}
      >
        Apply
        {isLoading && <CircularProgress size={30} color='primary' className={classes.spinner} />}
      </Button>
      {(Object.keys(filtersValues).length > 0) && <Button
        disabled={isLoading}
        className={classes.secondaryButton}
        variant='text'
        onClick={() => {
          onReset()
          setFiltersValues({})
        }}
      >
        Reset
                                                  </Button>}
    </Root>
  )
}

AccountFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  thunkAction: PropTypes.func,
  propFilters: PropTypes.object,
  addTypeFilter: PropTypes.bool,
}

export default AccountFilter
