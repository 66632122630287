//  libraries
import React from 'react'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  components
import CreateEditProduct from '../createEditProduct'

//  styles
import { useStyles } from './styles'

const EditModal = ({ index, open, handleClick, selectedArrayOfProducts, setSelectedArrayOfProducts, addedProduct, setChoosenArrayOfProducts, choosenArrayOfProducts }) => {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography component='div' className={classes.textEdit} align='center'>Edit Product </Typography>
          <div className={classes.formContainer}>
            <CreateEditProduct choosenArrayOfProducts={choosenArrayOfProducts} setChoosenArrayOfProducts={setChoosenArrayOfProducts} index={index} addedProduct={addedProduct} isEdit selectedArrayOfProducts={selectedArrayOfProducts} setSelectedArrayOfProducts={setSelectedArrayOfProducts} closeModal={handleClick} />
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

EditModal.propTypes = {
  handleClick: PropTypes.func,
  addedProduct: PropTypes.object,
  open: PropTypes.bool,
  setSelectedArrayOfProducts: PropTypes.func,
  selectedArrayOfProducts: PropTypes.array,
  index: PropTypes.number,
  choosenArrayOfProducts: PropTypes.array,
  setChoosenArrayOfProducts: PropTypes.func,
}

export default EditModal
