import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  resendButton: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    padding: '5px 10px',
    fontSize: 14,
    fontFamily: '"Roboto", sans-serif',
    borderRadius: 4,
    border: 'none',
    boxShadow: '0 2px 3px black',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5rem 0',
    transform: 'translate(0,0)',
    minWidth: 'max-content',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      cursor: 'alias',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
      transform: 'translate(0, 2px)',
    },
    '&[disabled]': {
      backgroundColor: theme.palette.common.second2,
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },

  loading: {
    position: 'absolute',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogTitle: {
    '& > h2': {
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
  },
  dialogContent: {
    color: 'black',
    fontWeight: 'normal',
  },
  dialogCancel: {
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: '#c53737',
  },
  dialogConfirm: {
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: theme.palette.primary.main,
  },
}))
