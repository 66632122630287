//  libraries
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Pusher from 'pusher-js'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Avatar from '@material-ui/core/Avatar'
import Hidden from '@material-ui/core/Hidden'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import PrimaryTabs from './primaryTabs'
import NotifyPanel from './notifyPanel'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//  redux
import { cleanState } from 'redux/ducks/user'
import { primaryTabUpdate } from 'redux/ducks/primaryTab'
import { notificationCleanState as cleanNotifications, notificationListAdd } from 'redux/ducks/notifications'

//  tools
import { clearSession, clearStorage } from 'tools'

//  styles
import { useStyles, DesktopContainer, MobileContainer } from './styles'
import { tuckerLogo } from 'assets'
import { FaUserCircle } from 'react-icons/fa'
import { FiMenu, FiCornerDownLeft } from 'react-icons/fi'

export default function NavBar () {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const { userId, name: username, role, image = null } = useSelector(state => state.session?.data)
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const [mobileNotificationOpen, setMobileNotificationOpen] = useState(false)
  const [stringRole, setStringRole] = useState()
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    encrypted: true,
  })

  let value = image

  if (value instanceof File) {
    value = URL.createObjectURL(value)
  } else if (typeof value === 'object') {
    value = undefined
  }

  useEffect(() => {
    const channel = pusher.subscribe(userId)
    channel.bind('notification', newNotification => {
      dispatch(notificationListAdd(newNotification))
    })
    return () => {
      pusher.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    switch (role) {
      case 'tucker':
        setStringRole('Administrative')
        break
      case 'logistic':
        setStringRole('Logistics Rep')
        break
      case 'account':
        setStringRole('Accounting')
        break
      case 'shipment':
        setStringRole('Shipping/Recieving')
        break
      case 'Customer Experience':
        setStringRole('Sales')
        break
      case 'sales':
        setStringRole('Sales')
        break
      case 'userAdmin':
        setStringRole('Client Admin')
        break
    }
  }, [role])

  const handleMenuClose = () => {
    setAnchorEl(null)
    setMobileMoreAnchorEl(null)
  }

  const handleSignOut = () => {
    clearSession()
    clearStorage()
    dispatch(cleanState())
    dispatch(cleanNotifications())
    dispatch(primaryTabUpdate('dashboard'))
    history.push('/login')
  }

  const handleProfile = () => {
    history.push('/profile')
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfile}>
        <IconButton
          color='inherit'
        >
          <FaUserCircle />
        </IconButton>
        Profile
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <IconButton
          color='inherit'
        >
          <FiCornerDownLeft />
        </IconButton>
        Sign Out
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => setMobileNotificationOpen(!mobileNotificationOpen)}>
        <Hidden mdUp>
          <NotifyPanel openNotification={mobileNotificationOpen} />
        </Hidden>
      </MenuItem>
      <MenuItem onClick={handleProfile}>
        <IconButton color='inherit'>
          <FaUserCircle />
        </IconButton>
        Profile
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <IconButton color='inherit'>
          <FiCornerDownLeft />
        </IconButton>
        Sign Out
      </MenuItem>
    </Menu>
  )

  const userInitials = (userName) => {
    try {
      userName = userName.trim()
      if (!userName) return 'Us'
      if (userName.length <= 2) return userName
      let splitted = userName.split(' ')
      splitted = splitted.filter(item => item.trim() !== '')
      if (splitted.length >= 2) {
        return `${splitted[0][0].toUpperCase()}${splitted[1][0].toUpperCase()}`
      }
      return userName.substring(0, 2)
    } catch (error) {
      console.log(error)
      return 'Us'
    }
  }

  return (
    <div className={classes.navBar}>
      <AppBar className={classes.appBar} position='static' color='secondary'>
        <Toolbar className={classes.root}>
          <ImageNotDraggable className={classes.logo} externalImage={tuckerLogo} width='100px' onClick={() => history.push('/')} />
          <PrimaryTabs />
          {role
            ? <span className={classes.role}>{`User role: ${stringRole}`}</span>
            : <></>}
          <DesktopContainer>
            <Hidden smDown>
              <NotifyPanel />
            </Hidden>
            <div
              className={classes.profile}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className={classNames(classes.icon, classes.iconFont)}>
                {username?.split(' ')[0] || 'Username'}
              </span>
              <Avatar
                className={classes.avatar}
                src={value?.search('blob:') === -1 ? `${value}?${Math.random()}` : value}
              >
                {userInitials(username)}
              </Avatar>
            </div>
          </DesktopContainer>
          <MobileContainer>
            <IconButton
              edge='start'
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={(e) => setMobileMoreAnchorEl(e.currentTarget)}
              color='inherit'
            >
              <FiMenu />
            </IconButton>
          </MobileContainer>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  )
}
