//  libraries
import React from 'react'

//  components
import MessageModal from 'components/common/messageModal'
import FormChangePassword from 'components/auth/formChangePassword'

//  hooks
import { useHistory } from 'react-router-dom'

// redux
import { resetPasswordRequest } from 'redux/ducks/user'

function ResetPassword () {
  const history = useHistory()
  const customMessage = 'Please enter an account password'

  return (
    <>
      <FormChangePassword action={resetPasswordRequest} customMessage={customMessage} buttonText='Reset password' />
      <MessageModal
        path='session.errors'
        title='SUCCESS!'
        body='You have been granted access to the Tucker Customer Portal. Sign in to quote and track shipments.'
        submitText='Go to Login'
        errorText='Go to Reset Password'
        handleSubmitButton={() => history.push('/login')}
        handleErrorButton={() => history.push('/forgot-password')}
      />
    </>
  )
}

export default ResetPassword
