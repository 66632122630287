import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    display: 'inline-block',
  },
  rowContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  content: {
  },
  secondaryText: {
    display: 'flex',
    color: '#7E7E7E',
    fontSize: 14,
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 0,
  },
})
