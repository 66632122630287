//  tools
import { loadStorage } from 'tools'

//  mocks
import module from 'mocks/module.json'

const userPrivileges = (currentPath, location, userRole) => {
  let permissions = ''
  let role = ''
  const privatePaths = ['/dashboard', '/profile']
  const hasSession = !!loadStorage()
  const finalRoute = hasSession ? '/dashboard' : `/login?redirect=${location}`

  if (hasSession) {
    permissions = loadStorage('permissions')
    role = loadStorage('role')
  }
  Object.entries(permissions).forEach(([key, value]) => {
    if (value) privatePaths.push(...module[key])
  });

  (role === userRole) && privatePaths.push(...module[role])
  let filteredPrivatePaths = privatePaths
  if (role === 'sales') {
    // ⬇ remove quote/list from sales even if user has this permission
    filteredPrivatePaths = privatePaths.filter(path => !path.includes('/quote/'))
  }

  const isValidPath = filteredPrivatePaths.includes(currentPath)
  const isRender = isValidPath && hasSession
  return { finalRoute, isRender }
}

export default userPrivileges
