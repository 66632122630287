import baseModel from 'redux/baseModel'
import { saveStorage } from 'tools'

export default {
  ...baseModel,

  loadCompanies: (state, resp = {}) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...resp.data,
      },
      loading: false,
      errors: [],
    }
  },

  setNewSession: (resp = {}) => {
    const { _id: userId, token, name, email, image, role, companies, permissions, isFirstLogin, ...rest } = resp.data

    saveStorage(userId, 'userId')
    saveStorage(token)
    saveStorage(name, 'name')
    saveStorage(email, 'email')
    saveStorage(image, 'image')
    saveStorage(role, 'role')
    saveStorage(companies, 'companies')
    saveStorage(permissions, 'permissions')
    saveStorage(isFirstLogin, 'isFirstLogin')

    return {
      ...resp,
      data: {
        userId,
        token,
        name,
        email,
        image,
        role,
        companies,
        permissions,
        isFirstLogin,
        ...rest,
      },
      loading: false,
      errors: [],
    }
  },

  update: (state, resp) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...resp,
      },
    }
  },
}
