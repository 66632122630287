import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  subtitleAdvancedOption: {
    fontSize: 14,
    color: '#979797',
    marginBottom: 5,
  },
  transportImage: {
    width: '100%',
    '&:hover': {
      width: '120%',
      cursor: 'pointer',
    },
  },
}))
