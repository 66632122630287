//  libraries
import React from 'react'
import PropTypes from 'prop-types'

//  styles
import { useStyles, IconContainer, Circle, IconText, IconText2 } from './styles'
import { MdFiberManualRecord } from 'react-icons/md'

export default function CustomIcon ({ active, completed, icon }) {
  const classes = useStyles()
  return (
    <IconContainer>
      {
        active ? (
          <Circle>
            <MdFiberManualRecord className={classes.activeIcon} />
            <IconText2>{icon}</IconText2>
          </Circle>
        ) : (
          <>
            <MdFiberManualRecord className={completed ? classes.completeIcon : classes.icon} />
            <IconText>{icon}</IconText>
          </>
        )
      }
    </IconContainer>
  )
}

CustomIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.number.isRequired,
}
