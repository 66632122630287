//  libraries
import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import Button from '@material-ui/core/Button'
import TablePagination from '@material-ui/core/TablePagination'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { shipmentListFetch, cleanState } from 'redux/ducks/shipmentList'

//  tools
import { dateFormat } from 'tools'

// styles
import { tableStyles, useStyles } from './styles'
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io'
import { MdInsertDriveFile } from 'react-icons/md'
import { tableIcons } from 'styles/tableIcons'
import { globalTableOptions } from 'styles/globalTableStyles'

// components
import FilterBar from 'components/common/filterBar'
import Menu from 'components/common/tableFragments/Menu'
import ContainerTable from 'components/common/tableFragments/containerTable'
import FormattedCell from 'components/common/tableFragments/cells/formattedCell'
import LastLocationCell from 'components/common/tableFragments/cells/LastLocationCell'
import SelectStatus from 'components/common/tableFragments/cells/SelectStatus'
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'

function LtlShipmentList () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const { data = [], page = 0, totalCount = 0, loading = true } = useSelector(state => state.entities.shipmentList.ltl)
  const filters = useSelector(state => state.ui.filters)
  const setPage = page - 1 < 0 ? 0 : page - 1 // Avoid to assign negative value.

  useEffect(() => {
    dispatch(shipmentListFetch('Ltl', { params: { limit, page: 1, ...filters } }))
    return () => {
      dispatch(cleanState())
    }
    //  eslint-disable-next-line
  }, [])

  return (
    <>
      <FilterBar
        filters={['searchBar', 'status', 'creationDate', 'pickupDate', 'deliveryDate', 'priceRange']}
        module='shipment'
        addTypeFilter
        thunkAction={(data, callback) => shipmentListFetch('Ltl', data, null, callback)}
      />
      <MaterialTable
        isLoading={loading}
        data={data}
        icons={tableIcons}
        actions={[
          {
            icon: 'save',
            tooltip: 'More Options',
            position: 'row',
            onClick: () => {},
          },
        ]}
        columns={[
          {
            title: 'Reference',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <b>#{rowData.refId}</b>,
          },
          {
            title: 'Status',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => <SelectStatus status={rowData.general?.status} />,
          },
          {
            title: 'Pro Number',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => rowData.references?.filter(item => item.ref === 'PRO NUMBER')[0]?.value || '...',
          },
          {
            title: 'Origin',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundUp className={classes.originIcon} />}
                mainText={rowData.origin?.city}
                secondMainText={rowData.origin?.state}
                secondaryText={rowData.origin?.postalCode}
              />
            ),
          },
          {
            title: 'Destination',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundDown className={classes.destinationIcon} />}
                mainText={rowData.destination?.city}
                secondMainText={rowData.destination?.state}
                secondaryText={rowData.destination?.postalCode}
              />
            ),
          },
          {
            title: 'Equipment Type',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => {
              return rowData.general?.equipmentType || '...'
            },
          },
          {
            title: 'Pickup Date',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.pickup?.startDate)}
                time={rowData.shipping?.pickup?.startTime}
              />
            )
            ,
          },
          {
            title: 'Delivery Date',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.delivery?.startDate)}
                time={rowData.shipping?.delivery?.startTime}
              />
            ),
          },
          {
            title: 'Last Location',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <LastLocationCell
                mainText={rowData.general?.currentCity}
                secondMainText={rowData.general?.currentState}
                firstSecondaryText={dateFormat(rowData.general?.lastUpdate, 'MM-DD-YYYY HH:MM')}
              />
            ),
          },
        ]}
        components={{
          Pagination: (componentsProps) => {
            return (
              <TablePagination
                {...componentsProps}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(shipmentListFetch('Ltl', { params: { limit, page: page + 1, ...filters } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  componentsProps.onChangeRowsPerPage(evt)
                  dispatch(shipmentListFetch('Ltl', { params: { limit: evt.target.value, page, ...filters } }))
                }}
              />
            )
          },
          Container: props => (
            <ContainerTable>
              {props.children}
            </ContainerTable>
          ),
          Toolbar: props => (
            <div>
              <div style={{ padding: '0px 10px' }}>
                {props.selectedRows.length ? <div label='Chip 1' color='secondary' style={{ marginRight: 5 }}><Button onClick={() => { console.log('Rows Selected', props.selectedRows) }} variant='outlined' startIcon={<MdInsertDriveFile />} style={{ height: 'auto', backgroundColor: '#63728022', color: '#63728055', textTransform: 'none', fontSize: '11pt', border: 0 }}>Export CSV</Button></div> : null}
              </div>
            </div>
          ),
          Action: props => (
            <Menu
              item={props.data}
            />
          ),
        }}
        options={{
          ...globalTableOptions,
          toolbar: true,
          showTitle: false,
          exportCsv: (props) => { console.log('to export in csv', props) },
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          selection: true,
          selectionProps: {
            color: 'primary',
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No Shipments to display',
          },
        }}
      />
    </>
  )
}

export default LtlShipmentList
