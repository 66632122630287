import TYPES from './types'
import { resolve } from 'tools'

export function shipmentListFetch (table, data, statePath = '', callback = () => {}) {
  return (dispatch, getState, api) => {
    dispatch(shipmentListRequest(table.toLowerCase()))
    const payload = data || resolve(statePath, getState())
    const endPoint = `get${table}`
    return api.shipmentService[endPoint](payload).then(
      ({ data }) => {
        dispatch(shipmentListSucceeded(data, table.toLowerCase()))
        callback()
      },
      (error) => {
        const newError = error.response?.data || { error: error.message }
        dispatch(shipmentListFailed(newError))
        callback()
      },
    )
  }
}

export const shipmentListRequest = table => ({
  type: TYPES.SHIPMENT_LIST.FETCH.REQUEST,
  table,
})

export const shipmentListSucceeded = (payload, table) => ({
  type: TYPES.SHIPMENT_LIST.FETCH.SUCCEEDED,
  payload,
  table,
})

export const shipmentListFailed = (payload) => ({
  type: TYPES.SHIPMENT_LIST.FETCH.FAILED,
  payload,
})

export const shipmentListUpdateStatus = payload => ({
  type: TYPES.SHIPMENT_LIST.UPDATE.STATUS,
  payload,
})

/**
 * GENERAL
 */

export const cleanErrors = () => ({
  type: TYPES.SHIPMENT_LIST.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.SHIPMENT_LIST.CLEAN.STATE,
})
