import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 100%;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, p, button, iframe {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  iframe {
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
  } 

  button: {
    background: transparent;
    border: 0;
    outline: 0;
  }

  body {
    background: #ffffff;
    margin: 0 auto;
    overscroll-behavior: none;
    width: 100%;
    height: 100vh;
  }

  #root {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    height: 100%
  }

  
  table > tbody > tr > .MuiTableCell-root{
    border-bottom: none;
    width: 3%;
  }
  
  table tbody:before {
    line-height:1em;
    content:".";
    color:white; 
    display:block;
  }
`
