import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: props => ({
    marginTop: '30px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    lineHeight: 'normal',
  }),
}))

export const Root = styled.div`
  display: grid;
  grid-auto-rows: 100px;
  grid-template-columns: repeat(auto-fill, 250px);
  justify-content: center;
  align-content: flex-start;
  column-gap: 50px;
`
