import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    borderBottom: '1px solid #DBDFE3',
  },
  closeButton: {
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: 'right',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  contentCloseButton: {
    display: 'block',
    padding: 0,
    paddingRight: theme.spacing(1),
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  content: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderBottom: '1px solid #DBDFE3',
  },
  contentTextPreDialog: {
    margin: 0,
    padding: 5,
    color: '#1a1a1a',
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  contentText: {
    margin: 0,
    padding: '0.8rem 2rem',
    color: '#1a1a1a',
  },
  button: {
    padding: '1rem',
    margin: '0.3rem',
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    color: 'white',
    textTransform: 'none',
    minWidth: 40,
    height: '2.5rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelButton: {
    marginLeft: 10,
    backgroundColor: '#888888',
    '&:hover': {
      backgroundColor: '#777',
    },
  },
  submitButton: {
    backgroundColor: '#D57800',
    marginLeft: '1rem',
    '&:hover': {
      backgroundColor: '#CC5500',
    },
  },
  successIcon: {
    fontSize: '3rem',
    color: theme.palette.common.orange,
    marginRight: '1rem',
  },
}))
