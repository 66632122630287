import React, { useState } from 'react'
import {
  ClickAwayListener,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Grid,
} from '@material-ui/core'

// hooks
import { useDispatch } from 'react-redux'

// tools
import { dateFormat } from 'tools'

// styles
import { useStyles } from './styles'
import { FaAngleDown } from 'react-icons/fa'
import { IoIosClose } from 'react-icons/io'

// components
import { advancedFilters } from '../../constants/filters'
import { filtersDeleteAdvancedFilters } from 'redux/ducks/filters'

function formatTextInput (filtersValuesState) {
  const {
    priceRange,
    transportType = '',
    'createdAt[gte]': createdSince,
    'createdAt[lte]': createdTo,
    'pickupWindow.startDate[gte]': pickupSince,
    'pickupWindow.startDate[lte]': pickupTo,
    'deliveryWindow.startDate[gte]': deliverySince,
    'deliveryWindow.startDate[lte]': deliveryTo,
  } = filtersValuesState

  const transportTypeFormatted = transportType.charAt(0).toUpperCase() + transportType.slice(1)
  const createdAt = !createdTo ? '' : `${dateFormat(createdSince)} - ${dateFormat(createdTo)}`
  const pickupDate = !pickupTo ? '' : `${dateFormat(pickupSince)} - ${dateFormat(pickupTo)}`
  const deliveryDate = !deliveryTo ? '' : `${dateFormat(deliverySince)} - ${dateFormat(deliveryTo)}`
  const editedPriceRange = priceRange ? `$ ${priceRange[0]} - $ ${priceRange[1]}` : ''

  let inputValue = [
    transportTypeFormatted,
    editedPriceRange,
    createdAt,
    pickupDate,
    deliveryDate,
  ].filter(textFilter => textFilter !== '').join(', ').replace(/^, /, '').replace('dry-van', 'Dry van')

  inputValue = inputValue.length > 37 ? inputValue.substring(0, 37).concat('...') : inputValue
  return inputValue
}

function DropDownFilters ({ filtersList, cbHandler, filtersValuesState, setFiltersValuesState }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const inputValue = formatTextInput(filtersValuesState)

  const cleanAdvancedFilters = () => {
    setFiltersValuesState(currentValues => {
      const newValues = { ...currentValues }
      const advancedFilters = [
        'createdAt[lte]',
        'createdAt[gte]',
        'pickupWindow.startDate[gte]',
        'pickupWindow.startDate[lte]',
        'deliveryWindow.startDate[gte]',
        'deliveryWindow.startDate[lte]',
        'priceRange',
        'transportType',
      ]
      dispatch(filtersDeleteAdvancedFilters(advancedFilters))
      advancedFilters.forEach(filter => delete newValues[filter])
      return newValues
    })
  }

  const handleClick = () => {
    if (open) {
      cleanAdvancedFilters()
    } else if (inputValue.length < 5) {
      setOpen(true)
    } else {
      cleanAdvancedFilters()
    }
  }

  const CreationDate = advancedFilters.creationDate.Component
  const PickupDate = advancedFilters.pickupDate.Component
  const DeliveryDate = advancedFilters.deliveryDate.Component
  const PriceRange = advancedFilters.priceRange.Component
  const TransportType = advancedFilters.transportType.Component

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.root}>
        <TextField
          id='rangeDate'
          readOnly
          fullWidth
          variant='outlined'
          className={classes.fieldText}
          onFocus={() => setOpen(true)}
          value={inputValue}
          placeholder='Advanced options'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton edge='end' onClick={handleClick}>
                  {
                    open || inputValue.length > 5
                      ? <IoIosClose />
                      : <FaAngleDown style={{ color: '#637280' }} />
                  }
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            readOnly: true, // to avoid the f... chrome autocomplete
          }}
        />
        {open && (
          <Box
            className={classes.dropdown}
            display='block'
            boxShadow={3}
          >
            <Grid container justify='center' spacing={4}>
              {
                filtersList.includes('transportType') &&
                (<Grid item xs={11} style={{ maxHeight: '7rem' }}><TransportType filtersValues={filtersValuesState} cbHandler={cbHandler} /></Grid>)
              }
              <Grid item xs={11} container direction='row' justify='space-between'>
                {
                  filtersList.includes('creationDate') &&
                  (<CreationDate filtersValues={filtersValuesState} cbHandler={cbHandler} />)
                }
                {
                  filtersList.includes('pickupDate') &&
                  (<PickupDate filtersValues={filtersValuesState} cbHandler={cbHandler} />)
                }
                {
                  filtersList.includes('deliveryDate') &&
                  (<DeliveryDate filtersValues={filtersValuesState} cbHandler={cbHandler} />)
                }
              </Grid>
              {
                filtersList.includes('priceRange') &&
                (<Grid item xs={11}><PriceRange filtersValues={filtersValuesState} cbHandler={cbHandler} /></Grid>)
              }
            </Grid>
          </Box>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default DropDownFilters
