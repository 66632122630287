import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.common.second3,
    fontSize: 15,
    fontWeight: 400,
    gridColumn: '1/-1',
    margin: '1rem 0',
  },
  text: {
    color: '#2E384D',
    fontWeight: 400,
  },
  checked: {
    color: theme.palette.common.second6,
  },
}))

export const ChecksContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 42%);
    gap: 0 20px;
    justify-content: center;
    align-content: center;
    margin-top: 10px;
  `}
`
