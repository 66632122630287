import TYPES from './types'

export const AccountsRequest = (payload, callback) => ({
  type: TYPES.ACCOUNTS.FETCH.REQUEST,
  payload,
  callback,
})

export const AccountsSucceeded = payload => ({
  type: TYPES.ACCOUNTS.FETCH.SUCCEEDED,
  payload,
})

export const AccountsFailed = payload => ({
  type: TYPES.ACCOUNTS.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.ACCOUNTS.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.ACCOUNTS.CLEAN.STATE,
})
