//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useFormContext, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  styles
import { instructionsIcon } from 'assets'
import { useStyles, Content, Item, ItemCheck, Title, Row } from './styles'

export default function InfoLtl () {
  const { register, errors, watch, control } = useFormContext()
  const shipping = useSelector(state => state.newForm.shipping)
  const classes = useStyles()

  return (
    <Content>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={instructionsIcon} width='22px' />
          <Typography variant='h4' className={classes.colorText} component='label'> Special instructions for Shipper </Typography>
        </Title>
        <TextField
          name='shipping.instructionsShipper'
          error={!!errors?.shipping?.instructionsShipper}
          variant='outlined'
          inputRef={register}
          multiline
          rows={5}
          InputProps={{
            className: classes.inputBase,
          }}
          defaultValue={shipping?.instructionsShipper}
        />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={instructionsIcon} width='22px' />
          <Typography variant='h4' className={classes.colorText} component='label'> Special instructions for Consignee </Typography>
        </Title>
        <TextField
          name='shipping.instructionsConsignee'
          error={!!errors?.shipping?.instructionsConsignee}
          variant='outlined'
          inputRef={register}
          multiline
          rows={5}
          InputProps={{
            className: classes.inputBase,
          }}
          defaultValue={shipping?.instructionsConsignee}
        />
      </Item>
      <ItemCheck>
        <Title>
          <Typography variant='h4' className={classes.colorText}>
            Would you like this shipment to be fully insured?
          </Typography>
        </Title>
        <Row>
          <Controller
            render={props => (
              <Checkbox
                onChange={e => props.onChange(e.target.checked)}
                checked={props.value}
                color='default'
                classes={{
                  checked: classes.checked,
                }}
              />
            )}
            defaultValue={!!shipping?.insured}
            name='shipping.insured'
            control={control}
          />
          <Typography variant='h5' className={classes.colorText} component='label'> Yes </Typography>
          {
            watch('shipping.insured', shipping?.insured) && (
              <>
                <Controller
                  render={props => (
                    <Checkbox
                      onChange={e => props.onChange(e.target.checked)}
                      checked={props.value}
                      color='default'
                      classes={{
                        checked: classes.checked,
                      }}
                    />
                  )}
                  defaultValue={!!shipping?.greater}
                  name='shipping.greater'
                  control={control}
                />
                <Typography variant='h5' className={classes.colorText} component='label'> Is the value greater than $2 lb.? </Typography>
              </>
            )
          }
        </Row>
      </ItemCheck>
    </Content>
  )
}
