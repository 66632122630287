//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

// redux
import { newFormDelete } from 'redux/ducks/newForm'

// hooks
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { locationButtonsDelete } from 'redux/ducks/locationButtons'

// styles
import { useStyles } from './styles'
import { IoMdAdd } from 'react-icons/io'

// components
import LtlQuoteList from 'components/quote/ltl/ltlLists/quoteList'
import TlQuoteList from 'components/quote/tl/tlLists/quoteList'
import AllTable from 'components/quote/all'
import LtlShipmentList from 'components/shipment/ltl/ltlLists/shipmentList'
import TlShipmentList from 'components/shipment/tl/tlLists/shipmentList'

const components = {
  Quote: {
    all: <AllTable />,
    ltl: <LtlQuoteList />,
    tl: <TlQuoteList />,
  },
  Shipment: {
    ltl: <LtlShipmentList />,
    tl: <TlShipmentList />,
  },
}

function ListTemplate ({ isShipmentList = false }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const type = useParams().type
  const modality = isShipmentList ? 'Shipment' : 'Quote'

  dispatch(locationButtonsDelete()) // clean locations in convert to shipment use case

  const handleNewQuoteButton = () => {
    dispatch(newFormDelete())
    history.push(`/${modality.toLowerCase()}/new/${type}`)
  }

  return (
    <div className={classes.container}>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h1' className={classes.h1}> {`${modality==='Quote'? 'Quotes': 'Shipments'}`} </Typography>
        {!isShipmentList && (
          <Button
            variant='contained'
            color='primary'
            className={classes.newQuoteShipment}
            startIcon={<IoMdAdd className={classes.icon} />}
            onClick={handleNewQuoteButton}
          >
            {`New ${modality}`}
          </Button>
        )}
      </Box>
      {components[modality][type]}
    </div>
  )
}

ListTemplate.propTypes = {
  isShipmentList: PropTypes.bool,
}

export default ListTemplate
