//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import LoadInformation from './loadInformation'
import AdditionalInformation from './additionalInformation'

//  styles
import { boxIconActive } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'

export default function PackageInformation () {
  const classes = useStyles()

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={boxIconActive} width='35px' />
        <Typography variant='h1' className={classes.heading}>
          Shipment Information
        </Typography>
      </HeadingContainer>
      <LoadInformation />
      <AdditionalInformation />
    </Root>
  )
}
