import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  UserListSucceeded,
  UserListFailed,
} from './actions'
import API from 'API'

function * fetchUserList (action) {
  try {
    const { data } = yield call(API.adminService.usersByCompany, action.payload)
    yield put(UserListSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(UserListFailed(newError))
    action.callback()
  }
}

function * fetchUTuckerUsers () {
  try {
    const { data } = yield call(API.adminService.tuckerUsers)
    yield put(UserListSucceeded(data))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(UserListFailed(newError))
  }
}

export default function * rootUserList () {
  yield takeEvery(TYPES.USER_LIST.FETCH.REQUEST, fetchUserList)
  yield takeEvery(TYPES.TUCKER_USERS.FETCH.REQUEST, fetchUTuckerUsers)
}
