//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

//  utils
import { dateFormat } from 'tools'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//  redux
import { filtersUpdate } from 'redux/ducks/filters'
import { quoteListFetch } from 'redux/ducks/quoteList'
import { shipmentListFetch } from 'redux/ducks/shipmentList'
import { QuoteDetailsRequest } from 'redux/ducks/quoteDetails'
import { notificationListDeleteRequest } from 'redux/ducks/notifications'

//  styles
import { useStyles } from './styles'
import { GoPrimitiveDot } from 'react-icons/go'
import { MdClose } from 'react-icons/md'

function NotifyItems ({ items, setItemLoading }) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const role = useSelector(state => state.session.data.role)

  // TODO: Add logic to rest of notification types
  const clickableNotification = {
    tlQuoteCreation: '/quote/list/tl',
    tlQuoteCreationSales: '/sales/list/:refId',
    tlQuoteRate: '/quote/list/tl',
    tlQuoteRateSales: '/sales/list/:refId',
    quoteStatusUpdate: '/quote/list/tl',
    tlShipmentCreation: '/shipment/list/tl',
    unableToQuote: '/quote/list/tl',
    unableToQuoteSales: '/sales/list/:refId',
    shipmentStatusUpdate: '/shipment/list/tl',
    shipmentDocument: '/shipment/list/tl',
  }
  const quoteListNotifications = ['tlQuoteCreation', 'tlQuoteRate', 'unableToQuote', 'quoteStatusUpdate']
  const shipmentListNotifications = ['tlShipmentCreation', 'shipmentStatusUpdate', 'shipmentDocument']

  const handleNotificationClick = (typeNotification, refId) => () => {
    if (!clickableNotification[typeNotification]) return

    setItemLoading(true)
    const isSameDestiny = history.location.pathname === clickableNotification[typeNotification]
    dispatch(filtersUpdate({ searchTerm: refId }))

    if (quoteListNotifications.includes(typeNotification)) {
      const typeWithRole = role === 'sales' ? `${typeNotification}Sales` : typeNotification
      notificationClick(quoteListFetch, refId, isSameDestiny, typeWithRole)
      return
    }

    if (shipmentListNotifications.includes(typeNotification)) {
      notificationClick(shipmentListFetch, refId, isSameDestiny, typeNotification)
    }
  }

  const notificationClick = (dispatchFunction, refId, isSameDestiny, typeNotification) => {
    const path = typeNotification.includes('Sales')
      ? clickableNotification[typeNotification].replace(':refId', refId)
      : clickableNotification[typeNotification]

    if (!isSameDestiny && role !== 'sales') {
      history.push(path)
      setItemLoading(false)
      return
    }

    if (role === 'sales') {
      if (history.location.pathname.includes('/sales/list/') && typeNotification.includes('Quote')) {
        history.push(path)
        dispatch(QuoteDetailsRequest( // Force to refresh
          { quoteId: refId },
          () => setItemLoading(false),
        ))
        return
      }
      history.push(path)
      setItemLoading(false)
      return
    }

    dispatch(dispatchFunction( // Force to refresh
      'Tl',
      { params: { limit: 10, page: 1, searchTerm: refId } },
      null,
      () => setItemLoading(false)))
  }

  const handleDeleteNotification = (id) => () => {
    dispatch(notificationListDeleteRequest(id))
  }

  return (
    <>
      {
        items.map((notification) => {
          const { _id, title, type, data, updatedAt, read } = notification
          const isClickable = type in clickableNotification
          return (
            <div className={classes.itemContainer} key={_id}>
              <MenuItem
                onClick={isClickable ? handleNotificationClick(type, data.refId) : () => {}}
                className={isClickable ? classes.notificationItem : classes.unClickable}
              >
                {!read && <GoPrimitiveDot className={classes.readFlag} />}
                <Typography color='primary' variant='h4' className={classes.notificationTitle}>{title}</Typography>
                <Typography component='p' style={{ fontSize: 14 }}>{data.refId}</Typography>
                <Typography component='p' style={{ fontSize: 11 }}>{dateFormat(updatedAt)}</Typography>
              </MenuItem>
              <MdClose onClick={handleDeleteNotification(_id)} className={classes.closeIcon} />
              <Divider style={{ margin: '0.2rem 0' }} />
            </div>
          )
        })
      }
    </>
  )
}

NotifyItems.propTypes = {
  items: PropTypes.array.isRequired,
  setItemLoading: PropTypes.func.isRequired,
}

export default NotifyItems
