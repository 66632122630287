//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import TlItem from './tlItem'
import LtlItem from './ltlItem'
import Accessorials from './accessorials'
import Carrier from './carrier'
import ImageNotDraggable from 'components/common/imageNotDraggable'
import {formatToMassUnit} from 'tools'

//  hooks
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

//  styles
import { boxIconActive, truckIconOrigin, accessorialsIconActive } from 'assets'
import { useStyles, Root, HeadingContainer, Title } from './styles'

export default function ItemsCard () {
  const { type } = useParams()
  const data = useSelector(state => state.entities.shipmentDetails.data.items || [])
  const classes = useStyles()

  const totalWeight = data.reduce((acc, value) => (
    acc + value.weight
  ), 0)

  return (
    <Root>
      <HeadingContainer>
        <Title>
          <ImageNotDraggable externalImage={boxIconActive} width='35px' />
          <Typography variant='h2' className={classes.heading}>
            Items
          </Typography>
        </Title>
        {/* <div>
          <Typography variant='h4' className={classes.title}> Total Weight </Typography>
          <Typography variant='h4' className={classes.text} align='center'> {`${totalWeight ? formatToMassUnit(totalWeight) + ' lbs' : '...'}`}</Typography>
        </div> */}
      </HeadingContainer>
      {
        type === 'tl' ? <TlItem /> : <LtlItem />
      }
      {
        type === 'ltl' && (
          <>
            <Title className='second'>
              <ImageNotDraggable externalImage={accessorialsIconActive} width='40px' />
              <Typography variant='h2' className={classes.heading}>
                Accessorials
              </Typography>
            </Title>
            <Accessorials />
          </>
        )
      }
      <Title className='second'>
        <ImageNotDraggable externalImage={truckIconOrigin} width='40px' />
        <Typography variant='h2' className={classes.heading}>
          Rate Information
        </Typography>
      </Title>
      <Carrier />
    </Root>
  )
}
