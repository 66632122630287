import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  panel: {
    width: '500px',
    border: `1px solid ${theme.palette.common.second6}`,
    borderRadius: '4px',
    marginTop: '0px !important',
  },
  summary: {
    flexFlow: 'column',
  },
  heading: {
    fontWeight: 400,
    color: theme.palette.common.second1,
    marginBottom: 20,
  },
  title: {
    fontWeight: 400,
    color: theme.palette.common.second3,
  },
  text: {
    fontSize: 18,
    color: theme.palette.common.second2,
    fontWeight: 500,
  },
  text2: {
    color: theme.palette.common.second3,
    fontWeight: 500,
    fontStyle: 'italic',
    marginRight: '30px',
  },
  details: {
    display: 'grid',
    rowGap: '20px',
  },
}))

export const Root = styled.div`
  margin-left: 52px;
  margin-top: 27px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 500px);
  gap: 10px 60px;
  align-items: start;
`

export const Row = styled.div`
  display: flex;
  .exp-right {
    margin-right: 10px;
  }
`
