//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  hooks
import { useHistory } from 'react-router-dom'

//  styles
import { useStyles, TitleContainer, Link } from './styles'
import { FaArrowLeft } from 'react-icons/fa'

function BackButton ({ text, to }) {
  const history = useHistory()
  const classes = useStyles()

  return (
    <TitleContainer>
      {to ? (
        <Link to={to}>
          <FaArrowLeft className='icon' />
        </Link>
      ) : <FaArrowLeft className='icon' onClick={() => history.goBack()} />}
      {
        text && <Typography variant='h2' className={classes.text}> {text} </Typography>
      }
    </TitleContainer>
  )
}

BackButton.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
}

export default BackButton
