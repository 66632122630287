//  libraries
import React from 'react'

//  styles
import { Root } from './styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

export default function NotFound (props) {
  return (
    <Root>
      <Typography className='error404' variant='h1' color='primary'>404</Typography>
      <Divider orientation='vertical' className='divider' />
      <Typography variant='h1' color='primary' className='notFound'>Not Found</Typography>
    </Root>
  )
}
