//  libraries
import React from 'react'

//  styles
import { Container, Content } from './styles'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { modalCreatingShipment } from 'redux/ducks/modal'

//  components
import GeneralTabs from 'components/common/generalTabs'
import BackButton from 'components/common/backButton'
import LtlForm from 'components/shipment/ltl/ltlForm'
import TlForm from 'components/shipment/tl/tlForm'
import DialogModal from 'components/common/dialog'

export default function NewShipment () {
  const dispatch = useDispatch()
  const openDialog = useSelector(state => state.ui.modal.creatingShipmentDialog)
  const dialogBodyText = 'Please fill in the required information in order to submit your shipment.'
  const ltlForm = <LtlForm />
  const tlForm = <TlForm />

  return (
    <Container>
      <Content>
        <BackButton />
        <GeneralTabs content1={ltlForm} content2={tlForm} title='Shipment' />
      </Content>
      <DialogModal
        title='Creating Shipment...'
        open={openDialog}
        body={dialogBodyText}
        okText='Ok'
        onOk={() => dispatch(modalCreatingShipment(false))}
        onCancel={() => dispatch(modalCreatingShipment(false))}
      />
    </Container>
  )
}
