import { dateFormat, getTimeZone, dateFormatStartOf } from 'tools'

//  helpers
const timeValidation = (x, y) => x ? dateFormat(x, 'HH:mm') : y
const dateValidation = (x, y) => x ? dateFormatStartOf(x, '', 'day') : y

export default {
  update: (state, data) => {
    let format = { ...data }
    if (data.shipping) {
      format = {
        ...data,
        shipping: {
          ...state.shipping,
          ...data.shipping,
          pickup: {
            ...state.shipping?.pickup,
            ...data.shipping.pickup,
            startDate: dateValidation(data.shipping.pickup?.startDate, state.shipping?.pickup?.startDate),
            endDate: dateValidation(data.shipping.pickup?.endDate, state.shipping?.pickup?.endDate),
            startTime: timeValidation(data.shipping.pickup?.startTime, state.shipping?.pickup?.startTime),
            endTime: timeValidation(data.shipping.pickup?.endTime, state.shipping?.pickup?.endTime),
          },
          delivery: {
            ...state.shipping?.delivery,
            ...data.shipping.delivery,
            startDate: dateValidation(data.shipping.delivery?.startDate, state.shipping?.delivery?.startDate),
            endDate: dateValidation(data.shipping.delivery?.endDate, state.shipping?.delivery?.endDate),
            startTime: timeValidation(data.shipping.delivery?.startTime, state.shipping?.delivery?.startTime),
            endTime: timeValidation(data.shipping.delivery?.endTime, state.shipping?.delivery?.endTime),
          },
        },
        timeZone: getTimeZone()
      }
    }
    return {
      ...state,
      ...format,
    }
  },

  delete: (initialState) => ({
    ...initialState,
  }),

  loadFromQuoteLtl: (data) => {
    const { refId, origin, destination, items, shipping, accessorials, references } = data
    const format = {
      disabled: true,
      quoteRefId: refId,
      from: {
        state: origin.state,
        country: origin.country,
        city: origin.city,
        zip: origin.postalCode,
      },
      to: {
        state: destination.state,
        country: destination.country,
        city: destination.city,
        zip: destination.postalCode,
      },
      products: items.map(product => ({
        nmfc: product.nmfcCode,
        hazmat: product.hazmat,
        isNew: product.isNewItem,
        description: product.description,
        pieces: product.totalPieces,
        quantity: product.totalPackages,
        length: product.packageDimensions?.length,
        width: product.packageDimensions?.width,
        height: product.packageDimensions?.height,
        weight: product.totalWeight,
        packageType: product.packageType,
        freightClass: product.freightClass,
        itemName: product.itemName,
      })),
      accessorials,
      shipping,
      references,
    }
    return format
  },

  loadFromQuoteTl: (data) => {
    const { refId, origin, destination, items, transportType, shipping, references = [] } = data
    const format = {
      disabled: true,
      quoteRefId: refId,
      from: {
        state: origin.state,
        country: origin.country,
        city: origin.city,
        zip: origin.postalCode,
      },
      to: {
        state: destination.state,
        country: destination.country,
        city: destination.city,
        zip: destination.postalCode,
      },
      products: items.map(product => ({
        pieces: product.totalPieces,
        weight: product.totalWeight,
        value: product.value,
        isHigh: product.isHightValue,
        hazmat: product.hazmat,
        hazmatValue: product.hazmatValue,
        isNew: product.isNewItem,
        commodity: product.commodity,
        overWeight: product.isOverWeight,
        overSize: product.isOverSize,
        tempMin: product.tempMin,
        tempMax: product.tempMax,
        length: product.packageDimensions?.length,
        width: product.packageDimensions?.width,
        height: product.packageDimensions?.height,
        isTarping: product.isTarping,
        tarpingValue: product.tarpingValue,
      })),
      shipping,
      transport: transportType,
      references,
    }
    // replace PO NUMBER by Purchase Order Number
    const indexPoNumber = references.findIndex(({ ref }) => ref === 'PO NUMBER')
    if (indexPoNumber > -1) {
      const value = references.find(({ ref }) => ref === 'PO NUMBER')?.value || null
      if (value !== null) {
        format.references[indexPoNumber] = {
          ref: 'Purchase Order Number',
          value,
        }
      }
    }
    return format
  },

  loadFromShipmentLtl: (data) => {
    const { refId, shipper, consignee, items, accessorials, shipping, references } = data
    const format = {
      shipmentId: refId,
      from: {
        state: shipper.state,
        country: shipper.country,
        city: shipper.city,
        zip: shipper.zipCode,
        contact: {
          company: shipper.companyName,
          address: shipper.address,
          address2: shipper.address2,
          name: shipper.contactName,
          phone: shipper.phone,
          mail: shipper.email,
        },
      },
      to: {
        state: consignee.state,
        country: consignee.country,
        city: consignee.city,
        zip: consignee.zipCode,
        contact: {
          company: consignee.companyName,
          address: consignee.address,
          address2: consignee.address2,
          name: consignee.contactName,
          phone: consignee.phone,
          mail: consignee.email,
        },
      },
      products: items.map(product => ({
        itemName: product.name,
        pieces: product.pieces,
        length: product.packageDimensions?.length,
        width: product.packageDimensions?.width,
        height: product.packageDimensions?.height,
        quantity: product.quantity,
        weight: product.weight,
        packageType: product.packageType,
        freightClass: product.freightClass,
        nmfc: product.NMFC,
        hazmat: product.hazmat,
        isNew: product.isNew,
        description: product.description,
      })),
      accessorials,
      shipping,
      references,
    }
    return format
  },

  loadFromShipmentTl: (data) => {
    const { refId, shipper, consignee, items, general, shipping, references, fromMcLeod } = data
    const format = {
      shipmentId: refId,
      from: {
        state: shipper.state,
        country: shipper.country,
        city: shipper.city,
        zip: shipper.zipCode,
        contact: {
          company: shipper.companyName,
          address: shipper.address,
          address2: shipper.address2,
          name: shipper.contactName,
          phone: shipper.phone,
          mail: shipper.email,
        },
      },
      to: {
        state: consignee.state,
        country: consignee.country,
        city: consignee.city,
        zip: consignee.zipCode,
        contact: {
          company: consignee.companyName,
          address: consignee.address,
          address2: consignee.address2,
          name: consignee.contactName,
          phone: consignee.phone,
          mail: consignee.email,
        },
      },
      products: items.map(product => ({
        pieces: product.pieces,
        weight: product.weight,
        value: product.value,
        commodity: product.commodity,
        hazmat: product.hazmat,
        isNew: product.isNew,
        isHigh: product.hightValue,
        hazmatValue: product.hazmatValue,
        overWeight: product.isOverWeight,
        overSize: product.isOverSize,
        tempMin: product.tempMin,
        tempMax: product.tempMax,
        length: product.packageDimensions?.length,
        width: product.packageDimensions?.width,
        height: product.packageDimensions?.height,
        isTarping: product.isTarping,
        tarpingValue: product.tarpingValue,
      })),
      shipping,
      transport: general.transportType,
      references,
      fromMcLeod,
    }
    return format
  },

  loadFromRatesList: (data) => {
    const { refId, ...rest } = data
    const format = {
      quoteRefId: refId || '',
      ...rest,
    }

    return format
  },

  loadUser: (data) => {
    const { _id, name, role, email, permissions, image } = data
    const format = {
      userId: _id,
      name,
      email,
      role,
      permissions,
      image,
    }
    return format
  },

  loadCompany: (data) => {
    const { _id, type, parent, image, general, billTo, remitTo, notifications, mlCompanyId } = data
    const format = {
      mlCompanyId,
      companyId: _id,
      type,
      parent,
      image,
      general,
      billTo,
      remitTo,
      notifications,
    }
    return format
  },

  loadSales: (data) => {
    const { refId, origin, destination, items, shipping, transportType, references } = data
    const format = {
      quoteId: refId,
      from: {
        state: origin.state,
        country: origin.country,
        city: origin.city,
        zip: origin.postalCode,
      },
      to: {
        state: destination.state,
        country: destination.country,
        city: destination.city,
        zip: destination.postalCode,
      },
      products: items.map(product => ({
        pieces: product.totalPieces,
        weight: product.totalWeight,
        value: product.value,
        isHigh: product.isHightValue,
        hazmat: product.hazmat,
        hazmatValue: product.hazmatValue,
        isNew: product.isNewItem,
        commodity: product.commodity,
        overWeight: product.isOverWeight,
        overSize: product.isOverSize,
        tempMin: product.tempMin,
        tempMax: product.tempMax,
        length: product.packageDimensions?.length,
        width: product.packageDimensions?.width,
        height: product.packageDimensions?.height,
        isTarping: product.isTarping,
        tarpingValue: product.tarpingValue,
      })),
      shipping,
      transport: transportType,
      references,
      timeZone: getTimeZone()

    }
    return format
  },
}
