import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles(theme => ({
  heading: {
    margin: '12px 0',
  },
  subtitle: {
    color: theme.palette.common.second3,
    marginTop: 26,
    marginBottom: 8,
  },
}))

export const HeadingContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  padding: 33px  24px 0 24px;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 39;
`

export const Item = styled.div`
  ${({ theme }) => css`
  display: grid;
  grid-auto-flow: column;
  width: 188px;
  height: 60px;
  opacity: 0.2;
  border-radius: 4px;
  background-color: ${theme.palette.common.second6};
  font-size: 15px;
  margin-top: 10px;
  justify-content: space-between;
  padding: 10;
  `}
`
