//  libraries
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { createSelector } from 'reselect'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

// hooks
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

//  redux
import { companySelectorUpdate, companySelectorReset } from 'redux/ducks/companySelector'
import { stepperUpdate } from 'redux/ducks/stepper'

//  styles
import { useStyles, SelectContainer, Company, customStyles } from './styles'

export default function CompanySelector ({ tucker = false }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation().pathname
  const activeSaveButton = useSelector(state => state.ui.stepper.activeSaveButton)
  const isEditAction = useParams().action === 'edit' && !activeSaveButton
  const parent = useSelector(state => state.ui.companySelector.companyParent)
  const child = useSelector(state => state.ui.companySelector.companyChild)
  const disabled = useSelector(state => state.ui.companySelector.disabled)
  const showParent = useSelector(state => state.ui.companySelector.showParent)
  const role = useSelector(state => state.session.data.role)
  const [loading, setLoading] = useState(false)

  const isSingleCompany = role === 'tucker' || role === 'sales'
  const isAdminEditingUser = location.includes('/admin/user')

  useEffect(() => {
    if (!isSingleCompany) return
    !isAdminEditingUser && dispatch(companySelectorReset())
  }, [dispatch, isSingleCompany, isAdminEditingUser, location])

  const additionalData = () => {
    const data = []
    if (showParent && role !== 'customer') data.push(parent)
    return data
  }

  const dataCompanyParent = createSelector(
    state => state.session.data.companies ? state.session.data.companies.map(({ value, label, mlCompanyId }) => ({
      value,
      label: label.toUpperCase(),
      mlCompanyId,
    })) : [],
    (companyParent) => (companyParent),
  )
  const dataCompanyChild = createSelector(
    state => state.session.data.companies || [],
    (companies) => {
      const [{ children } = {}] = companies.filter(company => company.value === parent.value)
      const data = children ? children.concat(additionalData())
        : additionalData()
      return data
    },
  )

  const companyParentList = useSelector(dataCompanyParent)

  const companyChildList = useSelector(dataCompanyChild)

  const companyChild = companyChildList[0] ? companyChildList : []

  /**
   * Remove tucker company option in newForm
   */
  const validCompanies = () => {
    const routesWithoutTucker = [
      '/quote/new/tl',
      '/shipment/convert/tl',
      '/shipment/new/tl',
      '/sales/new/tl',
    ]
    if (routesWithoutTucker.includes(location)) {
      return companyParentList.filter(company => company.value !== '5f36d688fd875233200cead6')
    }
    return companyParentList
  }

  const companyParent = validCompanies()

  useEffect(() => {
    if (isSingleCompany) return
    dispatch(companySelectorUpdate({
      companyParent: parent || { value: companyParent[0].value || '', label: companyParent[0].label || '', mlCompanyId: companyParent[0].mlCompanyId || '' },
      companyChild: child || { value: companyParent[0].value || '', label: companyParent[0].label || '', mlCompanyId: companyParent[0].mlCompanyId || '' },
      disabled: false,
      showParent: true,
    }))
  }, [])

  const handleChangeChild = (data) => {
    dispatch(companySelectorUpdate({ companyChild: data }))
    if (isEditAction) dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  useEffect(() => {
    if (isSingleCompany) return
    if (loading) return
    if (companyParent && !companyChild.length && companyParent[0]) {
      setLoading(true)
      dispatch(companySelectorUpdate({
        companyChild: companyParent[0],
        disabled: false,
        showParent: false,
      }))
    }
  }, [companyChild.length, companyParent, dispatch, role, isSingleCompany, loading])

  useEffect(() => {
    if (companyChild.length && !companyChild.some(data => data.value === child?.value)) {
      handleChangeChild(companyChild[0])
    }
    //  eslint-disable-next-line
  }, [companyChild.length])

  const handleChangeParent = (data) => {
    if (disabled) {
      dispatch(companySelectorUpdate({
        companyParent: data,
        companyChild: child.value === 'tucker' ? child : data,
      }))
    } else dispatch(companySelectorUpdate({ companyParent: data, companyChild: data }))
    if (isEditAction) dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  return (
    <SelectContainer>
      {
        (role === 'tucker' || role === 'sales' || role === 'userAdmin') && (
          <Company>
            <Typography variant='h4' className={classes.label} component='label' align='left' noWrap>
              Select account
            </Typography>
            <Select
              classNamePrefix='select'
              name='account'
              isSearchable
              value={parent}
              onChange={handleChangeParent}
              options={companyParent}
              styles={customStyles}
              isDisabled={role === 'userAdmin'}
            />
          </Company>
        )
      }
      {/* <Company>
        <Typography variant='h4' className={classes.label} component='label' align='left' noWrap>
          Select Location
        </Typography>
        <Select
          defaultValue={companyChild[0]}
          classNamePrefix='select'
          name='company'
          isSearchable
          value={child}
          onChange={handleChangeChild}
          options={companyChild}
          styles={customStyles}
          isDisabled={disabled}
        />
      </Company> */}
    </SelectContainer>
  )
}

CompanySelector.propTypes = {
  tucker: PropTypes.bool,
}
