import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  rowPieces: {
    textAlign: 'center',
    maxWidth: 124,
  },
  formElementsBox: {
    height: '85%',
    overflow: 'auto',
    scrollbarColor: theme.palette.common.second3,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb ': {
      background: '#CBD6E2',
      borderRadius: 4,
    },
  },
  styledName: {
    marginBottom: 9,
    fontSize: 14,
    color: theme.palette.common.second3,
  },
  styledTextfield: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD6E2',
      },
    },
  },
  styledTotalWeight: {
    fontSize: 18,
    color: '#637280',
    marginRight: 12,
  },
  styledTotalWeightValue: {
    fontSize: 20,
    color: theme.palette.common.second1,
    fontWeight: 'bold',
  },
  styledTemplateButton: {
    margin: '0px 35px',
    backgroundColor: theme.palette.common.orange,
    height: 50,
    fontSize: 18,
    color: theme.palette.common.second8,
    width: '11.25rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.common.orange,
    },
  },
  styledCloseButton: {
    height: 50,
    fontSize: 18,
    color: theme.palette.common.second8,
    backgroundColor: '#cbd6e2',
    width: '11.25rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#637280',
    },
  },
  styledAddItemButton: {
    height: 50,
    fontSize: 18,
    color: theme.palette.common.second8,
    backgroundColor: theme.palette.primary.main,
    width: '11.25rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  styledCheckbox: {
    padding: 0,
  },
  checkboxIcon: {
    fontSize: 50,
    color: theme.palette.common.second6,
  },
  styledEditItemButton: {
    marginLeft: 35,
    width: '11.25rem',
    textTransform: 'none',
  },
  required: {
    color: 'red',
    marginTop: 10,
  },
  styledForm: {
    height: '100%',
    padding: '3rem 3rem 1rem 3rem',
  },
  oversize: {
    color: '#CBD6E2',
    fontSize: 18,
    marginRight: '2rem',
  },
  alertRow: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3rem',
    '&:empty': {
      display: 'none',
    },
  },
  styledToast: {
    padding: '2px',
    position: 'fixed',
    left: 0,
  },
}))

export const customStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => {
    return {
      ...base,
      width: '100%',
      boxShadow: state.isFocused ? '0 0 0 1px #979797' : 'none',
      border: `1px solid ${state.isFocused ? '#979797' : '#CBD6E2'}`,
      '&:hover': {
        border: '1px solid #979797',
      },
    }
  },
}
