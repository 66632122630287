import { makeStyles } from '@material-ui/core/styles'
import { globalTableStyles } from 'styles/globalTableStyles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles((theme) => {
  return ({
    userAvatar: {
      backgroundColor: '#630A5C',
      margin: '0 auto',
      width: 50,
      height: 50,
    },
    companyAvatar: {
      backgroundColor: '#630A5C',
      marginRight: 25,
      width: 50,
      height: 50,
    },
    text: {
      color: theme.palette.common.second1,
      fontWeight: 400,
    },
  })
})

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 200px;
    margin: 0 auto;
  `}
`

export const tableStyles = {
  ...globalTableStyles,
}
