import styled, { css } from 'styled-components'

export const Image = styled.img`
  ${({ width, height, minWidth }) => css`
    user-select: none;
    width: ${width};
    height: ${height};
    min-width: ${minWidth};
  `}
  
`
