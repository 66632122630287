import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  label: {
    color: '#637280',
    fontSize: 18,
    fontWeight: 'normal',
    marginRight: '15px',
  },
  PoText: {
    background: '#CBD6E211',
    width: '110px',
    borderColor: '#CBD6E222',
  },
}))

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column: 1 / 3;
`

export const Po = styled.div`
  display: flex;
  align-items: center;
  .icon {
    height: 25px;
    width: 25px;
    color: #637280;
    margin-left: 15px;
  }
`
