// libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import LinearProgress from '@material-ui/core/LinearProgress'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

// styles
import { useStyles } from './styles'
import { IoMdSend } from 'react-icons/io'

// components
import TabPanel from './tabPanel'
import CommentsList from './commentsList'

const message = 'In this moment this function is not available. Please try later or contact us.'
const initialError = {
  isError: false,
  message: '',
}

export default function Comments ({ comments, quoteId, loading, setMessageRequestAction, disabled }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [tabValue, setTabValue] = useState(0)
  const [internalComment, setInternalComment] = useState('')
  const [externalComment, setExternalComment] = useState('')
  const [errorFeedbackInternal, setErrorFeedbackInternal] = useState(initialError)
  const [errorFeedbackExternal, setErrorFeedbackExternal] = useState(initialError)
  const role = useSelector(state => state.session.data.role)
  const showInternal = ['sales', 'tucker'].includes(role)

  const onInternal = () => {
    if (!validComment(internalComment, 'internal')) return
    const payload = {
      commentType: 'internal',
      message: internalComment.trim(),
    }
    dispatch(setMessageRequestAction(
      payload,
      quoteId,
      () => setInternalComment(''), // callback
      () => { // callback error
        addToast(message, {
          appearance: 'error',
          autoDismiss: false,
          id: 'error-toast-sale',
        })
      },
    ))
  }

  const onExternal = () => {
    if (!validComment(externalComment, 'external')) return
    const payload = {
      commentType: 'external',
      message: externalComment.trim(),
    }
    dispatch(setMessageRequestAction(
      payload,
      quoteId,
      () => setExternalComment(''), // callback
      () => {
        addToast(message, {
          appearance: 'error',
          autoDismiss: false,
          id: 'error-toast-sale',
        })
      }, // callback error
    ))
  }

  const onChangeInternal = ({ target }) => {
    setInternalComment(target.value)
    validComment(target.value, 'internal')
  }

  const onChangeExternal = ({ target }) => {
    setExternalComment(target.value)
    validComment(target.value, 'external')
  }

  const validComment = (comment, typeComment) => {
    let isValid = true
    const length = comment.trim().length
    const setError = typeComment === 'internal' ? setErrorFeedbackInternal : setErrorFeedbackExternal
    if (length <= 1) {
      setError({
        isError: true,
        message: 'The comment must be longer',
      })
      isValid = false
    }
    if (length > 200) {
      setError({
        isError: true,
        message: 'The comment must be smaller',
      })
      isValid = false
    }
    if (isValid) setError(initialError)
    return isValid
  }

  return (
    <Box display='flex' m='1rem 0' flexDirection='column'>
      <AppBar position='static' color='default' elevation={0}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          aria-label='full width tabs example'
        >
          {showInternal && <Tab label='Internal Comments' className={classes.tab} />}
          <Tab label='External Comments' className={classes.tab} />
        </Tabs>
      </AppBar>
      {showInternal && (
        <TabPanel value={tabValue} index={0} className={classes.paddingY}>
          <CommentsList list={comments.internal || []} />
          <TextField
            error={errorFeedbackInternal.isError}
            fullWidth
            id='internal'
            placeholder='Add an Internal Comment'
            multiline
            rows={4}
            variant='outlined'
            onChange={onChangeInternal}
            value={internalComment}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Button
                  disabled={errorFeedbackInternal.isError || disabled}
                  onClick={onInternal}
                  className={classes.commentButton}
                  variant='outlined'
                  color='primary'
                  size='small'
                  startIcon={<IoMdSend />}
                >
                  Add
                </Button>
              ),
            }}
          />
          <FormHelperText className={classes.error}>{errorFeedbackInternal.message}</FormHelperText>
        </TabPanel>
      )}
      <TabPanel value={tabValue} index={showInternal ? 1 : 0} className={classes.paddingY}>
        <CommentsList list={comments.external || []} idListComment='external' />
        {role !== 'account' && (
          <TextField
            error={errorFeedbackExternal.isError}
            fullWidth
            id='external'
            placeholder='Add a Comment to the Customer'
            multiline
            rows={4}
            variant='outlined'
            onChange={onChangeExternal}
            value={externalComment}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Button
                  disabled={errorFeedbackExternal.isError || disabled}
                  onClick={onExternal}
                  className={classes.commentButton}
                  variant='outlined'
                  color='primary'
                  size='small'
                  startIcon={<IoMdSend />}
                >
                  Add
                </Button>
              ),
            }}
          />
        )}
        <FormHelperText className={classes.error}>{errorFeedbackExternal.message}</FormHelperText>
      </TabPanel>
      {loading && <LinearProgress className={classes.linearProgress} />}
    </Box>
  )
}

Comments.propTypes = {
  comments: PropTypes.shape({
    loading: PropTypes.bool,
    external: PropTypes.array,
    internal: PropTypes.array,
  }).isRequired,
  quoteId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  setMessageRequestAction: PropTypes.func.isRequired,
}
