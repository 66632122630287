//  libraries
import React, { useState, useEffect, useRef } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import MaterialTable, { MTableBody } from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'

// components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import PermissionsText from './permissionsText'
import CustomSwitch from './customSwitch'
import SortingHeader from 'components/common/tableFragments/sortingHeader'
import Pagination from 'components/common/tableFragments/pagination'
import TableLoading from 'components/common/tableFragments/tableLoading'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//  redux
import { UserListRequest } from 'redux/ducks/userList'
import { newFormLoadFromUserList } from 'redux/ducks/newForm'

//  tools
import { nameAbbreviation } from 'tools'

// styles
import { useStyles, Item, tableStyles } from './styles'
import { tuckerLogo } from 'assets'
import { tableIcons } from 'styles/tableIcons'
import { globalTableOptions } from 'styles/globalTableStyles'

export default function UserList () {
  const role = useSelector(state => state.session.data.role)
  const classes = useStyles()
  const dispatch = useDispatch()
  const tableRef = useRef(null)
  const history = useHistory()
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const [roleNames] = useState({
    account: 'Accounting',
    shipment: 'Shipping/Recieving',
    logistic: 'Logistics Rep',
    'Customer Experience': 'Sales',
    tucker: 'Administrative',
    sales: 'Sales',
    userAdmin: 'Client Admin',
  })
  const { data = [], page = 0, totalCount = 0, loading } = useSelector(state => state.entities.userList || {})
  const filters = useSelector(state => state.ui.filters)
  const companyId = useSelector(state => state.ui.companySelector.companyChild.value)
  const setPage = page - 1 < 0 ? 0 : page - 1 // Avoid to assign negative value.

  useEffect(() => {
    dispatch(UserListRequest({ companyId, params: { limit, page: 1 } }))
    //  eslint-disable-next-line
  }, [companyId])

  const handleDetails = (e, rowData) => {
    if (e.target.name === 'statusSwitch' || e.target.id === 'resendButton') return
    dispatch(newFormLoadFromUserList(rowData))
    history.push('/admin/users/edit')
  }

  return (
    <>
      <Pagination
        count={totalCount}
        rowsPerPage={limit}
        page={setPage}
        onChangePage={(evt, page) => {
          dispatch(UserListRequest({ companyId, params: { ...filters, limit, page: page + 1 } }))
        }}
        onChangeRowsPerPage={(evt, arg) => {
          setLimit(evt.target.value)
          tableRef.current.onChangeRowsPerPage(evt)
          dispatch(UserListRequest({ companyId, params: { ...filters, limit: evt.target.value, page } }))
        }}
      />
      <MaterialTable
        isLoading={loading}
        tableRef={tableRef}
        data={data}
        icons={tableIcons}
        columns={[
          {
            title: 'User Photo',
            sorting: false,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <Avatar className={classes.userAvatar} src={`${rowData.image}?${Math.random()}`}>
                {nameAbbreviation(rowData.name)}
              </Avatar>
            ),
          },
          {
            title: 'Full Name',
            cellStyle: tableStyles.cellStyle,
            sortKeyValue: 'name',
            render: rowData => (
              <>
                <Typography variant='h4' className={classes.text}>{rowData.name}</Typography>
                <PermissionsText
                  value={rowData.permissions?.shipment}
                  text='Full Access Shipment'
                  icon='shipment'
                />
              </>
            ),
          },
          {
            title: 'Role',
            sortKeyValue: 'role',
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <>
                <Typography variant='h4' className={classes.text}>{roleNames[rowData.role]}{console.log(rowData)}</Typography>
                <PermissionsText
                  value={rowData.permissions?.quote}
                  text='Full Access Quote'
                  icon='quote'
                />
              </>
            ),
          },
          {
            title: 'Email',
            sortKeyValue: 'email',
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <>
                <Typography variant='h4' className={classes.text}>{rowData.email}</Typography>
                <PermissionsText
                  value={rowData.permissions?.accounting}
                  text='Full Access Accounting'
                  icon='accounting'
                />
              </>
            ),
          },
          {
            title: 'Company',
            hidden: role === 'userAdmin',
            sorting: false,
            cellStyle: tableStyles.cellStyle,
            render: rowData => {
              if (companyId === 'tucker') {
                return (
                  <ImageNotDraggable externalImage={tuckerLogo} width='100px' />
                )
              }
              return (
                <Item>
                  <Avatar className={classes.companyAvatar} src={rowData.companyImage + '?' + Math.random()}>
                    {nameAbbreviation(rowData.company)}
                  </Avatar>
                  <Typography variant='h4' className={classes.text} noWrap>{rowData.company}</Typography>
                </Item>
              )
            },
          },
          {
            title: 'Status',
            sorting: false,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <CustomSwitch
                id={rowData._id}
                status={rowData.status}
                expiredActivationToken={rowData.expiredActivationToken}
                firstActivation={rowData.firstActivation}
              />
            ),
          },
        ]}
        components={{
          Pagination: (componentsProps) => {
            return (
              <TablePagination
                {...componentsProps}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(UserListRequest({ companyId, params: { limit, page: page + 1, ...filters } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  componentsProps.onChangeRowsPerPage(evt)
                  dispatch(UserListRequest({ companyId, params: { limit: evt.target.value, page, ...filters } }))
                }}
              />
            )
          },
          Body: (props) => (
            <>
              {loading && totalCount > 0 && <TableLoading />}
              <MTableBody {...props} />
            </>
          ),
          Header: (props) => <SortingHeader tableProps={props} limit={limit} listOf='user' />,
        }}
        onRowClick={handleDetails}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          sorting: true,
        }}
        style={{
          boxShadow: 'none',
          width: '100%',
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No users to display',
          },
        }}
      />
    </>
  )
}
