import React from 'react'
import { Box, Divider, Chip } from '@material-ui/core'
import { useStyles } from './styles'

function Accessorials ({ accessorialList }) {
  const classes = useStyles()

  return (
    <Box my={3} width='100%' display='flex' flexDirection='column' alignItems='center'>
      <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between' height={50}>
        <Box width='100%' display='flex' flexDirection='row' alignItems='baseline' justifyContent='start'>
          <h5>Accessorial Information</h5>
          <span className={classes.spanTotal}>{accessorialList.length} Accessories</span>
        </Box>
      </Box>
      <Divider style={{ width: '100%' }} />
      <Box width='80%' my={2}>
        {
          accessorialList.map((accessorial, index) => (
            <Chip className={classes.chip} label={accessorial.ref} key={`${accessorial.ref}-${index}`} />
          ))
        }
      </Box>
    </Box>
  )
}

export default Accessorials
