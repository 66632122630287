import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 12,
    color: theme.palette.common.second3,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  messageText: {
    color: theme.palette.common.second7,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
}))

export const Root = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template: repeat(4, 100px) / repeat(2, 250px);
    align-content: flex-start;
    justify-content: center;
    margin-top: 52px;
    width: 100%;
    height: 100%;
    column-gap: 50px;
  `}
`

export const Element = styled.div`
  ${({ start, end }) => css`
    display: flex;
    flex-flow: column;
  `}
`

export const ElementImage = styled.div`
  justify-self: center;
  align-self: center;
  grid-column: 1 / 2;
  grid-row: 1 / 4;
`

export const customStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => {
    return {
      ...base,
      borderWith: '2px',
      borderStyle: 'solid',
      borderColor: !state.isFocused ? state.selectProps.error ? 'red' : '#979797' : '#8D8E90',
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#F6F8FA' : '#FFFFFF',
      '&:hover': {
        border: '2px solid #8D8E90',
      },
    }
  },
}
