import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: 12,
    color: theme.palette.common.second3,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  check: {
    cursor: 'pointer',
  },
}))

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`
