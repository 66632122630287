import { makeStyles } from '@material-ui/core/styles'

import styled, { css } from 'styled-components'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 3.3rem',
    paddingTop: 29,
    paddingBottom: 10,
    display: 'grid',
    gridTemplateColumns: 'minmax(90%, 1500px)',
    gridAutoRows: 'min-content',
    justifyContent: 'center',
  },
  data: {
    display: 'flex',
  },
  timeout: {
    display: 'flex',
    marginLeft: '48px',
    marginTop: '16px',
  },
  title: {
    marginBottom: '8px',
  },
  h1: {
    marginTop: 12,
    fontSize: 21,
    color: theme.palette.common.second1,
    marginRight: 5,
    width: '100%',
    maxWidth: 1500,
    justifyContent: 'flex-start',
    marginBottom: '24px',
  },
  button: {
    marginTop: '26px',
    marginLeft: '24px',
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    color: 'white',
    textTransform: 'none',
    width: '8rem',
    height: '2.5rem',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialogTitle: {
    '& > h2': {
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
  },
  dialogContent: {
    color: 'black',
    fontWeight: 'normal',
  },
  dialogCancel: {
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: '#c53737',
  },
  dialogConfirm: {
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: theme.palette.primary.main,
  },
  switcher: {
    marginLeft: '48px',
  },
}))

export const Element = styled.div`
  ${({ start, end }) => css`
    display: flex;
    flex-flow: column;
    width: 300px;
  `}
`

export const ElementImage = styled.div`
  justify-self: center;
  align-self: center;
  grid-column: 1 / 2;
  grid-row: 1 / 4;
`

export const customStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => {
    return {
      ...base,
      borderWith: '2px',
      borderStyle: 'solid',
      borderColor: !state.isFocused ? state.selectProps.error ? 'red' : '#979797' : '#8D8E90',
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#F6F8FA' : '#FFFFFF',
      '&:hover': {
        border: '2px solid #8D8E90',
      },
    }
  },
}
