import moment from 'moment'

export const dateFormat = (date, format = 'MM-DD-YYYY') => moment(date).format(format)

// Don't convert the time zone. Instead, remains the original
export const dateFormatUtc = (date, format = 'MM-DD-YYYY') => moment.utc(date).format(format)

export const timeFormatUtc = (date, format = 'HH:mm') => moment.utc(date, 'HH:mm').local().format(format)

export const getTimeZone = () => localStorage.getItem('timeZone')

export const dateFormatStartOf = (date, format = 'MM-DD-YYYY', type = 'day') => moment(date).startOf(type).format(format)