import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  heading: {
    margin: '12px 20px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    lineHeight: 'normal',
  },
}))

export const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,210px);
  grid-auto-rows: 105px;
  column-gap: 30px;
  width: 100%;
  padding: 15px 0px 33px 50px;
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1 / -1;
`
