import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
  return ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  })
})
