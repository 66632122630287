//  libraries
import React, { useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { dryVanIcon, dryVanIconActive, flatbedIcon, flatbedIconActive, reeferIcon, reeferIconActive } from 'assets'
import { useStyles, ContainerType } from './styles'
import { IoIosRadioButtonOff } from 'react-icons/io'
import { FaCheckCircle } from 'react-icons/fa'
export default function Type ({ text, value, selected }) {
  const radio = useRef(null)
  const validation = selected === value
  const classes = useStyles({ validation })
  const disabled = useSelector(state => state.newForm.disabled)

  const icons = {
    'dry-van': validation ? dryVanIconActive : dryVanIcon,
    flatbed: validation ? flatbedIconActive : flatbedIcon,
    reefer: validation ? reeferIconActive : reeferIcon,
  }

  const handleFunction = () => {
    if (!disabled) radio.current.click()
  }

  return (
    <ContainerType onClick={handleFunction} className={classes.cardContainer}>
      <FormControlLabel
        disabled={disabled}
        value={value}
        className={classes.icon}
        control={
          <Radio
            ref={radio}
            icon={<IoIosRadioButtonOff className={classes.unCheckedIcon} />}
            checkedIcon={<FaCheckCircle className={classes.checkedIcon} />}
          />
        }
      />
      <ImageNotDraggable externalImage={icons[value]} className={classes.image} />
      <Typography variant='h1' align='center' className={classes.text}> {text} </Typography>
    </ContainerType>
  )
}

Type.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  selected: PropTypes.string,
}
