import TYPES from './types'
import initialState from './initialState'
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    /**
     * LOGIN
     */
    case TYPES.USER.LOGIN.FETCH.REQUEST:
      return model.loading(state, action.payload)
    case TYPES.USER.LOGIN.FETCH.SUCCEEDED:
      return model.setNewSession(action.payload)
    case TYPES.USER.LOGIN.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * AFTER-FIRST-LOGIN
     */
    case TYPES.USER.AFTER_FIRST_LOGIN.FETCH.REQUEST:
      return model.loading(state, action.payload)
    case TYPES.USER.AFTER_FIRST_LOGIN.FETCH.SUCCEEDED:
      return model.update(state, action.payload)
    case TYPES.USER.AFTER_FIRST_LOGIN.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * FORGOT PASSWORD
     */
    case TYPES.USER.FORGOT_PASSWORD.FETCH.REQUEST:
      return model.loading(state, action.payload)
    case TYPES.USER.FORGOT_PASSWORD.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.USER.FORGOT_PASSWORD.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * RESET PASSWORD
     */
    case TYPES.USER.RESET_PASSWORD.FETCH.REQUEST:
      return model.loading(state, action.payload)
    case TYPES.USER.RESET_PASSWORD.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.USER.RESET_PASSWORD.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * ACTIVATE ACCOUNT
     */
    case TYPES.USER.ACTIVATE.FETCH.REQUEST:
      return model.loading(state, action.payload)
    case TYPES.USER.ACTIVATE.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.USER.ACTIVATE.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * RESEND ACTIVATION EMAIL
     */
    case TYPES.USER.RESEND_ACTIVATION_EMAIL.FETCH.REQUEST:
      return model.loading(state, action.payload)
    case TYPES.USER.RESEND_ACTIVATION_EMAIL.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.USER.RESEND_ACTIVATION_EMAIL.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * COMPANIES BY USER
     */
    case TYPES.USER.COMPANIES.FETCH.REQUEST:
      return model.loading(state, action.payload)
    case TYPES.USER.COMPANIES.FETCH.SUCCEEDED:
      return model.loadCompanies(state, action.payload)
    case TYPES.USER.COMPANIES.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
    * GET KILLSWITCH BOOL VALUE
    */
    case TYPES.USER.GET_KILLSWITCH.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.USER.GET_KILLSWITCH.FETCH.SUCCEEDED:
      return model.update(state,action.payload)
    case TYPES.USER.GET_KILLSWITCH.FETCH.FAILED:
      return model.error(state,action.payload)
     /*
    UPDATE KILLSWITCH BOOL VALUE
    */
    case TYPES.USER.UPDATE_KILLSWITCH.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.USER.UPDATE_KILLSWITCH.FETCH.SUCCEEDED:
      return model.update(state,action.payload)
    case TYPES.USER.UPDATE_KILLSWITCH.FETCH.FAILED:
      return model.error(state,action.payload)
    /**
     * GENERAL
     */
    case TYPES.USER.UPDATE:
      return model.update(state, action.payload)
    case TYPES.USER.CLEAN.ERRORS:
      return model.clean(state)
    case TYPES.USER.CLEAN.STATE:
      return model.clean(initialState)
    default:
      return state
  }
}
