import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  spanTotal: {
    fontSize: 14,
    color: '#a1a1a1',
    marginLeft: 12,
  },
  spanItem: {
    fontSize: 14,
    color: '#a1a1a1',
  },
  chip: {
    borderRadius: 5,
    marginRight: 5,
    marginBottom: 10,
    padding: 5,
  },
})
