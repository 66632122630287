export default {
  from: {
    idLocation: null,
    showSave: false,
    showUpdate: false,
    showDelete: false,
  },
  to: {
    idLocation: null,
    showSave: false,
    showUpdate: false,
    showDelete: false,
  },
}
