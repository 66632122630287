import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles, Checkbox } from '@material-ui/core'
export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: '1%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '0.1fr 0.9fr',
    gap: '20px 1px',
    gridTemplateAreas: '"infobar" "mainContent"',
    backgroundColor: '#FAFAFA',
  },

  infobar: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    gap: '1px 1px',
    gridTemplateAreas: '"contentCheckboxes contentButtons"',
    gridArea: 'infobar',
  },

  contentButtons: {
    gridArea: 'contentButtons',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  contentCheckboxes: {
    gridArea: 'contentCheckboxes',
  },

  mainContent: {
    display: 'flex',
    gridArea: 'mainContent',
    justifyContent: 'space-between',
  },

  itemsTable: {
    width: '49%',
    backgroundColor: theme.palette.common.white,
  },

  ratesTable: {
    width: '49%',
    backgroundColor: theme.palette.common.white,
  },
  table: {
    height: '100%',
  },
  headTable: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.5em 1em',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '12pt',
    fontWeight: 600,
  },
  anotherStpStyle: {
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '.2em .5em',
    color: '#9B3D3D',
  },
  anotherStpStyle2: {
    marginLeft: '1em',
  },
  createShipment: {
    backgroundColor: theme.palette.common.orange,
    color: 'white',
    width: 210,
    height: 40,
    fontSize: '1em',
    textTransform: 'none',
    marginLeft: '2em',
    '&:hover': {
      backgroundColor: '#CC5500',
      borderColor: '#a25c01',
    },
  },
  requote: {
    color: 'white',
    width: 188,
    fontSize: '1em',
    textTransform: 'none',
    height: 40,
  },
  count: {
    fontStyle: 'normal',
    fontWeight: 200,
  },

}))

export const GreenCheckbox = withStyles({
  root: {
    color: 'grey',
    '&$checked': {
      color: '#D57800',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />)
