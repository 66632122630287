import { makeStyles } from '@material-ui/core/styles'
import { globalTableStyles } from 'styles/globalTableStyles'

export const useStyles = makeStyles((theme) => ({
  icon: {
    width: 25,
    height: 25,
    marginTop: 2,
  },
  originIcon: {
    fontSize: 'inherit',
    color: '#2CB556',
  },
  destinationIcon: {
    fontSize: 'inherit',
    color: '#EA5D5D',
  },
  shipmentType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    backgroundColor: '#4B2367',
    padding: '2px 4px',
    width: 60,
    borderRadius: 4,
    fontSize: '10pt',
  },
  equipmentType:{
    textTransform: 'capitalize',
  },
  reference: {
    fontWeight: 500,
    fontStyle: 'bold',
  },
}))

export const tableStyles = {
  ...globalTableStyles,
}
