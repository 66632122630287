//  libraries
import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

//  components
import Card from '../card'

//  hooks
import { useFormContext } from 'react-hook-form'

//  mocks
import optionsAccessorials from 'mocks/newAccessorials.json'

//  styles
import { useStyles, Container, Title, Content, CardContainer, ButtonContainer } from './styles'
import { MdSearch } from 'react-icons/md'

export default function AddModal ({ open, handleClick, selected, setSelected }) {
  const { setValue, getValues } = useFormContext()
  const accessorials = getValues().accessorials
  const [search, setSearch] = useState('')
  const options = optionsAccessorials.filter((item) =>
    item.label.toLocaleLowerCase().search(search.toLocaleLowerCase()) !== -1,
  )
  const classes = useStyles()

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const handleSave = () => {
    setValue('accessorials', selected)
    handleClick()
  }

  const handleCancel = () => {
    setSelected(accessorials)
    handleClick()
  }

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Container>
          <Title>
            <Typography variant='h2' className={classes.colorText} component='label'> Accessorials </Typography>
          </Title>
          <Content>
            <TextField
              value={search}
              onChange={handleSearch}
              className={classes.search}
              variant='outlined'
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <MdSearch color='#4b2367' />
                  </InputAdornment>
                ),
                placeholder: 'Search',
              }}
            />
            <CardContainer>
              {
                options.map((item, index) =>
                  <Card key={index} item={item} selected={selected} setSelected={setSelected} />,
                )
              }
            </CardContainer>
            <ButtonContainer>
              <Button variant='contained' className={classes.cancelButton} onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant='contained' className={classes.addButton} onClick={handleSave}>
                Confirm
              </Button>
            </ButtonContainer>
          </Content>
        </Container>
      </Fade>
    </Modal>
  )
}

AddModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
}
