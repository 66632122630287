import { Auth } from './services/Auth'
import { Quote } from './services/Quote'
import { Shipment } from './services/Shipment'
import { Accounting } from './services/Accounting'
import { Admin } from './services/Admin'
import { Notification } from './services/Notification'
import { Profile } from './services/Profile'
import { Location } from './services/Location'

class API {
  constructor () {
    this.authService = new Auth()
    this.quoteService = new Quote()
    this.shipmentService = new Shipment()
    this.accountingService = new Accounting()
    this.adminService = new Admin()
    this.notificationService = new Notification()
    this.profileService = new Profile()
    this.location = new Location()
  }
}

export default new API()
