import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  notificationListSucceeded,
  notificationListFailed,
  notificationListUpdateSucceeded,
  notificationListUpdateFailed,
  notificationListDeleteSucceeded,
  notificationListDeleteFailed,
} from './actions'
import API from 'API'

function * fetchNotificationList (action) {
  try {
    const { data } = yield call(API.notificationService.getNotification, action.payload)
    yield put(notificationListSucceeded(data.data))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(notificationListFailed(newError))
  }
}

function * fetchNotificationListUpdate (action) {
  try {
    yield call(API.notificationService.updateNotification, action.payload)
    yield put(notificationListUpdateSucceeded(action.payload.notifications))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(notificationListUpdateFailed(newError))
  }
}

function * fetchNotificationListDelete (action) {
  try {
    const { data } = yield call(API.notificationService.deleteNotification, action.idNotification)
    if (data.success) yield put(notificationListDeleteSucceeded(action.idNotification))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(notificationListDeleteFailed(newError))
  }
}

export default function * rootNotificationList () {
  yield takeEvery(TYPES.NOTIFICATIONS.GET_LIST.FETCH.REQUEST, fetchNotificationList)
  yield takeEvery(TYPES.NOTIFICATIONS.UPDATE_READ.FETCH.REQUEST, fetchNotificationListUpdate)
  yield takeEvery(TYPES.NOTIFICATIONS.UPDATE_DELETE.FETCH.REQUEST, fetchNotificationListDelete)
}
