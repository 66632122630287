import axios from 'axios'
import { loadSession, loadStorage } from 'tools'

export const FETCH = async (method, endpoint, payload = {}, url = null, isPdf = false, formData = false) => {
  const URL = url || `${process.env.REACT_APP_BASE_BACKEND_URL}${endpoint}`
  const Authorization = loadSession() || loadStorage()
  const { params } = payload

  const instance = axios.create({
    headers: {
      'content-type': 'application/json',
      Accept: 'application/pdf',
      Authorization: Authorization && `Bearer ${Authorization}`,
    },
  })

  if (formData) {
    return new Promise((resolve, reject) => {
      const _method = method.toLocaleLowerCase()
      try {
        return resolve(axios({
          method: _method,
          url: URL,
          data: payload,
          headers: {
            Authorization: Authorization && `Bearer ${Authorization}`,
          },
        }))
      } catch (error) {
        return reject(error)
      }
    })
  }

  return new Promise((resolve, reject) => {
    const _method = method.toLocaleLowerCase()
    if (instance[_method]) {
      try {
        return resolve(instance({
          method: _method,
          url: URL,
          data: payload,
          params,
          responseType: isPdf ? 'arraybuffer' : 'json',
        }))
      } catch (error) {
        return reject(error)
      }
    }
    return reject(new Error('Method not is valid'))
  })
}
