//  libraries
import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// components
import QuoteListSalesRep from 'components/quote/tl/salesRep/quoteListSalesRep'

// redux
import { newFormDelete } from 'redux/ducks/newForm'

// hooks
import { useDispatch } from 'react-redux'

//  styles
import { useStyles } from './styles'
import { IoMdAdd } from 'react-icons/io'

function SalesRepList ({ history }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleNewQuoteButton = () => {
    dispatch(newFormDelete())
    history.push('/sales/new/tl')
  }

  return (
    <div className={classes.container}>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h1' className={classes.h1}>Quotes</Typography>
        <Button
          variant='contained'
          color='primary'
          className={classes.newQuoteShipment}
          startIcon={<IoMdAdd className={classes.icon} />}
          onClick={handleNewQuoteButton}
        >
          New Quote
        </Button>
      </Box>
      <QuoteListSalesRep />
    </div>
  )
}

export default SalesRepList
