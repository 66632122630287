import { makeStyles } from '@material-ui/core/styles'
import { globalTableStyles } from 'styles/globalTableStyles'

export const useStyles = makeStyles((theme) => {
  return ({
    companyAvatar: {
      background: '#630A5C',
      margin: '0 auto',
      width: 50,
      height: 50,
    },
    text: {
      color: theme.palette.common.second1,
      fontWeight: 400,
    },
  })
})

export const tableStyles = {
  ...globalTableStyles,
}
