export const authRoutes = {
  login: '/api/v1/auth/user/login',
  forgotPassword: '/api/v1/auth/user/forgot-password',
  resetPassword: '/api/v1/auth/user/reset-password/:token',
  activateAccount: '/api/v1/auth/user/activate-account/:token',
  check: '/api/v1/auth/user/me',
  companiesByUser: '/api/v1/company',
  isFirstLogin: '/api/v1/auth/user/accepted-terms',
  resendActivationEmail: '/api/v1/auth/user/resend-activation',
}
