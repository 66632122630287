//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import PropTypes from 'prop-types'

// styles
import { useStyles, Root } from './styles'
import { FaCircle } from 'react-icons/fa'

const color = {
  active: '#D57800',
  expired: '#b12323',
  schedule: '#3A1852',
  pending: '#979797',
}

export default function StatusTD ({ status = '', expirationDate }) {
  const classes = useStyles()

  const estimateTime = (date) => {
    let left
    let text
    if (date) {
      const exp = moment(date)
      const now = moment()
      left = exp.diff(now, 'days')
      text = 'days'
      if (left === 1) text = 'day'
      else if (left < 1) {
        left = exp.diff(now, 'hours')
        text = 'hours'
      }
      return <Typography variant='h6' className={classes.error} noWrap> {`Expires in ${left} ${text}`}</Typography>
    }
    return null
  }

  return (
    <div>
      <Root customColor={color[status.toLowerCase()]}>
        <FaCircle style={{ width: 8 }} />
        <span className={classes.statusText}>{status.toLowerCase()}</span>
      </Root>
      {status === 'Active' && estimateTime(expirationDate)}
    </div>
  )
}

StatusTD.propTypes = {
  status: PropTypes.string,
  expirationDate: PropTypes.string,
}
