import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'

export const useStyles = makeStyles(theme => (
  {
    backArrowLink: {
      color: theme.palette.common.second3,
      margin: '0.5rem 1.5rem 0.5rem 1.5rem',
    },
    button: {
      fontWeight: 'normal',
      fontSize: 18,
      textTransform: 'none',
      width: 140,
      height: 50,
      color: theme.palette.common.second8,
      backgroundColor: '#290c3d',
      '&:hover': {
        backgroundColor: '#3A1852',
      },
    },
    sendButton: {
      marginTop: '1rem',
      fontSize: 18,
      width: 140,
      height: 50,
      textTransform: 'none',
      float: 'right',
    },
    linearProgress: {
      flexGrow: 1,
      margin: '2rem 0 0 1rem',
    },
    containerContent: {
      paddingBottom: '4rem',
      minWidth: 752,
    },
  }
))

export const Burger = styled(GiHamburgerMenu)`
  color: #4B2367;
  font-size: 40px;
  margin-right: 10px;
`
