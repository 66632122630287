//  libraries
import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import PropTypes from 'prop-types'

//  hooks
import { useSelector } from 'react-redux'
import { useFormContext, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

//  styles
import { useStyles, Title, Item1, Item2 } from './styles'
import { FaCheck } from 'react-icons/fa'

const message = 'Your shipment requires special attention and treatment. To provide an accurate quote, a member of our team will reach out to you for more details.'

export default function Tarping ({ isTarping, tarpingValue }) {
  const classes = useStyles()
  const { addToast, removeToast } = useToasts()
  const { control, watch } = useFormContext()
  const disabled = useSelector(state => state.newForm.disabled)
  const show = watch('products[0].isTarping', isTarping)
  const alert = watch('products[0].tarpingValue')

  useEffect(() => {
    if (alert === 'other') {
      addToast(message, {
        appearance: 'warning',
        autoDismiss: false,
        id: 'warning-toast-tarping',
      })
    }
    return () => { removeToast('warning-toast-tarping') }
    //  eslint-disable-next-line
  }, [alert])

  return (
    <>
      <Item1 width='200px'>
        <Title>
          <Typography variant='h4' className={classes.colorText} align='center' component='label'>
            X foot tarp required
          </Typography>
        </Title>
        <Controller
          render={props => (
            <Checkbox
              onChange={e => props.onChange(e.target.checked)}
              checked={props.value}
              color='default'
              classes={{
                root: classes.checkbox,
                checked: classes.checked,
              }}
              disabled={disabled}
            />
          )}
          defaultValue={isTarping}
          name='products[0].isTarping'
          control={control}
        />
      </Item1>
      {show && (
        <Item2>
          <Controller
            as={
              <RadioGroup className={classes.radioGroup}>
                <FormControlLabel
                  className={classes.radio}
                  value='4'
                  disabled={disabled}
                  control={
                    <Radio
                      icon={<FaCheck className={classes.unCheckedIcon} />}
                      checkedIcon={<FaCheck className={classes.checkedIcon} />}
                    />
                  }
                  label='4ft'
                  labelPlacement='top'
                />
                <FormControlLabel
                  className={classes.radio}
                  value='6'
                  disabled={disabled}
                  control={
                    <Radio
                      icon={<FaCheck className={classes.unCheckedIcon} />}
                      checkedIcon={<FaCheck className={classes.checkedIcon} />}
                    />
                  }
                  label='6ft'
                  labelPlacement='top'
                />
                <FormControlLabel
                  className={classes.radio}
                  value='8'
                  disabled={disabled}
                  control={
                    <Radio
                      icon={<FaCheck className={classes.unCheckedIcon} />}
                      checkedIcon={<FaCheck className={classes.checkedIcon} />}
                    />
                  }
                  label='8ft'
                  labelPlacement='top'
                />
                <FormControlLabel
                  className={classes.radio}
                  value='other'
                  disabled={disabled}
                  control={
                    <Radio
                      icon={<FaCheck className={classes.unCheckedIcon} />}
                      checkedIcon={<FaCheck className={classes.checkedIcon} />}
                    />
                  }
                  label='Other'
                  labelPlacement='top'
                />
              </RadioGroup>
            }
            name='products[0].tarpingValue'
            defaultValue={tarpingValue}
            control={control}
          />
        </Item2>
      )}
    </>
  )
}

Tarping.propTypes = {
  isTarping: PropTypes.bool,
  tarpingValue: PropTypes.string,
}
