//  libraries
import React, { useState, useEffect } from 'react'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'

//  components
import BackButton from 'components/common/backButton'
import CompanyInfo from 'components/common/companyInfo'
import UserForm from 'components/admin/user/userForm'
import CompanySelector from 'components/common/companySelector'

//  hooks
import { useDispatch, useSelector } from 'react-redux'

//  redux
import { CompanyInfoRequest } from 'redux/ducks/companyInfo'

//  styles
import { Container, Top, Content, Burger } from './styles'

export default function CreateEditUser () {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const companyId = useSelector(state => state.ui.companySelector.companyChild.value)
  const company = useSelector(state => state.entities.companyInfo?.data || {})

  useEffect(() => {
    if (companyId !== 'tucker' && companyId !== '') dispatch(CompanyInfoRequest(companyId))
    //  eslint-disable-next-line
  }, [companyId])

  return (
    <Container>
      <Top>
        <BackButton />
        <CompanySelector />
      </Top>
      <Content>
        <Hidden mdDown>
          <CompanyInfo company={company} />
        </Hidden>
        <Hidden lgUp>
          <Drawer
            open={open}
            onClose={() => setOpen(false)}
          >
            <CompanyInfo company={company} />
          </Drawer>
          <Burger onClick={() => setOpen(true)} />
        </Hidden>
        <UserForm />
      </Content>
    </Container>
  )
}
