import TYPES from './types'

export const productsRequest = payload => ({
  type: TYPES.PRODUCTS.FETCH.REQUEST,
  payload,
})
export const productsSucceeded = payload => ({
  type: TYPES.PRODUCTS.FETCH.SUCCEEDED,
  payload,
})

export const productsFailed = payload => ({
  type: TYPES.PRODUCTS.FETCH.FAILED,
  payload,
})
