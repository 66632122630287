import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '35px',
    fontWeight: 900,
    color: theme.palette.primary.main,
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    width: '50%',
    height: 76,
  },
}))

export const Layout = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    width: 100%;
    padding: 60px 0;
    height: 100%;
  `}
`

export const Title = styled.div`
  border-bottom: 2px solid #CBD6E2;
  width: 90%;
  height: 76px;
  margin: 0 auto;
  min-width: 400px;
`
