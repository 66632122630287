//  libraries
import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import PropTypes from 'prop-types'

//  tools
import { floatNumberValidator } from 'tools'

//  components
import FormInputs from 'components/common/formInputs'

//  hooks
import { useFormContext, Controller, useWatch } from 'react-hook-form'

//  styles
import { useStyles, Root, Header, Content, Item, Row } from './styles'
import { FaPercent, FaDollarSign } from 'react-icons/fa'

export default function EstimationForm2 ({ disabled }) {
  const classes = useStyles()
  const { register, errors, control, setValue, trigger } = useFormContext()
  const inputProps = {
    lang: 'en',
    type: 'number',
  }
  const price = useWatch({
    control,
    name: 'price',
  })
  const isPercentageUplift = useWatch({
    control,
    name: 'isPercentageUplift',
  })
  const upliftValue = useWatch({
    control,
    name: 'upliftValue',
  })

  useEffect(() => {
    let totalRate = isPercentageUplift
      ? (price / (1 - upliftValue / 100)) // Calc by percentage
      : (Number(price) + Number(upliftValue)) // Calc by plain value
    totalRate = totalRate?.toFixed(2)

    let aux = isPercentageUplift ? totalRate - price : Math.round((1 - price / totalRate) * 100)
    aux = aux ? aux?.toFixed(2) : 0
    setValue('totalRate', totalRate)
    setValue('aux', aux)
    //  eslint-disable-next-line
  }, [isPercentageUplift, upliftValue, price])

  return (
    <Root>
      <Header>
        <Typography variant='h5' className={classes.title} align='center'>Cost</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Margin</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Customer Rate</Typography>
      </Header>
      <Content>
        <Item>
          <FormInputs.Input
            disabled={disabled}
            name='price'
            rules={floatNumberValidator}
            isControlled
            control={control}
            variant='outlined'
            showError={!!errors.price?.message}
            error={errors.price}
            classNameError={classes.error}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {!isPercentageUplift ? <FaPercent /> : <FaDollarSign />}
                </InputAdornment>
              ),
            }}
          />
        </Item>
        <Row>
          <Controller
            render={props => (
              <Switch
                onChange={e => {
                  props.onChange(e.target.checked)
                  trigger()
                }}
                checked={props.value}
                disabled={disabled}
                color='primary'
              />
            )}
            control={control}
            name='isPercentageUplift'
          />
          <Box display='flex' flexDirection='column' marginRight='10px'>
            <FormInputs.Input
              disabled={disabled}
              name='upliftValue'
              inputRef={register}
              showError={!!errors.upliftValue?.message}
              error={errors.upliftValue}
              classNameError={classes.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {isPercentageUplift ? <FaPercent /> : <FaDollarSign />}
                  </InputAdornment>
                ),
              }}
              inputProps={inputProps}
            />
          </Box>
          <FormInputs.Input
            disabled
            name='aux'
            inputRef={register}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {!isPercentageUplift ? <FaPercent /> : <FaDollarSign />}
                </InputAdornment>
              ),
            }}
            inputProps={{ lang: 'en' }}
          />
        </Row>
        <Item>
          <FormInputs.Input
            disabled
            name='totalRate'
            inputRef={register}
            InputProps={{
              lang: 'en',
              endAdornment: (
                <InputAdornment position='end'>
                  {!isPercentageUplift ? <FaPercent /> : <FaDollarSign />}
                </InputAdornment>
              ),
            }}
          />
        </Item>
      </Content>
    </Root>
  )
}

EstimationForm2.propTypes = {
  disabled: PropTypes.bool,
}
