import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  ShipmentDetailsTlSucceeded,
  ShipmentDetailsTlFailed,
  ShipmentDetailsLtlSucceeded,
  ShipmentDetailsLtlFailed,
  ShipmentDetailsSetMessageSucceeded,
  ShipmentDetailsSetMessageFailed,
} from './actions'
import API from 'API'

function * fetchShipmentTlDetails (action) {
  try {
    const { data } = yield call(API.shipmentService.getTl, action.payload)
    yield put(ShipmentDetailsTlSucceeded(data))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(ShipmentDetailsTlFailed(newError))
  }
}

function * fetchShipmentLtlDetails (action) {
  try {
    const { data } = yield call(API.shipmentService.getLtl, action.payload)
    yield put(ShipmentDetailsLtlSucceeded(data))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(ShipmentDetailsLtlFailed(newError))
  }
}

function * fetchSetMessage (action) {
  try {
    const { data } = yield call(API.shipmentService.setTlMessage, action.payload, action.quoteId)
    yield put(ShipmentDetailsSetMessageSucceeded(data.data, action.payload.commentType))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(ShipmentDetailsSetMessageFailed(newError))
    action.errorCallback()
  }
}

export default function * rootShipmentDetails () {
  yield takeEvery(TYPES.SHIPMENT_DETAILS.TL.FETCH.REQUEST, fetchShipmentTlDetails)
  yield takeEvery(TYPES.SHIPMENT_DETAILS.LTL.FETCH.REQUEST, fetchShipmentLtlDetails)
  yield takeEvery(TYPES.SHIPMENT_DETAILS.SET_MESSAGE.FETCH.REQUEST, fetchSetMessage)
}
