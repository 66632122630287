import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  headCell: {
    fontSize: 18,
    fontWeight: 600,
    flexBasis: 1,
    flexGrow: 1,
    textAlign: 'center',
    marginRight: '1rem',
  },

  itemCell: {
    flexBasis: 1,
    flexGrow: 1,
    textAlign: 'center',
  },

  commentCell: {
    fontSize: 14,
    flexBasis: 1,
    flexGrow: 3,
    maxHeight: '8rem',
    overflow: 'auto',
    scrollbarColor: theme.palette.common.second3,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb ': {
      background: '#CBD6E2',
      borderRadius: 4,
    },
    listStylePosition: 'outside',
    listStyleType: 'initial',
  },

  comment: {
    margin: '0 0 1rem 1.5rem',
    fontStyle: 'italic',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  headerRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12pt',
    fontWeight: 500,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    padding: '0.4rem',
    boxShadow: '0px 1px 2px rgba(0,0,0,.1)',
    width: '100%',
  },

  row: {
    display: 'flex',
    fontSize: '12pt',
    fontWeight: 500,
    padding: '1em 0',
    boxShadow: '0 0 0 rgba(0,0,0,.1)',
  },

  arrow: {
    color: theme.palette.primary.main,
  },

}))
