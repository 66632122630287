import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  CompanyListSucceeded,
  CompanyListFailed,
} from './actions'
import API from 'API'

function * fetchCompanyList (action) {
  try {
    const { data } = yield call(API.adminService.companies, action.payload)
    yield put(CompanyListSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(CompanyListFailed(newError))
    action.callback()
  }
}

export default function * rootCompanyList () {
  yield takeEvery(TYPES.COMPANY_LIST.FETCH.REQUEST, fetchCompanyList)
}
