import TYPES from './types'

export const McLeodCompaniesRequest = (payload, callback = () => {}, errorCallback = () => {}) => ({
  type: TYPES.MCLEOD_COMPANIES.FETCH.REQUEST,
  payload,
  callback,
  errorCallback,
})

export const McLeodCompaniesSucceeded = payload => ({
  type: TYPES.MCLEOD_COMPANIES.FETCH.SUCCEEDED,
  payload,
})

export const McLeodCompaniesFailed = payload => ({
  type: TYPES.MCLEOD_COMPANIES.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.MCLEOD_COMPANIES.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.MCLEOD_COMPANIES.CLEAN.STATE,
})
