import keyMirror from 'keymirror-nested'

export default keyMirror({
  QUOTE_LIST: {
    UPDATE: {
      SELECTED_RATE: null,
      CARRIERS_RATES: null,
      ASSIGN: null,
    },
    FETCH: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
    REQUOTE: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    SURVEY: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
  },
})
