// Libraries
import React, { forwardRef } from 'react'
// Icons
import {
  MdAddBox,
  MdCheck,
  MdArrowDownward,
  MdKeyboardArrowLeft,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdChevronRight,
  MdClear,
  MdModeEdit,
  MdFilterList,
  MdFirstPage,
  MdLastPage,
  MdRemove,
  MdSearch,
  MdViewColumn,
} from 'react-icons/md'
import { AiOutlineDelete, AiOutlineCheckCircle } from 'react-icons/ai'
import { FiDownload } from 'react-icons/fi'

export const iconSizeStyle = {
  width: '27px',
  height: '27px',
  color: '#5F5F5F',
}

export const tableIcons = {
  Add: forwardRef((props, ref) => <MdAddBox style={iconSizeStyle} {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <MdCheck style={iconSizeStyle} {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <MdClear style={iconSizeStyle} {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <AiOutlineDelete style={iconSizeStyle} {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <MdChevronRight style={iconSizeStyle} {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <MdModeEdit style={iconSizeStyle} {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <FiDownload style={iconSizeStyle} {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <MdFilterList style={iconSizeStyle} {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <MdFirstPage style={iconSizeStyle} {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <MdLastPage style={iconSizeStyle} {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <MdChevronRight style={iconSizeStyle} {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <MdKeyboardArrowLeft style={iconSizeStyle} {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <MdClear style={iconSizeStyle} {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <MdSearch style={iconSizeStyle} {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <MdArrowDownward style={iconSizeStyle} {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <MdRemove style={iconSizeStyle} {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <MdViewColumn style={iconSizeStyle} {...props} ref={ref} />),
  ToggleUp: forwardRef((props, ref) => <MdKeyboardArrowUp style={iconSizeStyle} {...props} ref={ref} />),
  ToggleDown: forwardRef((props, ref) => <MdKeyboardArrowDown style={iconSizeStyle} {...props} ref={ref} />),
  CheckCircle: forwardRef((props, ref) => <AiOutlineCheckCircle style={iconSizeStyle} {...props} ref={ref} />),
}
