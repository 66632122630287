import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: '10px 0 0 0',
    [theme.breakpoints.up('lg')]: {
      padding: '0px 100px 0 30px',
    },
    display: 'grid',
    gridAutoFlow: 'column',
  },
  tab: {
    fontSize: '15px',
    textTransform: 'none',
    color: '#637280',
    fontWeight: 'normal',
    opacity: '0.75',
    flexGrow: 'unset',
    minWidth: 103,
    whiteSpace: 'nowrap',
  },
  selected: {
    fontWeight: 'bold',
  },
  indicator: {
    height: 4,
  },
}))
