import TYPES from './types'

export const notificationListRequest = (payload) => ({
  type: TYPES.NOTIFICATIONS.GET_LIST.FETCH.REQUEST,
  payload,
})

export const notificationListSucceeded = payload => {
  return {
    type: TYPES.NOTIFICATIONS.GET_LIST.FETCH.SUCCEEDED,
    payload,
  }
}

export const notificationListFailed = payload => ({
  type: TYPES.NOTIFICATIONS.GET_LIST.FETCH.FAILED,
  payload,
})

/**
 * ADD
 */

export const notificationListAdd = payload => ({
  type: TYPES.NOTIFICATIONS.ADD,
  payload,
})

/**
 * UPDATE READ
 */

export const notificationListUpdateRequest = (payload) => ({
  type: TYPES.NOTIFICATIONS.UPDATE_READ.FETCH.REQUEST,
  payload,
})

export const notificationListUpdateSucceeded = (readNotifications) => {
  return {
    type: TYPES.NOTIFICATIONS.UPDATE_READ.FETCH.SUCCEEDED,
    readNotifications,
  }
}

export const notificationListUpdateFailed = payload => ({
  type: TYPES.NOTIFICATIONS.UPDATE_READ.FETCH.FAILED,
  payload,
})

/**
 * UPDATE DELETED
 */

export const notificationListDeleteRequest = (idNotification) => ({
  type: TYPES.NOTIFICATIONS.UPDATE_DELETE.FETCH.REQUEST,
  idNotification,
})

export const notificationListDeleteSucceeded = (idNotification) => {
  return {
    type: TYPES.NOTIFICATIONS.UPDATE_DELETE.FETCH.SUCCEEDED,
    idNotification,
  }
}

export const notificationListDeleteFailed = payload => ({
  type: TYPES.NOTIFICATIONS.UPDATE_DELETE.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */

export const notificationCleanState = () => ({
  type: TYPES.NOTIFICATIONS.CLEAN.STATE,
})
