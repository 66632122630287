import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Link as LinkRouter } from 'react-router-dom'

export const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: '10px',
    width: '350px',
    background: theme.palette.background.paper,
    borderRadius: '6px',
    '& .MuiInputLabel-filled': {
      marginTop: '-8px',
    },
  },
  inputBase: {
    height: '45px',
    background: `${theme.palette.background.paper} !important`,
    color: theme.palette.common.second2,
    '& .MuiFilledInput-input': {
      padding: '15px 0px 10px 12px',
    },
  },
  submitButton: {
    display: 'block',
    margin: '0 auto',
    textTransform: 'none',
    width: '350px',
    height: '45px',
    fontWeight: '400',
    fontSize: '16px',
    color: 'white',
    background: '#D57800',
    '&:hover': {
      background: '#CC5500',
    },
  },
  messageText: {
    marginTop: 12,
    width: '70%',
    height: 48,
    color: theme.palette.common.second7,
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  errorForm: {
    margin: 0,
  },
  leftArrow: {
    width: '40px',
    height: '24px',
    color: '#fff',
  },
}))

export const Link = styled(LinkRouter)`
  color: white;
  width: auto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  display: flex;
  align-items: center;
`

export const Form = styled.form`
  width: 630px;
  height: 90%;
  max-height: 330px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
