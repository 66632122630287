// libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { yupResolver } from '@hookform/resolvers'

//  hooks
import { useForm, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

// redux
import { fetchPost } from 'redux/ducks/postData'
import { productsRequest } from 'redux/ducks/products'

// mocks
import optionsTypes from 'mocks/packageTypes.json'
import optionsFreight from 'mocks/freightClass.json'

// tools
import { createProduct } from 'tools/yupValidators'

// styles
import { useStyles, customStyles } from './styles'
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
import { alert } from 'assets'

// components
import FormInputs from 'components/common/formInputs'
import ImageNotDraggable from 'components/common/imageNotDraggable'

const CreateEditProduct = ({
  index,
  addedProduct = {},
  closeModal,
  setChoosenArrayOfProducts,
  choosenArrayOfProducts,
}) => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    errors,
    unregister,
    setValue,
    reset,
    control,
    watch,
    clearErrors,
    setError,
  } = useForm({
    provider: yupResolver(createProduct),
    defaultValues: {
      pieces: '',
      weight: '',
      quantity: '',
      width: '',
      height: '',
      length: '',
      nmfcCode: '',
      hazmat: false,
      isNewItem: false,
      ...addedProduct,
    },
  })

  const products = useSelector(state => state.entities.products.data)
  const totalWeight = watch('weight') * watch('quantity')
  const [selectedPackageType, setSelectedPackageType] = useState({ label: addedProduct.packageType, value: addedProduct.packageType }) //  renamed
  const [selectedFreightClass, setSelectedFreightClass] = useState({ label: addedProduct.freightClass, value: addedProduct.freightClass })
  const classes = useStyles()
  const { addToast } = useToasts()

  useEffect(() => {
    register({ name: 'freightClass' })
    register({ name: 'packageType' })
    return () => {
      unregister({ name: 'packageType' })
      unregister({ name: 'freightClass' })
    }
    //  eslint-disable-next-line
  }, [])

  const handleFreightClass = (data) => {
    setValue('freightClass', data.label)
    clearErrors('freightClass')
    setSelectedFreightClass(data)
  }

  const handlePackageType = (data) => {
    setValue('packageType', data.label)
    clearErrors('packageType')
    setSelectedPackageType(data)
  }

  const onSubmit = data => {
    const [exist = {}] = products.filter(el => el.itemName === data.itemName)
    const found = Object.keys(exist).length

    if (!found) {
      clearErrors('itemName')
      dispatch(fetchPost('quoteService.createProductLTl', data))
      addToast("'your data has been saved'", {
        appearance: 'success',
        autoDismiss: true,
        id: 'success-save-data',
      })
    } else {
      setError('itemName', 'duplicate name', 'product name already exist')
    }
  }

  const handleSubmitEditItem = data => {
    data._id = addedProduct._id
    const newSelected = [...choosenArrayOfProducts]
    const [exist = {}] = choosenArrayOfProducts.filter(el => el.itemName === data.itemName && el._id !== data._id)
    const found = Object.keys(exist).length

    if (!found) {
      newSelected[index] = {
        ...data,
        isOverSize: (data.height > 90 || data.length > 102 || data.width > 102),
        totalWeight: data.weight * data.quantity,
        isOverWeight: data.weight * data.quantity > 5000,
      }
      clearErrors('itemName')
      closeModal()
    } else {
      setError('itemName', 'duplicate name', 'product name already exist')
    }
    setChoosenArrayOfProducts(newSelected)
  }

  const handleSubmitEdit = data => {
    data._id = addedProduct._id
    const [exist = {}] = products.filter(el => el.itemName === data.itemName && el._id !== data._id)
    const newSelected = [...choosenArrayOfProducts]
    const found = Object.keys(exist).length
    if (!found) {
      clearErrors('itemName')
      dispatch(fetchPost('quoteService.updateProductLtl', data, '', () => { dispatch(productsRequest()) }))
      newSelected[index] = {
        ...data,
        isOverSize: (data.height > 90 || data.length > 102 || data.width > 102),
        totalWeight: data.weight * data.quantity,
        isOverWeight: data.weight * data.quantity > 5000,
      }
      setChoosenArrayOfProducts(newSelected)
    } else {
      setError('itemName', 'duplicate name', 'product name already exist')
    }
    closeModal()
  }

  const handleSubmitAddItem = data => {
    data._id = addedProduct._id
    const newSelected = [...choosenArrayOfProducts]
    const [exist = {}] = choosenArrayOfProducts.filter(el => el.itemName === data.itemName)
    const found = Object.keys(exist).length

    if (!found) {
      newSelected.push({
        ...data,
        isOverSize: (data.height > 90 || data.length > 102 || data.width > 102),
        totalWeight: data.weight * data.quantity,
        isOverWeight: data.weight * data.quantity > 5000,
      })
      addToast('an item has been added', {
        appearance: 'success',
        autoDismiss: true,
        id: 'success-add-item',
      })
      clearErrors('itemName')
      setValue('freightClass', '')
      setValue('packageType', '')
      reset()
      register({ name: 'freightClass' })
      register({ name: 'packageType' })
      setSelectedPackageType({ label: null, value: null })
      setSelectedFreightClass({ label: null, value: null })
    } else {
      setError('itemName', 'duplicate name', 'product name already exist')
    }
    setChoosenArrayOfProducts(newSelected)
  }

  const printAlert = (messageAlert) => (
    <>
      <ImageNotDraggable externalImage={alert} width='25px' />
      <Typography variant='caption' className={classes.oversize}>{messageAlert}</Typography>
    </>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.styledForm}>
      <div className={classes.formElementsBox}>
        <div style={{ margin: '0 3rem 3rem 3rem' }}>
          <FormLabel className={classes.styledName} component='div'>Name</FormLabel>
          <TextField
            error={!!errors.itemName}
            fullWidth
            variant='outlined'
            name='itemName'
            placeholder='Product name'
            inputRef={register}
            className={classes.styledTextfield}
          />
          {errors.itemName && <p className={classes.required}> {errors.itemName.message}</p>}
        </div>
        <Box display='flex' justifyContent='space-between' m='3rem'>
          <div style={{ width: '21rem' }}>
            <FormLabel className={classes.styledName} component='div'>PackageType</FormLabel>
            <Select
              value={selectedPackageType}
              error={!!errors.packageType}
              onChange={handlePackageType}
              options={optionsTypes}
              styles={customStyles}
            />
            {errors.packageType && <p className={classes.required}> {errors.packageType.message}</p>}
          </div>
          <div style={{ width: '21rem' }}>
            <FormLabel className={classes.styledName} component='div'>Freight Class</FormLabel>
            <Select
              value={selectedFreightClass}
              error={!!errors.freightClass}
              options={optionsFreight}
              styles={customStyles}
              onChange={handleFreightClass}
            />
            {errors.freightClass && <p className={classes.required}> {errors.freightClass.message}</p>}
          </div>
          <div style={{ width: '8rem' }}>
            <FormLabel className={classes.styledName} component='div'>NMFC</FormLabel>
            <FormInputs.InputMask
              className={classes.styledTextfield}
              isControlled
              mask='99999-99'
              control={control}
              name='nmfcCode'
              variant='outlined'
              error={errors.nmfcCode}
              placeholder='xxxxx-xx'
            />
            {errors.nmfcCode && <p className={classes.required}> {errors.nmfcCode.message}</p>}
          </div>
        </Box>
        <Box display='flex' justifyContent='space-between' m='3rem'>
          <div className={classes.rowPieces}>
            <FormLabel className={classes.styledName} component='div'>Pieces</FormLabel>
            <FormInputs.InputMask
              className={classes.styledTextfield}
              isControlled
              control={control}
              name='pieces'
              variant='outlined'
              error={errors.pieces}
              mask='9999'
            />
            {errors.pieces && <p className={classes.required}> {errors.pieces.message}</p>}
          </div>
          <div className={classes.rowPieces}>
            <FormLabel className={classes.styledName} component='div'>Net Weight</FormLabel>
            <FormInputs.InputMask
              className={classes.styledTextfield}
              isControlled
              control={control}
              name='weight'
              variant='outlined'
              error={errors.weight}
              mask='99999'
            />
            {errors.weight && <p className={classes.required}> {errors.weight.message}</p>}
          </div>
          <div className={classes.rowPieces}>
            <FormLabel className={classes.styledName} component='div'>Quantity</FormLabel>
            <FormInputs.InputMask
              className={classes.styledTextfield}
              isControlled
              control={control}
              name='quantity'
              variant='outlined'
              error={errors.quantity}
              mask='999'
            />
            {errors.quantity && <p className={classes.required}> {errors.quantity.message}</p>}
          </div>
          <div className={classes.rowPieces}>
            <FormLabel className={classes.styledName} component='div'>Width</FormLabel>
            <FormInputs.InputMask
              className={classes.styledTextfield}
              isControlled
              control={control}
              name='width'
              variant='outlined'
              error={errors.width}
              mask='9999'
            />
            {errors.width && <p className={classes.required}> {errors.width.message}</p>}
          </div>
          <div className={classes.rowPieces}>
            <FormLabel className={classes.styledName} component='div'>Height</FormLabel>
            <FormInputs.InputMask
              className={classes.styledTextfield}
              isControlled
              control={control}
              name='height'
              variant='outlined'
              error={errors.height}
              mask='99999'
            />
            {errors.height && <p className={classes.required}> {errors.height.message}</p>}
          </div>
          <div className={classes.rowPieces}>
            <FormLabel className={classes.styledName} component='div'>Length</FormLabel>
            <FormInputs.InputMask
              className={classes.styledTextfield}
              isControlled
              control={control}
              name='length'
              variant='outlined'
              error={errors.length}
              mask='99999'
            />
            {errors.length && <p className={classes.required}> {errors.length.message}</p>}
          </div>
        </Box>
        <div className={classes.alertRow}>
          {
            (watch('width') > 102 || watch('height') > 90 || watch('length') > 102) &&
              printAlert('Oversize detected')
          }
          {(totalWeight > 5000) && printAlert('Overweight detected')}
        </div>
        <Box display='flex' justifyContent='space-between' m='3rem'>
          <div style={{ width: '32rem' }}>
            <FormLabel className={classes.styledName} component='div'>Description</FormLabel>
            <TextField
              className={classes.styledTextfield}
              name='description'
              variant='outlined'
              fullWidth
              multiline
              inputRef={register}
              rows={4}
              error={!!errors.description}
            />
            {errors.description && <p className={classes.required}> {errors.description.message}</p>}
          </div>
          <div style={{ width: '3.2rem' }}>
            <FormLabel className={classes.styledName} component='div'>Hazmat</FormLabel>
            <Controller
              render={props => (
                <Checkbox
                  onChange={e => props.onChange(e.target.checked)}
                  checked={props.value}
                  color='primary'
                  icon={<MdCheckBoxOutlineBlank className={classes.checkboxIcon} />}
                  checkedIcon={<MdCheckBox className={classes.checkboxIcon} />}
                />
              )}
              className={classes.styledCheckbox}
              name='hazmat'
              control={control}
            />
          </div>
          <div style={{ width: '3.2rem', textAlign: 'center' }}>
            <FormLabel className={classes.styledName} component='div'>New</FormLabel>
            <Controller
              render={props => (
                <Checkbox
                  onChange={e => props.onChange(e.target.checked)}
                  checked={props.value}
                  color='primary'
                  icon={<MdCheckBoxOutlineBlank className={classes.checkboxIcon} />}
                  checkedIcon={<MdCheckBox className={classes.checkboxIcon} />}
                />
              )}
              className={classes.styledCheckbox}
              name='isNewItem'
              control={control}
            />
          </div>
        </Box>
        <Box display='flex' m='0rem 3rem'>
          <Typography
            component='h1'
            gutterBottom
            className={classes.styledTotalWeight}
          >
            Total Weight
          </Typography>
          <Typography
            component='h1'
            gutterBottom
            className={classes.styledTotalWeightValue}
          >
            {`${totalWeight.toLocaleString('en-US')} lbs`}
          </Typography>
        </Box>
      </div>
      <Box display='flex' justifyContent='center' alignItems='center' height='15%'> {/* Submit buttons */}
        <Button className={classes.styledCloseButton} onClick={closeModal} variant='contained'>
          Close
        </Button>
        {(Object.keys(addedProduct).length)
          ? (
            <>
              <Button className={`${classes.styledAddItemButton} ${classes.styledEditItemButton}`} onClick={handleSubmit(handleSubmitEdit)} variant='contained'>
                Upload
              </Button>
              <Button className={classes.styledTemplateButton} onClick={handleSubmit(handleSubmitEditItem)} variant='contained'>
                Save
              </Button>
            </>
          )
          : (
            <>
              <Button className={classes.styledTemplateButton} onClick={handleSubmit(onSubmit)} variant='contained'>
                Save as template
              </Button>
              <Button className={classes.styledAddItemButton} onClick={handleSubmit(handleSubmitAddItem)} variant='contained'>
                Add item
              </Button>
            </>
          )}
      </Box>
    </form>
  )
}

CreateEditProduct.propTypes = {
  addedProduct: PropTypes.object,
  index: PropTypes.number,
  closeModal: PropTypes.func,
  choosenArrayOfProducts: PropTypes.array,
  setChoosenArrayOfProducts: PropTypes.func,
}

export default CreateEditProduct
