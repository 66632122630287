//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'

//  components
import ZipCodeSelector from './zipCodeSelector'
import ImageNotDraggable from 'components/common/imageNotDraggable'
import FormInputs from 'components/common/formInputs'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { postIcon, locationIcon } from 'assets'
import { useStyles, Row, Title, Item } from './styles'

export default function LocationForm ({ location }) {
  const { register, errors } = useFormContext()
  const fromTo = createSelector(
    state => state.newForm[location],
    (info) => (info || {}),
  )
  const data = useSelector(fromTo)
  const classes = useStyles()

  return (
    <>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={postIcon} width='33px' />
          <Typography variant='h4' className={classes.colorText} component='label'>
            Zip Code/City
          </Typography>
        </Title>
        <Row>
          <ZipCodeSelector key={location} location={location} value={data.zip || ''} />
          <div>
            <FormInputs.Input
              key={`${location}.city`}
              name={`${location}.city`}
              inputRef={register(requiredValidator)}
              showError
              error={errors[location]?.city}
              defaultValue={data.city}
              classNameError={classes.error}
              disabled
            />
          </div>
        </Row>
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={locationIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>
            State
          </Typography>
        </Title>
        <FormInputs.Input
          key={`${location}.state`}
          name={`${location}.state`}
          inputRef={register(requiredValidator)}
          showError
          error={errors[location]?.state}
          defaultValue={data.state}
          classNameError={classes.error}
          disabled
        />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={locationIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>
            Country
          </Typography>
        </Title>
        <FormInputs.Input
          key={`${location}.country`}
          name={`${location}.country`}
          inputRef={register(requiredValidator)}
          showError
          error={errors[location]?.country}
          defaultValue={data.country}
          classNameError={classes.error}
          disabled
        />
      </Item>
    </>
  )
}

LocationForm.propTypes = {
  location: PropTypes.string.isRequired,
}
