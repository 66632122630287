//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.POST_DATA.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.POST_DATA.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.POST_DATA.FETCH.FAILED:
      return model.error(state, action.payload)
    case TYPES.POST_DATA.CLEAN.ERRORS:
      return model.clean(state)
    case TYPES.POST_DATA.CLEAN.STATE:
      return model.clean(initialState)
    default:
      return state
  }
}
