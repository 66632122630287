import { FETCH } from '../fetch'
import { locationRoutes } from '../routes/location'

export class Location {
  async getLocationByName (payload) {
    const response = await FETCH('get', locationRoutes.getLocationsByName, payload)
    return response
  }

  async saveNewLocation (payload) {
    const response = await FETCH('post', locationRoutes.setNewLocation, payload)
    return response
  }

  async deleteLocation (locationId) {
    const response = await FETCH('put', locationRoutes.deleteLocation.replace(':locationId', locationId))
    return response
  }

  async updateLocation (locationId, payload) {
    const response = await FETCH('put', locationRoutes.updateLocation.replace(':locationId', locationId), payload)
    return response
  }
}
