import TYPES from './types'

export const locationButtonsUpdate = payload => ({
  type: TYPES.LOCATION_BUTTONS.UPDATE,
  payload,
})

export const locationButtonsDelete = () => ({
  type: TYPES.LOCATION_BUTTONS.DELETE,
})
