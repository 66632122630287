// libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'

// hooks
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

// redux
import { newFormLoadFromQuoteLtl } from 'redux/ducks/newForm'
import { quoteLisRequoteFetch } from 'redux/ducks/quoteList'
import { companySelectorUpdate } from 'redux/ducks/companySelector'

// styles
import { useStyles } from './styles'

// components
import RatesTable from 'components/quote/common/carriersDetail'
import Items from 'components/common/tableFragments/quoteInfo/items'
import MessageModal from 'components/common/messageModal'
import { modalUpdate } from 'redux/ducks/modal'

const message = 'The quote was updated !'

function LtlQuoteDetail ({ rowData }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { selectedRate, items, rates } = rowData
  const [selectedIndex, setSelectedIndex] = useState(() => rowData.rates.findIndex(rate => rate._id === selectedRate))
  const requote = {
    loading: false,
    success: null,
  }
  const ratesData = rates.map(rate => ({ ...rate, requote }))
  const quoteIndex = rowData.tableData.id
  const requoteIsLoading = useSelector(state => state.entities.quoteList.ltl.data[quoteIndex]?.requote?.loading)
  const { addToast } = useToasts()

  const onConvertToShipment = () => {
    const { companyParent, companyChild, ...data } = rowData
    dispatch(newFormLoadFromQuoteLtl(data))
    dispatch(companySelectorUpdate({ companyParent, companyChild: companyChild || companyParent }))
    history.push('/shipment/convert/ltl')
  }

  const onRequote = () => {
    dispatch(quoteLisRequoteFetch(rowData._id, () => {
      addToast(message, {
        appearance: 'success',
        autoDismiss: true,
        id: 'success-toast-requote',
      })
    }))
  }

  const onModalSubmit = () => {
    dispatch(modalUpdate(true))
  }

  const references = rowData?.references?.map((item) => {
    return `${item.ref}: ${item.value}`
  })

  const listReferences = references?.join(', ')

  const totalWeight = items?.reduce((prev, next) => {
    return (prev + (Number(next.weight) || Number(next.totalWeight))) || 0
  }, 0)

  return (
    <>
      {requoteIsLoading && <LinearProgress />}
      <MessageModal
        title='IRREVERSIBLE MOVEMENT!'
        body='Are you sure you want to update this quote?'
        submitText='Yes'
        cancelText='Cancel'
        handleSubmitButton={onRequote}
      />
      <div className={classes.gridContainer}>
        <div className={classes.infobar}>
          <div className={classes.contentButtons}>
            <Button
              variant='contained'
              size='small'
              color='primary'
              onClick={onModalSubmit}
              className={classes.requote}
            >
              Requote
            </Button>
            <Button
              size='small'
              onClick={onConvertToShipment}
              variant='contained'
              className={classes.createShipment}
            >
              Convert to shipment
            </Button>
          </div>
          <div className={classes.contentCheckboxes}>
            <Typography display='inline' className={classes.anotherStpStyle2}>
              Insured:
              <Typography component='span' display='inline' className={classes.anotherStpStyle}>
                {rowData?.shipping?.insured ? 'Yes' : 'No'}
              </Typography>
            </Typography>
            <Typography display='inline' className={classes.anotherStpStyle2}>
              Greater than:
              <Typography component='span' display='inline' className={classes.anotherStpStyle}>
                {rowData?.shipping?.greater ? 'Yes' : 'No'}
              </Typography>
            </Typography>
            <Typography display='inline' className={classes.anotherStpStyle2}>
              References:
              <Typography component='span' display='inline' className={classes.anotherStpStyle}>
                {listReferences}
              </Typography>
            </Typography>
          </div>
        </div>
        <div className={classes.mainContent}>
          <div className={classes.itemsTable}>
            <Card className={classes.table}>
              <CardContent className={classes.headTable}>
                <div>
                  List of items |
                  <span className={classes.count}>
                    {items?.length} {items?.length !== 1 ? 'items' : 'item'}
                  </span>
                </div>
                <div>
                  Total Weight:
                  <span className={classes.count}>
                    {totalWeight} Lbs
                  </span>
                </div>
              </CardContent>
              <CardContent>
                <Items itemsData={items} />
              </CardContent>
            </Card>
          </div>
          <div className={classes.ratesTable}>
            <Card className={classes.table}>
              <CardContent className={classes.headTable}>
                Rates by carrier
              </CardContent>
              <CardContent>
                <RatesTable ratesData={ratesData} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} refId={rowData.refId} />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default LtlQuoteDetail

LtlQuoteDetail.propTypes = {
  rowData: PropTypes.shape({
    origin: PropTypes.object.isRequired,
    destination: PropTypes.object.isRequired,
    selectedRate: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    rates: PropTypes.array.isRequired,
    refId: PropTypes.string.isRequired,
  }),
}
