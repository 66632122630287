//  libraries
import React from 'react'
import { useMediaQuery } from 'react-responsive'

//  components
import DeskTop from './desktop'
import Mobile from './mobile'

export default function VehicleAccessorials () {
  const isDesktop = useMediaQuery({ minWidth: 601 })
  return isDesktop ? <DeskTop /> : <Mobile />
}
