//  libraries
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import PropTypes from 'prop-types'

//  styles
import { useStyles } from './styles'

export default function Loader ({ open = true }) {
  const classes = useStyles()

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress size={100} className={classes.root} />
    </Backdrop>
  )
}

Loader.propTypes = {
  open: PropTypes.bool,
}
