import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles({
  statusText: {
    marginLeft: 15,
    fontSize: 16,
    textTransform: 'capitalize',
  },
  expiration: {
    fontSize: 12,
    color: '#b12323',
  },
  error: {
    color: '#B12323',
  },
})

export const Root = styled.div`
  ${({ theme, customColor }) => css`
    display: flex;
    color: ${customColor || '#111'};
    align-items: center;
    justify-content: center;
  `}
`
