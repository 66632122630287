export default {
  loading: true,
  errors: [],
  data: [{
    comments: {
      loading: false,
    },
    marketRates: {
      loading: false,
    },
  }],
}
