//  libraries
import React, { useEffect, useState } from 'react'
import { createSelector } from 'reselect'
import Select from 'react-select'
import PropTypes from 'prop-types'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { zipCodesGet } from 'redux/ducks/zipCodes'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { Container, customStyles } from './styles'

export default function ZipCodeSelector ({ location, value }) {
  const zipCodes = createSelector(
    state => state.entities.zipCodes[location].options,
    (options) => options.map((item) => ({
      value: item.PostalCode,
      label: item.Label,
      country: item.Country,
      state: item.State,
      city: item.City,
    })),
  )

  const dispatch = useDispatch()
  const [selected, setSelected] = useState({ label: value, value: value })
  const [search, setSearch] = useState(value)
  const loading = useSelector(state => state.entities.zipCodes[location].loading)
  const disabled = useSelector(state => state.newForm.disabled)
  const optionsZipCodes = useSelector(zipCodes)
  const { register, errors, setValue, unregister, clearErrors, watch } = useFormContext()
  const idLocation = useSelector(state => state.ui.locationButtons[location].idLocation)

  useEffect(() => {
    register({ name: `${location}.zip` }, requiredValidator)
    setValue(`${location}.zip`, value)
    return () => {
      unregister(`${location}.zip`)
    }
    //  eslint-disable-next-line
  }, [location])

  useEffect(() => { // triggered when the value is updated by locationSelector component + handleSelect()
    const zipValue = watch(`${location}.zip`)
    setSelected({ value: zipValue, label: zipValue })
    setSearch(watch(`${location}.zip`))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idLocation, location])

  const handleSearch = (value, { action }) => {
    if (value.length > 7) return
    if (action === 'menu-close') {
      setSearch(selected.value)
    } else if (action !== 'input-blur' && action !== 'set-value') {
      setSearch(value)
      setSelected({ label: '', value: '' })
      setValue(`${location}.zip`, null)
      if (value.length >= 5) {
        dispatch(zipCodesGet(value, location))
      }
    }
  }

  const handleSelect = (data, { action }) => {
    if (data) {
      setSearch(data.value)
      setSelected(data)
      setValue(`${location}.zip`, data.value)
      setValue(`${location}.country`, data.country === 'USA' ? 'US' : data.country)
      setValue(`${location}.state`, data.state)
      setValue(`${location}.city`, data.city)
      clearErrors([`${location}.zip`, `${location}.country`, `${location}.state`, `${location}.city`])
    }
  }

  const formatOptionLabel = ({ value, label }, { context }) => {
    if (context === 'value') {
      return <div>{value}</div>
    } else if (context === 'menu') {
      return (
        <div>{label}</div>
      )
    }
  }

  return (
    <Container>
      <Select
        name='input'
        inputId={`input-search-${location}`}
        classNamePrefix='select'
        placeholder=''
        formatOptionLabel={formatOptionLabel}
        value={selected}
        inputValue={search}
        onInputChange={handleSearch}
        isLoading={loading}
        options={optionsZipCodes}
        error={errors[location] && !!errors[location].zip}
        styles={customStyles}
        onChange={handleSelect}
        noOptionsMessage={() => !search ? 'Please enter a Zip Code' : 'No data'}
        isDisabled={disabled}
      />
    </Container>
  )
}

ZipCodeSelector.propTypes = {
  location: PropTypes.string.isRequired,
  value: PropTypes.string,
}
