export default {
  from: {
    options: [],
    loading: false,
  },
  to: {
    options: [],
    loading: false,
  },
  general: {
    options: [],
    loading: false,
  },
  billTo: {
    options: [],
    loading: false,
  },
  remitTo: {
    options: [],
    loading: false,
  },
  errors: [],
}
