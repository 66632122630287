export default {
  ltl: {
    data: [],
    loading: false,
  },
  tl: {
    data: [],
    loading: false,
  },
  all: {
    data: [],
    loading: false,
  },
  errors: [],
}
