import TYPES from './types'
import axios from 'axios'

function fetchInfo (search) {
  return axios('https://geocoder.ls.hereapi.com/6.2/geocode.json', {
    params: {
      apiKey: process.env.REACT_APP_HERE_MAPS_API,
      postalcode: `${search}`,
      country: "USA, CAN"
    },
  })
}

export const zipCodesGet = (value, location) => {
  return function (dispatch) {
    dispatch(zipCodesRequest(location))
    if (value) {
      return fetchInfo(value).then(
        response => {
          response.data.Response.View.length > 0 && dispatch(zipCodesSucceeded(response.data.Response.View[0].Result, location))
        },
        error => dispatch(zipCodesFailed({ error })),
      )
    }
    return null
  }
}

export const zipCodesRequest = payload => ({
  type: TYPES.ZIP_CODES.FETCH.REQUEST,
  payload,
})

const zipCodesSucceeded = (payload, location) => ({
  type: TYPES.ZIP_CODES.FETCH.SUCCEEDED,
  payload,
  location,
})

const zipCodesFailed = payload => ({
  type: TYPES.ZIP_CODES.FETCH.FAILED,
  payload,
})
