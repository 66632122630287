//  libraries
import React, { useState, useEffect } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from 'react-select'
import FormHelperText from '@material-ui/core/FormHelperText'

//  components
import FormInputs from 'components/common/formInputs'
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useFormContext, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

//  redux
import { stepperUpdate } from 'redux/ducks/stepper'

//  mocks
import optionsDelivery from 'mocks/delivery.json'

//  tools
import { requiredValidator, numberValidator } from 'tools'

//  styles
import { instructionsIcon, boxIcon, dryVanIcon } from 'assets'
import { useStyles, customStyles, Item, Title, Row, LoadsContainer, SelectContainer } from './styles'
import { FaCheck } from 'react-icons/fa'

export default function InfoTl () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const shipping = useSelector(state => state.newForm.shipping)
  const activeSaveButton = useSelector(state => state.ui.stepper.activeSaveButton)
  const isEditAction = useParams().action === 'edit' && !activeSaveButton
  const initialSelect = shipping?.loadRange ? { label: shipping?.loadRange, value: shipping?.LoadRange } : null
  const { register, errors, setValue, control, unregister, watch } = useFormContext()
  const [loadRange, setLoadRange] = useState(initialSelect)
  const [show, setShow] = useState(false)
  const loads = watch('shipping.loads')

  useEffect(() => {
    if (loads > 1) {
      register({ name: 'shipping.loadRange' }, requiredValidator)
      setValue('shipping.loadRange', shipping?.loadRange)
      setShow(true)
    } else {
      unregister('shipping.loadRange')
      setShow(false)
    }
    return () => {
      unregister('shipping.loadRange')
    }
    // eslint-disable-next-line
  }, [loads])

  const handleLoadRange = (value) => {
    setLoadRange(value)
    setValue('shipping.loadRange', value.value)
    if (isEditAction) dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  return (
    <>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={instructionsIcon} width='22px' />
          <Typography variant='h4' className={classes.colorText} component='label'> Special instructions </Typography>
        </Title>
        <FormInputs.Input
          name='shipping.instructionsShipper'
          inputRef={register}
          multiline
          rows={5}
          InputProps={{
            className: classes.inputBase,
          }}
          defaultValue={shipping?.instructionsShipper}
        />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={dryVanIcon} width='50px' />
          <Typography variant='h4' className={classes.colorText} component='label'> Driver Type </Typography>
        </Title>
        <Controller
          as={
            <RadioGroup className={classes.radioGroup}>
              <FormControlLabel
                className={classes.radio}
                value='Team'
                control={
                  <Radio
                    icon={<FaCheck className={classes.unCheckedIcon} />}
                    checkedIcon={<FaCheck className={classes.checkedIcon} />}
                  />
                }
                label='Team'
                labelPlacement='top'
              />
              <FormControlLabel
                className={classes.radio}
                value='Single'
                control={
                  <Radio
                    icon={<FaCheck className={classes.unCheckedIcon} />}
                    checkedIcon={<FaCheck className={classes.checkedIcon} />}
                  />
                }
                label='Single'
                labelPlacement='top'
              />
              <FormControlLabel
                className={classes.radio}
                value='TBD'
                control={
                  <Radio
                    icon={<FaCheck className={classes.unCheckedIcon} />}
                    checkedIcon={<FaCheck className={classes.checkedIcon} />}
                  />
                }
                label='TBD'
                labelPlacement='top'
              />
            </RadioGroup>
          }
          defaultValue={shipping?.driver || 'Single'}
          name='shipping.driver'
          control={control}
        />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={boxIcon} width='25px' />
          <Typography variant='h4' className={classes.colorText} component='label'> Number </Typography>
        </Title>
        <Row>
          <LoadsContainer>
            <Typography variant='h4' className={classes.loadText} component='label' align='center'> Loads </Typography>
            <FormInputs.InputMask
              className={classes.loadNumber}
              name='shipping.loads'
              error={errors?.shipping?.loads}
              variant='outlined'
              control={control}
              rules={numberValidator}
              defaultValue={shipping?.loads || ''}
              isControlled
            />
          </LoadsContainer>
          {
            show && (
              <SelectContainer style={{width: 'auto', marginRight: 'left'}}>
                <Select
                  isSearchable={false}
                  placeholder='Select'
                  options={optionsDelivery}
                  value={loadRange}
                  onChange={handleLoadRange}
                  styles={customStyles}
                  error={errors?.shipping?.loadRange}
                />
                <FormHelperText error={!!errors?.shipping?.loadRange}>
                  {errors?.shipping?.loadRange?.message}
                </FormHelperText>
              </SelectContainer>
            )
          }
        </Row>
      </Item>
    </>
  )
}
