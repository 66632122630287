//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  hooks
import { useSelector } from 'react-redux'

//  tools
import { formatToCurrency } from 'tools'

//  styles
import { useStyles, Content } from './styles'

export default function BillingHistory () {
  const { billingHistory = [] } = useSelector(state => state.entities.accountingInfo.data)
  const classes = useStyles()

  return (
    <Content>
      {
        billingHistory.map((item, index) => (
          <div key={index} className='item'>
            <Typography variant='h2' className={classes.value}>
              {(item.value !== undefined && item.value !== '') ? formatToCurrency(parseInt(item.value)) : '...'}
            </Typography>
            <Typography variant='h3' className={classes.title} noWrap>
              {item.name}
            </Typography>
          </div>
        ))
      }
    </Content>
  )
}
