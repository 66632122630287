//  libraries
import React from 'react'
import { Switch, BrowserRouter, Redirect } from 'react-router-dom'

//  Private  Route
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

//  tools
import { validateRoutes } from 'tools'

//  Pages
import Login from 'pages/login'
import ResetPassword from 'pages/resetPassword'
import ForgotPassword from 'pages/forgotPassword'
import ActivateAccount from 'pages/activateAccount'
import Dashboard from 'pages/dashboard'
import Quote from 'pages/quote'
import NewQuote from 'pages/newQuote'
import RatesListPage from 'pages/ratesList'
import SalesRepList from 'pages/salesRepList'
import QuoteDetails from 'pages/quoteDetails'
import Shipment from 'pages/shipment'
import NewShipment from 'pages/newShipment'
import ratesListShipment from 'pages/ratesListShipment'
import Bol from 'pages/bol'
import ShipmentDetails from 'pages/shipmentDetails'
import Accounting from 'pages/accounting'
import Users from 'pages/users'
import CreateEditUser from 'pages/createEditUser'
import Companies from 'pages/companies'
import CreateEditCompany from 'pages/createEditCompany'
import Profile from 'pages/profile'
import Settings from 'pages/settings'

const { finalRoute } = validateRoutes()
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* add to hide ltl module */}
        <Redirect from='/quote/list/ltl' to='/quote/list/tl' />
        <Redirect from='/quote/new/ltl' to='/quote/new/tl' />
        <Redirect from='/shipment/list/ltl' to='/shipment/list/tl' />
        <Redirect from='/shipment/new/tl' to='/shipment/list/tl' />
        <Redirect from='/shipment/new/ltl' to='/shipment/list/ltl' />
        <Redirect from='/shipment/details/ltl/:id' to='/shipment/list/tl' />
        <Redirect from='/quote/rates/list' to='/quote/new/tl' />
        <PublicRoute exact path='/login' component={Login} />
        <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
        <PublicRoute exact path='/reset-password/:id' component={ResetPassword} />
        <PublicRoute exact path='/activate-account/:id' component={ActivateAccount} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/quote/list/:type(ltl|tl|all)' component={Quote} />
        <PrivateRoute exact path='/quote/:action(new)/:type(ltl|tl)' component={NewQuote} />
        <PrivateRoute exact path='/quote/rates/list' component={RatesListPage} />
        <PrivateRoute exact path='/shipment/list/:type(ltl|tl|all)' component={Shipment} />
        <PrivateRoute exact path='/shipment/:action(new|edit|convert)/:type(ltl|tl)' component={NewShipment} />
        <PrivateRoute exact path='/shipment/rates/list' component={ratesListShipment} />
        <PrivateRoute exact path='/shipment/details/:type(ltl|tl)/:id' component={ShipmentDetails} />
        <PrivateRoute exact path='/shipment/bol/:type(ltl|tl)' component={Bol} />
        <PrivateRoute exact path='/accounting' component={Accounting} />
        <PrivateRoute exact path='/admin/users' component={Users} />
        <PrivateRoute exact path='/admin/users/:action(new|edit)' component={CreateEditUser} />
        <PrivateRoute exact path='/admin/companies' component={Companies} />
        <PrivateRoute exact path='/admin/settings' component={Settings} />
        <PrivateRoute exact path='/admin/companies/:action(new|edit)' component={CreateEditCompany} />
        <PrivateRoute exact path='/sales/list' component={SalesRepList} />
        <PrivateRoute exact path='/sales/list/:id' component={QuoteDetails} />
        <PrivateRoute exact path='/sales/:action(new|edit)/:type(ltl|tl)' component={NewQuote} />
        <PrivateRoute exact path='/profile' component={Profile} />
        <Redirect to={finalRoute} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
