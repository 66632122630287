//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { documentIcon } from 'assets'
import { useStyles, Root, HeadingContainer, Content } from './styles'

export default function ContactCard () {
  const { name, email, phone, cellPhone } = useSelector(state => state.entities.shipmentDetails.data.contactInfo || {})
  const classes = useStyles()

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={documentIcon} width='25px' />
        <Typography variant='h2' className={classes.heading}>
          Contact Information
        </Typography>
      </HeadingContainer>
      <Content>
        <div>
          <Typography variant='h4' className={classes.title}> Name </Typography>
          <Typography variant='h4' className={classes.text}> {name || '...'} </Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.title}> Email </Typography>
          <Typography variant='h4' className={classes.text}> {email || '...'} </Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.title}> Phone </Typography>
          <Typography variant='h4' className={classes.text}> {phone || '856-317-9600'} </Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.title}> CellPhone </Typography>
          <Typography variant='h4' className={classes.text}> {cellPhone || '...'} </Typography>
        </div>
      </Content>
    </Root>
  )
}
