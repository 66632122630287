export const quoteRoutes = {
  getAll: '/api/v1/quote',
  ltl: '/api/v1/quote/ltl',
  ltlProducts: '/api/v1/quote/ltl/products',
  updateProductLtl: '/api/v1/quote/ltl/products/:productId',
  saveRate: '/api/v1/quote/ltl/rates',
  updateRatesListLtl: '/api/v1/quote/ltl/:quoteId/requote',
  setRejectSurvey: '/api/v1/quote/survey',
  tl: '/api/v1/quote/tl',
  updateTlMarket: '/api/v1/quote/tl/updateRate',
  resend: '/api/v1/quote/tl/:quoteId/resend',
  return: '/api/v1/quote/tl/:quoteId/return',
  getTlById: '/api/v1/quote/tl/:quoteId',
  setTlMessage: '/api/v1/quote/tl/:quoteId/comment',
  setTlRate: '/api/v1/quote/tl/:quoteId/rate',
  delete: '/api/v1/quote/:quoteId/delete',
  assignQuoteToASales: '/api/v1/quote/tl/:quoteId/assignedQuote',
}
