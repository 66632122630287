import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  selected: {
    width: 47,
    minWidth: 30,
    height: 39,
    backgroundColor: theme.palette.primary.dark,
    border: 'solid 1px #CBD6E2',
    color: '#fff',
    marginRight: '1rem',
    fontSize: 14,
  },
  noSelected: {
    width: 47,
    minWidth: 30,
    height: 39,
    backgroundColor: '#fff',
    border: 'solid 1px #CBD6E2',
    color: '#979797',
    marginRight: '1rem',
    fontSize: 14,
  },
}))
