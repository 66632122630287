import { all, call } from 'redux-saga/effects'
import rootUser from 'redux/ducks/user/saga'
import rootProducts from 'redux/ducks/products/saga'
import rootCarrierList from 'redux/ducks/carrierList/saga'
import rootQuoteDetails from 'redux/ducks/quoteDetails/saga'
import rootShipmentDetails from 'redux/ducks/shipmentDetails/saga'
import rootAccountingList from 'redux/ducks/accountingList/saga'
import rootAccountingInfo from 'redux/ducks/accountingInfo/saga'
import rootUserList from 'redux/ducks/userList/saga'
import rootCompanyInfo from 'redux/ducks/companyInfo/saga'
import rootCompanyList from 'redux/ducks/companyList/saga'
import rootAccounts from 'redux/ducks/accounts/saga'
import rootNotifications from 'redux/ducks/notifications/saga'
import rootMcLeodCompanies from 'redux/ducks/mcLeodCompanies/saga'
import rootLocations from 'redux/ducks/locations/saga'

export default function * rootSaga () {
  yield all([
    call(rootUser),
    call(rootProducts),
    call(rootCarrierList),
    call(rootQuoteDetails),
    call(rootShipmentDetails),
    call(rootAccountingList),
    call(rootAccountingInfo),
    call(rootUserList),
    call(rootCompanyList),
    call(rootCompanyInfo),
    call(rootAccounts),
    call(rootNotifications),
    call(rootMcLeodCompanies),
    call(rootLocations),
  ])
}
