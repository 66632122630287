import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 3.3rem',
    paddingTop: 29,
    paddingBottom: 10,
    display: 'grid',
    gridTemplateColumns: 'minmax(90%, 1500px)',
    gridAutoRows: 'min-content',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  h1: {
    marginTop: 12,
    fontSize: 21,
    color: theme.palette.common.second1,
    marginRight: 5,
  },
  button: {
    marginTop: 24,
    marginLeft: 10,
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: '#D57800',
    '&:hover': {
      backgroundColor: '#D57800',
    }
  },
  unActiveKillSwitchButton: {
    marginTop: 24,
    marginLeft: 10,
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: 'gray',
    '&:hover': {
      backgroundColor: '#gray',
    }
  },
  activeKillSwitchButton: {
    marginTop: 24,
    marginLeft: 10,
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: '#c53737',
    '&:hover': {
      backgroundColor: '#c53737',
    }
  },
  dialogTitle: {
    '& > h2': {
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
  },
  dialogContent: {
    color: 'black',
    fontWeight: 'normal',
  },
  dialogCancel: {
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: '#c53737',
  },
  dialogConfirm: {
    minWidth: 130,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'none',
    height: 38,
    color: theme.palette.common.second8,
    backgroundColor: theme.palette.primary.main,
  },
}))
