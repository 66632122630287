//  libraries
import React, { useState, useEffect } from 'react'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

//  components
import ProductModal from './productModal'
import CardAddedProducts from './cardAddedProducts'
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { useStyles } from './styles'
import { MdAdd } from 'react-icons/md'
import { items, AddItems, alert } from 'assets'

function ProductInformation () {
  const { setValue, register, unregister } = useFormContext()
  const disabled = useSelector(state => state.newForm.disabled)
  const products = useSelector(state => state.newForm.products || [])
  const [selectedArrayOfProducts, setSelectedArrayOfProducts] = useState([])
  const [choosenArrayOfProducts, setChoosenArrayOfProducts] = useState(products)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  let totalWeightProducts, isOverSize

  useEffect(() => {
    register('products', requiredValidator)
    return () => unregister('products')
    //  eslint-disable-next-line
  },[])

  useEffect(() => {
    setValue('products', choosenArrayOfProducts)
    //  eslint-disable-next-line
  }, [choosenArrayOfProducts])

  const handleClick = () => {
    setOpen(!open)
  }

  const handleChoosenProducts = () => {
    const arrayOfProducts = selectedArrayOfProducts.map(item => ({ ...item }))
    setChoosenArrayOfProducts(arrayOfProducts)
  }

  if (choosenArrayOfProducts.length > 0) {
    totalWeightProducts = choosenArrayOfProducts.reduce((total, product) => {
      return total + (product.weight * product.quantity)
    }, 0) || '...'
    isOverSize = choosenArrayOfProducts.some(element => element.isOverSize)
  }

  return (
    <>
      <Box display='flex' width='100%' flexWrap='wrap' margin='0 2rem 2rem 2rem'>
        <Box width='100%' height='3rem' marginTop='2rem'>
          <ImageNotDraggable externalImage={items} width='32px' />
          <Typography variant='caption' className={classes.itemText}>Items</Typography>
        </Box>
        {
          choosenArrayOfProducts.length
            ? (choosenArrayOfProducts.map((product, index) => {
              return (
                <CardAddedProducts
                  choosenArrayOfProducts={choosenArrayOfProducts}
                  setChoosenArrayOfProducts={setChoosenArrayOfProducts}
                  selectedArrayOfProducts={selectedArrayOfProducts}
                  setSelectedArrayOfProducts={setSelectedArrayOfProducts}
                  key={index}
                  addedProduct={product}
                  index={index}
                />
              )
            }))
            : <ImageNotDraggable externalImage={AddItems} className={classes.addItems} />
        }
      </Box>
      <div className={classes.container}>
        {choosenArrayOfProducts && choosenArrayOfProducts.length > 0 && (
          <div className={classes.totalContainer}>
            <Typography variant='h1' className={classes.total}> Total items added </Typography>
            <Typography variant='h1' className={classes.value}> {choosenArrayOfProducts.length} </Typography>
            <Typography variant='h1' className={classes.total}> Total Weight </Typography>
            <Typography variant='h1' className={classes.value}> {totalWeightProducts.toLocaleString('en-US')} </Typography>
            {isOverSize && (
              <div className={classes.positionOversize}>
                <ImageNotDraggable externalImage={alert} width='25px' />
                <Typography variant='caption' className={classes.oversize}> oversize detected </Typography>
              </div>)}
          </div>
        )}
        <Fab className={classes.fab} onClick={handleClick} disabled={disabled}>
          <MdAdd className={classes.icon} />
        </Fab>
      </div>
      <ProductModal
        open={open}
        handleClick={handleClick}
        selectedArrayOfProducts={selectedArrayOfProducts}
        setSelectedArrayOfProducts={setSelectedArrayOfProducts}
        choosenArrayOfProducts={choosenArrayOfProducts}
        setChoosenArrayOfProducts={setChoosenArrayOfProducts}
        handleChoosenProducts={handleChoosenProducts}
      />
    </>
  )
}

export default ProductInformation
