import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .error404 {
    font-size: 10rem
  }

  .divider {
    margin: 0 2rem;
    height: 11rem;
  }

  .notFound {
    width: 8rem;
  }
`
