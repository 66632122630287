import { call, takeEvery, put, select } from 'redux-saga/effects'
import TYPES from './types'
import { carrierListSucceeded, carrierListFailed } from './actions'
import API from 'API'

function * fetchCarrierListQuote (action) {
  try {
    const payload = yield select(state => state.newForm)
    const companies = yield select(state => state.ui.companySelector)
    const { data } = yield call(API.quoteService.createLtl, { ...payload, ...companies })
    yield put(carrierListSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(carrierListFailed(newError))
  }
}

function * fetchCarrierListShipment (action) {
  try {
    const formData = yield select(state => state.newForm)
    const { from, to, products } = formData
    const { data } = yield call(API.shipmentService.getLtlRates, { from, to, products })
    yield put(carrierListSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(carrierListFailed(newError))
  }
}

export default function * rootCarrierList () {
  yield takeEvery(TYPES.CARRIER_LIST.FETCH.QUOTE_REQUEST, fetchCarrierListQuote)
  yield takeEvery(TYPES.CARRIER_LIST.FETCH.SHIPMENT_REQUEST, fetchCarrierListShipment)
}
