import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  heading: {
    margin: '12px 20px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  radioGroup: {
    display: 'grid',
    gap: '80px 80px',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}))

export const Root = styled.div`
  display: grid;
  row-gap: 20px;
  width: 100%;
  padding: 15px 0px 33px 50px;
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 70px;
`
