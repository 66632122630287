//  libraries
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

//  styles
import { useStyles, Container, Actions, ValueContainer, Selector } from './styles.js'
import { FaPlus, FaMinus } from 'react-icons/fa'

export default function Card ({ item, selected, setSelected }) {
  const newSelected = [...selected]
  const { label, options, min, max } = item
  const [data = { value: min || 1 }] = selected.filter(element => element.ref === item.label)
  const index = selected.findIndex(element => element.ref === item.label)
  const [value, setValue] = useState(data.value)
  const position = selected.map(item => item.ref).indexOf(item.label)
  const classes = useStyles()

  const handleAdd = item => () => {
    setSelected(state => [...state, { ref: item.label, value, code: item.code }])
  }

  const handleDelete = index => () => {
    const newSelected = [...selected]
    newSelected.splice(index, 1)
    setSelected(newSelected)
  }

  const handlePlus = () => {
    const newValue = value + 1
    if (newValue <= max) {
      setValue(newValue)
      if (index !== -1) {
        newSelected[index].value = newValue
        setSelected(newSelected)
      }
    }
  }

  const handleMinus = () => {
    const newValue = value - 1
    if (newValue >= min) {
      setValue(newValue)
      if (index !== -1) {
        newSelected[index].value = newValue
        setSelected(newSelected)
      }
    }
  }

  return (
    <Container selected={index === -1 ? 0 : 1}>
      <Typography variant='h3' className={classes.colorText} component='label'> {label} </Typography>
      <Actions>
        <ValueContainer>
          {options && (
            <>
              <Typography variant='h5' className={classes.titleValue} component='label'> Value </Typography>
              <Selector>
                <Button variant='contained' className={classes.buttonValue} onClick={handleMinus}>
                  <FaMinus className='minus' />
                </Button>
                <Typography variant='h1' className={classes.value} component='label'> {value} </Typography>
                <Button variant='contained' className={classes.buttonValue} onClick={handlePlus}>
                  <FaPlus className='plus' />
                </Button>
              </Selector>
            </>
          )}
        </ValueContainer>
        {
          position === -1 ? (
            <Button variant='contained' className={classes.addButton} onClick={handleAdd(item)}>
              Add
            </Button>
          ) : (
            <Button variant='contained' className={classes.addButton} onClick={handleDelete(position)}>
              Delete
            </Button>
          )
        }

      </Actions>
    </Container>
  )
}

Card.propTypes = {
  item: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
}
