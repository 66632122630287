import TYPES from './types'

export const companySelectorUpdate = payload => ({
  type: TYPES.SELECTOR.UPDATE,
  payload,
})

export const companySelectorReset = () => ({
  type: TYPES.SELECTOR.RESET,
})
