//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

//  styles
import { useStyles, Root } from './styles'

export default function CustomerReferences ({ data = [], title }) {
  const classes = useStyles()

  return (
    <Root>
      <Typography variant='h5' className={classes.title} noWrap>{title}</Typography>
      <div>
        {data.map(({ ref, value }) => (
          <Typography key={ref} variant='h4' className={classes.text}>
            {ref}: <span className={classes.value}>{value}</span>
          </Typography>
        ))}
      </div>
    </Root>
  )
}

CustomerReferences.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
