import { makeStyles } from '@material-ui/core'
import { iconSizeStyle } from 'styles/tableIcons'
import { globalTableStyles } from 'styles/globalTableStyles'

export const useStyles = makeStyles(theme => (
  {
    icon: {
      ...iconSizeStyle,
      color: theme.palette.common.orange,
    },
    logo: {
      width: 50,
      height: 36,
      color: '#979797',
    },
    error: {
      fontStyle: 'italic',
      color: '#b12323',
      fontSize: 16,
      margin: 5,
    },
    errorDetail: {
      fontStyle: 'italic',
      color: '#b12323',
      fontSize: 16,
      border: 'solid 1px #eaeff4',
      borderTop: 'solid 1px white',
      padding: '2rem',
      margin: 0,
      marginBottom: '1rem',
    },
    tbody: {
      display: 'none',
    },

    sumary: {
      padding: 0,
      width: '100%',
      '&:hover': {
        backgroundColor: '#F4F4F4',
        borderColor: '#F4F4F4',
      },
      '&:focus': {
        backgroundColor: '#DDDDDD',
        borderColor: '#DDDDDD',
      },
    },
    panelDetail: {
      border: '1px solid #4A236655',
      borderStyle: 'inset',
    },
    headCell: {
      display: 'flex',
      fontSize: '12pt',
      flexBasis: 1,
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    headCellCheckbox: {
      display: 'flex',
      fontSize: '12pt',
      width: 60,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 .4em',
    },
    headNameCell: {
      display: 'flex',
      flexBasis: 2,
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },

    headerRow: {
      display: 'flex',
      fontSize: '12pt',
      fontWeight: 500,
      borderBottom: '2px solid rgba(0,0,0,.1)',
      paddingBottom: '1em',
      boxShadow: '0px 1px 2px rgba(0,0,0,.1)',
    },
    row: {
      display: 'flex',
      cursor: 'pointer',
      fontSize: '12pt',
      fontWeight: 500,
      padding: '1em 0',
      boxShadow: '0 0 0 rgba(0,0,0,.1)',
      '&:hover': {
        backgroundColor: '#F4F4F4',
        borderColor: '#F4F4F4',
      },
      '&:select': {
        backgroundColor: '#DDDDDD',
        borderColor: '#DDDDDD',
      },
    },
    tooltip: {
      minWidth: 600,
      maxWidth: 650,
      backgroundColor: theme.palette.primary.main,
    },
    arrow: {
      color: theme.palette.primary.main,
    },
    detailContainer: {
      width: '100%',
      padding: '1em .5em',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    detailContent: {
      display: 'flex',
      width: '100%',
      fontSize: '12pt',
    },
    detailBlock: {
      display: 'flex',
      flexBasis: '15%',
      flexDirection: 'column',
    },
    detailBlockHeader: {
      display: 'flex',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      color: '#5A5A5A',
    },
    detaiDescriptionlBlock: {
      display: 'flex',
      flexBasis: '55%',
      flexDirection: 'column',
    },
    detailBlockContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    detailDescBlockContent: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    serviceCell: {
      textAlign: 'center',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: '.2em .6em',
      borderRadius: '10px',
      fontSize: '10pt',
    },
    divTable: {
      display: 'table',
      width: '100%',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    divTableRow: {
      display: 'table-row',
    },
    divTableHeading: {
      backgroundColor: '#EEE',
      display: 'table-header-group',
      fontWeight: 'bold',
    },
    divTableCell: {
      display: 'table-cell',
      padding: '3px 10px',
      textAlign: 'center',
    },
    divTableHead: {
      display: 'table-cell',
      padding: '3px 10px',
      textAlign: 'center',
    },
    divTableBody: {
      display: 'table-row-group',
    },
  }
))

export const tableStyles = {
  ...globalTableStyles,
  errorRow: {
    borderBottom: 'solid 1px #b12323',
  },
}
