import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    borderBottom: `2px solid ${theme.palette.common.second6}`,
  },
  indicator: {
    height: '6px',
  },
  tab: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: '500',
    paddingBottom: '20px',
    fontSize: '22px',
    color: '#637280',
    textTransform: 'capitalize',
  },
  activeTab: {
    fontWeight: '900',
    fontSize: '35px',
    '& .MuiTab-wrapper': {
      alignItems: 'start',
    },
  },
}))

export const Container = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    margin: 5px 0;
    background-color: ${theme.palette.secondary.main};
  `}
`
