export const emailValidator = {
  required: { value: true, message: 'This field is required' },
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Invalid email address!',
  },
}

export const requiredValidator = {
  required: { value: true, message: 'This field is required' },
}

export const phoneValidator = {
  required: { value: true, message: 'This field is required' },
  validate: { positive: value => value.replace(/[^0-9]/g, '').length === 10 || 'It must have 10 digits' },
}

export const passwordValidator = {
  required: { value: true, message: 'This field is required' },
  minLength: { value: 8, message: 'It must have 8 characters or more' },
  maxLength: { value: 25, message: 'It must have 25 characters or less' },
  pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, message: 'Password must have contain at least one mayus letter, number and special character.' },
}

export const numberValidator = {
  required: { value: true, message: 'This field is required' },
  pattern: { value: /^[0-9]+$/, message: 'This field must be a number' },
}

export const floatNumberValidator = {
  required: { value: true, message: 'This field is required' },
  min: { value: 0, message: 'Min value must be 0' },
  max: { value: 1000000, message: 'Max value must be 100.000' },
  validate: value => !isNaN(value) || 'This field must be a number. Check decimals commas.',
}

export const basicTextInputValidator = {
  maxLength: {
    value: 35,
    message: 'It must have 35 characters or less',
  },
}
