//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import TablePagination from '@material-ui/core/TablePagination'

// components
import PaginationActions from './actions'

//  styles
import { useStyles } from './styles'
import { globalTableOptions } from 'styles/globalTableStyles'

export default function Pagination ({
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const classes = useStyles()
  return (
    <TablePagination
      component='div'
      className={classes.topFooter}
      rowsPerPageOptions={globalTableOptions.pageSizeOptions.map(option => ({ value: option, label: `${option} rows` }))}
      colSpan={3}
      labelRowsPerPage=''
      labelDisplayedRows={() => {}}
      ActionsComponent={PaginationActions}
      // props
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  )
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
}
