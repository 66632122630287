//  libraries
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

//  Redux
import { primaryTabUpdate } from 'redux/ducks/primaryTab'
import { filtersDelete } from 'redux/ducks/filters'

//  hooks
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

//  mocks
import moduleTabs from 'mocks/moduleTabs.json'

//  styles
import { useStyles } from './styles'

function PrimaryTabs () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const tabs = ['dashboard']
  const [, currentLocation = ''] = location.pathname.split('/')
  const tabPosition = useSelector(state => state.ui.primaryTab.position)
  const permissions = useSelector(state => state.session.data.permissions)
  const role = useSelector(state => state.session.data.role)
  Object.entries(permissions).forEach(([key, value]) => {
    if (value) tabs.push(...moduleTabs[key])
  })
  tabs.push(...moduleTabs[role])

  useEffect(() => {
    if (!tabPosition.includes(currentLocation)) {
      const [path = 'dashboard'] = tabs.filter(
        path => path.includes(currentLocation),
      )
      dispatch(primaryTabUpdate(path))
    }
    //  eslint-disable-next-line
  }, [currentLocation])

  const handleChange = (event, newValue) => {
    ReactGA.event({
      category: 'Navigate',
      action: newValue,
    })
    dispatch(primaryTabUpdate(newValue))
    dispatch(filtersDelete())
    history.push(`/${newValue}`)
  }

  const renderTabs = (label, value) => {
    const validTap = !!tabs.includes(value)
    // -------------- ⬇ remove quote/list from sales even if user has this permission
    if (!validTap || (role === 'sales' && value.includes('quote/list/'))) return null
    return (
      <Tab
        classes={{ root: classes.tab, selected: classes.selected }}
        label={label}
        value={value}
      />
    )
  }

  return (
    <Tabs
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
      value={tabPosition}
      indicatorColor='primary'
      textColor='primary'
      onChange={handleChange}
      aria-label='tabs'
      variant='fullWidth'
    >
      {renderTabs('Dashboard', 'dashboard')}
      {renderTabs('Quotes', 'quote/list/ltl')}
      {renderTabs('Shipments', 'shipment/list/ltl')}
      {renderTabs('Accounting', 'accounting')}
      {renderTabs('Quote Status', 'sales/list')}
      {renderTabs('Users', 'admin/users')}
      {renderTabs('Companies', 'admin/companies')}
      {renderTabs('Settings', 'admin/settings')}
    </Tabs>
  )
}

export default PrimaryTabs
