import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  unCheckedIcon: {
    padding: '1px',
    width: '21px',
    height: '21px',
    color: theme.palette.secondary.main,
    border: 'solid 3px #989898',
    borderRadius: '3px',
  },
  checkedIcon: {
    color: theme.palette.secondary.main,
    background: theme.palette.common.second6,
    borderRadius: '3px',
    padding: '3px',
    width: '21px',
    height: '21px',
  },
  radio: {
    margin: '0 0.5em',
    '& .MuiTypography-root': {
      color: theme.palette.common.second1,
      paddingBottom: 8,
      paddingTop: 3.2833,
      fontSize: 18,
      fontWeight: 'normal',
      fontStretch: 'normal',
    },
  },
}))

export const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  grid-column: 1 / -1;
`
