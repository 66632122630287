//  libraries
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'

//  hooks
import { useFormContext, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  mocks
import notificationsFields from 'mocks/notificationsFields.js'

//  components
import TransferList from './transferList'

//  styles
import { useStyles, ChecksContainer } from './styles'

export default function Notifications () {
  const classes = useStyles()
  const { register, setValue, control, unregister } = useFormContext()
  const notifications = useSelector(state => state.newForm.notifications || {})
  const [allSelected, setAllSelected] = useState(false)

  useEffect(() => {
    notificationsFields.forEach((item, index) => {
      register(`notifications.permissions[${index}].type`)
      setValue(`notifications.permissions[${index}].type`, item.type)
    })

    return () => {
      notificationsFields.forEach((item, index) => {
        unregister(`notifications.permissions[${index}].type`)
      })
    }
    //  eslint-disable-next-line
  }, [])

  const handleSelectAll = () => {
    const newValue = !allSelected
    notificationsFields.forEach((noti, index) => {
      setValue(`notifications.permissions[${index}].value`, newValue)
    })
    setAllSelected(value => !value)
  }

  return (
    <ChecksContainer>
      <Typography variant='h4' className={classes.title}>
        This account administrator has the following permissions for the application:
      </Typography>
      <Box display='flex' justifyContent='center' alignItems='center' gridColumn='1 / -1'>
        <Checkbox
          checked={allSelected}
          onChange={handleSelectAll}
          color='default'
          classes={{
            checked: classes.checked,
          }}
        />
        <Typography variant='h3' className={classes.text} component='label'>
          SELECT ALL
        </Typography>
      </Box>
      {
        notificationsFields.map((item, index) => (
          <div key={item.message.replace(' ', '')}>
            <Controller
              name={`notifications.permissions[${index}].value`}
              defaultValue={notifications.permissions?.[index]?.value || false}
              control={control}
              render={props => (
                <Checkbox
                  onChange={e => props.onChange(e.target.checked)}
                  checked={props.value}
                  color='default'
                  classes={{
                    checked: classes.checked,
                  }}
                />
              )}
            />
            <Typography variant='h4' className={classes.text} component='label'>
              {item.message}
            </Typography>
          </div>
        ))
      }
      <Typography variant='h4' className={classes.title}>
        Please select the users who should receive notifications about this account:
      </Typography>
      <TransferList />
    </ChecksContainer>
  )
}
