import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '35px',
    fontWeight: 900,
    color: theme.palette.primary.main,
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    width: '50%',
    height: 76,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const Content = styled.div`
  margin-top: 15px;
  height: 100%;
  max-width: 1200px;
  width: 100%;
`

export const Title = styled.div`
  border-bottom: 2px solid #CBD6E2;
  width: 100%;
  height: 76px;
`
