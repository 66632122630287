import { makeStyles } from '@material-ui/core/styles'
import { globalTableStyles } from 'styles/globalTableStyles'

export const useStyles = makeStyles((theme) => ({
  redirect: {
    cursor: 'pointer',
    color: theme.palette.primary.light,
    fontWeight: '500',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  reference: {
    color: theme.palette.primary.light,
    fontWeight: '400',
  },
  mcId: {
    fontWeight: '500',
    fontSize: 18,
  },
  originIcon: {
    fontSize: 'inherit',
    color: '#2CB556',
  },
  destinationIcon: {
    fontSize: 'inherit',
    color: '#EA5D5D',
  },
  equipmentType: {
    textTransform: 'capitalize',
  },
  exportButton: {
    height: 'auto',
    backgroundColor: '#63728022',
    color: '#63728055',
    textTransform: 'none',
    fontSize: '11pt',
    border: 0,
    marginRight: 15,
  },
  primaryColor: {
    color: '#4b2367',
    textTransform: 'capitalize',
  },
}))

export const tableStyles = {
  ...globalTableStyles,
}
