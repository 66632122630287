export default {
  set: (state, payload = {}) => {
    return {
      ...state,
      ...payload,
    }
  },

  deleteAdvancedFilters: (state, payload = []) => {
    const newState = { ...state }
    payload.forEach(key => delete newState[key])
    return {
      ...newState,
    }
  },
}
