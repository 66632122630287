
export const customStyles = {
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    fontSize: '12px',
    fontFamily: '"Roboto", sans-serif',
  }),
  control: (base, state) => {
    return ({
      ...base,
      borderStyle: 'solid',
      borderWith: '2px',
      borderColor: !state.isFocused ? state.selectProps.error ? 'red' : '#979797' : '#8D8E90',
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#F6F8FA' : '#FFFFFF',
      '&:hover': {
        border: '1px solid #8D8E90',
      },
    })
  },
  input: (base, state) => {
    return {
      ...base,
      [`& #${state.id}`]: {
        overflow: 'hidden',
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        opacity: '1 !important',
        font: 'inherit',
      },
    }
  },
}
