import keyMirror from 'keymirror-nested'

export default keyMirror({
  SHIPMENT_DETAILS: {
    TL: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    LTL: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
    SET_MESSAGE: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
  },
})
