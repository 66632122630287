import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
  return ({
    text: {
      color: theme.palette.common.second3,
      fontWeight: 400,
      margin: '0 5px',
    },
  })
})

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 215px;
  & .icon {
    margin-bottom: 3px;
  }
`
