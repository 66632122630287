import React from 'react'
import ShipmentRatesList from 'components/shipment/ltl/ltlLists/ratesList'

function ratesListShipment () {
  return (
    <ShipmentRatesList />
  )
}

export default ratesListShipment
