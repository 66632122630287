//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    /**
     * TL
     */
    case TYPES.SHIPMENT_DETAILS.TL.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.SHIPMENT_DETAILS.TL.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.SHIPMENT_DETAILS.TL.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * TL set comment
     */
    case TYPES.SHIPMENT_DETAILS.SET_MESSAGE.FETCH.REQUEST:
      return model.customLoadingMessage(state)
    case TYPES.SHIPMENT_DETAILS.SET_MESSAGE.FETCH.SUCCEEDED:
      return model.setMessage(state, action.payload, action.commentType)
    case TYPES.SHIPMENT_DETAILS.SET_MESSAGE.FETCH.FAILED:
      return model.customErrorComments(state, action.payload)
    /**
     * LTL
     */
    case TYPES.SHIPMENT_DETAILS.LTL.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.SHIPMENT_DETAILS.LTL.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.SHIPMENT_DETAILS.LTL.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * GENERAL
     */
    case TYPES.SHIPMENT_DETAILS.CLEAN.ERRORS:
      return model.clean(state)
    case TYPES.SHIPMENT_DETAILS.CLEAN.STATE:
      return model.customClean(initialState)
    default:
      return state
  }
}
