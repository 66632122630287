//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import CustomSelector from 'components/common/customSelector'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  mocks
import optionsAccessorials from 'mocks/accessorials.json'

//  styles
import { useStyles, HeadingContainer, Content, Item } from './styles'
import { IoIosClose } from 'react-icons/io'

export default function Mobile () {
  const accessorials = useSelector(state => state.newForm.accessorials)
  const [selected, setSelected] = useState(accessorials || [])
  const { setValue, register, unregister } = useFormContext()
  const classes = useStyles()

  useEffect(() => {
    register('accessorials')
    setValue('accessorials', accessorials)
    return () => unregister('accessorials')
    //  eslint-disable-next-line
  }, [accessorials])

  useEffect(() => {
    setValue('accessorials', selected)
    // eslint-disable-next-line
  }, [selected])

  const handleSelect = (data) => {
    setSelected(data)
  }

  const handleDelete = (index) => {
    const newSelected = [...selected]
    newSelected.splice(index, 1)
    setSelected(newSelected)
  }

  return (
    <div>
      <Typography variant='h4'>  Vehicle accessorials </Typography>
      <div>
        <HeadingContainer>
          <Typography variant='h5' className={classes.heading}>
            You can choose one or more accesories
          </Typography>
        </HeadingContainer>
        <Typography variant='h5' className={classes.subtitle}>
          Accessories and values
        </Typography>
        <CustomSelector selected={selected} content={optionsAccessorials} setSelected={handleSelect} />
        <Content>
          {
            selected.map((item, index) => {
              return (
                <Item key={index}>
                  <Typography variant='h5'>
                    {item.label} {item.option && ` (${item.option})`}
                  </Typography>
                  <IoIosClose onClick={() => handleDelete(index)} />
                </Item>
              )
            })
          }
        </Content>
      </div>
    </div>
  )
}
