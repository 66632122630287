//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { TimePicker, DatePicker } from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import moment from 'moment'
import PropTypes from 'prop-types'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useFormContext, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'

//  redux
import { stepperUpdate } from 'redux/ducks/stepper'

//  styles
import { locationIcon } from 'assets'
import { useStyles, Item, Title } from './styles'
import CustomToolbar from 'components/common/customToolbar'

export default function AdditionalForm ({ name }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { errors, control, setValue, watch, register, unregister, setError, clearErrors } = useFormContext()
  const isEditAction = useParams().action === 'edit'
  const activeSaveButton = useSelector(state => state.ui.stepper.activeSaveButton)
  const shipping = useSelector(state => state.newForm.shipping)
  const data = shipping[name] || {}
  // const disabled = useSelector(state => state.newForm.disabled)
  // const [startTimeDisable, setStartTimeDisable] = useState(disabled) // probablemente despues pidan que se bloquee
  const [startTime, setStartTime] = useState(
    moment(
      data.startDate ? moment(data.startDate).format("HH:mm") : "14:00",
      "HH:mm"
    ).format()
  );
  const [endTime, setEndTime] = useState(
    moment(
      data.endDate ? moment(data.endDate).format("HH:mm") : "18:00",
      "HH:mm"
    ).format()
  );
  const appointmentRequired = watch('shipping.pickup.isAppointmentRequired')
  const AppointmentScheduledAndConfirmed = watch('shipping.pickup.isAppointmentScheduledAndConfirmed')
  const bothActivated = appointmentRequired && AppointmentScheduledAndConfirmed
  const formStartDate = watch(`shipping.${name}.startDate`)
  const formEndDate = watch(`shipping.${name}.endDate`)

  useEffect(() => { // REGISTERS and initial hook form values
    register(`shipping.${name}.startTime`)
    setValue(`shipping.${name}.startTime`, startTime)
    register(`shipping.${name}.endTime`)
    setValue(`shipping.${name}.endTime`, endTime)
    if (moment(data?.startDate).startOf('day') < moment().startOf('day')) {
      setError(`shipping.${name}.startDate`, {
        type: 'manual',
        message: 'The start date is before today',
      })
    }
    return () => {
      unregister(`shipping.${name}.startTime`)
      unregister(`shipping.${name}.endTime`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => { // BLOCK-UNBLOCK StartTime
    if (name === 'pickup') {
      // setStartTimeDisable(!bothActivated && appointmentRequired) // probablemente despues pidan que se bloquee
      if (!appointmentRequired) {
        setValue('shipping.pickup.isAppointmentScheduledAndConfirmed', false)
      }
    }
  }, [bothActivated, appointmentRequired, name, unregister, setValue])
  
  useEffect(() => { // validations
    if (moment(formStartDate).startOf('day').isSame(moment(formEndDate).startOf('day')) && moment(startTime).add('2', 'h') > endTime && !AppointmentScheduledAndConfirmed) {
      setError(`shipping.${name}.endTime`, {
        type: 'manual',
        message: 'Time windows must be at least 2 hours.',
      })
    } else {
      clearErrors(`shipping.${name}.endTime`)
    }
    console.log('errors :>> ', errors.shipping);
    if (AppointmentScheduledAndConfirmed && name === 'pickup') {
      setValue(`shipping.${name}.endTime`, moment(startTime))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStartDate, formEndDate, startTime, endTime, AppointmentScheduledAndConfirmed, setError, clearErrors, setValue, name])

  useEffect(() => { // Edit validations
    if (isEditAction) {
      const startDefaultValue = data?.startDate || moment()
      const endDefaultValue = data?.endDate || getMinDate()
      const changeStart = moment(startDefaultValue).format('DD-MM-YYYY') !== moment(formStartDate).format('DD-MM-YYYY')
      const changeEnd = moment(endDefaultValue).format('DD-MM-YYYY') !== moment(formEndDate).format('DD-MM-YYYY')
      if (changeStart || changeEnd) {
        enableSaveButton()
      } else {
        dispatch(stepperUpdate({ activeSaveButton: false }))
      }
    }
    if (moment(formStartDate) > moment(formEndDate)) {
      setError(`shipping.${name}.endDate`, { type: 'manual', message: '' })
    } else {
      clearErrors(`shipping.${name}.endDate`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStartDate, formEndDate])

  // HANDLES

  const startTimeChangeHandle = (newTime) => {
    setStartTime(newTime)
    setValue(`shipping.${name}.startTime`, newTime)
    if (isEditAction) enableSaveButton()
    if (!bothActivated && name === 'pickup') { // when only startTime is visible
      setValue(`shipping.${name}.endTime`, newTime)
      return
    }
    if (newTime > endTime) {
      const newEndDate = moment(newTime).add('2', 'h')
      setEndTime(newEndDate)
      setValue(`shipping.${name}.endTime`, newEndDate)
    }
  }

  const endTimeChangeHandle = newTime => {
    setEndTime(newTime)
    setValue(`shipping.${name}.endTime`, newTime)
    if (isEditAction) enableSaveButton()
  }

  /**
   * delivery.endDate depends of pickup.endDate and pickup.startDate.
   * It have to be older than them
   */
  const getMinDate = () => {
    // Step 2, name === 'delivery'
    if (name === 'delivery') {
      return moment(shipping.pickup.endDate).add(2, 'h')
    }
    // Step 1, name === 'pickup'
    return moment(data?.startDate) < moment()
      ? moment().add(2, 'h')
      : moment(data?.startDate).add(2, 'h')
  }

  const enableSaveButton = () => {
    if (!activeSaveButton) dispatch(stepperUpdate({ activeSaveButton: true }))
  }
  return (
    <>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={locationIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>
            {`${name[0].toUpperCase() + name.slice(1)} Start Date`}
          </Typography>
        </Title>
        <Controller
          as={
            <DatePicker
              format='MM-DD-YYYY'
              inputVariant='outlined'
              className={classes.datePicker}
              minDate={name === 'pickup' ? moment() : getMinDate()}
              DialogProps={{
                className: classes.modal,
              }}
              // disabled={disabled}
            />
          }
          defaultValue={data?.startDate || moment()}
          name={`shipping.${name}.startDate`}
          control={control}
        />
        <FormHelperText className={classes.error} error={!!errors.shipping?.[name]?.startDate}>
          {errors.shipping?.[name]?.startDate?.message}
        </FormHelperText>
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={locationIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>
            {`${name[0].toUpperCase() + name.slice(1)} End Date`}
          </Typography>
        </Title>
        <Controller
          as={
            <DatePicker
              disablePast
              minDate={formStartDate}
              format='MM-DD-YYYY'
              inputVariant='outlined'
              className={classes.datePicker}
              DialogProps={{
                className: classes.modal,
              }}
              error={!!errors.shipping?.[name]?.startDate?.message}
            />
          }
          // This prevents dates from the past taking into account startDate
          defaultValue={data?.endDate || getMinDate()}
          minDateMessage={name === 'delivery'
            ? 'Delivery must be after pickup date'
            : 'Select a later date'}
          name={`shipping.${name}.endDate`}
          control={control}
        />
        <FormHelperText className={classes.error} error={!!errors.shipping?.[name]?.endDate}>
          {errors.shipping?.[name]?.endDate?.message}
        </FormHelperText>
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={locationIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>
            {`${name[0].toUpperCase() + name.slice(1)} Start Time`}
          </Typography>
        </Title>
        <TimePicker
          mask='__:__'
          inputVariant='outlined'
          className={classes.datePicker}
          // disabled={startTimeDisable} // probablemente despues pidan que se bloquee
          ToolbarComponent={CustomToolbar}
          ampm={false}
          value={startTime}
          onChange={startTimeChangeHandle}
        />
        <FormHelperText className={classes.error} error={!!errors.shipping?.[name]?.startTime}>
          {errors.shipping?.[name]?.startTime?.message}
        </FormHelperText>
      </Item>
      {!bothActivated && (
        <Item>
          <Title>
            <ImageNotDraggable externalImage={locationIcon} width='20px' />
            <Typography variant='h4' className={classes.colorText} component='label'>
              {`${name[0].toUpperCase() + name.slice(1)} End Time`}
            </Typography>
          </Title>
          <TimePicker
            mask='__:__'
            inputVariant='outlined'
            className={classes.datePicker}
            ToolbarComponent={CustomToolbar}
            ampm={false}
            value={endTime}
            minDate={startTime}
            onChange={endTimeChangeHandle}
            error={!!errors.shipping?.[name]?.endTime?.message}
          />
          <FormHelperText className={classes.error} error={!!errors.shipping?.[name]?.endTime}>
            {errors.shipping?.[name]?.endTime?.message}
          </FormHelperText>
        </Item>
      )}
    </>
  )
}

AdditionalForm.propTypes = {
  name: PropTypes.string.isRequired,
}
