import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    margin: 'auto',
    color: theme.palette.common.second3,
    fontWeight: '500',
  },
  text: {
    marginTop: '5px',
    lineHeight: 'normal',
    fontWeight: '300',
  },
  bold: {
    fontWeight: 'bold',
  },
  originIcon: {
    fontSize: 'inherit',
    color: '#2CB556',
  },
  destinationIcon: {
    fontSize: 'inherit',
    color: '#EA5D5D',
  },
}))

export const Root = styled.div`
  margin-bottom: 2rem;
`

export const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 90px 135px 135px 110px 110px 110px;
  justify-content: space-between;
  background-color: #CBD6E233;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px ${({ theme }) => theme.palette.common.second6};
  border-bottom: none;
`

export const Content = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template: 50px / 90px 135px 135px 110px 110px 110px;
  justify-content: space-between;
  border: solid 1px ${({ theme }) => theme.palette.common.second6};
  height: 90px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 30px;
`
