//  libraries
import React from 'react'

//  components
import MessageModal from 'components/common/messageModal'
import FormChangePassword from 'components/auth/formChangePassword'

//  hooks
import { useHistory } from 'react-router-dom'

// redux
import { activeRequest } from 'redux/ducks/user'

function ActivateAccount () {
  const history = useHistory()
  const customMessage = 'Please enter a new password to activate your account'

  return (
    <>
      <FormChangePassword action={activeRequest} customMessage={customMessage} buttonText='Activate account' />
      <MessageModal
        path='session.errors'
        title='SUCCESSFUL ACTIVATION!'
        body='Your access has been authorized!'
        submitText='Go to Login'
        errorText='Got it'
        handleCancelButton={() => history.push('/login')}
        handleSubmitButton={() => history.push('/login')}
      />
    </>
  )
}

export default ActivateAccount
