import { createMuiTheme } from '@material-ui/core/styles'
import { enUS } from '@material-ui/core/locale';

export const theme = createMuiTheme({
  zIndex: {
    tooltip: 1000,
  },
  palette: {
    common: {
      orange: '#D57800',
      deepOrange: '#CC5500',
      second1: '#222222',
      second2: '#8D8E90',
      second3: '#979797',
      second4: '#EEEEEE',
      second5: '#F5F5F5',
      second6: '#CBD6E2',
      second7: '#F6F8FA',
      second8: '#FFFFFF',
    },
    primary: {
      main: '#4B2367',
      dark: '#3A1852',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 16,
    body1: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 18,
      color: '#637280',
    },
    caption: {
      fontSize: 20,
      color: '#222222',
      marginLeft: 12,
    },
    subtitle1: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 38,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#1a1a1a',
    },
    h1: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 30,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: '#1a1a1a',
    },
    h2: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 25,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.28,
      letterSpacing: 'normal',
      color: '#1a1a1a',
    },
    h3: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 20,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.2,
      letterSpacing: 'normal',
      color: '#1a1a1a',
    },
    h4: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 16,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#1a1a1a',
    },
    h5: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#1a1a1a',
    },
    h6: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: '#1a1a1a',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#979797',
          },
          '&:hover fieldset': {
            borderColor: '#8D8E90',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#979797',
          },
        },
      },
    },
    MuiInput: {
      underline: {
        '&$disabled::before': {
          borderBottomStyle: 'solid',
        },
      },
    },
    MuiInputBase: {
      root: {
        height: '38px',
        fontFamily: '"Roboto", sans-serif',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '&$disabled': {
          background: '#F6F8FA',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      },
      outlined: {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.8rem',
      },
    },
    MuiButton: {
      root: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: '24px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      },
    },
    MuiTab: {
      root: {
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.1px',
      },
    },
    MuiIconButton: {
      root: {
        fontFamily: 'Oswald-Regular',
        fontSize: '18px',
        fontWeight: '300',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '0.83',
        letterSpacing: '0.25px',
      },
    },
    MuiFilledInput: {
      multiline: {
        height: 'auto',
      },
    },
    MuiOutlinedInput: {
      multiline: {
        height: 'auto',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#4B2367',
        },
      },
      checked: {
      },
    },
    MuiPickersBasePicker: {
      containerLandscape: {
        flexDirection: 'column',
        maxWidth: '100%',
      },
    },
    MuiPickersToolbar: {
      toolbarLandscape: {
        justifyContent: 'center',
        height: 100,
        maxWidth: '100%',
      },
    },
    MuiPickersTimePickerToolbar: {
      toolbarAmpmLeftPadding: {
        paddingLeft: 0,
      },
      hourMinuteLabelAmpmLandscape: {
        marginTop: 'initial',
      },
      ampmLandscape: {
        margin: '0 -20px 0 20px',
        flexBasis: 'initial',
        flexDirection: 'column',
      },
    },
    MuiLink: {
      root: {
        fontStyle: 'italic',
      },
      underlineHover: {
        textDecoration: 'underline',
        '&:hover': {
          color: '#D57800',
        },
      },
    },
  },
},enUS)
