import { loadSession, loadStorage } from 'tools'

export default {
  data: {
    userId: loadStorage('userId') || loadSession('userId'),
    token: loadStorage() || loadSession(),
    name: loadStorage('name') || loadSession('name'),
    email: loadStorage('email') || loadSession('email'),
    image: loadStorage('image') || loadSession('image'),
    role: loadStorage('role') || loadSession('role'),
    companies: loadStorage('companies') || loadSession('companies'),
    permissions: loadStorage('permissions') || loadSession('permissions'),
    killSwitch: ''
  },
  loading: false,
  errors: [],
}

