//  libraries
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

//  components
import Loader from 'components/common/loader'

//  redux
import { loginRequest } from 'redux/ducks/user'
import { companySelectorReset } from 'redux/ducks/companySelector'

//  hooks
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'hooks/useQuery'

//  tools
import { clearStorage, emailValidator, requiredValidator, clearSession } from 'tools'

//  styles
import { useStyles, Link, Form } from './styles'
import { FaUserAlt, FaKey } from 'react-icons/fa'
import moment from 'moment'

function FormLogin () {
  const query = useQuery()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const loading = useSelector(state => state.session.loading)
  const errorsSession = useSelector(state => state.session.errors)
  const { register, handleSubmit, errors, setValue } = useForm()
  const [showCode, setShowCode] = useState(false)
  const [codeLabel, setCodeLabel] = useState('')
  const [codeEmail, setCodeEmail] = useState('')

  if (errorsSession.length) {
    setValue('password', '')
    setValue('code', '')
  }

  const onSubmit = (data) => {
    dispatch(loginRequest({ ...data, email: data.email.toLowerCase() }, (need2FA) => {
      setShowCode(need2FA)
      if (!need2FA) {
        dispatch(companySelectorReset())
        if (!localStorage.getItem('timeZone')) {
          localStorage.setItem('timeZone', moment().format('Z'))
        }
        if (query.get('redirect')) history.push(query.get('redirect'))
        else history.push('/dashboard')
      } else {
        clearStorage()
        clearSession()
        setCodeLabel('Please enter verification code sent to')
        setCodeEmail(`"${data.email.toLowerCase()}"`)
      }
    }))
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name='email'
        label='Username'
        className={classes.input}
        error={!!errors.email}
        autoComplete='username'
        variant='filled'
        fullWidth
        inputRef={register(emailValidator)}
        InputProps={{
          className: classes.inputBase,
          endAdornment: <InputAdornment position='end'><FaUserAlt color='#4b2367' /></InputAdornment>,
        }}
      />
      <FormHelperText className={classes.errorForm} error={!!errors.email}>
        {errors?.email?.message}
      </FormHelperText>
      <TextField
        name='password'
        label='Password'
        type='password'
        error={!!errors.password}
        className={classes.input}
        autoComplete='current-password'
        variant='filled'
        fullWidth
        inputRef={register(requiredValidator)}
        InputProps={{
          className: classes.inputBase,
          endAdornment: <InputAdornment position='end'><FaKey color='#4b2367' /></InputAdornment>,
        }}
      />
      <FormHelperText className={classes.errorForm} error={!!errors.password}>
        {errors?.password?.message}
      </FormHelperText>
      <Link to='/forgot-password'>Forgot Password?</Link>
      {(showCode) && (
        <div style={{ color: 'white', textAlign: 'start' }}>{codeLabel}</div>
      )}
      {(showCode) && (
        <div style={{ color: 'white', textAlign: 'start' }}>{codeEmail}</div>
      )}
      {(showCode) && (
        <TextField
          name='code'
          label='Enter code'
          type='password'
          error={!!errors.code}
          variant='filled'
          className={classes.input}
          fullWidth
          inputRef={register(requiredValidator)}
          InputProps={{
            className: classes.inputBase,
            endAdornment: <InputAdornment position='end'><FaKey color='#4b2367' /></InputAdornment>,
          }}
        />)}
      {(showCode) && (
        <FormHelperText className={classes.errorForm} error={!!errors.code}>
          {errors?.code?.message}
        </FormHelperText>
      )}

      <Button
        type='submit'
        className={classes.submitButton}
        variant='contained'
        disabled={loading}
      >
        Log In
      </Button>
      <Loader open={loading} />
    </Form>
  )
}

export default FormLogin
