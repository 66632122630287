// libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

// tools
import { formatToCurrency, dateFormat } from 'tools'

// styles
import { useStyles } from './styles'

function Items ({ itemsData = {} }) {
  const classes = useStyles()
  const formatDate = itemsData.rate?.pickupWindow?.date
    ? dateFormat(itemsData.rate?.pickupWindow.date, 'MM/DD/YYYY')
    : '...'
  const formatTime = itemsData.rate?.pickupWindow?.time

  const comments = itemsData.comments?.external?.map((comment) => (
    <Typography key={comment._id} component='li' className={classes.comment}>
      {comment?.message}
    </Typography>
  ))

  return (
    <CardContent style={{ width: '100%' }}>
      <div className={classes.headerRow}>
        <Typography className={classes.headCell}>Rate</Typography>
        <Typography className={classes.headCell}>Transit Time</Typography>
        <Typography className={classes.headCell} style={{ flex: '1.8 1' }}>Estimated Pickup Date</Typography>
        <Typography className={classes.headCell} style={{ flex: '3 1' }}>Comments</Typography>
      </div>
      <div>
        <div className={classes.row}>
          <Typography className={classes.itemCell}>
            {`${itemsData.rate?.customerPrice
              ? formatToCurrency(itemsData.rate?.customerPrice)
              : '...'}`}
          </Typography>
          <Typography className={classes.itemCell}>
            {itemsData.rate?.daysInTransit
              ? `${itemsData.rate?.daysInTransit} Days`
              : '...'}
          </Typography>
          <div className={classes.itemCell} style={{ flexGrow: 1.8 }}>
            {itemsData.rate?.pickupWindow?.date
              ? (
                <>
                  <Typography display='block'> {formatDate} </Typography>
                  <Typography display='block'> {formatTime} </Typography>
                </>
              )
              : '...'}
          </div>
          <ul className={classes.commentCell}>
            {comments?.length
              ? comments
              : <Typography align='center' component='li'>...</Typography>}
          </ul>
        </div>
      </div>
    </CardContent>
  )
}

export default Items
