import TYPES from './types'
import { resolve } from 'tools'

export function quoteListFetch (table, data, statePath = '', callback = () => {}) {
  return (dispatch, getState, api) => {
    dispatch(quoteListRequest(table.toLowerCase()))
    const payload = data || resolve(statePath, getState())
    const endPoint = `get${table}`
    return api.quoteService[endPoint](payload).then(
      ({ data }) => {
        dispatch(quoteListSucceeded(data, table.toLowerCase()))
        callback()
      },
      (error) => {
        const newError = error.response?.data || { error: error.message }
        dispatch(quoteListFailed(newError))
        callback()
      },
    )
  }
}

export const quoteListRequest = table => ({
  type: TYPES.QUOTE_LIST.FETCH.REQUEST,
  table,
})

export const quoteListSucceeded = (payload, table) => ({
  type: TYPES.QUOTE_LIST.FETCH.SUCCEEDED,
  payload,
  table,
})

export const quoteListFailed = (payload) => ({
  type: TYPES.QUOTE_LIST.FETCH.FAILED,
  payload,
})

/**
 * RE-QUOTE
 */

export function quoteLisRequoteFetch (quoteId, callback) {
  return (dispatch, getState, api) => {
    dispatch(quoteLisRequoteRequest(quoteId))
    return api.quoteService.reQuote(quoteId)
      .then(({ data }) => {
        if (data.success) {
          const { customerPrice, carrierPrice, addedP44Rates: ratesList, selectedRate } = data.data
          dispatch(quoteLisRequoteSucceeded(ratesList, customerPrice, carrierPrice, selectedRate))
          callback()
        } else {
          dispatch(quoteLisRequoteFailed(quoteId))
        }
      })
      .catch(error => {
        console.log(error)
        dispatch(quoteLisRequoteFailed(quoteId))
      })
  }
}

export const quoteLisRequoteRequest = (quoteId) => ({
  type: TYPES.QUOTE_LIST.REQUOTE.FETCH.REQUEST,
  quoteId,
})

export const quoteLisRequoteSucceeded = (ratesList, customerPrice, carrierPrice, selectedRate) => ({
  type: TYPES.QUOTE_LIST.UPDATE.CARRIERS_RATES,
  ratesList,
  customerPrice,
  carrierPrice,
  selectedRate,
})

export const quoteLisRequoteFailed = (quoteId) => ({
  type: TYPES.QUOTE_LIST.REQUOTE.FETCH.FAILED,
  quoteId,
})

/**
 * SURVEY
 */

export const quoteListSurveyFetch = (payload, successCallback, errorCallback) => {
  return (dispatch, getState, api) => {
    dispatch(quoteListSurveyRequest())
    return api.quoteService.setRejectSurvey(payload)
      .then(({ data }) => {
        const { quoteId, status } = data.data
        if (data.success) {
          dispatch(quoteListSurveySucceeded(quoteId, status))
          successCallback()
        } else {
          dispatch(quoteListSurveyFailed(quoteId))
          errorCallback()
        }
      })
      .catch(() => {
        dispatch(quoteListSurveyFailed(payload.quoteId))
        errorCallback()
      })
  }
}

export const quoteListSurveyRequest = (payload) => ({
  type: TYPES.QUOTE_LIST.SURVEY.FETCH.REQUEST,
  payload,
})

export const quoteListSurveySucceeded = (quoteId, newStatus) => ({
  type: TYPES.QUOTE_LIST.SURVEY.FETCH.SUCCEEDED,
  quoteId,
  newStatus,
})

export const quoteListSurveyFailed = (quoteId) => ({
  type: TYPES.QUOTE_LIST.SURVEY.FETCH.FAILED,
  quoteId,
})

export const quoteListAssignSucceeded = (quoteId, newData) => ({
  type: TYPES.QUOTE_LIST.UPDATE.ASSIGN,
  quoteId,
  newData,
})
/**
 * GENERAL
 */
export const quoteLisUpdateLtlSelectedRate = (refId, newSelectedRate) => ({
  type: TYPES.QUOTE_LIST.UPDATE.SELECTED_RATE,
  refId,
  newSelectedRate,
})

export const updateRatesAndStatusById = (refId, customerPrice, carriersRates) => ({
  type: TYPES.QUOTE_LIST.UPDATE.CARRIERS_RATES,
  refId,
  customerPrice,
  carriersRates,
})

export const cleanErrors = () => ({
  type: TYPES.QUOTE_LIST.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.QUOTE_LIST.CLEAN.STATE,
})
