import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  colorText: {
    color: theme.palette.common.second1,
    marginLeft: 18,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
  },
  inputBase: {
    height: 'auto',
  },
  checked: {
    color: '#CBD6E2',
  },
}))

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: center;
`

export const ItemInstructions = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 3;
`

export const ItemCheck = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`
