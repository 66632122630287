import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  toolbar: {
    flexWrap: 'wrap',
    padding: '0 6px',
    maxWidth: '21rem',
  },

  toolbarText: {
    width: '100%',
    textAlign: 'center',
    fontSize: '0.8rem',
    margin: '-8px 0',
  },
})
