import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
    minWidth: 370,
  },
  okButton: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelButton: {
    textTransform: 'none',
    backgroundColor: '#cbd6e2',
    fontSize: '1rem',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#637280',
    },
  },
  dialogTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  textColor: {
    color: theme.palette.common.black,
  },
}))
