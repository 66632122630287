// libraries
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
// styles
import { useStyles } from './styles'

const ProductDetail = ({ item, title }) => {
  const classes = useStyles()
  return (
    <>
      <Typography style={{ color: 'white', padding: '.2em .5em', fontWeight: 600 }}>{title}</Typography>
      <div className={classes.detailContainer}>
        <div className={classes.detailContent}>
          <div className={classes.detaiDescriptionlBlock}>
            <div className={classes.detailBlockHeader}>
              Description
            </div>
            <Typography component='div' className={classes.detailDescBlockContent}>{item.description}</Typography>
          </div>
          <div className={classes.detailBlock}>
            <div className={classes.detailBlockHeader}>
              Packaging
            </div>
            <Typography component='div' className={classes.detailBlockContent}> {item.packageType}</Typography>
          </div>
          <div className={classes.detailBlock}>
            <div className={classes.detailBlockHeader}>
              Hazmat
            </div>
            <Typography component='div' className={classes.detailBlockContent}> {item.hazmat ? 'Yes' : 'No'} </Typography>
          </div>
          <div className={classes.detailBlock}>
            <div className={classes.detailBlockHeader}>
              New/Used
            </div>
            <Typography component='div' className={classes.detailBlockContent}>{item.isNewItem ? 'New' : 'Used'}</Typography>
          </div>
        </div>
      </div>
    </>
  )
}

function Items ({ itemsData = [] }) {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.headerRow}>
        <div className={classes.headNameCell}>Item</div>
        <div className={classes.headCell}>Pieces</div>
        <div className={classes.headCell}>Class</div>
        <div className={classes.headCell}>Dimensions</div>
        <div className={classes.headCell}>Quantity</div>
        <div className={classes.headCell}>NMFC</div>
        <div className={classes.headCell}>Weight</div>
      </div>
      <div style={{ width: '100%' }}>
        {
          itemsData.map((product = [], index) => (

            <Tooltip
              TransitionComponent={Zoom}
              PopperProps={{
                disablePortal: true,
              }}
              key={index}
              id={'tooltip' + index}
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              arrow
              title={<ProductDetail title='Product Detail' item={product} />}
            >
              <div className={classes.row}>
                <div className={classes.headNameCell}>{product.itemName || '--'}</div>
                <div className={classes.headCell}>{product.totalPieces || '--'}</div>
                <div className={classes.headCell}>{product.freightClass || '--'}</div>
                <div className={classes.headCell}>{product.length || product.packageDimensions?.length}x{product.width || product.packageDimensions?.width}x{product.height || product.packageDimensions?.height}</div>
                <div className={classes.headCell}>{product.totalPackages}</div>
                <div className={classes.headCell}>{product.nmfcCode || '--'}</div>
                <div className={classes.headCell}>{product.weight || product.totalWeight} Lbs</div>
              </div>
            </Tooltip>
          ))
        }

      </div>
    </div>
  )
}

ProductDetail.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
}

export default Items
