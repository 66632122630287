import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  button: {
    fontSize: 12,
    color: theme.palette.common.second8,
    backgroundColor: '#cbd6e2',
    margin: '1rem auto',
    textTransform: 'none',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: '#637280',
    },
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
  },
  loader: {
    alignSelf: 'center',
  },
  badge: {
    '& .MuiBadge-badge': {
      padding: 0,
      fontSize: '0.70em',
    },
  },
  paper: {
    maxHeight: '27rem',
    width: '15rem',
    padding: '1rem',
    overflowX: 'hidden',
    position: 'fixed',
    top: 61,
    right: '13rem',
    left: 'auto',
    zIndex: 5,
  },
  title: {
    color: '#4b4b4b',
  },
}))
