//  libraries
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import DialogModal from 'components/common/dialog'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  mocks
import referenceList from 'mocks/references.js'

//  styles
import { instructionsIcon } from 'assets'
import { useStyles, Content, Item, ItemButton, Title, Row, ReferenceContainer, ItemRef, customStyles } from './styles'
import { FaTrashAlt } from 'react-icons/fa'

export default function Reference ({ type }) {
  const classes = useStyles()
  const selectorRef = React.useRef(null)
  const { register, unregister, setValue } = useFormContext()
  const data = useSelector(state => state.newForm.references)
  const shipping = useSelector(state => state.newForm.shipping)
  const [openModal, setOpenModal] = useState(false)
  const [referenceOptions, setReferencesOptions] = useState(referenceList
    .map(({ label }) => ({ value: label, label })))
  const [selected, setSelected] = useState({ label: '', value: '' })
  const [references, setReferences] = useState(data || [])
  const [text, setText] = useState('')
  const [okDelete, setOkDelete] = useState(false)
  const importantReferences = ['BM', 'OR', 'PO']
  const [deleting, setDeleting] = useState({
    indexToDelete: null,
    refToDelete: {},
  })

  useEffect(() => {
    if (deleting.indexToDelete !== null && okDelete) {
      removeReference()
      selectorRef.current.select.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [okDelete])

  useEffect(() => {
    register({ name: 'references' })
    setValue('references', data)
    // if quote already includes purchase, then remove it from options
    if (data.map(({ ref }) => ref).includes('Purchase Order Number')) {
      setReferencesOptions((oldRef) => oldRef.filter(({ label }) => label !== 'Purchase Order Number'))
    }
    return () => { unregister({ name: 'references' }) }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setValue('references', references)
    // eslint-disable-next-line
  }, [references])

  const handleSelect = (data) => {
    if (data) {
      setSelected(data)
      setReferencesOptions((oldOptions) => oldOptions.filter(({ value }) => value !== data.value))
    }
  }

  const handleChange = (event) => {
    setText(event.target.value)
  }

  const handleSave = () => {
    if (!!text && selected.value !== '') {
      setReferences([...references, { ref: selected.value, value: text }])
      setText('')
      setSelected({ value: '', label: '' })
    }
    selectorRef.current.select.focus()
  }

  const handleDelete = index => () => {
    const dataDeleting = {
      indexToDelete: index,
      refToDelete: references[index],
    }
    setDeleting(dataDeleting)
    if (importantReferences.includes(dataDeleting.refToDelete.ref)) {
      setOpenModal(true)
      return
    }
    setOkDelete(true)
  }

  const removeReference = () => {
    // remove ref from selected list references
    const newReferences = [...references]
    newReferences.splice(deleting.indexToDelete, 1)
    setReferences(newReferences)
    // add deleted reference on the selector options
    const updateOptions = referenceOptions.slice()
    const newOptionIndex = referenceList.findIndex(({ value }) => value === deleting.refToDelete.ref)
    updateOptions.splice(newOptionIndex, 0, referenceList[newOptionIndex])
    setReferencesOptions(updateOptions)
    // reset toDelete values
    setDeleting({
      indexToDelete: null,
      refToDelete: {},
    })
    setOkDelete(false)
  }

  return (
    <Content>
      {type === 'tl' && (
        <Item>
          <Title>
            <ImageNotDraggable externalImage={instructionsIcon} width='22px' />
            <Typography variant='h4' className={classes.colorText} component='label'>Special instructions for Consignee</Typography>
          </Title>
          <TextField
            name='shipping.instructionsConsignee'
            variant='outlined'
            inputRef={register}
            multiline
            rows={5}
            InputProps={{
              className: classes.inputBase,
            }}
            defaultValue={shipping?.instructionsConsignee}
          />
        </Item>
      )}
      <Item>
        <Title>
          <ImageNotDraggable externalImage={instructionsIcon} width='22px' />
          <Typography variant='h4' className={classes.colorText} component='label'>Reference</Typography>
        </Title>
        <Row>
          <Select
            classNamePrefix='select'
            name='input'
            placeholder=''
            isSearchable
            value={selected}
            onChange={handleSelect}
            options={referenceOptions}
            styles={customStyles}
            ref={selectorRef}
          />
          <TextField
            variant='outlined'
            value={text}
            onChange={handleChange}
            inputProps={{
              maxLength: 18,
            }}
          />
        </Row>
        <ItemButton>
          <Button className={classes.button} variant='contained' color='primary' onClick={handleSave}>
            Add
          </Button>
        </ItemButton>
      </Item>
      {references.length > 0 && (
        <Item>
          <Title>
            <ImageNotDraggable externalImage={instructionsIcon} width='22px' />
            <Typography variant='h4' className={classes.colorText} component='label'>Added references</Typography>
          </Title>
          <ReferenceContainer>
            {references.map((item, index) => (
              <ItemRef key={item.ref}>
                <Typography variant='h6' className={classes.ref}>
                  {item.ref} {` (${item.value})`}
                </Typography>
                <IconButton onClick={handleDelete(index)}>
                  <FaTrashAlt />
                </IconButton>
              </ItemRef>
            ))}
          </ReferenceContainer>
        </Item>
      )}
      <DialogModal
        open={openModal}
        title='Deleting Reference'
        body='Are you sure you want to delete this reference?'
        okText='Delete Reference'
        cancelText='Cancel'
        onOk={() => {
          setOkDelete(true)
          setOpenModal(false)
        }}
        onCancel={() => { setOpenModal(false) }}
      />
    </Content>
  )
}
