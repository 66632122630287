import keyMirror from 'keymirror-nested'

export default keyMirror({
  NOTIFICATIONS: {
    GET_LIST: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
    ADD: null,
    UPDATE_READ: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
    UPDATE_DELETE: {
      FETCH: {
        REQUEST: null,
        SUCCEEDED: null,
        FAILED: null,
      },
    },
  },
})
