import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  heading: {
    marginLeft: 24,
    color: theme.palette.primary.main,
    fontWeight: 400,
    lineHeight: 'normal',
    fontSize: 24,
  },
  title: {
    fontWeight: 400,
    color: theme.palette.common.second3,
  },
  text: {
    fontSize: 18,
    color: theme.palette.common.second1,
    fontWeight: 400,
  },
}))

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  display: grid;
  row-gap: 25px;
  margin-left: 52px;
  margin-top: 27px;
`

export const Row = styled.div`
  display: flex;
  & .first {
    margin-right: 77px;
  }
  & .right {
    margin-right: 33px;
  }
`

export const Block = styled.div`
  display: grid;
  grid-template-columns: 215px 215px;
  gap: 10px 20px;
  justify-items: left;
`
