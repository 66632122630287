import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  itemContainer: {
    display: 'block',
  },
  notificationItem: {
    display: 'inline-block',
    padding: '0.5rem',
    width: '86%',
  },
  unClickable: {
    display: 'inline-block',
    padding: '0.5rem',
    cursor: 'default',
    width: '86%',
    '&:hover': {
      background: '#fff',
    },
  },
  readFlag: {
    color: theme.palette.common.orange,
    float: 'right',
    marginTop: 5,
  },
  closeIcon: {
    display: 'inline',
    color: theme.palette.common.second2,
    padding: 4,
    borderRadius: '100%',
    fontSize: '1.4rem',
    position: 'relative',
    top: -7,
    '&:hover': {
      backgroundColor: theme.palette.common.second7,
    },
  },
  notificationTitle: {
    whiteSpace: 'pre-line',
  },
}))
