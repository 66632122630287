import { Link as LinkRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  text: {
    fontWeight: 'bold',
    color: theme.palette.common.second1,
  },
}))

export const TitleContainer = styled.div`
  display: flex;
  margin: auto 0;
  .icon {
    color: #979797;
    font-size: 20px;
    cursor: pointer;
  }
`

export const Link = styled(LinkRouter)`
  margin: auto 20px auto 0px;
`
