//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import {
  CompanyCard,
  ShipperConsigneeCard,
  ReferenceCard,
  DocumentCard,
  ItemsCard,
  TrackingCard,
  ContactCard,
  CommentsCard,
} from 'components/shipment/common/cards'
import TrackStepper from 'components/shipment/common/trackStepper'

//  styles
import { documentIcon } from 'assets'
import { useStyles, Root, HeadingContainer, Content } from './styles'

export default function Details () {
  const classes = useStyles()

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={documentIcon} width='40px' />
        <Typography variant='h1' className={classes.heading}>
          Shipment Details
        </Typography>
      </HeadingContainer>
      <Content>
        <TrackStepper />
        <CompanyCard />
        <ShipperConsigneeCard />
        <ItemsCard />
        <ReferenceCard />
        <DocumentCard />
        <TrackingCard />
        <CommentsCard />
        <ContactCard />
      </Content>
    </Root>
  )
}
