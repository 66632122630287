//  libraries
import React, { useState } from 'react'
import MaterialTable from 'material-table'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'

// components
import Menu from './menu'
import MessageModal from 'components/common/messageModal'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { fetchPost } from 'redux/ducks/postData'

//  tools
import { dateFormat } from 'tools'

// Styles
import { AiOutlineFilePdf } from 'react-icons/ai'
import { useStyles, Container, tableStyles } from './styles'
import { globalTableOptions } from 'styles/globalTableStyles'
import { tableIcons } from 'styles/tableIcons'

export default function AccountList ({ data, loading }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const { data, loading } = useSelector(state => state.entities.accountingList || {})
  const [pdfUrl, setPdfUrl] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const request = (endPoint, data, action) => {
    dispatch(fetchPost(endPoint, data, '', (response) => {
      // eslint-disable-next-line no-undef
      const file = new Blob(
        [response],
        { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      setPdfUrl(fileURL)
      if (action === 'download') {
        const link = document.createElement('a')
        link.download = 'invoice'
        link.href = fileURL
        link.click()
        window.URL.revokeObjectURL(fileURL)
      }
      if (action === 'modal') setOpenModal(true)
    }))
  }

  return (
    <>
      <MaterialTable
        isLoading={loading}
        data={data}
        icons={tableIcons}
        actions={[
          {
            name: 'menu',
            icon: '',
            onClick: () => {},
          },
        ]}
        columns={[
          {
            field: 'billDate',
            title: 'Invoice Date',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <Typography variant='h4' className={classes.text}>
                {dateFormat(rowData.billDate, 'MM/DD/YY')}
              </Typography>
            ),
          },
          {
            field: 'deliveryDate',
            title: 'Delivery Date',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <Typography variant='h4' className={classes.text}>
                {dateFormat(rowData.deliveryDate, 'MM/DD/YY')}
              </Typography>
            ),
          },
          {
            field: 'orderId',
            title: 'Order #',
            type: 'numeric',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <Typography variant='h4' className={classes.text}>
                {rowData.orderId}
              </Typography>
            ),
          },
          {
            field: 'invoiceNumber',
            title: 'Invoice #',
            type: 'numeric',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <Typography variant='h4' className={classes.text}>
                {rowData.invoiceNumber}
              </Typography>
            ),
          },
          {
            title: 'Reference #',
            field: 'reference',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <Typography variant='h4' className={classes.text}>
                {rowData.reference}
              </Typography>
            ),
          },
          {
            field: 'totalCharges',
            title: 'Amount',
            type: 'currency',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: rowData => (
              <Typography variant='h4' className={classes.text}>
                $ {rowData.totalCharges.toFixed(2) || rowData.totalCharges}
              </Typography>
            ),
          },
        ]}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          sorting: true,
        }}
        components={{
          Action: ({ data }) => (
            <Menu id={data.orderId} request={request} />
          ),
        }}
        style={{
          boxShadow: 'none',
          width: '100%',
        }}
        localization={{
          header: {
            actions: 'View Documents',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No invoices to display',
          },
        }}
      />
      <Modal
        open={openModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        onClose={() => { setOpenModal(false) }}
      >
        <Container>
          <iframe title='pdf' width='100%' height='100%' src={pdfUrl} />
        </Container>
      </Modal>
      <MessageModal
        path='entities.postData.errors'
      />
    </>
  )
}
