import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 400,
    color: theme.palette.common.second3,
  },
  title2: {
    marginTop: '10px',
    color: '#262626',
    fontWeight: 400,
  },
  text1: {
    marginTop: '5px',
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  text2: {
    marginTop: '5px',
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.common.second1,
  },
  text3: {
    fontWeight: 400,
    color: '#ABABAB',
    marginTop: '10px',
  },
}))

export const Root = styled.div`
  margin-left: 52px;
  margin-top: 27px;
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(${({ tl }) => tl ? 2 : 5}, auto);
  grid-template-rows: repeat(3, auto);
  .carrier {
    grid-column-start: 4;
  }
  .customer {
    grid-column-start: 5;
  }
  .divider {
    margin: 5px 0;
    grid-column: 1/-1;
    grid-row-start: 2;
  }
`
