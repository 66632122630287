// libraries
import React from 'react'
import { FormHelperText } from '@material-ui/core'

//  hooks
import { Controller } from 'react-hook-form'

export default function InputElement ({
  isControlled,
  defaultValue,
  name,
  control,
  rules,
  showError,
  error,
  render,
  onChange,
  classNameError,
  ...props
}) {
  return (
    <>
      {isControlled ? (
        <Controller
          as={render(props)}
          defaultValue={defaultValue}
          name={name}
          control={control}
          rules={rules}
          onChange={onChange}
        />
      ) : (
        render({ ...props, onChange, defaultValue, name })
      )}
      {showError && (
        <FormHelperText error={!!error} className={classNameError}>{error?.message}</FormHelperText>
      )}
    </>
  )
}
