import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
  `}
`

export const Content = styled.div`
  max-width: 1200px;
  margin: 20px 10px;
  width: 100%;
`
