import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  title2: {
    fontSize: 18,
    lineHeight: 1.22,
    color: theme.palette.common.second1,
    fontWeight: 400,
  },
  title3: {
    color: theme.palette.common.second3,
    fontWeight: 400,
  },
  subtitle: {
    color: theme.palette.common.second3,
    fontWeight: 400,
  },
  text: {
    fontSize: 18,
    color: theme.palette.common.second1,
    fontWeight: 400,
  },
  text2: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}))

export const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 31px 64px 34px 28px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid ${theme.palette.common.second6};
    border-radius: 4px;
  `}
`

export const Section = styled.div`
  display: flex;
  align-items: center;
   & .image {
    align-self: flex-start;
  }
   & .company {
    margin-left: 20px;
  }
`

export const Section2 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-end;
  gap: 40px 60px;
`
