//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

// components
import FilterBar from 'components/common/filterBar'
import CompanyList from 'components/admin/companies/companyList'

//  hooks
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

//  redux
import { CompanyListRequest } from 'redux/ducks/companyList'
import { newFormDelete } from 'redux/ducks/newForm'

//  styles
import { useStyles } from './styles'
import { BsPlus } from 'react-icons/bs'

export default function Companies () {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleNewCompany = () => {
    dispatch(newFormDelete())
    history.push('/admin/companies/new')
  }

  return (
    <div className={classes.container}>
      <Typography variant='h1' className={classes.h1}>Companies List</Typography>
      <Box display='flex' justifyContent='space-between'>
        <FilterBar
          filters={['searchBar']}
          thunkAction={CompanyListRequest}
        />
        <Button
          variant='contained'
          className={classes.button}
          startIcon={<BsPlus />}
          onClick={handleNewCompany}
        >
          New Company
        </Button>
      </Box>
      <CompanyList />
    </div>
  )
}
