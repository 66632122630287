import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '90%',
    position: 'absolute',
    height: '88%',
    maxWidth: '1050px',
  },
  styledTab: {
    backgroundColor: 'white',
  },
  styledTabItem: {
    height: 74,
  },
  tabPanel: {
    flexGrow: 1,
    height: 10,
  },
}))
