import TYPES from './types'

export const AccountingInfoRequest = (payload) => ({
  type: TYPES.ACCOUNTING_INFO.FETCH.REQUEST,
  payload,
})

export const AccountingInfoSucceeded = payload => ({
  type: TYPES.ACCOUNTING_INFO.FETCH.SUCCEEDED,
  payload,
})

export const AccountingInfoFailed = payload => ({
  type: TYPES.ACCOUNTING_INFO.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.ACCOUNTING_INFO.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.ACCOUNTING_INFO.CLEAN.STATE,
})
