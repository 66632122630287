//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.QUOTE_DETAILS.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.QUOTE_DETAILS.FETCH.SUCCEEDED:
      return model.capturedResponse(state, action.payload)
    case TYPES.QUOTE_DETAILS.FETCH.FAILED:
      return model.error(state, action.payload)
    /**
     * NEW MESSAGE
     */
    case TYPES.QUOTE_DETAILS.SET_MESSAGE.FETCH.REQUEST:
      return model.customLoadingMessage(state)
    case TYPES.QUOTE_DETAILS.SET_MESSAGE.FETCH.SUCCEEDED:
      return model.setMessage(state, action.payload, action.commentType)
    case TYPES.QUOTE_DETAILS.SET_MESSAGE.FETCH.FAILED:
      return model.customErrorComments(state, action.payload)
    /**
     * UPDATE MARKET RATES
     */
    case TYPES.QUOTE_DETAILS.UPDATE_RATES.FETCH.REQUEST:
      return model.customLoadingMarketRates(state)
    case TYPES.QUOTE_DETAILS.UPDATE_RATES.FETCH.SUCCEEDED:
      return model.updateMarketRates(state, action.payload)
    case TYPES.QUOTE_DETAILS.UPDATE_RATES.FETCH.FAILED:
      return model.customErrorMarketRates(state, action.payload)
      /**
     * DECLINE QUOTE
     */
    case TYPES.QUOTE_DETAILS.DECLINE.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.QUOTE_DETAILS.DECLINE.FETCH.SUCCEEDED:
      return model.updateStatus(state, action.payload)
    case TYPES.QUOTE_DETAILS.DECLINE.FETCH.FAILED:
      return model.declineError(state, action.payload)
    /**
     * GENERAL
     */
    case TYPES.QUOTE_DETAILS.CLEAN.ERRORS:
      return model.clean(state)
    case TYPES.QUOTE_DETAILS.CLEAN.STATE:
      return model.clean(initialState)
    default:
      return state
  }
}
