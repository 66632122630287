import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Radio } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
  button: {
    width: 140,
    height: 34,
    borderRadius: 4,
    fontSize: 14,
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.palette.common.orange,
    },
  },
  modalTitle: {
    margin: '1.5rem',
  },
}))

export const CustomRadio = withStyles(theme => ({
  root: {
    color: theme.palette.common.second3,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => <Radio color='default' {...props} />)
