//  libraries
import React, { useLayoutEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

// tools
import { dateFormat } from 'tools'

//  styles
import { Container } from './styles'
import { locationIconActive } from 'assets'

function moveMapToFocus (map, start) {
  map.setCenter({ lat: start.latitude, lng: start.longitude })
  map.setZoom(6)
}
export default function Map ({ theme = 'normal.day', trackingInfo }) {
  const [map, setMap] = useState(null)
  const [router, setRouter] = useState(null)
  const [group, setGroup] = useState(null)
  const mapRef = useRef(null)
  const customIcon = new window.H.map.Icon(locationIconActive)

  const info = useMemo(() => trackingInfo
    .filter(coordinate => coordinate.latitude && coordinate.longitude)
  , [trackingInfo])

  const routingParameters = {
    transportMode: 'truck',
    routingMode: 'fast',
    return: ['polyline'],
  }

  useLayoutEffect(() => {
    const platform = new window.H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API,
    })

    const defaultLayers = platform.createDefaultLayers()

    const _map = new window.H.Map(
      mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: {
          lat: info[0].latitude,
          lng: info[0].longitude,
        },
        zoom: 6,
        pixelRatio: window.devicePixelRatio || 1,
      },
    )
    window.onload = setTimeout(() => moveMapToFocus(_map, info[0]), 500)

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => _map.getViewPort().resize())

    // change theme
    const tiles = platform.getMapTileService()
    const layer = tiles.createTileLayer(
      'maptile',
      theme,
      256,
      'png8',
    )
    _map.setBaseLayer(layer)

    // _map.addLayer(defaultLayers.vector.normal.trafficincidents)
    _map.addLayer(defaultLayers.vector.normal.traffic)

    // eslint-disable-next-line no-unused-vars
    const _behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(_map))
    // eslint-disable-next-line new-cap
    const _ui = new window.H.ui.UI.createDefault(_map, defaultLayers, 'en-US')
    // eslint-disable-next-line new-parens
    _ui.setUnitSystem(window.H.ui.UnitSystem.IMPERIAL)

    const _group = new window.H.map.Group()
    _map.addObject(_group)

    _group.addEventListener('tap', function (e) {
      _map.setCenter(e.target.getGeometry())
      const bubble = new window.H.ui.InfoBubble(e.target.getGeometry(), {
        content: e.target.getData(),
      })
      _ui.addBubble(bubble)
    }, false)

    setGroup(_group)
    setMap(_map)
    setRouter(platform.getRoutingService(null, 8))
    // eslint-disable-next-line
  }, [])

  const addMarkerToGroup = (coordinate, city, date, time) => {
    if (coordinate.lat === undefined || coordinate.lng === undefined) return
    const marker = new window.H.map.Marker(coordinate, { icon: customIcon })
    marker.setData(`
    <div class='mapContent'>
      <div class='title'> ${city} </div>
      <div class='date'> ${date} </div>
      <div class='date'> ${time} </div>
    </div>
    `)
    group.addObject(marker)
  }

  const onResult = (result) => {
    if (result.routes.length) {
      result.routes[0].sections.forEach((section) => {
        // eslint-disable-next-line new-cap
        const lineString = window.H.geo.LineString.fromFlexiblePolyline(section.polyline)

        const routeLine = new window.H.map.Polyline(lineString, {
          style: { strokeColor: '#4B2367', lineWidth: 3 },
        })

        map.addObjects([routeLine])
      })
    }
  }

  (() => {
    if (!map) return
    const origin = info[0]
    const destination = info.slice(-1)[0]

    for (let i = 0; i < info.length; i++) {
      const actual = info[i]
      const next = info[i + 1]

      if (actual.latitude === undefined || actual.longitude === undefined) continue
      if (next) {
        routingParameters.origin = actual.latitude + ',' + actual.longitude
        routingParameters.destination = next.latitude + ',' + next.longitude
        router.calculateRoute(
          routingParameters,
          onResult,
          (error) => console.log(error),
        )
      }
      addMarkerToGroup({
        lat: actual.latitude,
        lng: actual.longitude,
      },
      actual.city,
      actual.date && dateFormat(actual.date, 'MM/DD/YY'),
      actual.date && dateFormat(actual.date, 'HH:mm'))
    }

    const center = new window.H.geo.Rect(origin.latitude, origin.longitude, destination.latitude, destination.longitude)
    map.getViewModel().setLookAtData({
      bounds: center,
    })
  })()

  return (
    <Container ref={mapRef} />
  )
}

Map.propTypes = {
  theme: PropTypes.oneOf([
    'normal.day',
    'normal.day.grey',
    'normal.day.transit',
    'normal.night',
    'normal.night.grey',
    'reduced.night',
    'reduced.day',
    'pedestrian.day',
    'pedestrian.night',
  ]),
  info: PropTypes.array,
}
