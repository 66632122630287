import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 22,
    color: theme.palette.common.second1,
    fontWeight: 500,
    marginTop: 14,
    paddingBottom: 25,
    borderBottom: `2px solid ${theme.palette.common.second6}`,
  },
  avatar: {
    height: '160px',
    width: '160px',
    margin: '0 auto',
    background: theme.palette.primary.main,
  },
  companyName: {
    marginTop: '32px',
    fontSize: '24px',
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  companyEmail: {
    marginTop: '4px',
    fontSize: '24px',
    fontWeight: 400,
    color: theme.palette.common.second1,
    borderBottom: `2px solid ${theme.palette.common.second6}`,
    paddingBottom: 40,
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 700,
    color: theme.palette.common.second1,
  },
  text1: {
    fontWeight: 400,
    color: theme.palette.common.second3,
    textAlign: 'center',
  },
  text2: {
    fontWeight: 400,
    fontSize: '18px',
    color: theme.palette.common.second1,
  },
}))

export const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    margin-left: 10px;
    padding-right: 40px;
    width: 100%;
    height: 100%;
    @media (max-width: 1270px) {
      margin-top: 160px;
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    padding:  40px 40px 0 40px;
  `}
`

export const SubContent = styled.div`
  ${({ theme }) => css`
  padding:  22px 32px 0 40px;
  `}
`

export const Info = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 10px 10px;
    justify-content: flex-start;
    margin-top: 23px;
    margin-bottom: 30px;
    align-items: center;
  `}
`
