import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const useStyles = makeStyles(theme => ({
  item: {
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: theme.palette.common.second1,
  },
}))

export const Root = styled.div`
  margin-left: 52px;
  margin-top: 27px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 500px);
  gap: 10px 60px;
`

export const Content = styled.div`
  ${({ theme }) => css`
    width: 500px;
    height: 90px;
    padding: 29px;
    border: 1px solid ${theme.palette.common.second6};
    border-radius: 4px;
  `}
`
