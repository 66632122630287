//  libraries
import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'

//  components
import CustomIcon from './customIcon'
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

// tools
import { dateFormat } from 'tools'

//  styles
import { reeferIconActive, dryVanIconActive, flatbedIconActive } from 'assets'
import { useStyles, Root, StepperContainer, Title, CustomStepperConnector } from './styles'

const icon = {
  'dry-van': dryVanIconActive,
  reefer: reeferIconActive,
  flatbed: flatbedIconActive,
}

export default function TrackStepper () {
  let active
  const { type } = useParams()
  const info = useSelector(state => state.entities.shipmentDetails.data.statusHistory || {})
  const { departure, arrived, status, transportType } = useSelector(state => state.entities.shipmentDetails.data?.general || {})
  const steps = Object.keys(info).map((key, index) => (
    { ...info[key], name: key }
  ))
  const delivered = status === 'Delivered'
  const classes = useStyles({ delivered })

  if (delivered) {
    active = 7
  } else {
    active = steps.findIndex(pos => pos.name === status)
  }

  return (
    <Root>
      <StepperContainer>
        <Title>
          <div>
            <Typography variant='h4' className={classes.title} align='center'> Departure </Typography>
            <Typography variant='h4' className={classes.text}> {departure ? dateFormat(departure, 'MM/DD/YYYY HH:mm') : ''}</Typography>
          </div>
          <div>
            <Typography variant='h4' className={classes.title} align='center'> Arrived </Typography>
            <Typography variant='h4' className={classes.text}> {arrived ? dateFormat(arrived, 'MM/DD/YYYY HH:mm') : ''} </Typography>
          </div>
        </Title>
        <Stepper
          activeStep={active}
          alternativeLabel
          connector={(
            <CustomStepperConnector delivered={delivered ? 1 : 0} />
          )}
        >
          {steps.map(({ city, state, date, name }, index) => (
            <Step key={name}>
              <StepLabel
                StepIconComponent={CustomIcon} StepIconProps={{
                  delivered: delivered,
                }}
              >
                <Typography variant='h4' className={classes.text}> {name || ''} </Typography>
                {['dispatched', 'in transit'].includes(name.toLowerCase()) && (
                  <>
                    <Typography variant='h4' className={classes.location}> {city || ''} </Typography>
                    <Typography variant='h6' className={classes.location} gutterBottom> {state || ''} </Typography>
                  </>
                )}
                {name.toLowerCase() !== 'in review' && (
                  <>
                    <Typography variant='h5' className={classes.date}> {date ? dateFormat(date, 'MM/DD/YY') : ''} </Typography>
                    <Typography variant='h5' className={classes.date}> {date ? dateFormat(date, 'HH:mm') : ''} </Typography>
                  </>
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperContainer>
      {type === 'tl' && (
        <div className='info'>
          <Typography variant='h4' className={classes.subtitle}> Transport Type </Typography>
          <Typography variant='h4' className={classes.text}> {transportType ? transportType.charAt(0).toUpperCase() + transportType.slice(1) : '...'} </Typography>
          <ImageNotDraggable className='image' externalImage={transportType ? icon[transportType] : icon['dry-van']} width='120px' />
        </div>
      )}
    </Root>
  )
}
