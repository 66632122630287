import { makeStyles } from '@material-ui/core'
import { iconSizeStyle } from 'styles/tableIcons'
import { globalTableStyles } from 'styles/globalTableStyles'

export const useStyles = makeStyles(theme => (
  {
    icon: {
      ...iconSizeStyle,
      color: theme.palette.common.orange,
    },
    logo: {
      width: 50,
      height: 36,
      color: '#979797',
    },
    error: {
      fontStyle: 'italic',
      color: '#b12323',
      fontSize: 16,
    },
    errorDetail: {
      fontStyle: 'italic',
      color: '#b12323',
      fontSize: 16,
      border: 'solid 1px #eaeff4',
      borderTop: 'solid 1px white',
      padding: '2rem',
      margin: 0,
      marginBottom: '1rem',
    },
  }
))

export const tableStyles = {
  ...globalTableStyles,
  errorRow: {
    borderBottom: 'solid 1px #b12323',
  },
  serviceCell: {
    textAlign: 'center',
    color: 'purple',
  },
}
