// LTL comments are commented

export default [
  // {
  //   message: "Send an email when a quote LTL is created",
  //   type: "ltlQuoteCreation"
  // },
  {
    message: 'Send an email when a TL quote is created',
    type: 'tlQuoteCreation',
  },
  {
    message: 'Send an email when quote status is updated',
    type: 'quoteStatusUpdate',
  },
  // {
  //   message: "Send an email when an LTL shipment is created",
  //   type: "ltlShipmentCreation"
  // },
  {
    message: 'Send an email when an TL shipment is created',
    type: 'tlShipmentCreation',
  },
  {
    message: 'Send an email when shipment is marked picked up',
    type: 'shipmentUpdatePickup',
  },
  {
    message: 'Send an email when a shipment status is updated',
    type: 'shipmentStatusUpdate',
  },
  {
    message: 'Send an email when the shipment is marked delivered',
    type: 'shipmentUpdateDelivery',
  },
]
