import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  setOfProducts: {
    padding: '6.9%',
    paddingTop: '2%',
    paddingBottom: '1%',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
  },
  styledInput: {
    '& input': {
      paddingLeft: theme.spacing(1),
    },
  },
  productsContainer: {
    display: 'grid',
    overflowY: 'scroll',
    width: 'calc( 100% + 15px )',
    paddingRight: 5,
    marginTop: 50,
    marginBottom: 10,
    '&::-webkit-scrollbar-thumb': {
      width: '7px',
      height: '130px',
      opacity: 0.3,
      borderRadius: '3.5px',
      backgroundColor: '#cbd6e2',
      backgroundClip: 'content-box',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'white',
      width: 10,
    },
  },
  styledTotalItems: {
    marginTop: 10,
  },
  styledTotals: {
    marginBottom: 0,
  },
  productButtons: {
    width: 180,
    height: 50,
    '& span': {
      fontSize: 18,
      color: 'white',
    },
    backgroundColor: '#cbd6e2',
    '&:hover': {
      backgroundColor: '#4b2367',
    },
  },
  styledCancelButton: {
    marginLeft: 35,
  },
}))
