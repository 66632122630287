// BaseModel
import baseModel from 'redux/baseModel'

export default {
  ...baseModel,
  customLoading: (state, table) => {
    return {
      ...state,
      [table]: {
        ...state[table],
        loading: true,
      },
      errors: [],
    }
  },

  saveData: (state, payload, table) => {
    return {
      ...state,
      [table]: {
        ...payload,
        page: payload.page,
        loading: false,
      },
    }
  },

  updateLtlSelectedRate: (state, refId, newSelectedRate) => {
    const index = state.ltl.data.findIndex(row => row.refId === refId)
    const newRates = state.ltl.data.slice()
    newRates[index].selectedRate = newSelectedRate
    return {
      ...state,
      ltl: {
        ...state.ltl,
        data: newRates,
        loading: false,
      },
    }
  },

  updateTlStatus: (state, refId, newStatus) => {
    const index = state.tl.data.findIndex(row => row.refId === refId)
    const newRates = state.tl.data.slice()
    newRates[index].status = newStatus
    return {
      ...state,
      tl: {
        ...state.tl,
        data: newRates,
        loading: false,
      },
    }
  },

  updateTlRjectError: (state, refId) => {
    return {
      ...state,
      tl: {
        ...state.tl,
        loading: false,
      },
    }
  },

  updateRates: (state, ratesList, customerPrice, carrierPrice, selectedRate) => {
    const { refId, successCarriers } = ratesList
    const index = state.ltl.data.findIndex(row => row.refId === refId)
    const newRates = state.ltl.data.slice()
    const requote = {
      loading: false,
      success: null,
    }
    newRates[index] = { ...newRates[index], requote }
    newRates[index].selectedRate = selectedRate
    newRates[index].customerPrice = customerPrice
    newRates[index].carrierPrice = carrierPrice
    newRates[index].rates = [...successCarriers]

    return {
      ...state,
      ltl: {
        ...state.ltl,
        data: newRates,
      },
    }
  },

  updateTlQuoteByRefId: (state, refId, newValues) => {
    const newData = [...state.tl.data]
    const indexToModify = newData.findIndex((q) => q.refId === refId)
    newData[indexToModify] = { ...newData[indexToModify], ...newValues }
    return {
      ...state,
      tl: {
        ...state.tl,
        data: newData,
      },
    }
  },

  rowLoading: (state, quoteId) => {
    const index = state.ltl.data.findIndex(row => row._id === quoteId)
    const newRequoteState = state.ltl.data.slice()
    const requote = {
      loading: true,
      success: null,
    }
    newRequoteState[index] = { ...newRequoteState[index], requote }

    return {
      ...state,
      ltl: {
        ...state.ltl,
        data: newRequoteState,
      },
    }
  },

  requoteError: (state, quoteId) => {
    const index = state.ltl.data.findIndex(row => row._id === quoteId)
    const newRequoteState = state.ltl.data.slice()
    const requote = {
      loading: false,
      success: null,
    }
    newRequoteState[index] = { ...newRequoteState[index], requote }

    return {
      ...state,
      ltl: {
        ...state.ltl,
        data: newRequoteState,
      },
    }
  },

  customError: (state, data = {}) => {
    return {
      ...state,
      errors: [...state.errors, data.error],
      ltl: {
        loading: false,
      },
      tl: {
        loading: false,
      },
      all: {
        loading: false,
      },
    }
  },
}
