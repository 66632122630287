import React from 'react'
import Paper from '@material-ui/core/Paper'

function ContainerTable ({ children }) {
  return (
    <Paper elevation={0}>
      {children}
    </Paper>
  )
}

export default ContainerTable
