//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.USER_LIST.FETCH.REQUEST:
      return model.loading(state)
    case TYPES.USER_LIST.FETCH.SUCCEEDED:
      return model.setUserList(state, action.payload)
    case TYPES.USER_LIST.FETCH.FAILED:
      return model.error(state, action.payload)
    case TYPES.USER_LIST.UPDATE.STATUS:
      return model.updateStatus(state, action.payload)
    case TYPES.USER_LIST.CLEAN.ERRORS:
      return model.clean(state)
    case TYPES.USER_LIST.CLEAN.STATE:
      return model.clean(initialState)
    case TYPES.USER_LIST.DELETE:
      return model.deleteUserList(state, action.payload)
    default:
      return state
  }
}
