import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles((theme) => ({
  activeIcon: {
    width: '50px',
    height: '50px',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '-2px',
    top: '6px',
  },
  icon: {
    width: '60px',
    height: '60px',
    color: theme.palette.common.second6,
  },
  completeIcon: {
    width: '60px',
    height: '60px',
    color: (props) => props.delivered ? theme.palette.common.orange : theme.palette.primary.main,
  },
}))

export const Circle = styled.div`
  width: 46px;
  height: 46px;
  border: 2px solid #4B2367;
  border-radius: 50%;
  margin-top: 8px;
`

export const IconContainer = styled.div`
  position: relative;
  z-index: 10;
`
