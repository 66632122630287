import TYPES from './types'

export const AccountingListRequest = (companyId, payload = {}, callback = () => {}) => ({
  type: TYPES.ACCOUNTING_LIST.FETCH.REQUEST,
  companyId,
  payload,
  callback,
})

export const AccountingListSucceeded = payload => ({
  type: TYPES.ACCOUNTING_LIST.FETCH.SUCCEEDED,
  payload,
})

export const AccountingListFailed = payload => ({
  type: TYPES.ACCOUNTING_LIST.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.ACCOUNTING_LIST.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.ACCOUNTING_LIST.CLEAN.STATE,
})
