// BaseModel
import baseModel from 'redux/baseModel'

export default {
  ...baseModel,
  updateStatus: (state, payload) => {
    const { id, value } = payload
    const newData = [...state.data]
    const index = newData.findIndex(row => row._id === id)
    newData[index].status = value
    return {
      ...state,
      data: newData,
    }
  },

  setUserList: (state, data = {}) => {
    const filteredData = data.data.filter(user => user.email !== 'mldatasync@email.com')
    return {
      ...state,
      ...data,
      data: filteredData,
      loading: false,
      errors: [],
    }
  },

  deleteUserList: (state, id) => {
    const data = state.data
    const filteredData = data.filter(item => item._id !== id)  
    return {
      ...state,
      data: filteredData,
      loading: false,
      errors: [],
    }
  }
}
