//  libraries
import React, { useEffect, useState } from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from 'react-select'
import PropTypes from 'prop-types'

//  hooks
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { locationButtonsUpdate } from 'redux/ducks/locationButtons'
import { locationGetRequest } from 'redux/ducks/locations'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { useStyles, customStyles } from './styles'

function LocationSelector ({ location, value }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, errors, setValue, unregister, clearErrors, control } = useFormContext()
  const companyId = useSelector(state => state.ui.companySelector.companyChild.value)
  const disabledForm = useSelector(state => state.newForm.disabled)
  const { data: optionsLocations, loading } = useSelector(state => state.entities.locations)
  const [selected, setSelected] = useState({ label: value, value })
  const [inputValue, setInputValue] = useState(value)
  const locationChange = useWatch({
    control,
    name: `${location}.contact.company`,
  })

  useEffect(() => {
    register({ name: `${location}.contact.company` }, requiredValidator)
    setValue(`${location}.contact.company`, value)
    return () => {
      unregister(`${location}.contact.company`)
    }
    //  eslint-disable-next-line
  }, [location])

  useEffect(() => {
    if (locationChange === '') {
      setSelected({ value: locationChange, label: locationChange })
      setInputValue('')
    }
  }, [locationChange])

  const handleSearch = (value, { action }) => {
    if (!(action === 'menu-close' || action === 'input-blur' || action === 'set-value')) {
      setSelected({ value, label: value })
      setValue(`${location}.contact.company`, value)
      clearErrors([`${location}.contact.company`])
      setInputValue(value)
      const params = { companyName: value, companyId }
      dispatch(locationGetRequest({ params }))
    }
  }

  const handleSelect = (data) => {
    if (data) {
      const { _id, value, city, country, postalCode, state, contactInfo } = data
      const { addressLines = [], companyName, contactName, email, phoneNumber } = contactInfo
      setInputValue(value)
      setSelected(data)

      // Add provided values by backend to react-hook-form's elements
      if (!disabledForm) {
        setValue(`${location}.zip`, postalCode)
        setValue(`${location}.country`, country)
        setValue(`${location}.state`, state)
        setValue(`${location}.city`, city)
      }
      setValue(`${location}.contact.company`, companyName)
      setValue(`${location}.contact.address`, addressLines[0] || '')
      setValue(`${location}.contact.address2`, addressLines[1] || '')
      setValue(`${location}.contact.name`, contactName)
      setValue(`${location}.contact.phone`, phoneNumber)
      setValue(`${location}.contact.mail`, email)

      clearErrors([
        `${location}.zip`,
        `${location}.country`,
        `${location}.state`,
        `${location}.city`,
        `${location}.contact.company`,
        `${location}.contact.address`,
        `${location}.contact.address2`,
        `${location}.contact.name`,
        `${location}.contact.phone`,
        `${location}.contact.mail`,
      ])

      // If select an option from react-select then show update and delete buttons actions
      dispatch(locationButtonsUpdate({
        [location]: {
          idLocation: _id,
          showUpdate: true,
          showDelete: true,
        },
      }))
    }
  }

  const formatOptionLabel = ({ value, label }, { context }) => {
    if (context === 'value') {
      return <div>{value}</div>
    } else if (context === 'menu') {
      return <div>{label}</div>
    }
  }

  return (
    <>
      <Select
        name={`${location}input2`}
        inputId={`input-search-${location}-2`}
        classNamePrefix='select'
        placeholder=''
        formatOptionLabel={formatOptionLabel}
        value={selected}
        inputValue={inputValue}
        onInputChange={handleSearch}
        isLoading={loading}
        options={optionsLocations}
        error={errors[location] && !!errors[location].contact?.company}
        styles={customStyles}
        onChange={handleSelect}
        autoFocus
      />
      <FormHelperText className={classes.error} error={errors[location] && !!errors[location].contact?.company}>
        {errors[location] && errors[location].contact?.company?.message}
      </FormHelperText>
    </>
  )
}

LocationSelector.propTypes = {
  location: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default LocationSelector
