//  libraries
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

//  hooks
import { useSelector } from 'react-redux'
import { useFormContext, Controller } from 'react-hook-form'

//  styles
import { useStyles, Title, Item } from './styles'

export default function Checkboxes ({ isNew, hazmat, hazmatValue }) {
  const classes = useStyles()
  const { control, register, watch } = useFormContext()
  const disabled = useSelector(state => state.newForm.disabled)
  const show = watch('products[0].hazmat', hazmat)

  return (
    <>
      <Box display='flex' justifyContent='space-around'>
        <Item>
          <Title>
            <Typography variant='h4' className={classes.colorText} align='center' component='label'>
              Used
            </Typography>
          </Title>
          <Controller
            render={props => (
              <Tooltip title='The product is not new'>
                <Checkbox
                  onChange={e => props.onChange(e.target.checked)}
                  checked={props.value}
                  color='default'
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checked,
                  }}
                  disabled={disabled}
                />
              </Tooltip>
            )}
            defaultValue={!!isNew}
            name='products[0].isNew'
            control={control}
          />
        </Item>
        <Item>
          <Title>
            <Typography variant='h4' className={classes.colorText} align='center' component='label'>
              Hazmat
            </Typography>
          </Title>
          <Controller
            render={props => (
              <Checkbox
                onChange={e => props.onChange(e.target.checked)}
                checked={props.value}
                color='default'
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
                disabled={disabled}
              />
            )}
            defaultValue={!!hazmat}
            name='products[0].hazmat'
            control={control}
          />
        </Item>
      </Box>
      {
        show && (
          <Item width='200px'>
            <Title>
              <Typography variant='h4' className={classes.colorText} align='center' component='label'>
                UN #
              </Typography>
            </Title>
            <TextField
              name='products[0].hazmatValue'
              variant='outlined'
              inputRef={register}
              defaultValue={hazmatValue}
              disabled={disabled}
            />
          </Item>
        )
      }
    </>
  )
}

Checkboxes.propTypes = {
  isNew: PropTypes.bool.isRequired,
  hazmat: PropTypes.bool.isRequired,
  hazmatValue: PropTypes.string,
}
