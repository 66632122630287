//  libraries
import React, { useEffect } from 'react'
import { TimePicker, DatePicker } from '@material-ui/pickers'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useParams } from "react-router-dom";

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useFormContext, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

//  styles
import { calendarIcon, hourIcon } from 'assets'
import { useStyles, Item, Title } from './styles'

import { stepperUpdate } from 'redux/ducks/stepper'

const message = 'Your shipment is requested to pick up in less than 24 hours. A Tucker team member will escalate your quote'

export default function SelectDateTime ({ name, tbd = false }) {
  const classes = useStyles()
  const { addToast, removeToast } = useToasts()
  const { errors, control, watch } = useFormContext()
  const dispatch = useDispatch()
  const data = useSelector(state => state.newForm.shipping ? state.newForm.shipping[name] : {})
  const isEditAction = useParams().action === 'edit'
  const alertDate = watch(`shipping.${name}.startDate`)
  const handleChange = () => {
    isEditAction && dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  useEffect(() => {
    if (alertDate < moment().add(1, 'days')) {
      addToast(message, {
        appearance: 'warning',
        autoDismiss: false,
        id: 'warning-toast-startDate',
      })
    } else {
      removeToast('warning-toast-startDate')
    }
    //  eslint-disable-next-line
  }, [alertDate])

  return (
    <>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={calendarIcon} width='22px' />
          <Typography variant='h5' className={classes.colorText} component='label' align='center'> Pickup Date </Typography>
        </Title>
        <Controller
          as={
            <DatePicker
              minDate={moment()}
              format='MM-DD-YYYY'
              inputVariant='outlined'
              className={classes.datePicker}
              DialogProps={{
                className: classes.modal,
              }}
              onAccept={handleChange}
              disabled = {false}
            />
          }
          defaultValue={data?.startDate ? moment(data?.startDate) : moment().add(2, 'days')}
          name={`shipping.${name}.startDate`}
          control={control}
        />
        <FormHelperText error={errors.shipping && errors.shipping[name] && !!errors.shipping[name].startDate}>
          {errors.shipping && errors.shipping[name] && errors.shipping[name].startDate?.message}
        </FormHelperText>
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={hourIcon} width='22px' />
          <Typography variant='h5' className={classes.colorText} component='label' align='center'> Time </Typography>
        </Title>
        <Controller
          as={
            <TimePicker
              mask='__:__ _M'
              inputVariant='outlined'
              className={classes.datePicker}
              onAccept={handleChange}
              disabled = {false}
            />
          }
          defaultValue={data?.startTime ? moment(data?.startTime, 'HH:mm').format() : moment('07:00', "HH:mm").format()}
          name={`shipping.${name}.startTime`}
          control={control}
        />
        <FormHelperText error={errors.shipping && errors.shipping[name] && !!errors.shipping[name].startTime}>
          {errors.shipping && errors.shipping[name] && errors.shipping[name].startTime?.message}
        </FormHelperText>
      </Item>
    </>
  )
}

SelectDateTime.propTypes = {
  name: PropTypes.string.isRequired,
  tbd: PropTypes.bool,
}
