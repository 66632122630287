// libraries
import React from 'react'
import { Button } from '@material-ui/core'
import { useHistory, useParams, useLocation } from 'react-router-dom'
// styles
import { useStyles } from './styles'

function TypeFilter () {
  const classes = useStyles()
  const history = useHistory()
  const type = useParams().type
  const location = useLocation()
  const module = location.pathname.split('/')[1]
  return (
    <>
      {/* {module !== 'shipment' && (
        <Button
          className={type === 'all' ? classes.selected : classes.noSelected}
          onClick={() => history.push('/quote/list/all')}
          disableElevation
        >
          ALL
        </Button>
      )} */}
      {/* <Button
        className={type === 'ltl' ? classes.selected : classes.noSelected}
        onClick={() => history.push(`/${module}/list/ltl`)}
        disableElevation
      >
        LTL
      </Button> */}
      <Button
        className={type === 'tl' ? classes.selected : classes.noSelected}
        onClick={() => history.push(`/${module}/list/tl`)}
        disableElevation
      >
        TL
      </Button>

    </>
  )
}

export default TypeFilter
