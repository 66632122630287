import { makeStyles, withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'

export const useStyles = makeStyles(theme => ({
  colorText: {
    color: theme.palette.common.second1,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
    margin: '0 auto 10px auto',
  },
  error: {
    fontSize: '15px',
  },
}))

export const Title = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: center;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  min-height: 105px;
`
export const Range = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    min-height: auto;
  }
`
export const RangeText = styled.div`
  margin: 0.5rem 1.5rem 0;
`

export const CustomTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.common.orange,
  },
  tooltip: {
    background: theme.palette.common.orange,
    padding: '0.8rem',
    fontSize: '0.9rem',
    fontWeight: 400,
  },
}))(Tooltip)
