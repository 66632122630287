import TYPES from './types'

export const CompanyListRequest = (payload, callback = () => {}) => ({
  type: TYPES.COMPANY_LIST.FETCH.REQUEST,
  payload,
  callback,
})

export const CompanyListSucceeded = payload => ({
  type: TYPES.COMPANY_LIST.FETCH.SUCCEEDED,
  payload,
})

export const CompanyListFailed = payload => ({
  type: TYPES.COMPANY_LIST.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.COMPANY_LIST.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.COMPANY_LIST.CLEAN.STATE,
})
