import TYPES from './types'

export const QuoteDetailsRequest = (payload, callback = () => { }) => ({
  type: TYPES.QUOTE_DETAILS.FETCH.REQUEST,
  payload,
  callback,
})

export const QuoteDetailsSucceeded = payload => ({
  type: TYPES.QUOTE_DETAILS.FETCH.SUCCEEDED,
  payload,
})

export const QuoteDetailsFailed = payload => ({
  type: TYPES.QUOTE_DETAILS.FETCH.FAILED,
  payload,
})

/**
 * NEW MESSAGE
 */

export const QuoteDetailsSetMessageRequest = (payload, quoteId, callback, errorCallback) => ({
  type: TYPES.QUOTE_DETAILS.SET_MESSAGE.FETCH.REQUEST,
  payload,
  quoteId,
  callback,
  errorCallback,
})

export const QuoteDetailsSetMessageSucceeded = (payload, commentType) => ({
  type: TYPES.QUOTE_DETAILS.SET_MESSAGE.FETCH.SUCCEEDED,
  payload,
  commentType,
})

export const QuoteDetailsSetMessageFailed = payload => ({
  type: TYPES.QUOTE_DETAILS.SET_MESSAGE.FETCH.FAILED,
  payload,
})

/**
 * UPDATE MARKET RATES
 */

export const QuoteDetailsMarketRatesRequest = (payload, callBack, errorCallback) => ({
  type: TYPES.QUOTE_DETAILS.UPDATE_RATES.FETCH.REQUEST,
  payload,
  callBack,
  errorCallback,
})

export const QuoteDetailsMarketRatesSucceeded = (payload) => ({
  type: TYPES.QUOTE_DETAILS.UPDATE_RATES.FETCH.SUCCEEDED,
  payload,
})

export const QuoteDetailsMarketRatesFailed = payload => ({
  type: TYPES.QUOTE_DETAILS.UPDATE_RATES.FETCH.FAILED,
  payload,
})

/**
 * DECLINE QUOTE
 */

export const QuoteDetailsDeclineQuoteRequest = (payload, callback, errorCallback) => ({
  type: TYPES.QUOTE_DETAILS.DECLINE.FETCH.REQUEST,
  payload,
  callback,
  errorCallback,
})

export const QuoteDetailsDeclineQuoteSucceeded = (payload, commentType) => ({
  type: TYPES.QUOTE_DETAILS.DECLINE.FETCH.SUCCEEDED,
  payload,
  commentType,
})

export const QuoteDetailsDeclineQuoteFailed = payload => ({
  type: TYPES.QUOTE_DETAILS.DECLINE.FETCH.FAILED,
  payload,
})

/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.QUOTE_DETAILS.CLEAN.ERRORS,
})

export const cleanState = () => ({
  type: TYPES.QUOTE_DETAILS.CLEAN.STATE,
})
