import { FETCH } from 'API/fetch'
import { adminRoutes } from 'API/routes/admin'
import bcrypt from 'bcryptjs'

export class Admin {
  async usersByCompany (data) {
    const { companyId, ...payload } = data
    const response = await FETCH('get', adminRoutes.usersByCompany.replace(':companyId', companyId), payload)
    return response
  }

  async createUser (data) {
    const response = await FETCH('post', adminRoutes.createUser, data, null, false, true)
    return response
  }

  async setTimeout (data) {
    const response = await FETCH('post', adminRoutes.setTimeout, data)
    return response
  }

  async getTimeout () {
    const response = await FETCH('get', adminRoutes.getTimeout)
    return response
  }

  async editUser (data) {
    const userId = data.get('userId')
    data.delete('userId')
    if (data.get('password')) {
      const salt = await bcrypt.genSalt(10)
      const password = await bcrypt.hash(data.get('password'), salt)
      data.set('password', password)
      data.set('confirm', password)
    }
    const response = await FETCH('put', adminRoutes.updateUser.replace(':id', userId), data)
    return response
  }

  async companyById (data) {
    const response = await FETCH('get', adminRoutes.companyById.replace(':id', data))
    return response
  }

  async companies (data) {
    const response = await FETCH('get', adminRoutes.companies, data)
    return response
  }

  async createCompanies (data) {
    const response = await FETCH('post', adminRoutes.companies, data)
    return response
  }

  async editCompany (data) {
    const companyId = data.get('companyId')
    data.delete('companyId')
    const response = await FETCH('put', adminRoutes.companyById.replace(':id', companyId), data)
    return response
  }

  async Accounts () {
    const response = await FETCH('get', adminRoutes.accounts)
    return response
  }

  async mcLeodCompanies (data) {
    let response
    const { code, action } = data
    if (action === 'edit') {
      response = await FETCH('get', adminRoutes.mcLeodCompaniesUpdate.replace(':code', code))
    } else {
      response = await FETCH('get', adminRoutes.mcLeodCompanies.replace(':code', code))
    }
    return response
  }

  async tuckerUsers () {
    const response = await FETCH('get', adminRoutes.tuckerUsers)
    return response
  }

  async getKillSwitch () {
    const response = await FETCH('get', adminRoutes.killSwitch)
    return response
  }

  async updateKillSwitch () {
    const response = await FETCH('put', adminRoutes.killSwitch)
    return response
  }
}
