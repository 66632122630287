import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '603px',
    height: '90%',
    maxHeight: 330,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  textInputs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginTop: '10px',
    width: '350px',
    background: theme.palette.background.paper,
    borderRadius: '6px',
    '& .MuiInputLabel-filled': {
      marginTop: '-8px',
    },
  },
  inputBase: {
    height: '45px',
    background: `${theme.palette.background.paper} !important`,
    color: theme.palette.common.second2,
    '& .MuiFilledInput-input': {
      padding: '15px 0px 10px 30px',
    },
  },
  submitButton: {
    display: 'block',
    margin: '0 auto',
    textTransform: 'none',
    width: '350px',
    height: '45px',
    fontWeight: '400',
    fontSize: '16px',
    color: 'white',
    background: '#D57800',
    '&:hover': {
      background: '#CC5500',
    },
  },
  message: {
    marginTop: '25px',
    color: theme.palette.common.second7,
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  messageText: {
    color: theme.palette.common.second7,
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  errorForm: {
    fontSize: 16,
    width: '60%',
  },
}))
