//  libraries
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

// hooks
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

// redux
import { fetchPost } from 'redux/ducks/postData'
import { modalUpdate, modalCreatingShipment } from 'redux/ducks/modal'
import { newFormLoadFromQuoteTl } from 'redux/ducks/newForm'
import { companySelectorUpdate } from 'redux/ducks/companySelector'
import { QuoteDetailsDeclineQuoteRequest } from 'redux/ducks/quoteDetails'

//  styles
import { useStyles, Root } from './styles'

//  components
import RejectSurvey from 'components/quote/common/rejectSurvey'

export default function Buttons ({ disabled, onSubmit }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast, removeAllToasts } = useToasts()
  const [quoteInfo = {}] = useSelector(state => state.entities.quoteDetails.data) || []
  const { refId = '...', comments = {}, status = '' } = quoteInfo
  const isActive = ['Active', 'Scheduled'].includes(status)
  const [openSurvey, setOpenSurvey] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    return () => { removeAllToasts() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReSend = () => {
    dispatch(fetchPost('quoteService.reSendTl', refId, null, () => {
      dispatch(modalUpdate(true))
    }))
  }

  const handleReturn = () => {
    if (comments.external?.length > 0) {
      dispatch(fetchPost('quoteService.returnTl', refId, null, () => {
        dispatch(modalUpdate(true))
      }))
    } else {
      addToast('Please add an external comment, visible to the customer, in order to return this quote.', {
        appearance: 'warning',
        autoDismiss: true,
        id: 'warning-toast-mcleod',
      })
    }
  }

  const handleConvert = () => {
    /**
     * Validation. Maybe it is not necessary because
     * it only happens when status is active. Implement it
     * if you need to convert to shipment in any status
     */
    // const isValid = await trigger() // remember to import useFormContext()
    // if (!isValid) {
    //   onSubmit()
    //   return
    // }
    const { companyParent, companyChild, ...data } = quoteInfo
    dispatch(modalCreatingShipment(true))
    dispatch(newFormLoadFromQuoteTl(data))
    dispatch(companySelectorUpdate({ companyParent, companyChild: companyChild || companyParent }))
    history.push('/shipment/convert/tl')
  }

  const handleDecline = (refId, surveyData) => {
    setShowError(false)
    const payload = {
      quoteId: refId,
      status: 'Rejected',
      survey: surveyData,
    }
    const successCallback = () => {
      setShowError(false)
      setOpenSurvey(false)
      addToast('Successful rejection.', {
        appearance: 'success',
        autoDismiss: true,
        id: 'success-toast-sales-reject',
      })
    }
    const errorCallback = () => {
      setShowError(true)
      setOpenSurvey(false)
      addToast('Rejection service not available', {
        appearance: 'error',
        autoDismiss: true,
        id: 'error-toast-sales-reject',
      })
    }
    dispatch(QuoteDetailsDeclineQuoteRequest(payload, successCallback, errorCallback))
  }

  return (
    <Root>
      {isActive && (
        <>
          <Box display='flex' flexDirection='column'>
            <Button
              className={classNames(classes.button)}
              variant='contained'
              onClick={() => setOpenSurvey(true)}
            >
              Decline
            </Button>
            {showError && (
              <Typography component='div' className={classes.errorText}>
                Update not executed. Please try later.
              </Typography>
            )}
          </Box>
          <Button
            className={classNames(classes.button, classes.convertButton)}
            variant='contained'
            onClick={handleConvert}
          >
            Convert to Shipment
          </Button>
          <RejectSurvey
            onClose={() => setOpenSurvey(false)}
            open={openSurvey}
            onDeclineConfirmation={handleDecline}
            refId={refId}
          />
        </>
      )}
      {disabled ? (
        <Button
          className={classes.button}
          variant='contained'
          onClick={handleReSend}
        >
          Resend
        </Button>
      )
        : (
          <>
            {status.toLowerCase() !== 'rejected' && (
              <Button
                className={classNames(classes.button, classes.backButton)}
                variant='contained'
                onClick={handleReturn}
              >
                Return to Customer
              </Button>
            )}
            <Button
              className={classes.button}
              variant='contained'
              type='submit'
            >
              Send
            </Button>
          </>
        )}
    </Root>
  )
}

Buttons.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
}
