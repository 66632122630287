import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  activeIcon: {
    width: '54px',
    height: '54px',
    color: '#4B2367',
    position: 'absolute',
    right: '-2px',
    top: '18px',
  },
  icon: {
    width: '60px',
    height: '60px',
    color: '#CBD6E2',
  },
  completeIcon: {
    width: '60px',
    height: '60px',
    color: '#4B2367',
  },
}))

export const Circle = styled.div`
  width: 46px;
  height: 46px;
  border: 2px solid #4B2367;
  border-radius: 50%;
  margin: 20px 0;
`

export const IconContainer = styled.div`
  position: relative;
`

export const IconText = styled.div`
  position: absolute;
  left: 23px;
  top: 17px;
  color: #FFFFFF;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

export const IconText2 = styled(IconText)`
  left: 18px;
  top: 32px;
`
