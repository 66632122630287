//  Libraries
import React from 'react'

//  components
import MessageModal from 'components/common/messageModal'
import FormForgotPassword from 'components/auth/formForgotPassword'

const ForgotPassword = () => {
  return (
    <>
      <FormForgotPassword />
      <MessageModal
        title='SUCCESS!'
        body='Password reset email sent.' // TMCP-766
        path='session.errors'
      />
    </>
  )
}

export default ForgotPassword
