//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import Select from 'react-select'

import { Element, customStyles, useStyles } from './styles.js'

import { updateKillSwitch, getKillSwitch } from 'redux/ducks/user'

import API from 'API'

//  mocks
import idleList from 'mocks/idle.json'

import { Button, Switch, FormControlLabel, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core'

//  hooks,
// import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//  redux
import { fetchPost } from 'redux/ducks/postData'
import styled from 'styled-components'

export default function Settings () {
  const dispatch = useDispatch()

  const killSwitch = useSelector(state => state.session.data.killSwitch)

  const userRole = useSelector(state => state.session.data.role)

  const [killSwitchFlag, setKillSwitchFlag] = useState(false)
  const [killSwitchOpen, setKillSwitchOpen] = useState(false)
  const [killSwitchWord, setKillSwitchWord] = useState(false)

  const [killSwitchValue, setKillSwitchValue] = useState()

  const classes = useStyles()

  const [idles] = useState(idleList)
  const [idle, setIdle] = useState(10)

  const validateUserRole = () => {
    if (userRole !== 'tucker') { return }
    setKillSwitchFlag(true)
  }

  const killswitchModalOpen = () => {
    setKillSwitchWord(false)
    setKillSwitchOpen(true)
  }

  const validateWord = (e) => {
    if (e.target.value !== 'enable' && e.target.value !== 'disable') { return }
    setKillSwitchWord(true)
  }

  const killSwitchConfirmation = () => {
    if (!killSwitchWord) { return }
    killswitchModalClose()
    dispatch(updateKillSwitch())
  }

  const killswitchModalClose = () => {
    setKillSwitchOpen(false)
    setKillSwitchWord(false)
  }

  const workKillSwitchValue = () => {
    if (killSwitch) {
      setKillSwitchValue('on')
    } else {
      setKillSwitchValue('off')
    }
  }

  // Get killswitch value trigger
  useEffect(() => {
    dispatch(getKillSwitch())
  }, [])

  // validate role to shor or hide killswitch dialog
  useEffect(() => {
    validateUserRole()
  }, [userRole])

  useEffect(() => {
    workKillSwitchValue()
  }, [killSwitch])

  useEffect(() => {
    API.adminService.getTimeout()
      .then((res) => {
        const idle = res.data.timeout ? res.data.timeout : 10
        const obj = idleList.find(item => item.value === idle)
        setIdle(obj)
      })
  }, [])

  const handleChangeIdle = (data) => {
    setIdle(data)
  }

  const onApply = () => {
    const timeout = idle.value

    dispatch(fetchPost('adminService.setTimeout', {
      timeout: timeout,
    }, null, () => console.log('onApply')))
  }

  const KillswitchToggle = styled((props) => (
    <Switch color='primary' checked={killSwitch} />
  ))(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      padding: 0,
      transitionDuration: '300ms',
      '& .Mui-checked': {
        transform: 'translateX(16px)',
        color: '#4B2367',
        '& .MuiSwitch-track': {
          backgroundColor: '#4B2367',
          opacity: 0.5,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#4B2367',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: 'red',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      backgroundColor: '"000',
      opacity: 0.38,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }))
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Typography variant='h1' className={classes.h1}>Settings</Typography>
      </div>
      <div className={classes.data}>
        <FormControlLabel className={classes.switcher} labelPlacement='start' label='Disable User Login' onClick={killswitchModalOpen} control={<KillswitchToggle />} />
        {killSwitchFlag
          ? !killSwitch
            ? <Dialog open={killSwitchOpen} onClose={killswitchModalClose}>
              <DialogTitle className={classes.dialogTitle}>Confirm disable user login</DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogContent}>
                  This will disable access for all non-Tucker user accounts. To confirm please type in the following word: 'disable'
                </DialogContentText>
                <TextField
                  autoFocus
                  margin='dense'
                  id='word'
                  label='Confirmation word'
                  type='text'
                  fullWidth
                  variant='standard'
                  onChange={validateWord}
                />
              </DialogContent>
              <DialogActions>
                <Button className={classes.dialogCancel} onClick={killswitchModalClose}>Cancel</Button>
                <Button className={classes.dialogConfirm} onClick={killSwitchConfirmation}>Confirm</Button>
              </DialogActions>
              </Dialog>
            : <Dialog open={killSwitchOpen} onClose={killswitchModalClose}>
              <DialogTitle className={classes.dialogTitle}>Confirm enable user login</DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogContent}>
                  This will enable access for all non-Tucker user accounts. To confirm please type in the following word: 'enable'
                </DialogContentText>
                <TextField
                  autoFocus
                  margin='dense'
                  id='word'
                  label='Confirmation word'
                  type='text'
                  fullWidth
                  variant='standard'
                  onChange={validateWord}
                />
              </DialogContent>
              <DialogActions>
                <Button className={classes.dialogCancel} onClick={killswitchModalClose}>Cancel</Button>
                <Button className={classes.dialogConfirm} onClick={killSwitchConfirmation}>Confirm</Button>
              </DialogActions>
              </Dialog>
          : <></>}
      </div>
      <div className={classes.timeout}>
        <Element>
          <Typography className={classes.title} component='label'>
            Session Timeout
          </Typography>
          <Select
            name='idle'
            value={idle}
            isSearchable
            onChange={handleChangeIdle}
            options={idles}
            styles={customStyles}
            classNamePrefix='select'
          />
        </Element>
        <Button
          className={classes.button}
          variant='contained'
          onClick={onApply}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}
