//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { createSelector } from 'reselect'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector } from 'react-redux'

// tools
import { dateFormat } from 'tools'

//  styles
import { agendaIcon } from 'assets'
import { useStyles, HeadingContainer, Content, Row, Block } from './styles'

export default function Info ({ name }) {
  const user = createSelector(
    state => ({
      shippingInfo: state.entities.shipmentDetails.data.shipping[name === 'shipper' ? 'pickup' : 'delivery'],
      ...state.entities.shipmentDetails.data[name]
    }),
    info => ({ ...info }),
  )
  const {
    shippingInfo = {},
    companyName,
    address,
    address2,
    contactName,
    city,
    state,
    country,
    zipCode,
    phone,
    specialInstructions,
    scheduleArriveEarly = shippingInfo?.startDate,
    scheduleArriveLate = shippingInfo?.endDate,
    actualArrival,
    actualDeparture,
  } = useSelector(user || {})
  const classes = useStyles()

  return (
    <div>
      <HeadingContainer>
        <ImageNotDraggable externalImage={agendaIcon} width='40px' />
        <Typography variant='h2' className={classes.heading}>
          {name.charAt(0).toUpperCase() + name.slice(1) + ' Information'}
        </Typography>
      </HeadingContainer>
      <Content>
        <div>
          <Typography variant='h4' className={classes.title}> Company Name </Typography>
          <Typography variant='h4' className={classes.text}> {companyName || 'Company Name'} </Typography>
        </div>
        <Row>
          <div className='first'>
            <Typography variant='h4' className={classes.title}> Address </Typography>
            <Typography variant='h4' className={classes.text}> {address || '...'} </Typography>
          </div>
          <div>
            <Typography variant='h4' className={classes.title}> Address 2 </Typography>
            <Typography variant='h4' className={classes.text}> {address2 || '...'} </Typography>
          </div>
        </Row>
        <Row>
          <div className='right'>
            <Typography variant='h4' className={classes.title}> City </Typography>
            <Typography variant='h4' className={classes.text}> {city || '...'} </Typography>
          </div>
          <div className='right'>
            <Typography variant='h4' className={classes.title}> State </Typography>
            <Typography variant='h4' className={classes.text}> {state || '...'} </Typography>
          </div>
          <div className='right'>
            <Typography variant='h4' className={classes.title}> Country </Typography>
            <Typography variant='h4' className={classes.text}> {country || '...'} </Typography>
          </div>
          <div>
            <Typography variant='h4' className={classes.title}> Zip Code </Typography>
            <Typography variant='h4' className={classes.text}> {zipCode || '...'} </Typography>
          </div>
        </Row>
        <div>
          <Typography variant='h4' className={classes.title}> Contact name </Typography>
          <Typography variant='h4' className={classes.text}> {contactName || '...'} </Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.title}> Phone </Typography>
          <Typography variant='h4' className={classes.text}> {phone || '...'} </Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.title}> Comments / Special Instructions </Typography>
          <Typography variant='h4' className={classes.text}> {specialInstructions || '...'} </Typography>
        </div>
        <Block>
          <Box width='100%'>
            <Typography variant='h4' className={classes.title}> Scheduled Arriving Date </Typography>
            <Typography variant='h4' className={classes.text} align='center'> {scheduleArriveEarly ? dateFormat(scheduleArriveEarly, 'MM/DD/YY') : '...'} </Typography>
            <Typography variant='h4' className={classes.text} align='center'> {scheduleArriveEarly ? dateFormat(scheduleArriveEarly, 'HH:mm') : '...'} </Typography>
          </Box>
          <Box width='100%'>
            <Typography variant='h4' className={classes.title}> Actual Arriving Date </Typography>
            <Typography variant='h4' className={classes.text} align='center'> {actualArrival ? dateFormat(actualArrival, 'MM/DD/YY') : '...'}</Typography>
            <Typography variant='h4' className={classes.text} align='center'> {actualArrival ? dateFormat(actualArrival, 'HH:mm') : '...'} </Typography>
          </Box>
          <Box width='100%'>
            <Typography variant='h4' className={classes.title}> Scheduled Departure Date </Typography>
            <Typography variant='h4' className={classes.text} align='center'> {scheduleArriveLate ? dateFormat(scheduleArriveLate, 'MM/DD/YY') : '...'} </Typography>
            <Typography variant='h4' className={classes.text} align='center'> {scheduleArriveLate ? dateFormat(scheduleArriveLate, 'HH:mm') : '...'} </Typography>
          </Box>
          <Box width='100%'>
            <Typography variant='h4' className={classes.title}> Actual Departure Date </Typography>
            <Typography variant='h4' className={classes.text} align='center'> {actualDeparture ? dateFormat(actualDeparture, 'MM/DD/YY') : '...'} </Typography>
            <Typography variant='h4' className={classes.text} align='center'> {actualDeparture ? dateFormat(actualDeparture, 'HH:mm') : '...'} </Typography>
          </Box>
        </Block>
      </Content>
    </div>
  )
}
