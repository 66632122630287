import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  CompanyInfoSucceeded,
  CompanyInfoFailed,
} from './actions'
import API from 'API'

function * fetchCompanyInfo (action) {
  try {
    const { data } = yield call(API.adminService.companyById, action.payload)
    yield put(CompanyInfoSucceeded(data))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(CompanyInfoFailed(newError))
  }
}

export default function * rootCompanyInfo () {
  yield takeEvery(TYPES.COMPANY_INFO.FETCH.REQUEST, fetchCompanyInfo)
}
