//  tools
import { loadSession, loadStorage } from './storage'

const validateRoutes = () => {
  let role = ''
  let finalRoute = ''
  const hasSession = !!loadSession()
  const isRemember = !!loadStorage()
  if (hasSession) role = loadSession('role')
  if (isRemember) role = loadStorage('role')

  const isRender = hasSession || isRemember
  switch (role) {
    case 'tucker':
      finalRoute = hasSession || isRemember ? '/dashboard' : '/login'
      break
    case 'logistic':
      finalRoute = hasSession || isRemember ? '/dashboard' : '/login'
      break
    case 'sales':
      finalRoute = hasSession || isRemember ? '/sales/list' : '/login'
      break
    case 'account':
      finalRoute = hasSession || isRemember ? '/dashboard' : '/login'
      break
    case 'shipment':
      finalRoute = hasSession || isRemember ? '/dashboard' : '/login'
      break
    case 'userAdmin':
      finalRoute = hasSession || isRemember ? '/dashboard' : '/login'
      break
    default:
      finalRoute = '/login'
  }

  return { finalRoute, isRender }
}

export default validateRoutes
