//  libraries
import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'

//  components
import SetOfProducts from '../setOfProducts'
import CreateEditProduct from '../createEditProduct'
import TabPanel from 'components/common/tabPanel'

//  hooks
import { useDispatch } from 'react-redux'

//  redux
import { productsRequest } from 'redux/ducks/products'

//  styles
import { useStyles } from './styles'

export default function ProductModal ({
  open,
  handleClick,
  selectedArrayOfProducts,
  setSelectedArrayOfProducts,
  choosenArrayOfProducts,
  setChoosenArrayOfProducts,
  handleChoosenProducts,
}) {
  const dispatch = useDispatch()
  const [value, setValueTab] = useState(0)
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    if (!newValue) {
      dispatch(productsRequest())
    }
    setValueTab(newValue)
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <AppBar position='static' color='default'>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              className={classes.styledTab}
            >
              <Tab className={classes.styledTabItem} label='Item from template' />
              <Tab className={classes.styledTabItem} label='Empty Item' />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <SetOfProducts
              handleChoosenProducts={handleChoosenProducts}
              selectedArrayOfProducts={selectedArrayOfProducts}
              setSelectedArrayOfProducts={setSelectedArrayOfProducts}
              closeModal={handleClick}
              choosenArrayOfProducts={choosenArrayOfProducts}
              setChoosenArrayOfProducts={setChoosenArrayOfProducts}
            />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <CreateEditProduct
              selectedArrayOfProducts={selectedArrayOfProducts}
              setSelectedArrayOfProducts={setSelectedArrayOfProducts}
              closeModal={handleClick}
              choosenArrayOfProducts={choosenArrayOfProducts}
              setChoosenArrayOfProducts={setChoosenArrayOfProducts}
            />
          </TabPanel>
        </div>
      </Fade>
    </Modal>
  )
}

ProductModal.propTypes = {
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  setSelectedArrayOfProducts: PropTypes.func,
  selectedArrayOfProducts: PropTypes.array,
  choosenArrayOfProducts: PropTypes.array,
  setChoosenArrayOfProducts: PropTypes.func,
}
