import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  loginSucceeded,
  loginFailed,
  forgotPasswordSucceeded,
  forgotPasswordFailed,
  resetPasswordSucceeded,
  resetPasswordFailed,
  activeSucceeded,
  activeFailed,
  companiesSucceeded,
  companiesFailed,
  afterFirstLoginSucceeded,
  afterFirstLoginFailed,
  resendActivationEmailSucceeded,
  resendActivationEmailFailed,
  getKillSwitchSucceded,
  getKillSwitchFailed,
  updateKillSwitchSucceded,
  updateKillSwitchFailed,
} from './actions'
import API from 'API'

function * fetchLogin (action) {
  try {
    const { data } = yield call(API.authService.login, action.payload)
    yield put(loginSucceeded(data))
    action.callback(data.data.need2FA)
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(loginFailed(newError))
  }
}

function * fetchAfterFirstLogin (action) {
  try {
    const { data } = yield call(API.authService.isFirstLogin, action.payload)
    yield put(afterFirstLoginSucceeded({ isFirstLogin: data.data.isFirstLogin }))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(afterFirstLoginFailed(newError))
  }
}

function * fetchForgotPassword (action) {
  try {
    const { data } = yield call(API.authService.forgotPassword, action.payload)
    yield put(forgotPasswordSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(forgotPasswordFailed(newError))
  }
}

function * fetchResetPassword (action) {
  try {
    const { data } = yield call(API.authService.resetPassword, action.payload)
    yield put(resetPasswordSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(resetPasswordFailed(newError))
  }
}

function * fetchActivate (action) {
  try {
    const { data } = yield call(API.authService.activateAccount, action.payload)
    yield put(activeSucceeded(data))
    action.callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(activeFailed(newError))
  }
}

function * fetchResendActivationEmail (action) {
  try {
    const { data } = yield call(API.authService.resendActivationEmail, action.payload)
    yield put(resendActivationEmailSucceeded(data))
    action.callback(data.success ? 'success' : 'warning', data.error || data.msg)
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(resendActivationEmailFailed(newError))
    action.callback('error')
  }
}

function * fetchCompanies () {
  try {
    const { data: companies } = yield call(API.authService.companiesByUser)
    yield put(companiesSucceeded(companies))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(companiesFailed(newError))
  }
}

function * fetchKillSwitch () {
  try {
    const data = yield call(API.adminService.getKillSwitch)
    yield put(getKillSwitchSucceded({ killSwitch: data.data.killSwitch }))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(getKillSwitchFailed(newError))
  }
}

function * updateKillSwitch () {
  try {
    const data = yield call(API.adminService.updateKillSwitch)
    yield put(updateKillSwitchSucceded({ killSwitch: data.data.killSwitch }))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(updateKillSwitchFailed(newError))
  }
}

export default function * rootUser () {
  yield takeEvery(TYPES.USER.LOGIN.FETCH.REQUEST, fetchLogin)
  yield takeEvery(TYPES.USER.AFTER_FIRST_LOGIN.FETCH.REQUEST, fetchAfterFirstLogin)
  yield takeEvery(TYPES.USER.FORGOT_PASSWORD.FETCH.REQUEST, fetchForgotPassword)
  yield takeEvery(TYPES.USER.RESET_PASSWORD.FETCH.REQUEST, fetchResetPassword)
  yield takeEvery(TYPES.USER.ACTIVATE.FETCH.REQUEST, fetchActivate)
  yield takeEvery(TYPES.USER.RESEND_ACTIVATION_EMAIL.FETCH.REQUEST, fetchResendActivationEmail)
  yield takeEvery(TYPES.USER.COMPANIES.FETCH.REQUEST, fetchCompanies)
  yield takeEvery(TYPES.USER.GET_KILLSWITCH.FETCH.REQUEST, fetchKillSwitch)
  yield takeEvery(TYPES.USER.UPDATE_KILLSWITCH.FETCH.REQUEST, updateKillSwitch)
}
