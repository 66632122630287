//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'

//  components
import FormInputs from 'components/common/formInputs'
import ImageNotDraggable from 'components/common/imageNotDraggable'
import LocationSelector from './locationSelector'
import LocationForm from 'components/common/forms/locationForm'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { locationButtonsUpdate } from 'redux/ducks/locationButtons'

//  tools
import { requiredValidator, emailValidator, basicTextInputValidator, phoneValidator } from 'tools'

//  styles
import { locationIcon, companyIcon, contactIcon, phoneIcon, addressIcon } from 'assets'
import { useStyles, Item, Title } from './styles'

export default function ContactForm ({ location }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, errors, control } = useFormContext()
  const fromTo = createSelector(
    state => state.newForm[location],
    (info) => (info || {}),
  )
  const data = useSelector(fromTo)
  const { showSave = false, ...rest } = useSelector(state => state.ui.locationButtons[location] || false)

  const handleInputChange = () => {
    dispatch(locationButtonsUpdate({
      [location]: {
        ...rest,
        showSave: true,
        showUpdate: true,
      },
    }))
  }

  return (
    <>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={companyIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>Company Name</Typography>
        </Title>
        <LocationSelector key={`${location}-location`} location={location} value={data.contact?.company || ''} />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={addressIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>Address</Typography>
        </Title>
        <FormInputs.Input
          key={`${location}.contact.address`}
          name={`${location}.contact.address`}
          inputRef={register({ ...basicTextInputValidator, ...requiredValidator })}
          showError
          error={errors[location]?.contact?.address}
          defaultValue={data.contact?.address}
          classNameError={classes.error}
          onChange={showSave ? null : handleInputChange}
        />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={addressIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>Address 2</Typography>
        </Title>
        <FormInputs.Input
          key={`${location}.contact.address2`}
          name={`${location}.contact.address2`}
          inputRef={register(basicTextInputValidator)}
          showError
          error={errors[location]?.contact?.address2}
          defaultValue={data.contact?.address2}
          classNameError={classes.error}
          onChange={showSave ? null : handleInputChange}
        />
      </Item>
      <LocationForm location={location} />
      <Item>
        <Title>
          <ImageNotDraggable externalImage={contactIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>Contact Name</Typography>
        </Title>
        <FormInputs.Input
          key={`${location}.contact.name`}
          name={`${location}.contact.name`}
          inputRef={register({ ...basicTextInputValidator, ...requiredValidator })}
          showError
          error={errors[location]?.contact?.name}
          defaultValue={data.contact?.name}
          classNameError={classes.error}
          onChange={showSave ? null : handleInputChange}
        />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={phoneIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>Phone Number</Typography>
        </Title>
        <FormInputs.InputMask
          key={`${location}.contact.phone`}
          name={`${location}.contact.phone`}
          variant='outlined'
          control={control}
          rules={phoneValidator}
          mask='(999) 999-9999'
          error={errors[location] && errors[location].contact?.phone}
          defaultValue={data.contact?.phone || ''}
          classNameError={classes.error}
          onChange={showSave ? null : handleInputChange}
          isControlled
          showError
        />
      </Item>
      <Item>
        <Title>
          <ImageNotDraggable externalImage={locationIcon} width='20px' />
          <Typography variant='h4' className={classes.colorText} component='label'>
            Company Email
          </Typography>
        </Title>
        <FormInputs.Input
          key={`${location}.contact.mail`}
          name={`${location}.contact.mail`}
          inputRef={register({ pattern: emailValidator.pattern })}
          showError
          error={errors[location]?.contact?.mail}
          defaultValue={data.contact?.mail}
          classNameError={classes.error}
          onChange={showSave ? null : handleInputChange}
        />
      </Item>
    </>
  )
}

ContactForm.propTypes = {
  location: PropTypes.string.isRequired,
}
