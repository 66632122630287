import TYPES from './types'

export const modalUpdate = payload => ({
  type: TYPES.MODAL.UPDATE,
  payload,
})

export const modalCreatingShipment = payload => ({
  type: TYPES.MODAL.CREATING_SHIPMENT,
  payload,
})
