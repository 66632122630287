// libraries
import React from 'react'
import { Box, Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import PropTypes from 'prop-types'

// styles
import { tableStyles } from './styles'

function Detail ({ detailData, isAdmin }) {
  const styles = tableStyles
  return (
    <div style={styles.detailContainer}>
      <Box my={3} width='90%' m={5}>
        <MaterialTable
          columns={[
            {
              title: '',
              field: 'description',
              cellStyle: tableStyles.nameColumn,
              width: '60%',
            },
            {
              title: 'Carrier',
              field: 'carrierPrice',
              hidden: !isAdmin,
              type: 'numeric',
              headerStyle: tableStyles.column,
              cellStyle: tableStyles.cellStyle,
              width: '20%',
            },
            {
              title: 'Customer price',
              field: 'customerPrice',
              type: 'numeric',
              headerStyle: tableStyles.column,
              cellStyle: tableStyles.cellStyle,
              width: '20%',
            },
          ]}
          data={detailData}
          options={{
            toolbar: false,
            search: false,
            paging: false,
            sorting: false,
            rowStyle: tableStyles.rowStyle,
          }}
          components={{
            Container: props => (
              <Paper elevation={0}>
                {props.children}
              </Paper>
            ),
          }}
        />
      </Box>
    </div>
  )
}

export default Detail

Detail.propTypes = {
  detailData: PropTypes.array.isRequired,
}
