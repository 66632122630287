import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    gridColumn: '1 / -1',
    margin: '1rem 0',
    minHeight: '15rem',
    maxHeight: '20rem',
  },
  card: {
    margin: '0 1rem',
    marginBottom: '1rem',
    minHeight: 'inherit',
    maxHeight: 'inherit',
  },
  listHeader: {
    padding: theme.spacing(1, 2),
    '& div .MuiTypography-h5': {
      color: theme.palette.primary.main,
    },
  },
  cardHeaderTitle: {
    margin: '0.5rem 0',
    marginTop: 0,
  },
  list: {
    width: 'min-content',
    minWidth: '18rem',
    minHeight: 'inherit',
    maxHeight: 'inherit',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    overflowX: 'hidden',
  },
  listLeft: {
    maxHeight: 'calc(100% - 95px)',
  },
  listRight: {
    maxHeight: 'calc(100% - 49px)',
  },
  listItem: {
    display: 'block',
    paddingTop: 0,
    marginBottom: 2,
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listItemText: {
    overflowWrap: 'anywhere',
    fontSize: '1rem',
  },
  roleTitle: {
    textAlign: 'center',
    margin: '0.5rem 0',
    fontSize: 21,
    color: theme.palette.primary.main,
  },
  name: {
    fontSize: 16,
    color: '#2E384D',
    marginLeft: 3,
  },
  email: {
    fontSize: 14,
    color: '#2E384D',
    fontStyle: 'italic',
  },
  chip: {
    height: 'max-content',
    padding: 5,
  },
  rightIcon: {
    position: 'absolute',
    top: 8,
    right: 5,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.common.second3,
    },
  },
}))
