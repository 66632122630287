export const adminRoutes = {
  usersByCompany: '/api/v1/admin/company/:companyId/users',
  createUser: '/api/v1/admin/user',
  updateUser: '/api/v1/admin/user/:id',
  companies: '/api/v1/admin/company',
  companyById: '/api/v1/admin/company/:id',
  accounts: '/api/v1/admin/accounts',
  mcLeodCompanies: '/api/v1/admin/mlCompany/:code',
  mcLeodCompaniesUpdate: '/api/v1/admin/mlCompany/:code/update',
  tuckerUsers: '/api/v1/admin/tuckerUsers',
  killSwitch: '/api/v1/admin/killswitch',
  setTimeout: '/api/v1/admin/setTimeout',
  getTimeout: '/api/v1/admin/getTimeout',
}
