import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '4rem',
    left: '-5rem',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: 20,
    width: 'max-content',
  },
  indicatorText: {
    color: theme.palette.common.second3,
    fontSize: 16,
    fontStyle: 'italic',
  },
  subtitleAdvancedOption: {
    fontSize: 14,
    color: '#979797',
    marginBottom: 5,
  },
  dateRangeInput: {
    '& input': {
      '&::placeholder': {
        fontSize: 14,
      },
    },
  },
}))
