//  libraries
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

//  hooks
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

//  redux
import { fetchPost } from 'redux/ducks/postData'
import { shipmentListUpdateStatus } from 'redux/ducks/shipmentList'

//  API
import API from 'API'

//  styles
import { useStyles } from './styles'
import { MdMoreVert, MdVisibility, MdPrint } from 'react-icons/md'

const Menu = ({ item }) => {
  const classes = useStyles()
  const anchorRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast, removeAllToasts } = useToasts()
  const { type } = useParams()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    return () => {
      removeAllToasts()
    }
  }, [removeAllToasts])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const request = (endPoint, action) => {
    endPoint
      .then(response => {
        // eslint-disable-next-line no-undef
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.download = 'BOL'
        link.href = fileURL
        link.click()
        window.URL.revokeObjectURL(fileURL)
      })
      .catch(error => {
        addToast('Bol is not available at the moment.', {
          appearance: 'error',
          autoDismiss: true,
          id: `error-get-BOL-pdf-${item._id}`,
        })
        console.log(error)
      })
  }

  const handlePOD = (id) => () => {
    request(API.shipmentService.getPdfTL(id), 'download')
  }

  const handleUpdateStatus = () => {
    dispatch(fetchPost('shipmentService.updateStatus', item.refId, null,
      () => {
        dispatch(shipmentListUpdateStatus({ _id: item._id, value: 'Canceled' }))
        addToast('Shipment canceled.', {
          appearance: 'success',
          autoDismiss: true,
          id: `success-cancel-row-shipment-${item._id}`,
        })
      },
    ))
  }

  return (
    <div>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
      >
        <MdMoreVert />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} transition placement='bottom-end'>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={false} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={(e) => { history.push(`/shipment/details/${type}/${item.refId}`) }} className={classes.menuItem}>
                    View detail<MdVisibility className={classes.icon} />
                  </MenuItem>
                  <MenuItem onClick={handlePOD(item.refId)} className={classes.menuItem}>
                    Print BOL<MdPrint className={classes.icon} />
                  </MenuItem>
                  <MenuItem onClick={handleUpdateStatus}>
                    Cancel
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

Menu.propTypes = {
  item: PropTypes.object,
}

export default Menu
