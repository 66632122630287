import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  colorText: {
    color: theme.palette.common.second1,
    marginLeft: 18,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
  },
  error: {
    fontSize: '15px',
  },
}))

export const Title = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: center;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`
