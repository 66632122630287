//  libraries
import React, { useEffect, useState, useRef } from 'react'
import ReactGA from 'react-ga'
import MaterialTable, { MTableBody } from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//  redux
import { CompanyListRequest } from 'redux/ducks/companyList'
import { newFormLoadFromCompanyList } from 'redux/ducks/newForm'

//  tools
import { nameAbbreviation } from 'tools'

//  styles
import { useStyles, tableStyles } from './styles'
import { tableIcons } from 'styles/tableIcons'
import { globalTableOptions } from 'styles/globalTableStyles'

//  components
import SortingHeader from 'components/common/tableFragments/sortingHeader'
import Pagination from 'components/common/tableFragments/pagination'
import TableLoading from 'components/common/tableFragments/tableLoading'

export default function CompanyList () {
  const classes = useStyles()
  const tableRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { data = [], page = 0, totalCount = 0, loading } = useSelector(state => state.entities.companyList || {})
  const filters = useSelector(state => state.ui.filters)
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const setPage = page - 1 < 0 ? 0 : page - 1 // Avoid to assign negative value.

  useEffect(() => {
    dispatch(CompanyListRequest({ params: { limit, page: 1 } }))
    //  eslint-disable-next-line
  }, [])

  return (
    <>
      <Pagination
        count={totalCount}
        rowsPerPage={limit}
        page={setPage}
        onChangePage={(evt, page) => {
          dispatch(CompanyListRequest({ params: { ...filters, limit, page: page + 1 } }))
        }}
        onChangeRowsPerPage={(evt, arg) => {
          setLimit(evt.target.value)
          tableRef.current.onChangeRowsPerPage(evt)
          dispatch(CompanyListRequest({ params: { ...filters, limit: evt.target.value, page } }))
        }}
      />
      <MaterialTable
        isLoading={loading}
        data={data}
        icons={tableIcons}
        tableRef={tableRef}
        columns={[
          {
            title: '',
            cellStyle: tableStyles.cellStyle,
            sorting: false,
            render: rowData => (
              <Avatar className={classes.companyAvatar} src={`${rowData.image}?${Math.random()}`}>
                {nameAbbreviation(rowData.general?.name)}
              </Avatar>
            ),
          },
          {
            title: 'Company Name',
            cellStyle: tableStyles.cellStyle,
            sortKeyValue: 'name',
            render: rowData => (
              <Typography variant='h4' className={classes.text}>{rowData.general?.name}</Typography>
            ),
          },
          {
            title: 'Type',
            cellStyle: tableStyles.cellStyle,
            sortKeyValue: 'type',
            render: rowData => (
              <Typography variant='h4' className={classes.text}>{rowData?.type}</Typography>
            ),
          },
          {
            title: 'Point of Contact',
            cellStyle: tableStyles.cellStyle,
            sortKeyValue: 'contact',
            render: rowData => (
              <Typography variant='h4' className={classes.text}>{rowData.general?.contact}</Typography>
            ),
          },
          {
            title: 'Email',
            cellStyle: tableStyles.cellStyle,
            sortKeyValue: 'email',
            render: rowData => (
              <Typography variant='h4' className={classes.text}>{rowData.general?.email}</Typography>
            ),
          },
          {
            title: 'Phone',
            cellStyle: tableStyles.cellStyle,
            sortKeyValue: 'phone',
            render: rowData => {
              return (
                <Typography variant='h4' className={classes.text} noWrap>{rowData.general?.phone}</Typography>
              )
            },
          },
        ]}
        components={{
          Pagination: (componentsProps) => {
            return (
              <TablePagination
                {...componentsProps}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(CompanyListRequest({ params: { limit, page: page + 1, ...filters } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  componentsProps.onChangeRowsPerPage(evt)
                  dispatch(CompanyListRequest({ params: { limit: evt.target.value, page, ...filters } }))
                }}
              />
            )
          },
          Body: (props) => (
            <>
              {loading && totalCount > 0 && <TableLoading />}
              <MTableBody {...props} />
            </>
          ),
          Header: (props) => <SortingHeader tableProps={props} limit={limit} listOf='company' />,
        }}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          sorting: true,
        }}
        style={{
          boxShadow: 'none',
          width: '100%',
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No companies to display',
          },
        }}
        onRowClick={(e, rowData) => {
          dispatch(newFormLoadFromCompanyList(rowData))
          ReactGA.event({
            category: 'Detail',
            action: '/admin/companies/edit',
            label: rowData.mlCompanyId,
          })
          history.push('/admin/companies/edit')
        }}
      />
    </>
  )
}
