import TYPES from './types'

export const newFormUpdate = payload => ({
  type: TYPES.NEW_FORM.UPDATE,
  payload,
})

export const newFormDelete = () => ({
  type: TYPES.NEW_FORM.DELETE,
})

export const newFormLoadFromQuoteLtl = payload => ({
  type: TYPES.NEW_FORM.LOAD.QUOTE.LTL,
  payload,
})

export const newFormLoadFromQuoteTl = payload => ({
  type: TYPES.NEW_FORM.LOAD.QUOTE.TL,
  payload,
})

export const newFormLoadFromShipmentLtl = payload => ({
  type: TYPES.NEW_FORM.LOAD.SHIPMENT.LTL,
  payload,
})

export const newFormLoadFromShipmentTl = payload => ({
  type: TYPES.NEW_FORM.LOAD.SHIPMENT.TL,
  payload,
})

export const newFormLoadFromRatesList = payload => ({
  type: TYPES.NEW_FORM.LOAD.RATE_LIST,
  payload,
})

export const newFormLoadFromUserList = payload => ({
  type: TYPES.NEW_FORM.LOAD.USER_LIST,
  payload,
})

export const newFormLoadFromCompanyList = payload => ({
  type: TYPES.NEW_FORM.LOAD.COMPANY_LIST,
  payload,
})

export const newFormLoadFromSalesDetails = payload => ({
  type: TYPES.NEW_FORM.LOAD.SALES_DETAILS,
  payload,
})
