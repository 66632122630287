import { FETCH } from '../fetch'
import { notificationRoutes } from '../routes/notifications'

export class Notification {
  async getNotification (data) {
    const response = FETCH('get', notificationRoutes.getNotifications, data)
    return response
  }

  async updateNotification (data) {
    const response = FETCH('patch', notificationRoutes.updateNotifications, data)
    return response
  }

  async deleteNotification (idNotification) {
    const response = FETCH('delete', notificationRoutes.deleteNotification.replace(':id', idNotification))
    return response
  }
}
