//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector } from 'react-redux'

//  API
import API from 'API'

//  styles
import { filesIcon } from 'assets'
import { useStyles, Root, HeadingContainer, Content, Card, Row } from './styles'

export default function DocumentCard () {
  const documents = useSelector(state => state.entities.shipmentDetails.data.documents || [])
  const refId = useSelector(state => state.entities.shipmentDetails.data.refId)
  const classes = useStyles()

  const request = (endPoint) => {
    endPoint.then(response => {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.download = 'invoice'
      link.href = fileURL
      link.click()
      window.URL.revokeObjectURL(fileURL)
    }, error => {
      console.log(error)
    })
  }

  const handleDocument = (documentId) => () => {
    request(API.shipmentService.getDocument({ shipmentId: refId, documentId }))
  }

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={filesIcon} width='40px' />
        <Typography variant='h2' className={classes.heading}>
          Supporting Documents
        </Typography>
      </HeadingContainer>
      <Content>
        {
          documents.filter(({ _id }) => _id !== 'pod').map(({ _id, name }, index) => (
            <Card key={index} onClick={handleDocument(_id)}>
              <Typography variant='h4' className={classes.title}>{_id || '...'}</Typography>
              <Row>
                <Typography variant='h4' className={classes.text} noWrap>{name || '...'}</Typography>
                <ImageNotDraggable externalImage={filesIcon} width='20px' />
              </Row>
            </Card>
          ))
        }
      </Content>
    </Root>
  )
}
