import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  colorText: {
    color: theme.palette.common.second1,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
    margin: '0 auto 10px auto',
  },
  radioGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: 50,
  },
  radio: {
    margin: 0,
    '& .MuiTypography-root': {
      color: theme.palette.common.second1,
      paddingBottom: 24,
      fontSize: 18,
      fontWeight: 'normal',
      fontStretch: 'normal',
    },
  },
  unCheckedIcon: {
    padding: '1px',
    width: '21px',
    height: '21px',
    color: theme.palette.secondary.main,
    border: 'solid 3px #989898',
    borderRadius: '3px',
  },
  checkedIcon: {
    color: theme.palette.secondary.main,
    background: theme.palette.common.second6,
    borderRadius: '3px',
    padding: '3px',
    width: '21px',
    height: '21px',
  },
  checkbox: {
    margin: '0 auto',
  },
  checked: {
    color: theme.palette.common.second6,
  },
}))

export const Title = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: center;
`

export const Item1 = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || 'auto'};
  grid-column: 1;
`

export const Item2 = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || 'auto'};
  grid-column: 2;
`
