import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  colorText: {
    color: theme.palette.common.second1,
    marginLeft: 18,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
  },
  loadText: {
    color: theme.palette.common.second1,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 'normal',
  },
  inputBase: {
    height: 'auto',
    padding: '10.5px 14px',
  },
  radioGroup: {
    display: 'inline',
  },
  radio: {
    margin: '0.5em 0.5em',
    '& .MuiTypography-root': {
      color: theme.palette.common.second1,
      paddingBottom: 8,
      paddingTop: 3.2833,
      fontSize: 18,
      fontWeight: 'normal',
      fontStretch: 'normal',
    },
  },
  unCheckedIcon: {
    padding: '1px',
    width: '21px',
    height: '21px',
    color: theme.palette.secondary.main,
    border: 'solid 3px #989898',
    borderRadius: '3px',
  },
  checkedIcon: {
    color: theme.palette.secondary.main,
    background: theme.palette.common.second6,
    borderRadius: '3px',
    padding: '3px',
    width: '21px',
    height: '21px',
  },
  loadNumber: {
    paddingTop: '12px',
    width: '50px',
  },
}))

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
  grid-template-rows: 100%;
`

export const Title = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: center;
`

export const Row = styled.div`
  margin-top: 43px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
`

export const LoadsContainer = styled.div`
  width: 80px;
`

export const SelectContainer = styled.div`
  margin-top: 33px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`

export const customStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => {
    return {
      ...base,
      borderWith: '2px',
      borderStyle: 'solid',
      borderColor: state.selectProps.error ? 'red' : '#979797',
      boxShadow: 'none',
      '&:hover': {
        border: '2px solid #8D8E90',
      },
    }
  },
}
