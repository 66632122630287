//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import PropTypes from 'prop-types'

//  components
import FormInputs from 'components/common/formInputs'
import LocationForm from '../locationForm'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

//  redux
import { AccountingInfoRequest } from 'redux/ducks/accountingInfo'

//  tools
import { requiredValidator, emailValidator, phoneValidator } from 'tools'

//  styles
import { useStyles, Item, CategoryItem, PosItem, customStyles } from './styles'

export default function GeneralForm ({ location, start = 1 }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [customer, SetCustomer] = useState({ value: '', label: '' })
  const { register, control, errors, setValue } = useFormContext()
  const data = useSelector(state => state.newForm[location] || {})
  const contacts = useSelector(state => state.entities.mcLeodCompanies.data?.contacts || [])
  const companyId = useSelector(state => state.newForm.mlCompanyId)
  const customerData = useSelector(state => state.entities.accountingInfo.data?.customerData)
  const action = useParams().action

  console.log(customerData)

  useEffect(() => {
    dispatch(AccountingInfoRequest(companyId))
    //  eslint-disable-next-line
  }, [companyId])

  const handleChange = (data) => {
    SetCustomer(data)
    setValue(`${location}.contact`, data.value || '')
    setValue(`${location}.email`, data.email || '')
    setValue(`${location}.phone`, data.phone || '')
  }

  return (
    <>
      <CategoryItem>
        <Typography variant='h4' className={classes.text} component='label'> Customer Category </Typography>
        <FormInputs.Input
          value={customerData?.category || ''}
          disabled
        />
      </CategoryItem>
      <Item>
        <Typography variant='h4' className={classes.text} component='label'> Customer Category Email </Typography>
        <FormInputs.Input
          // eslint-disable-next-line camelcase
          value={customerData?.distribution_email || ''}
          disabled
        />
      </Item>
      <PosItem pos={start}>
        <Typography variant='h4' className={classes.text} component='label'>Company Name</Typography>
        <FormInputs.Input
          key={`${location}.name`}
          name={`${location}.name`}
          inputRef={register(requiredValidator)}
          showError
          error={errors[location]?.name}
          defaultValue={data?.name}
        />
      </PosItem>
      {action === 'new' && (
        <Item>
          <Typography variant='h4' className={classes.text} component='label'>Contact Selector</Typography>
          <Select
            classNamePrefix='select'
            name='company'
            isSearchable
            value={customer}
            onChange={handleChange}
            options={contacts}
            styles={customStyles}
          />
        </Item>
      )}
      <Item>
        <Typography variant='h4' className={classes.text} component='label'>Contact</Typography>
        <FormInputs.Input
          key={`${location}.contact`}
          name={`${location}.contact`}
          inputRef={register(requiredValidator)}
          showError
          error={errors[location]?.contact}
          defaultValue={data?.contact}
        />
      </Item>
      <Item>
        <Typography variant='h4' className={classes.text} component='label'>Contact Email</Typography>
        <FormInputs.Input
          key={`${location}.email`}
          name={`${location}.email`}
          inputRef={register(emailValidator)}
          showError
          error={errors[location]?.email}
          defaultValue={data?.email}
        />
      </Item>
      <Item>
        <Typography variant='h4' className={classes.text} component='label'>Contact Phone</Typography>
        <FormInputs.InputMask
          key={`${location}.phone`}
          name={`${location}.phone`}
          showError
          error={errors[location]?.phone}
          isControlled
          variant='outlined'
          defaultValue={data?.phone || ''}
          mask='(999) 999-9999'
          control={control}
          rules={phoneValidator}
        />
      </Item>
      <LocationForm location={location} />
      <Item>
        <Typography variant='h4' className={classes.text} component='label'>Address</Typography>
        <FormInputs.Input
          key={`${location}.address`}
          name={`${location}.address`}
          inputRef={register(requiredValidator)}
          showError
          error={errors[location]?.address}
          defaultValue={data?.address}
        />
      </Item>
    </>
  )
}

GeneralForm.propTypes = {
  location: PropTypes.string.isRequired,
  start: PropTypes.number,
}
