// libraries
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import { yupResolver } from '@hookform/resolvers'
import moment from 'moment'

// components
import Loader from 'components/common/loader'
import CompanyInfo from 'components/common/companyInfo'
import DetailsForm1 from './detailsForm1'
import DetailsForm2 from './detailsForm2'
import CustomerReferences from './customerReferences'
import EstimationForm1 from './estimationForm1'
import EstimationForm2 from './estimationForm2'
import MarketRates from './marketRates'
import Comments from './comments'
import Buttons from './buttons'
import BackButton from 'components/common/backButton'
import MessageModal from 'components/common/messageModal'
import SimpleDataBox from './simpleDataBox'

// hooks
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

//  redux
import { newFormLoadFromSalesDetails } from 'redux/ducks/newForm'
import { companySelectorUpdate } from 'redux/ducks/companySelector'
import { QuoteDetailsSetMessageRequest } from 'redux/ducks/quoteDetails'

// tools
import { salesRepRateSchema } from 'tools'

//  API
import API from 'API'

// styles
import { useStyles, Burger } from './styles'

const message = 'In this moment this function is not available. Please try later or contact us.'

function TlDetailSalesRep () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()
  const [sendLoading, setSendLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [quoteInfo = {}] = useSelector(state => state.entities.quoteDetails.data) || []
  const {
    rate,
    marketRates = [],
    ratesHistory = [],
    refId = '...',
    company,
    userInfo,
    comments = {},
    status,
    references = [],
    shipping = {},
    survey,
    salesAssigned = null,
    assignedToMe = false,
  } = quoteInfo
  const disabledForm = ['Active', 'Scheduled'].includes(status) ||
    salesAssigned === null ||
    !assignedToMe
  const allCustomerReferences = references.slice()
  const miles = ratesHistory
    .find(rate => rate.miles)?.miles ||
    marketRates.find(rate => rate.miles)?.miles ||
    ''
  if (quoteInfo.items[0].hazmatValue) {
    allCustomerReferences.unshift({
      ref: 'HAZMAT VALUE',
      value: quoteInfo.items[0].hazmatValue,
    })
  }

  const getDefaultDaysInTransit = () => {
    const daysInTransit = miles === '' ? '' : Math.ceil(miles / 400) // 400 miles per day
    return daysInTransit <= 0 ? 1 : daysInTransit
  }

  const methods = useForm({
    resolver: yupResolver(salesRepRateSchema),
    defaultValues: {
      pickupDate: rate?.pickupWindow?.date ? moment(rate.pickupWindow.date).startOf('day') : null,
      pickupTime: rate?.pickupWindow?.time ? moment(rate.pickupWindow.time, 'HH:mm') : null,
      daysInTransit: rate?.daysInTransit || getDefaultDaysInTransit(),
      expirationDate: rate?.expirationDate ? moment(rate.expirationDate) : moment().add(7, 'days'),
      price: rate?.price || '',
      isPercentageUplift: rate?.isPercentageUplift !== undefined ? rate.isPercentageUplift : true,
      upliftValue: rate?.upliftValue || '',
      totalRate: '',
    },
  })

  const editQuote = () => {
    dispatch(newFormLoadFromSalesDetails(quoteInfo))
    dispatch(companySelectorUpdate({
      companyParent: quoteInfo.companyParent,
      companyChild: quoteInfo.companyChild || quoteInfo.companyParent,
    }))
    history.push('/sales/edit/tl')
  }

  const onSubmit = (values) => {
    setSendLoading(true)
    const distance = marketRates[0] === undefined ? 0 : marketRates[0].miles || 0
    values = {
      ...values,
      pickupDate: moment(values.pickupDate).startOf('day'),
      timeZone: localStorage.getItem('timeZone'),
    }

    API.quoteService.setTlRate({ ...values, distance, quoteId: refId })
      .then(() => history.push('/sales/list'))
      .catch(() => {
        addToast(message, {
          appearance: 'error',
          autoDismiss: true,
          id: 'error-toast-sale',
        })
      })
      .finally(() => setSendLoading(false))
  }

  return (
    <>
      <Box display='flex' mb='2rem' justifyContent='space-between'>
        <BackButton to='/sales/list' text={`Quote #${refId}`} />
        <Button
          variant='contained'
          className={classes.button}
          onClick={editQuote}
        >
          Edit
        </Button>
      </Box>
      <Grid container justify='center' className={classes.containerContent}>
        <Hidden mdDown>
          <Grid item lg={4}>
            <CompanyInfo company={company} userInfo={userInfo} />
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Drawer
            open={open}
            onClose={() => setOpen(false)}
          >
            <CompanyInfo company={company} userInfo={userInfo} />
          </Drawer>
          <Burger onClick={() => setOpen(true)} />
        </Hidden>
        <Grid item lg={8} sm={11}>
          <DetailsForm1 data={quoteInfo} />
          <DetailsForm2 data={quoteInfo} />
          <Box display='grid' gridTemplateColumns='1fr 1fr' gridGap='1rem'>
            <SimpleDataBox data={[
              { title: 'Commodity: ', text: quoteInfo.items[0]?.commodity },
              { title: 'Special Instructions: ', text: shipping.instructionsShipper },
            ]}
            />
            <CustomerReferences data={allCustomerReferences} title='Customer References: ' />
          </Box>
          {survey && <SimpleDataBox data={[{ title: 'Customer Survey Response: ', text: survey }]} />}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <EstimationForm1 disabled={disabledForm} miles={miles} />
              <EstimationForm2 disabled={disabledForm} />
              <MarketRates ratesHistory={ratesHistory} marketRates={marketRates} refId={refId} />
              <Comments
                comments={comments}
                quoteId={refId}
                loading={comments.loading || false}
                setMessageRequestAction={QuoteDetailsSetMessageRequest}
                disabled={salesAssigned === null || !assignedToMe}
              />
              <Buttons
                disabled={disabledForm}
                onSubmit={methods.handleSubmit(onSubmit)}
              />
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <Loader open={sendLoading} />
      <MessageModal
        title='SUCCESSFUL RESPONSE!'
        path='entities.postData.errors'
        body='The email was sent correctly.'
        handleCancelButton={() => { history.push('/sales/list') }}
        handleSubmitButton={() => { history.push('/sales/list') }}
        submitText='OK'
      />
    </>
  )
}

export default TlDetailSalesRep
