//  libraries
import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'

//  redux
import { stepperUpdate } from 'redux/ducks/stepper'

// styles
import { useStyles } from './styles'
import { FaUserCheck } from 'react-icons/fa'
import { AiOutlineDoubleRight } from 'react-icons/ai'
import { GoSearch } from 'react-icons/go'

function sortUsers (users) {
  const sortBy = (property) => (a, b) => {
    return a[property].localeCompare(b[property]) >= 0 ? 1 : -1
  }
  const tuckers = users.filter(user => user.role === 'tucker').sort(sortBy('name'))
  const sales = users.filter(user => user.role === 'sales').sort(sortBy('name'))
  return [...tuckers, ...sales]
}

function SideObject (remover, pusher, incomplete) {
  this.remover = remover
  this.pusher = pusher
  this.incomplete = incomplete
}

export default function TransferList () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, unregister, setValue } = useFormContext()
  const usersToNotify = useSelector(state => {
    const id = state.newForm.companyId
    return state.entities.companyList.data.find(company => company._id === id)?.usersToNotify || []
  })
  const tuckerUsers = useSelector(state => { // remove the right side elements in left side
    const users = state.entities.userList.data
    const toNotifyIds = usersToNotify.map(user => user._id)
    const repeatedIds = users.map(user => user._id).filter(val => toNotifyIds.indexOf(val) !== -1)
    return users.filter(val => !repeatedIds.includes(val._id))
  })
  const activeSaveButton = useSelector(state => state.ui.stepper.activeSaveButton)
  const [left, setLeft] = useState(sortUsers(tuckerUsers))
  const [right, setRight] = useState(usersToNotify)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => { // registers
    register('usersToNotify')
    return () => {
      unregister('usersToNotify')
    }
  }, [register, unregister])

  useEffect(() => { // sorts
    setLeft(left => sortUsers(left))
    setRight(right => {
      const sortedUsers = sortUsers(right)
      setValue('usersToNotify', sortedUsers.map(user => user._id))
      return sortedUsers
    })
  }, [right.length, setValue])

  const engineSearch = (data) => {
    if (searchValue === '') return data
    return left.filter(({ name, email }) => {
      return name.toLowerCase().includes(searchValue.toLowerCase()) ||
        email.toLowerCase().includes(searchValue.toLowerCase())
    })
  }

  const handleTransferUser = (from = 'left', idUser) => () => {
    const move = {
      left: new SideObject(setLeft, setRight, right),
      right: new SideObject(setRight, setLeft, left),
    }
    move[from].remover(users => users.filter(user => {
      if (user._id !== idUser) return true
      move[from].pusher([...move[from].incomplete, user])
      return false
    }))
    if (!activeSaveButton) dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  const getRoleTitle = (idTucker, idSale, index) => {
    if (idTucker === index) {
      return <Typography variant='h4' className={classes.roleTitle}>Tucker Admins</Typography>
    } else if (idSale === index) {
      return <Typography variant='h4' className={classes.roleTitle}>Sales/Account Managers</Typography>
    }
    return null
  }

  const renderUser = (name, email) => (
    <>
      <Typography variant='body2' className={classes.name}>{name}</Typography>
      <Typography variant='body2' className={classes.email}>{email}</Typography>
    </>
  )

  const renderLeftHeaderList = (title) => (
    <>
      <p className={classes.cardHeaderTitle}>{title}</p>
      <TextField
        className='searchBar'
        variant='outlined'
        fullWidth
        value={searchValue}
        onChange={({ target }) => { setSearchValue(target.value) }}
        placeholder='Search'
        InputProps={{
          startAdornment: <InputAdornment position='start'><GoSearch color='#CBD6E2' /></InputAdornment>,
        }}
      />
    </>
  )

  const renderList = (side = 'left') => {
    const users = side === 'left' ? engineSearch(left) : right
    const firstTuckerId = users.findIndex(user => user.role === 'tucker')
    const firstSalesId = users.findIndex(user => user.role === 'sales')
    if (side === 'left') {
      return (
        <List className={`${classes.list} ${classes.listLeft}`} role='list'>
          {users.map((user, i) => (
            <li key={user._id}>
              {getRoleTitle(firstTuckerId, firstSalesId, i)}
              <ListItem className={classes.listItem} onClick={handleTransferUser('left', user._id)} button>
                {renderUser(user.name, user.email)}
                <AiOutlineDoubleRight fontSize='2rem' className={classes.rightIcon} />
              </ListItem>
            </li>
          ),
          )}
          <ListItem />
        </List>
      )
    }
    return (
      <List className={`${classes.list} ${classes.listRight}`} role='list'>
        {users.map((user, i) => (
          <ListItem className={classes.listItem} key={user._id}>
            {getRoleTitle(firstTuckerId, firstSalesId, i)}
            <Chip
              label={renderUser(user.name, user.email)}
              onDelete={handleTransferUser('right', user._id)}
              className={classes.chip}
              icon={<FaUserCheck fontSize='1.5rem' />}
            />
          </ListItem>
        ),
        )}
      </List>
    )
  }

  return (
    <Box display='flex' justifyContent='center' className={classes.root} flexWrap='wrap'>
      <Card className={classes.card}>
        <CardHeader className={classes.cardHeader} title={renderLeftHeaderList('Select Tucker Users')} />
        <Divider />
        {renderList('left')}
      </Card>
      <Card className={classes.card} variant='outlined'>
        <CardHeader className={classes.cardHeader} title='Users to be Notified' />
        <Divider />
        {renderList('right')}
      </Card>
    </Box>
  )
}
