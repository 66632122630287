//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector } from 'react-redux'

// tools
import { dateFormat } from 'tools'

//  styles
import { buildingIcon } from 'assets'
import { useStyles, Root, Section, Section2 } from './styles'

export default function CompanyCard () {
  const classes = useStyles()
  const {
    companyName,
    createdDate,
    lastUpdate,
    currentLocation,
    status,
    contactName,
    contactEmail,
  } = useSelector(state => state.entities.shipmentDetails.data.general || {})

  return (
    <Root>
      <Section>
        <ImageNotDraggable className='image' externalImage={buildingIcon} width='30px' />
        <div className='company'>
          <Typography variant='h2' className={classes.title}>
            {companyName || '[Not Found Company]'}
          </Typography>
          <Typography variant='h4' className={classes.title2}>
            {createdDate ? dateFormat(createdDate) : '...'}
          </Typography>
          <Typography variant='h6' className={classes.title3}>Date created</Typography>
        </div>
      </Section>
      <Section2>
        <div>
          <Typography variant='h4' className={classes.subtitle}>Last Update</Typography>
          <Typography variant='h4' className={classes.text}>
            {lastUpdate ? dateFormat(lastUpdate) : '...'}
          </Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.subtitle}>Current Location</Typography>
          <Typography variant='h4' className={classes.text2}>{currentLocation || '...'}</Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.subtitle}>Status</Typography>
          <Typography variant='h4' className={classes.text2}>{status || '...'}</Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.subtitle}>Requesting User</Typography>
          <Typography variant='h4' className={classes.text}>{contactName || '...'}</Typography>
        </div>
        <div>
          <Typography variant='h4' className={classes.subtitle}>Email</Typography>
          <Typography variant='h4' className={classes.text2}>{contactEmail || '...'}</Typography>
        </div>
      </Section2>
    </Root>
  )
}
