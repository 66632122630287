// libraries
import React from 'react'
import PropTypes from 'prop-types'
import Zoom from '@material-ui/core/Zoom'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'

// styles
import { useStyles } from './styles'
import { MdRadioButtonUnchecked, MdCheckCircle } from 'react-icons/md'

// redux
import { quoteLisUpdateLtlSelectedRate } from 'redux/ducks/quoteList'

// hooks
import { useSelector, useDispatch } from 'react-redux'

// components
import { IsRoleAllowed } from 'tools'
const ErrorCard = ({ carrierName, errorMessage }) => {
  const classes = useStyles()
  return (
    <Box display='flex' justifyContent='end' className={classes.errorDetail}>
      <Typography variant='body1' style={{ color: '#b12323' }}>
        {`${carrierName} ${errorMessage}` || 'No carrier response'}
      </Typography>
    </Box>
  )
}

const RateDetail = ({ item, title }) => {
  let totalCarrier = 0
  let totalCustomer = 0
  const classes = useStyles()
  return (
    <>
      <Typography style={{ color: 'white', padding: '.2em .5em', fontWeight: 600 }}>{title}</Typography>
      <div className={classes.divTable}>
        <div className={classes.divTableHeading}>
          <div className={classes.divTableHead}>&nbsp;</div>
          {IsRoleAllowed('tucker', <div className={classes.divTableHead}>Carrier Price</div>)}
          <div className={classes.divTableHead}>Customer Price</div>
        </div>
        <div className={classes.divTableBody}>
          {
            item.carrierCharges.map((charge, index) => {
              totalCarrier += charge.carrierPrice
              totalCustomer += charge.customerPrice
              return (
                <div key={index} className={classes.divTableRow}>
                  <div className={classes.divTableCell}>{charge.description.toUpperCase()}</div>
                  {IsRoleAllowed('tucker', <div className={classes.divTableCell}>{charge.carrierPrice}</div>)}
                  <div className={classes.divTableCell}>{charge.customerPrice}</div>
                </div>
              )
            })
          }
          <div className={classes.divTableRow}>
            <div className={classes.divTableCell}>TOTAL</div>
            {IsRoleAllowed('tucker', <div className={classes.divTableCell}>{totalCarrier.toFixed(2)}</div>)}
            <div className={classes.divTableCell}>{totalCustomer.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </>
  )
}

ErrorCard.propTypes = {
  carrierName: PropTypes.string,
  errorMessage: PropTypes.string,
}

function RatesTable ({ ratesData, selectedIndex, setSelectedIndex, refId = null }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isQuoteListView = useSelector(state => state.entities.quoteList.ltl.data?.length > 0)

  const handleSelectChange = (row) => {
    setSelectedIndex(row._id)
    if (isQuoteListView) {
      dispatch(quoteLisUpdateLtlSelectedRate(refId, row._id))
    }
  }

  return (
    <div>
      <div className={classes.headerRow}>
        <div className={classes.headNameCell}>Carrier</div>
        <div className={classes.headCell}>Services</div>
        <div className={classes.headCell}>Days in Transit</div>
        {IsRoleAllowed('tucker', <div className={classes.headCell}>Carrier Price</div>)}
        <div className={classes.headCell}>Customer Price</div>
        <div className={classes.headCellCheckbox}> </div>
      </div>
      <div style={{ width: '100%' }}>
        {
          ratesData.map((rate, index) => (

            <Tooltip
              TransitionComponent={Zoom}
              PopperProps={{
                disablePortal: true,
              }}
              key={index}
              id={'tooltip' + index}
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              arrow
              title={<RateDetail title='Rate Detail' item={rate} />}
            >
              <div className={classes.row}>
                <div className={classes.headNameCell}>{rate?.carrierName}</div>
                <div className={classes.headCell}><div className={classes.serviceCell}>{rate?.serviceType}</div></div>
                <div className={classes.headCell}>{rate?.daysInTransit} {rate?.daysInTransit !== 1 ? 'Days' : 'Day'}</div>
                {IsRoleAllowed('tucker', <div className={classes.headCell}>${rate?.carrierPrice}</div>)}
                <div className={classes.headCell}>${rate?.customerPrice}</div>
                <div className={classes.headCellCheckbox}>
                  {rate.error ? <span className={classes.error}>Error</span>
                    : (
                      <Checkbox
                        icon={<MdRadioButtonUnchecked className={classes.icon} />}
                        checkedIcon={<MdCheckCircle className={classes.icon} />}
                        checked={index === selectedIndex}
                        onClick={() => {
                          if (!rate.error) {
                            handleSelectChange(rate)
                          }
                        }}
                      />)}
                </div>
              </div>
            </Tooltip>
          ))
        }
      </div>
    </div>
  )
}

export default RatesTable

RatesTable.propTypes = {
  ratesData: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  refId: PropTypes.string,
}
