import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    marginTop: '5px',
    color: theme.palette.common.second3,
    fontWeight: '500',
    minWidth: 'min-content',
  },
  text: {
    lineHeight: 'normal',
    fontWeight: '300',
    marginRight: '1rem',
    marginTop: 2,
    marginLeft: '1rem',
  },
  value: {
    fontStyle: 'italic',
  },
}))

export const Root = styled.div`
  display: flex;
  border: solid 1px ${({ theme }) => theme.palette.common.second6};
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 2rem;
`
