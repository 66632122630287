export const shipmentRoutes = {
  createTl: '/api/v1/shipment/tl',
  createLtl: '/api/v1/shipment/ltl',
  getLtl: '/api/v1/shipment/ltl',
  getTl: '/api/v1/shipment/tl',
  getById: '/api/v1/shipment/:shipmentId',
  getLtlRates: '/api/v1/shipment/ltl/rates',
  createPdfLtl: '/api/v1/shipment/ltl/bol',
  createPdfTl: '/api/v1/shipment/tl/bol',
  getPdfTl: '/api/v1/shipment/tl/bol/:shipmentId',
  getPdfLtl: '/api/v1/shipment/ltl/bol/:shipmentId',
  getDocument: '/api/v1/shipment/:shipmentId/documents/:documentId',
  sendEmail: '/api/v1/shipment/contactEmail',
  updateStatus: '/api/v1/shipment/tl/cancel/:shipmentId',
  setTlComment: '/api/v1/shipment/tl/:shipmentId/comment',
}
