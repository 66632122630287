//  libraries
import React, { useEffect, useState, useRef, useMemo } from 'react'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'
import MaterialTable, { MTableBody } from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { shipmentListFetch, cleanState } from 'redux/ducks/shipmentList'

//  tools
import { dateFormat, dateFormatUtc } from 'tools'

// styles
import { tableStyles, useStyles } from './styles'
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io'
import { globalTableOptions } from 'styles/globalTableStyles'
import { tableIcons } from 'styles/tableIcons'

// components
import FilterBar from 'components/common/filterBar'
import ContainerTable from 'components/common/tableFragments/containerTable'
import Menu from 'components/common/tableFragments/Menu'
import SelectStatus from 'components/common/tableFragments/cells/SelectStatus'
import FormattedCell from 'components/common/tableFragments/cells/shipmentFormatedCell'
import LastLocationCell from 'components/common/tableFragments/cells/LastLocationCell'
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'
import Pagination from 'components/common/tableFragments/pagination'
import TableLoading from 'components/common/tableFragments/tableLoading'
import SortingHeader from 'components/common/tableFragments/sortingHeader'

function TlShipmentList () {
  const classes = useStyles()
  const tableRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const { data = [], page = 0, totalCount = 0, loading = true } = useSelector(state => state.entities.shipmentList.tl)
  const filters = useSelector(state => state.ui.filters)
  const setPage = page - 1 < 0 ? 0 : page - 1

  const dataWithPoNumber = useMemo(() => data.map(value => {
    let poNumber, bolNumber, refNumber
    value.references.forEach(reference => {
      switch (reference.ref) {
        case 'Purchase Order Number':
          poNumber = reference.value
          break
        case 'BOL Number':
          bolNumber = reference.value
          break
        case 'Reference number':
          refNumber = reference.value
          break
      }
    })
    return {
      ...value,
      poNumber,
      bolNumber,
      refNumber,
    }
  }), [data])

  useEffect(() => {
    dispatch(shipmentListFetch('Tl', { params: { limit, page: 1, ...filters } }))
    return () => {
      dispatch(cleanState())
    }
  }, [])

  return (
    <>
      <FilterBar
        filters={['searchBar', 'status', 'creationDate', 'pickupDate', 'deliveryDate', 'priceRange', 'transportType']}
        thunkAction={(data, callback) => shipmentListFetch('Tl', data, null, callback)}
        propFilters={{ limit, page }}
        module='shipment'
        addTypeFilter
      />
      <Pagination
        count={totalCount}
        rowsPerPage={limit}
        page={setPage}
        onChangePage={(evt, page) => {
          dispatch(shipmentListFetch('Tl', { params: { ...filters, limit, page: page + 1 } }))
        }}
        onChangeRowsPerPage={(evt, arg) => {
          setLimit(evt.target.value)
          tableRef.current.onChangeRowsPerPage(evt)
          dispatch(shipmentListFetch('Tl', { params: { ...filters, limit: evt.target.value, page } }))
        }}
      />
      <MaterialTable
        tableRef={tableRef}
        isLoading={loading}
        data={dataWithPoNumber}
        icons={tableIcons}
        actions={[
          {
            icon: 'save',
            tooltip: 'More Options',
            position: 'row',
            onClick: () => { },
          },
        ]}
        columns={[
          {
            title: 'Pro Number',
            sortKeyValue: 'pro',
            render: rowData => {
              return rowData.mlShipmentId ? (
                <Typography
                  align='center' className={classes.redirect} onClick={() => {
                    ReactGA.event({
                      category: 'Detail',
                      action: '/shipment/details/tl/',
                      label: rowData.refId,
                    })
                    history.push(`/shipment/details/tl/${rowData.refId}`)
                  }}
                >
                  {rowData.mlShipmentId}
                </Typography>
              ) : <Typography align='center'>...</Typography>
            },
          },
          {
            title: '# reference',
            sortKeyValue: 'poNumber',
            render: rowData => (
              <Typography variant='body2' className={classes.reference}>{rowData.refNumber}</Typography>
            ),
          },
          {
            title: 'BOL number',
            sortKeyValue: 'poNumber',
            render: rowData => (
              <Typography variant='body2' className={classes.reference}>{rowData.bolNumber}</Typography>
            ),
          },
          {
            title: 'PO number',
            sortKeyValue: 'poNumber',
            render: rowData => (
              <Typography variant='body2' className={classes.reference}>{rowData.poNumber}</Typography>
            ),
          },
          {
            title: 'Status',
            sortKeyValue: 'status',
            render: rowData => <SelectStatus status={rowData.general?.status} />,
          },
          {
            title: 'Origin',
            sortKeyValue: 'origin',
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundUp className={classes.originIcon} />}
                mainText={rowData.origin?.city}
                secondMainText={rowData.origin?.state}
                secondaryText={rowData.origin?.postalCode}
              />
            ),
          },
          {
            title: 'Destination',
            sortKeyValue: 'destination',
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundDown className={classes.destinationIcon} />}
                mainText={rowData.destination?.city}
                secondMainText={rowData.destination?.state}
                secondaryText={rowData.destination?.postalCode}
              />
            ),
          },
          {
            title: 'Customer Code',
            sorting: false,
            render: rowData => (
              <Typography align='center' className={classes.primaryColor}>
                {rowData.company?.mlCompanyId || '...'}
              </Typography>
            ),
          },
          {
            title: 'Pickup Date',
            sortKeyValue: 'pickup',
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.pickup?.startDate)}
                time={dateFormat(rowData.shipping?.pickup?.startDate, 'HH:mm')}
              />
            ),
          },
          {
            title: 'Delivery Date',
            sortKeyValue: 'delivery',
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.delivery?.startDate)}
                time={dateFormat(rowData.shipping?.delivery?.startDate, 'HH:mm')}
              />
            ),
          },
          {
            title: 'Last Location',
            sorting: false,
            render: rowData => {
              return (
                <LastLocationCell
                  mainText={rowData.general?.currentCity}
                  secondMainText={rowData.general?.currentState}
                  firstSecondaryText={dateFormatUtc(rowData.general?.lastUpdate, 'MM-DD-YYYY HH:MM')}
                />
              )
            },
          },
        ]}
        components={{
          Pagination: (props) => {
            return (
              <TablePagination
                {...props}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(shipmentListFetch('Tl', { params: { ...filters, limit, page: page + 1 } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  props.onChangeRowsPerPage(evt)
                  dispatch(shipmentListFetch('Tl', { params: { ...filters, limit: evt.target.value, page } }))
                }}
              />
            )
          },
          Body: (props) => (
            <>
              {loading && totalCount > 0 && <TableLoading />}
              <MTableBody {...props} />
            </>
          ),
          Action: ({ data }) => <Menu item={data} />,
          Container: props => (
            <ContainerTable>
              {props.children}
            </ContainerTable>
          ),
          Header: (props) => <SortingHeader tableProps={props} limit={limit} listOf='shipment' />,
        }}
        options={{
          ...globalTableOptions,
          showTitle: false,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          sorting: true,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No Shipments to display',
          },
        }}
      />
    </>
  )
}

export default TlShipmentList
