import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 12,
    color: theme.palette.common.second3,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 400,
  },
}))

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`
