import makeStyles from '@material-ui/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: '5rem',
    textTransform: 'none',
    fontSize: 12,
    background: 'white',
  },
  linearProgress: {
    flexGrow: 1,
  },
  paddingY: {
    padding: '3px 0',
  },
  commentButton: {
    marginTop: '3rem',
  },
  error: {
    fontSize: 15,
    color: 'red',
  },
}))
