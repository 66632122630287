//  libraries
import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

//  utils
import { FETCH } from 'API/fetch'
import { dashboardRoutes } from 'API/routes/dashboard'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//  redux
import { filtersUpdate } from 'redux/ducks/filters'

//  styles
import { useStyles } from './styles'
import { writeDocumentIcon } from 'assets'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

function Dashboard () {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const selectorData = useSelector(state => state.session.data)
  const { role, companies } = selectorData
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState({
    quotesData: [],
    shipmentsData: [],
    accountingData: [],
  })

  useEffect(() => {
    FETCH('get', `${dashboardRoutes.getData}/${companies[0].mlCompanyId}`)
      .then(({ data }) => {
        const dataResponse = data.data
        setData({
          quotesData: dataResponse.filter(item => item.type.toLowerCase() === 'quote'),
          shipmentsData: dataResponse.filter(item => item.type.toLowerCase() === 'shipment')
            .map(item => ( // replace 'At Pick' by 'At Pickup' // Because the customer
              { ...item, name: item.name.replace('At Pick', 'At Pickup') }
            )),
          accountingData: dataResponse.filter(item => item.type.toLowerCase() === 'accounting'),
        })
      })
      .catch(() => { setIsError(true) })
      .finally(() => { setLoading(false) })
  }, [])

  const handleAccountingCard = () => { history.push('/accounting') }

  const card = (titleCard, value, isShipment = false) => {
    let path = ''
    if (role === 'sales' && !isShipment) {
      path = '/sales/list'
    } else {
      const modality = isShipment ? 'shipment' : 'quote'
      path = `/${modality}/list/tl`
    }
    const handleClick = () => {
      dispatch(filtersUpdate({ status: [titleCard.replace('At Pick-up', 'At Pick')] }))
      history.push(path)
    }
    return (
      <div className={`${classes.card} ${classes.hoverEffect}`} onClick={handleClick} key={titleCard}>
        <ImageNotDraggable externalImage={writeDocumentIcon} width='23px' style={{ marginRight: 20 }} />
        <Typography variant='h3' color='primary' gutterBottom>{titleCard}</Typography>
        <Typography variant='h2' color='primary' className={classes.valueText}>{value}</Typography>
      </div>
    )
  }

  const accountingCard = (titleCard, value) => (
    <div className={`${classes.card} ${classes.hoverEffect}`} onClick={handleAccountingCard} key={titleCard}>
      <Typography variant='h3' color='primary' gutterBottom>{titleCard}</Typography>
      <Typography variant='h2' color='primary' className={classes.maxWidth}>{value} {Number(value) !== 0 && 'USD'}</Typography>
      {Number(value) !== 0 && <Typography className={classes.dollarLabel}>Dollars</Typography>}
    </div>
  )

  const renderSubtitle = (subtitle) => (
    <>
      <Typography variant='h1' color='primary' className={classes.subtitle}>{subtitle}</Typography>
      <hr className={classes.hr} />
    </>
  )

  return (
    <>
      {loading && <div className={classes.maxWidth}><LinearProgress className={classes.maxWidth} /></div>}
      <Box display='flex' justifyContent='center' flexWrap='wrap' width='100%' p='2rem'>
        {data.shipmentsData.length > 0 && (
          <section className={classes.section}>
            {renderSubtitle('Shipments')}
            {data.shipmentsData.map((item) => card(item.name === 'At Pickup' ? 'At Pick-up' : item.name, item.value, true))}
          </section>
        )}
        <section className={classes.section}>
          {data.quotesData.length > 0 && (
            <>
              {renderSubtitle('Quotes')}
              {data.quotesData.map((item) => card(item.name, item.value))}
            </>
          )}
          {(role !== 'tucker' && role !== 'sales' && data.accountingData.length > 0) && (
            <>
              {renderSubtitle('Accounting')}
              {data.accountingData.map((item, index) => accountingCard(item.name, item.value, index))}
            </>
          )}
        </section>
        {isError && <Typography variant='h1' color='primary'>Information not available</Typography>}
      </Box>
    </>
  )
}

export default Dashboard
