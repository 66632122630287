//  libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'

//  styles
import { Container } from './styles'
import { FaAngleRight } from 'react-icons/fa'

export default function Item ({ children, handleClick }) {
  const [hovered, setHovered] = useState(false)
  const toggleHover = () => setHovered(!hovered)

  return (
    <Container
      hovered={hovered}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={handleClick}
    >
      <div>{children.label}</div>{children.options && children.options.length > 0 && <FaAngleRight />}
    </Container>

  )
}

Item.propTypes = {
  children: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
}
