import TYPES from './types'

export const stepperUpdate = payload => ({
  type: TYPES.STEPPER.UPDATE,
  payload,
})

export const stepperClear = () => ({
  type: TYPES.STEPPER.CLEAR,
})
