//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import { MTableHeader } from 'material-table'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

//  redux
import { filtersUpdate, filtersDeleteAdvancedFilters } from 'redux/ducks/filters'
import { quoteListFetch } from 'redux/ducks/quoteList'
import { shipmentListFetch } from 'redux/ducks/shipmentList'
import { CompanyListRequest } from 'redux/ducks/companyList'
import { UserListRequest } from 'redux/ducks/userList'

const reduxActionFetch = {
  quote: quoteListFetch,
  shipment: shipmentListFetch,
  company: CompanyListRequest,
  user: UserListRequest,
}

export default function SortingHeader ({ tableProps, limit, listOf }) {
  const dispatch = useDispatch()
  const isSalesList = useLocation().pathname.includes('/sales/list')
  const typeShipment = useParams().type === 'tl' || isSalesList ? 'Tl' : 'Ltl'
  const currentFilters = useSelector(state => state.ui.filters)
  const { page = 0 } = useSelector(state => state.entities.quoteList.tl)
  const companyId = useSelector(state => state.ui.companySelector.companyChild.value)
  const reduxAction = reduxActionFetch[listOf]

  const handleOrderChange = (orderBy, orderDirection) => {
    tableProps.onOrderChange(orderBy, orderDirection)
    const sortFilter = {
      ...currentFilters,
      sort: `${orderDirection === 'asc' ? '' : '-'}${tableProps.columns[orderBy].sortKeyValue}`,
    }

    if (orderDirection === '') {
      dispatch(filtersDeleteAdvancedFilters(['sort']))
      delete sortFilter.sort
    } else {
      dispatch(filtersUpdate({ ...sortFilter }))
    }

    const payload = {
      params: { ...sortFilter, limit, page },
    }

    if (listOf === 'user') {
      payload.companyId = companyId
    }

    const actionArgs = [payload]

    if (['quote', 'shipment'].includes(listOf)) {
      actionArgs.unshift(typeShipment)
    }

    dispatch(reduxAction(...actionArgs))
  }

  return <MTableHeader {...tableProps} onOrderChange={handleOrderChange} />
}

SortingHeader.propTypes = {
  tableProps: PropTypes.any.isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  listOf: PropTypes.oneOf(Object.keys(reduxActionFetch)).isRequired,
}
