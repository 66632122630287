import TYPES from './types'

/**
 * TL
 */
export const ShipmentDetailsTlRequest = (payload) => ({
  type: TYPES.SHIPMENT_DETAILS.TL.FETCH.REQUEST,
  payload,
})
export const ShipmentDetailsTlSucceeded = payload => ({
  type: TYPES.SHIPMENT_DETAILS.TL.FETCH.SUCCEEDED,
  payload,
})
export const ShipmentDetailsTlFailed = payload => ({
  type: TYPES.SHIPMENT_DETAILS.TL.FETCH.FAILED,
  payload,
})
/**
 * NEW MESSAGE
 */
export const ShipmentDetailsSetMessageRequest = (payload, quoteId, callback, errorCallback) => ({
  type: TYPES.SHIPMENT_DETAILS.SET_MESSAGE.FETCH.REQUEST,
  payload,
  quoteId,
  callback,
  errorCallback,
})
export const ShipmentDetailsSetMessageSucceeded = (payload, commentType) => ({
  type: TYPES.SHIPMENT_DETAILS.SET_MESSAGE.FETCH.SUCCEEDED,
  payload,
  commentType,
})
export const ShipmentDetailsSetMessageFailed = payload => ({
  type: TYPES.SHIPMENT_DETAILS.SET_MESSAGE.FETCH.FAILED,
  payload,
})
/**
 * LTL
 */
export const ShipmentDetailsLtlRequest = (payload) => ({
  type: TYPES.SHIPMENT_DETAILS.LTL.FETCH.REQUEST,
  payload,
})
export const ShipmentDetailsLtlSucceeded = payload => ({
  type: TYPES.SHIPMENT_DETAILS.LTL.FETCH.SUCCEEDED,
  payload,
})
export const ShipmentDetailsLtlFailed = payload => ({
  type: TYPES.SHIPMENT_DETAILS.LTL.FETCH.FAILED,
  payload,
})
/**
 * GENERAL
 */
export const cleanErrors = () => ({
  type: TYPES.SHIPMENT_DETAILS.CLEAN.ERRORS,
})
export const cleanState = () => ({
  type: TYPES.SHIPMENT_DETAILS.CLEAN.STATE,
})
