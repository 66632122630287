//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

//  components
import FormInputs from 'components/common/formInputs'
import ImageSelector from 'components/admin/common/imageSelector'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

//  mocks
import rolesList from 'mocks/roles.json'

//  styles
import { useStyles, Root, Element, ElementImage, customStyles } from './styles'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

export default function UserInfo () {
  const classes = useStyles()
  const { register, unregister, errors, setValue, clearErrors } =
    useFormContext()
  const isEditAction = useParams().action === 'edit'
  const {
    name,
    email,
    role: formRole = '',
    password,
    confirm,
    image: defaultImage,
  } = useSelector((state) => state.newForm)
  const { companyParent: parent, companyChild: child } = useSelector(
    (state) => state.ui.companySelector,
  )
  const [image, setImage] = useState(defaultImage)
  const [roles, setRoles] = useState(rolesList)
  const [show, setShow] = useState(false)
  const [role, setRole] = useState()

  const newForm = useSelector((state) => state.newForm)

  useEffect(() => {
    register('role')
    register('image')
    setValue('image', defaultImage)
    return () => {
      unregister('role')
      unregister('image')
    }
    //  eslint-disable-next-line
  }, []);

  /**
   * Show role options according with 3 user cases:
   * 1. when is tucker company, show tucker and sales
   * 2. when is a parent without children, only show Account
   * 3. when is a children, only show customer
   */
  useEffect(() => {
    let newRoles = []
    newRoles = rolesList.filter(({ value }) => {
      if (parent.value === '5f36d688fd875233200cead6') {
        return value !== 'userAdmin'
      } else {
        return (value !== 'tucker' && value !== 'sales')
      }
    })

    setRoles(newRoles)
    const newValue =
      newRoles.find((el) => el.value === formRole)?.value || newRoles[0].value
    setValue('role', newValue)
    setRole(rolesList.find((role) => role.value === newValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent.value, child.value, parent.label])

  const handleChangeRole = (data) => {
    if ((!isEditAction) && (data.value === 'userAdmin')) {
      newForm.permissions = {
        quote: true,
        accounting: true,
        shipment: true,
      }
    }
    setRole(data)
    setValue('role', data.value)
    clearErrors('role')
  }

  const handleSaveImage = (event) => {
    const file = event.target.files[0]
    const fileURL = URL.createObjectURL(file)
    setImage(fileURL)
    setValue('image', file)
  }

  return (
    <Root>
      <ElementImage>
        <ImageSelector image={image} handleSaveImage={handleSaveImage} />
      </ElementImage>
      <Element>
        <Typography variant='h4' className={classes.title} component='label'>
          Name
        </Typography>
        <FormInputs.Input
          name='name'
          inputRef={register}
          showError
          error={errors.name}
          defaultValue={name}
        />
      </Element>
      <Element>
        <Typography variant='h4' className={classes.title} component='label'>
          Email
        </Typography>
        <FormInputs.Input
          name='email'
          inputRef={register}
          showError
          error={errors.email}
          defaultValue={email}
          inputProps={{
            autoComplete: 'aaaanope',
          }}
        />
      </Element>
      <Element>
        <Typography variant='h4' className={classes.title} component='label'>
          Role
        </Typography>
        <Select
          name='role'
          isSearchable
          value={role}
          onChange={handleChangeRole}
          options={roles}
          styles={customStyles}
          classNamePrefix='select'
          error={errors.role}
        />
        <FormHelperText className={classes.messageText} error={!!errors.role}>
          {errors.role?.message}
        </FormHelperText>
      </Element>
      {isEditAction && (
        <>
          <Element>
            <Typography
              variant='h4'
              className={classes.title}
              component='label'
            >
              New Password
            </Typography>
            <FormInputs.Input
              name='password'
              inputRef={register}
              showError
              error={errors.password}
              type={show ? 'text' : 'password'}
              defaultValue={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {show ? (
                      <AiFillEyeInvisible
                        color='#CBD6E2'
                        onClick={() => setShow(!show)}
                      />
                    ) : (
                      <AiFillEye
                        color='#CBD6E2'
                        onClick={() => setShow(!show)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Element>
          <Element>
            <Typography
              variant='h4'
              className={classes.title}
              component='label'
            >
              Confirm Password
            </Typography>
            <FormInputs.Input
              name='confirm'
              inputRef={register}
              showError
              error={errors.confirm}
              type={show ? 'text' : 'password'}
              defaultValue={confirm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {show ? (
                      <AiFillEyeInvisible
                        color='#CBD6E2'
                        onClick={() => setShow(!show)}
                      />
                    ) : (
                      <AiFillEye
                        color='#CBD6E2'
                        onClick={() => setShow(!show)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Element>
        </>
      )}
    </Root>
  )
}
