//  libraries
import React, { useState } from 'react'
import ReactGA from 'react-ga'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import { yupResolver } from '@hookform/resolvers'

//  components
import TabPanel from 'components/common/tabPanel'
import UserInfo from './userInfo'
import Permissions from './permissions'
import Buttons from './buttons'
import MessageModal from 'components/common/messageModal'

//  hooks
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

//  redux
import { newFormUpdate } from 'redux/ducks/newForm'
import { fetchPost } from 'redux/ducks/postData'
import { modalUpdate } from 'redux/ducks/modal'

//  tools
import { userValidation, convertToFormatData } from 'tools'

//  styles
import { useStyles, Form } from './styles'
import { Dialog, DialogContent, IconButton } from '@material-ui/core'
import { MdClear } from 'react-icons/md'

export default function UseForm () {
  const EMPTY_COMPANY = 'No company was selected, please fill in the field.'
  const BAD_EMAIL = 'You have entered an invalid e-mail address. Please try again.'
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [action] = location.pathname.split('/').splice(-1)
  const role = useSelector(state => state.session.data.role)
  const [posTab, setPosTab] = useState('form')
  const methods = useForm({ resolver: yupResolver(userValidation(posTab, action)) })
  const companyId = useSelector(state => state.ui.companySelector.companyChild.value)
  const company = useSelector(state => state.entities.companyInfo?.data || {})
  const newForm = useSelector(state => state.newForm)
  const [emptyCompanyFieldError, setEmptyCompanyFieldError] = useState(false)
  const [errorString, seterrorString] = useState('')
  const handleChange = (event, newValue) => {
    const type = 'change'
    methods.handleSubmit(handleSave(type, newValue))()
  }

  const ExcludeEmails = [
    'leigh.h.sauter@gmail.com',
    'deanna9612@gmail.com',
    'emmyschildt@gmail.com',
  ]

  const handleSubmit = (event) => {
    event.preventDefault()
    const type = 'submit'
    const newValue = posTab === 'form' ? 'additional' : 'form'
    methods.handleSubmit(handleSave(type, newValue))()
  }

  const checkEmail = () => {
    const email = company.email
    if (!email) {
      return false
    }
    const sepatatorCompanyIndex = email.indexOf('@')
    if (sepatatorCompanyIndex < 0) {
      return false
    }
    const userEmail = newForm.email.toLowerCase()
    const sepatatoUserIndex = userEmail.indexOf('@')
    if (ExcludeEmails.includes(userEmail)) {
      return true
    }

    const validators = [email.substring(sepatatorCompanyIndex + 1, email.length)]
    if (role === 'tucker') {
      validators.push('tuckerco.com')
    }
    return validators.includes(userEmail.substring(sepatatoUserIndex + 1, userEmail.length))
  }

  const handleSave = (type, newValue) => (values) => {
    dispatch(newFormUpdate(values))
    if (type === 'submit' && posTab === 'additional') {
      const form = convertToFormatData({ ...newForm, email: newForm.email.toLowerCase(), ...values, companyId })
      if (!companyId) {
        seterrorString(EMPTY_COMPANY)
        setEmptyCompanyFieldError(true)
        return
      }
      if (!checkEmail()) {
        seterrorString(BAD_EMAIL)
        setEmptyCompanyFieldError(true)
        return
      }
      if (action === 'new') {
        ReactGA.event({
          category: 'Create',
          action: 'New User',
        })
        dispatch(fetchPost('adminService.createUser', form, '', () => {
          dispatch(modalUpdate(true))
        }))
      } else {
        ReactGA.event({
          category: 'Update',
          action: 'Edit User',
        })
        dispatch(fetchPost('adminService.editUser', form, '', () => {
          dispatch(modalUpdate(true))
        }))
      }
    } else {
      setPosTab(newValue)
    }
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit}>
        <AppBar position='static' color='secondary' className={classes.appBar}>
          <Tabs
            value={posTab}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab value='form' label={action === 'new' ? 'User Information' : 'Edit User Information'} classes={{ root: classes.tab, selected: classes.activeTab }} />,
            <Tab value='additional' label='Permissions' classes={{ root: classes.tab, selected: classes.activeTab }} />,
          </Tabs>
        </AppBar>
        <TabPanel value={posTab} index='form'>
          <UserInfo />
        </TabPanel>
        <TabPanel value={posTab} index='additional'>
          <Permissions />
        </TabPanel>
        <Buttons tab={posTab} />
        <MessageModal
          title='SUCCESS!'
          path='entities.postData.errors'
          body={action === 'new' ? 'This user was sucessfully created.' : 'The user was sucessfully updated.'}
          handleCancelButton={() => { history.goBack() }}
          handleSubmitButton={() => { history.goBack() }}
          submitText='OK'
        />
      </Form>
      <Dialog open={emptyCompanyFieldError}>
        <IconButton className={classes.closeButton} onClick={() => { setEmptyCompanyFieldError(false) }}>
          <MdClear />
        </IconButton>
        <DialogContent className={classes.emptyCompanyErrorMessage}>
          {errorString}
        </DialogContent>
      </Dialog>
    </FormProvider>
  )
}
