import styled, { css } from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    padding-bottom: 2rem;
  `}
`

export const Top = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 1200px;
    width: 100%;
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    display: grid;
    grid-template: max-content / 40px 700px;
    align-self: center;
    justify-content: center;
    max-width: 1200px;
    max-height: 800px;
    width: 100%;
    margin: 20px 0px;
    @media (min-width: 1280px) {
      grid-template: max-content / minmax(500px, 30%) 1fr;
    }
  `}
`

export const Burger = styled(GiHamburgerMenu)`
  color: #4B2367;
  font-size: 40px;
  margin-top: 16px;
`
