import keyMirror from 'keymirror-nested'

export default keyMirror({
  PRODUCTS: {
    FETCH: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
  },
})
