//  libraries
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import PropTypes from 'prop-types'

//  styles
import { useStyles } from './styles'
import { FaPlus, FaMinus } from 'react-icons/fa'

export default function Card ({ element, selectedArrayOfProducts, setSelectedArrayOfProducts }) {
  let newSelected = [...selectedArrayOfProducts]
  element.quantity = 0
  const [selected = {}] = selectedArrayOfProducts.filter(el => el._id === element._id)
  const found = Object.keys(selected).length
  const data = found ? selected : element
  const classes = useStyles()

  const addProduct = () => {
    if (found) {
      const indexOfProduct = newSelected.findIndex(product => product._id === element._id)
      newSelected[indexOfProduct].quantity += 1
      newSelected[indexOfProduct].totalWeight = newSelected[indexOfProduct].weight * newSelected[indexOfProduct].quantity
      newSelected[indexOfProduct].isOverWeight = newSelected[indexOfProduct].totalWeight > 5000
    } else {
      newSelected.push({
        ...element,
        totalWeight: element.weight,
        quantity: 1,
        isOverWeight: false,
        isOverSize: (element.height > 90 || element.length > 102 || element.width > 102),
      })
    }

    setSelectedArrayOfProducts(newSelected)
  }

  const deleteProduct = () => {
    if (found) {
      const indexOfProduct = newSelected.findIndex(product => product._id === element._id)
      newSelected[indexOfProduct].quantity -= 1
      newSelected[indexOfProduct].totalWeight = newSelected[indexOfProduct].quantity * newSelected[indexOfProduct].weight
      newSelected[indexOfProduct].isOverWeight = newSelected[indexOfProduct].totalWeight > 5000
    }
    newSelected = newSelected.filter(element => {
      return !(element.quantity === 0)
    })
    setSelectedArrayOfProducts(newSelected)
  }

  const classProductName = data.totalQuantity ? classes.styledTitle : classes.styledTitleUnselected
  const classTotalWeight = data.totalQuantity ? classes.weight : classes.weightUnselected
  const classTotalQuantity = data.totalQuantity ? classes.quantity : classes.quantityUnselected

  return (
    <Grid
      container
      className={classnames(
        classes.styledGrid,
        data.quantity > 0 ? classes.selected : null,
      )}
    >
      <Grid direction='row' justify='center' alignItems='flex-start' item container className={classes.styledGridItem}>
        <Grid container item xs={9}>
          <Grid item xs={9}>
            <Typography align='left' className={classProductName} component='h1' gutterBottom>
              {data.itemName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography align='center' className={classes.totalWeight} component='h1' variant='h2' gutterBottom>
            Total Weight
          </Typography>
          <Typography align='center' className={classTotalWeight} component='h1' variant='body1' gutterBottom>
            {data.totalWeight || 0}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.styledGridItem}>
        <Grid container item direction='row' justify='flex-start' alignItems='flex-start' xs={9}>
          <Grid item xs={1}>
            <Typography align='center' className={`${classes.styledGridFirstItem} ${classes.descriptionItems}`} component='h1' variant='h2' gutterBottom>
              Pieces
            </Typography>
            <Typography align='center' className={`${classes.styledGridFirstItemValue} ${classes.styledGridItemValue}`} component='h1' variant='h2' gutterBottom>
              {data.pieces}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align='center' className={classes.descriptionItems} component='h1' variant='h2' gutterBottom>
              Class
            </Typography>
            <Typography align='center' component='h1' variant='h2' className={classes.styledGridItemValue} gutterBottom>
              {data.freightClass}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align='center' className={classes.descriptionItems} component='h1' variant='h2' gutterBottom>
              Width
            </Typography>
            <Typography align='center' className={classes.styledGridItemValue} component='h1' variant='h2' gutterBottom>
              {data.width}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align='center' className={classes.descriptionItems} component='h1' variant='h2' gutterBottom>
              height
            </Typography>
            <Typography align='center' className={classes.styledGridItemValue} component='h1' variant='h2' gutterBottom>
              {data.height}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align='center' className={classes.descriptionItems} component='h1' variant='h2' gutterBottom>
              length
            </Typography>
            <Typography align='center' className={classes.styledGridItemValue} component='h1' variant='h2' gutterBottom>
              {data.length}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align='center' className={classes.descriptionItems} component='h1' variant='h2' gutterBottom>
              Net Weight
            </Typography>
            <Typography align='center' className={classes.styledGridItemValue} component='h1' variant='h2' gutterBottom>
              {data.weight}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align='center' className={classes.descriptionItems} component='h1' variant='h2' gutterBottom>
              Package Type
            </Typography>
            <Typography align='center' className={classes.styledGridItemValue} component='h1' variant='h2' gutterBottom>
              {data.packageType}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction='row' justify='center' alignItems='center' item xs={3}>
          <Grid item xs={12}>
            <Typography align='center' className={classes.totalWeight} component='h1' variant='h2' gutterBottom>
              Quantity
            </Typography>
          </Grid>
          <Button onClick={deleteProduct} className={classes.styledButtonProducts} variant='contained'><FaMinus className={classes.icon} /></Button>
          <Typography align='center' className={classTotalQuantity} component='h1' variant='h2' gutterBottom>
            {data.quantity || 0}
          </Typography>
          <Button onClick={addProduct} className={classes.styledButtonProducts} variant='contained'><FaPlus className={classes.icon} /></Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

Card.propTypes = {
  element: PropTypes.object,
  setSelectedArrayOfProducts: PropTypes.func,
  selectedArrayOfProducts: PropTypes.array,
}
