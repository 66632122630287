//  libraries
import React, { useState } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'

//  tools
import { formatToLocalString } from 'tools'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { useStyles, Root, Row } from './styles'
import { MdKeyboardArrowDown } from 'react-icons/md'

export default function LtlItem () {
  const [expanded, setExpanded] = useState(false)
  const data = useSelector(state => state.entities.shipmentDetails.data.items || [])
  const classes = useStyles()

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Root>
      {
        data.map((item, index) => {
          return (
            <ExpansionPanel key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} className={classes.panel}>
              <ExpansionPanelSummary
                classes={{
                  content: classes.summary,
                }}
                expandIcon={<MdKeyboardArrowDown />}
              >
                <Typography variant='h3' className={classes.heading}>{item.name || 'Product Description'}</Typography>
                <Row>
                  <div className='exp-right'>
                    <Typography variant='h4' className={classes.title} align='center'>Pieces</Typography>
                    <Typography variant='h4' className={classes.text} align='center'>{item.pieces || '...'}</Typography>
                  </div>
                  <div className='exp-right'>
                    <Typography variant='h4' className={classes.title} align='center'>Dimension</Typography>
                    <Typography variant='h4' className={classes.text} align='center'>
                      {formatToLocalString(item.dimension?.length)}/{formatToLocalString(item.dimension?.width)}/{formatToLocalString(item.dimension?.height)}
                    </Typography>
                  </div>
                  <div className='exp-right'>
                    <Typography variant='h4' className={classes.title} align='center'>Quantity</Typography>
                    <Typography variant='h4' className={classes.text} align='center'>{item.quantity || '...'}</Typography>
                  </div>
                  <div>
                    <Typography variant='h4' className={classes.title} align='center'>Total Weight</Typography>
                    <Typography variant='h4' className={classes.text} align='center'>{formatToLocalString(item.weight) || '...'}</Typography>
                  </div>
                </Row>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                <div>
                  <Typography variant='h4' className={classes.title}>Description</Typography>
                  <Typography variant='h4' className={classes.text}>{item.description || '...'}</Typography>
                </div>
                <Row>
                  <div className='exp-right'>
                    <Typography variant='h4' className={classes.title}>Package Type</Typography>
                    <Typography variant='h4' className={classes.text}>{item.packageType || '...'}</Typography>
                  </div>
                  <div className='exp-right'>
                    <Typography variant='h4' className={classes.title}>Freight Class</Typography>
                    <Typography variant='h4' className={classes.text}>{item.freightClass || '...'}</Typography>
                  </div>
                  <div>
                    <Typography variant='h4' className={classes.title} align='center'>NMFC</Typography>
                    <Typography variant='h4' className={classes.text} align='center'>{item.nmfc || '...'}</Typography>
                  </div>
                </Row>
                <Row>
                  <Typography variant='h5' className={classes.text2}>{item.hazmat && 'Hazmat'}</Typography>
                  <Typography variant='h5' className={classes.text2}>{item.isNew && 'New'}</Typography>
                </Row>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })
      }
    </Root>
  )
}
