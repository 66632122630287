//  libraries
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { persistStore } from 'redux-persist'
import API from 'API'

//  redux
import { cleanState, loginSucceeded } from 'redux/ducks/user'

//  reducers
import rootReducer from 'redux/reducers'

//  saga
import rootSaga from 'redux/saga'

//  tools
import { clearSession, clearStorage, loadStorage } from 'tools'

const composeEnhancers = (process.env.NODE_ENV === 'development')
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose

const sagaMiddleWare = createSagaMiddleware()
const middleware = [sagaMiddleWare, thunkMiddleware.withExtraArgument(API)]
const enhancers = [applyMiddleware(...middleware)]

const store = createStore(rootReducer, composeEnhancers(...enhancers))
const persist = persistStore(store)
sagaMiddleWare.run(rootSaga)

if (loadStorage()) {
  API.authService
    .checkUser()
    .then((resp) => {
      store.dispatch(loginSucceeded(resp.data))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        clearSession()
        clearStorage()
        store.dispatch(cleanState())
      }
    })
}

export { store, persist }
