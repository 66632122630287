//  libraries
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

//  redux
import { bolUpdate } from 'redux/ducks/bol'
import { modalUpdate } from 'redux/ducks/modal'

// hooks
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

// styles
import { useStyles, Container } from './styles'

//  components
import MessageModal from 'components/common/messageModal'

import API from 'API'

export default function Bol () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { type } = useParams()
  const { addToast, removeToast } = useToasts()
  const { save, id } = useSelector(state => state.ui.bol)
  const newForm = useSelector(state => state.newForm)
  const companies = useSelector(state => state.ui.companySelector)
  const [pdfUrl, setPdfUrl] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)

  if (!Object.keys(newForm).length) {
    history.push(`/shipment/new/${type}`)
  }

  useEffect(() => {
    let printPdf
    if (type === 'ltl') {
      printPdf = API.shipmentService.createPdfLtlPreview({ ...newForm, ...companies })
    } else {
      printPdf = API.shipmentService.createPdfTLPreview({ ...newForm, ...companies })
    }
    request(printPdf, false)
    return () => {
      removeToast('error-toast-saveShipment-BOL-view')
    }
    //  eslint-disable-next-line
  }, [type])

  const request = (endPoint, open) => {
    endPoint.then(response => {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      setPdfUrl(fileURL)
      open && window.open(fileURL)
    }, error => {
      console.log(error)
    })
  }

  const renderBol = () => {
    let printPdf
    if (type === 'ltl') {
      printPdf = API.shipmentService.getPdfLTL(id)
    } else {
      printPdf = API.shipmentService.getPdfTL(id)
    }
    request(printPdf, true)
  }

  const saveShipment = () => {
    setSaveLoading(true)
    let create
    if (type === 'ltl') {
      create = API.shipmentService.createLtl({ ...newForm, ...companies })
    } else {
      create = API.shipmentService.createTl({ ...newForm, ...companies })
    }
    create.then(response => {
      dispatch(bolUpdate({
        save: true,
        id: response.data.data.refId,
      }))
      dispatch(modalUpdate(true))
      setTimeout(() => {
        history.push('/shipment/list/tl')
      }, 7000)
    })
      .catch(() => {
        addToast('There was an error in this shipment creation.', {
          appearance: 'error',
          autoDismiss: true,
          id: 'error-toast-saveShipment-BOL-view',
        })
      })
      .finally(() => setSaveLoading(false))
  }

  const editPrevious = () => {
    history.push(`/shipment/convert/${type}`)
  }

  // const handleCreateAnother = () => {
  //   history.push(`/quote/list/${type}`)
  // }

  return (
    <Container>
      <iframe title='pdf' width='100%' height='100%' src={pdfUrl} />
      <Box display='flex' justifyContent='space-between' flexWrap='wrap' alignSelf='start'>
        <Button
          onClick={saveShipment}
          className={classes.button}
          variant='contained'
          disabled={save || saveLoading}
          fullWidth
        >
          Submit shipment
          {saveLoading && (
            <CircularProgress color='primary' className={classes.spinner} />
          )}
        </Button>
        <Button
          onClick={renderBol}
          className={`${classes.button} ${classes.twoButtonsInline}`}
          disabled={!save}
          variant='contained'
        >
          Open Bol
        </Button>
        <Button
          onClick={editPrevious}
          className={`${classes.button} ${classes.twoButtonsInline}`}
          variant='contained'
          disabled={save}
        >
          Edit previous
        </Button>
        {/* <Button onClick={handleCreateAnother} className={classes.button} variant='contained'>
          Create Another
        </Button> */}
      </Box>
      <MessageModal
        title='SHIPMENT GENERATED!'
        body='Your shipment is on its way! What happens next: a Tucker employee will quickly verify your shipment details to make sure everything’s as it should be. Then, your shipment is accepted and assigned.'
        submitText='Got it!'
        handleSubmitButton={() => { dispatch(modalUpdate(false)) }}
      />
    </Container>
  )
}
