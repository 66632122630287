import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  AccountingInfoSucceeded,
  AccountingInfoFailed,
} from './actions'
import API from 'API'

function * fetchAccountingInfo (action) {
  try {
    const { data } = yield call(API.accountingService.getInfo, action.payload)
    yield put(AccountingInfoSucceeded({
      ...data,
      data: {
        ...data.data,
        billingHistory: data.data.billingHistory.filter(billingHistoryItem => billingHistoryItem.name !== 'Current Credit'),
      },
    }))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(AccountingInfoFailed(newError))
  }
}

export default function * rootAccountingInfo () {
  yield takeEvery(TYPES.ACCOUNTING_INFO.FETCH.REQUEST, fetchAccountingInfo)
}
