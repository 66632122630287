import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  spanTotal: {
    fontSize: 16,
    color: theme.palette.common.second3,
    marginLeft: 12,
  },
  spanItem: {
    fontSize: 16,
    color: theme.palette.common.second3,
  },
  panelDetail: {
    border: '1px solid #4A236655',
    borderStyle: 'inset',
  },
  headCell: {
    display: 'flex',
    fontSize: '12pt',
    flexBasis: 1,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  headNameCell: {
    display: 'flex',
    flexBasis: 2,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  headerRow: {
    display: 'flex',
    fontSize: '12pt',
    fontWeight: 500,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    paddingBottom: '1em',
    boxShadow: '0px 1px 2px rgba(0,0,0,.1)',
  },
  row: {
    display: 'flex',
    cursor: 'pointer',
    fontSize: '12pt',
    fontWeight: 500,
    padding: '1em 0',
    boxShadow: '0 0 0 rgba(0,0,0,.1)',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      borderColor: '#F4F4F4',
    },
    '&:select': {
      backgroundColor: '#DDDDDD',
      borderColor: '#DDDDDD',
    },
  },
  tooltip: {
    minWidth: 600,
    maxWidth: 650,
    backgroundColor: theme.palette.primary.main,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  detailContainer: {
    width: '100%',
    padding: '1em .5em',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  detailContent: {
    display: 'flex',
    width: '100%',
    fontSize: '12pt',
  },
  detailBlock: {
    display: 'flex',
    flexBasis: '15%',
    flexDirection: 'column',
  },
  detailBlockHeader: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    color: '#5A5A5A',
  },
  detaiDescriptionlBlock: {
    display: 'flex',
    flexBasis: '55%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  detailBlockContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingTop: '.5em',
  },
  detailDescBlockContent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },

}))

export const cellTitleStyle = {
  fontWeight: 500,
  color: 'lightgrey',
  paddingBottom: '5px',
}

export const cellTextStyle = {
  fontWeight: 450,
  color: '#A9A9A9',
}
