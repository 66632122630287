//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  tools
import { formatToMassUnit,formatToCurrency } from 'tools'

//  components
import ItemIcons from 'components/common/tableFragments/itemsIcons'
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'

//  styles
import { useStyles, Root, Header, Content } from './styles'

const getTransportType = type => ({
  'dry-van': 'Dry Van',
  reefer: 'Reefer',
  flatbed: 'Flatbed',
})[type] || type

export default function DetailsForm2 ({ data }) {
  const classes = useStyles()
  const { items: [item], shipping, transportType } = data
  const { packageDimensions: { length = '...', width = '...', height = '...' } = {} } = item

  const details = (({
    hazmat = false,
    isNewItem = false,
    isHighValue = false,
    isOverSize = false,
    isExpedited = false,
    isOverWeight = false,
    isTwentyFourHours = false,
    isTarping = false,
  }) => ({
    hazmat,
    isNewItem,
    isHighValue,
    isOverSize,
    isExpedited,
    isOverWeight,
    isTwentyFourHours,
    isTarping,
  }))(item)

  const renderTransportType = () => {
    if (getTransportType(transportType) === 'Reefer') {
      return (
        <QuoteDateTD
          date={getTransportType(transportType)}
          time={`${item.tempMin || item.temperature || '...'} °F - 
            ${item.tempMax || item.temperature || '...'} °F`}
        />
      )
    } else if (getTransportType(transportType) === 'Flatbed') {
      return (
        <QuoteDateTD
          date={getTransportType(transportType)}
          time={item.tarpingValue ? `Tarping of ${item.tarpingValue} ft` : null}
        />
      )
    } else {
      return (
        <Typography variant='h4' className={classes.text} align='center'>
          {getTransportType(transportType)}
        </Typography>
      )
    }
  }

  return (
    <Root>
      <Header>
        <Typography variant='h5' className={classes.title} align='center'>Pieces</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Weight</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Value</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Details</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Driver Type</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Loads</Typography>
        <Typography variant='h5' className={classes.title} align='center'>Equipment Type</Typography>
      </Header>
      <Content>
        <div className={`${classes.text} ${classes.multipleElements}`}>
          {item?.totalPieces.toLocaleString('en-US') || '...'}
          {transportType === 'flatbed' && (
            <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
              <Typography className={classes.miniSpan} >Dimensions (inches)</Typography>
              <Typography className={classes.miniSpan}>
                {`${Number(width).toFixed(2).toLocaleString('en-US')} x ${Number(height).toFixed(2).toLocaleString('en-US')} x ${Number(length).toFixed(2).toLocaleString('en-US')}`}
              </Typography>
            </div>
          )}
        </div>
        <Typography variant='h4' className={classes.text} align='center'>
          {`${formatToMassUnit(item?.totalWeight)} lbs` || '...'}
        </Typography>
        <Typography variant='h4' className={classes.text} align='center'>
          {item?.value ? `${formatToCurrency(item.value)} USD` : '...'}
        </Typography>
        <ItemIcons details={details} tarpingValue={item.tarpingValue} hightValue={item.value} />
        <Typography variant='h4' className={classes.text} align='center'>{shipping?.driver || '...'}</Typography>
        <div className={`${classes.text} ${classes.multipleElements}`}>
          <Typography variant='h4' align='center'>{ formatToMassUnit(shipping?.loads) || '...'}</Typography>
          {shipping?.loadRange && <Typography align='center'>{shipping?.loadRange}</Typography>}
        </div>
        {renderTransportType()}
      </Content>
    </Root>
  )
}

DetailsForm2.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      totalPieces: PropTypes.number,
      totalWeight: PropTypes.number,
      value: PropTypes.number,
      commodity: PropTypes.string,
      hazmat: PropTypes.bool,
      isNewItem: PropTypes.bool,
      isHighValue: PropTypes.bool,
      isOverSize: PropTypes.bool,
      isExpedited: PropTypes.bool,
      isOverWeight: PropTypes.bool,
      isTwentyFourHours: PropTypes.bool,
    })),
    shipping: PropTypes.shape({
      driver: PropTypes.string,
      instructionsShipper: PropTypes.string,
    }),
  }),
}
