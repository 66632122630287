// libraries
import * as yup from 'yup'
import moment from 'moment'

/**
 * Helpers
 */
export const convertToNumber = val => Number(val)

export const twoDecimalNumber = val => /^\d{0,}?(?:\.\d{0,2})?$/.test(val)

export const momentToTime = val => moment.isMoment(val) ? moment(val).format('HH:mm') : null

/**
 * Schemas
 */

export const salesRepRateSchema = yup.object().shape({
  isPercentageUplift: yup.boolean()
    .required(true, 'Please select a type uplift'),
  upliftValue: yup.number()
    .when('isPercentageUplift', {
      is: true,
      then: yup.number()
        .typeError('Invalid uplift')
        .min(1, 'Min value allowed is 1%')
        .max(99, 'Max percentage allowed is 99%. You can change to plain value')
        .required('Uplift is required'),
      otherwise: yup.number()
        .typeError('Invalid uplift')
        .min(1, 'Min value allowed is 1%')
        .max(50000, 'Max plain value allowed is 50.000 You can change to percentage')
        .required('Uplift is required'),
    }),
  price: yup.number()
    .typeError('Invalid cost')
    .min(0.01, 'Min 0.01 allowed')
    .max(50000, 'Max 50.000 allowed')
    .required('Cost is required')
    .test('two-decimals', 'Invalid two decimals', twoDecimalNumber),
  pickupDate: yup.date()
    .typeError('Pickup Date is required')
    .required('Pickup Date is required'),
  pickupTime: yup.mixed()
    .typeError('Pickup time is required')
    .required('Pickup time is required')
    .transform(momentToTime),
  expirationDate: yup.date()
    .typeError('Expiration date is required')
    .required('Expiration date is required'),
  daysInTransit: yup.number()
    .typeError('Invalid Number of Days')
    .min(1, 'Min 1 day allowed')
    .max(100, 'Max 100 days allowed')
    .transform(convertToNumber)
    .required('Invalid days'),
  totalRate: yup.mixed(),
})

export const createProduct = yup.object().shape({
  itemName: yup.string()
    .max(25, 'Max 25 characters allowed')
    .required('Product name is required'),
  packageType: yup.string()
    .required('Package type is required'),
  freightClass: yup.string()
    .required('Freight class is required'),
  nmfcCode: yup.string()
    .notRequired()
    .transform((value) => value === '' ? undefined : value)
    .matches(/^[0-9]{5}-[0-9]{2}$/, 'nmfc must be a valid number'),
  pieces: yup.number()
    .typeError('Invalid value')
    .positive()
    .required('pieces is required'),
  weight: yup.number()
    .typeError('Invalid value')
    .positive()
    .required('weight is required'),
  quantity: yup.number()
    .typeError('Invalid value')
    .positive()
    .required('quantity is required'),
  width: yup.number()
    .typeError('Invalid value')
    .positive()
    .required('width is required'),
  height: yup.number()
    .typeError('Invalid value')
    .positive()
    .required('height is required'),
  length: yup.number()
    .typeError('Invalid value')
    .positive()
    .required('length is required'),
  description: yup.string()
    .max(200, 'Max 200 characters allowed')
    .required('description is required'),
  hazmat: yup.boolean(),
  isNewItem: yup.boolean(),
})

export const userValidation = (schema, action) => {
  const validation = {
    form: action === 'new' ? createUser : editUser,
    additional: permissions,
  }
  return validation[schema]
}

const createUser = yup.object().shape({
  name: yup.string()
    .required('this field is required'),
  email: yup.string()
    .email('email not valid')
    .required('this field is required'),
  role: yup.string()
    .required('this field is required')
    .oneOf(['tucker', 'account', 'logistic', 'sales', 'shipment', 'userAdmin'], 'the option selected is not valid'),
  image: yup.mixed(),
})

const editUser = yup.object().shape({
  name: yup.string()
    .required('this field is required'),
  email: yup.string()
    .email('email not valid')
    .required('this field is required'),
  role: yup.string()
    .required('this field is required')
    .oneOf(['tucker', 'account', 'logistic', 'sales', 'shipment', 'userAdmin'], 'the option selected is not valid'),
  password: yup
    .string()
    .notRequired()
    .transform((value) => value === '' ? undefined : value)
    .min(8, 'It must have 8 characters or more')
    .max(25, 'It must have 25 characters or less')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 'Password must have contain at least one mayus letter, number and special character'),
  confirm: yup.string()
    .notRequired()
    .transform((value) => value === '' ? undefined : value)
    .min(8, 'It must have 8 characters or more')
    .max(25, 'It must have 25 characters or less')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 'Password must have contain at least one mayus letter, number and special character')
    .when('password', (password, schema) => (
      schema.test('confirm', 'Passwords should match!', value => value === password)
    )),
  image: yup.mixed(),
})

const permissions = yup.object().shape({
  permissions: yup.object().shape({
    shipment: yup.boolean(),
    quote: yup.boolean(),
    accounting: yup.boolean(),
  }),
})

export const profileValidation = yup.object().shape({
  name: yup.string()
    .required('this field is required'),
  email: yup.string()
    .email('email not valid')
    .required('this field is required'),
  photo: yup.mixed(),
})

export const sendEmailValidation = yup.object().shape({
  to: yup.string()
    .email('email not valid')
    .required('this field is required'),
  subject: yup.string()
    .required('this field is required'),
  body: yup.string()
    .required('this field is required'),
})
