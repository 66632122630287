//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  tools
import { formatToLocalString,formatToMassUnit, formatToCurrency } from 'tools'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { useStyles, Root, Content, Row } from './styles'

export default function TlItem () {
  const classes = useStyles()
  const data = useSelector(state => state.entities.shipmentDetails.data.items || [])

  return (
    <Root>
      {data.map((item, index) => (
        <Content key={index}>
          <Typography variant='h3' className={classes.heading}>Product Description</Typography>
          <Row>
            <div className='right'>
              <Typography variant='h4' className={classes.title} align='center'>Pieces</Typography>
              <Typography variant='h4' className={classes.text} align='center'>{`${formatToMassUnit(item.pieces)}` || '...'}</Typography>
            </div>
            <div className='right'>
              <Typography variant='h4' className={classes.title} align='center'>Weight</Typography>
              <Typography variant='h4' className={classes.text} align='center'>{`${item?.weight ? formatToLocalString(item.weight) + ' lbs' : '...'}`}</Typography>
            </div>
            <div>
              <Typography variant='h4' className={classes.title} align='center'>Value</Typography>
              <Typography variant='h4' className={classes.text} align='center'>{`${formatToCurrency(item.value) || '...'}`}</Typography>
            </div>
          </Row>
          <Row>
            {item.tempMin &&
              <div className='right'>
                <Typography variant='h4' align='center' className={classes.title}>Min. Temperature</Typography>
                <Typography variant='h4' align='center' className={classes.text}> {item.tempMin} °F </Typography>
              </div>}
            {item.tempMax &&
              <div className='right'>
                <Typography variant='h4' align='center' className={classes.title}>Max. Temperature</Typography>
                <Typography variant='h4' align='center' className={classes.text}> {item.tempMax} °F </Typography>
              </div>}
          </Row>
          <div>
            <Typography variant='h4' className={classes.title}>Commodity</Typography>
            <Typography variant='h4' className={classes.text}> {item.commodity || '...'} </Typography>
          </div>
          <Row>
            <Typography variant='h5' className={classes.text2}>{item.hazmat && 'Hazmat'}</Typography>
            <Typography variant='h5' className={classes.text2}>{item.highValue && 'High Value'}</Typography>
            <Typography variant='h5' className={classes.text2}>{item.isNew && 'Is Used'}</Typography>
          </Row>
        </Content>
      ))}
    </Root>
  )
}
