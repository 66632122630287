import { makeStyles } from '@material-ui/core'
import { globalTableStyles } from 'styles/globalTableStyles'

export const useStyles = makeStyles(theme => ({
  companyAvatar: {
    background: '#630A5C',
    marginRight: '5px',
    width: 50,
    height: 50,
  },
  link: {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.dark,
    },
  },
  originIcon: {
    fontSize: 'inherit',
    color: '#2CB556',
  },
  destinationIcon: {
    fontSize: 'inherit',
    color: '#EA5D5D',
  },
  primaryColor: {
    color: '#4b2367',
    textTransform: 'capitalize',
  },
  textStatus: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  icon: {
    fontSize: '2rem',
    color: theme.palette.common.second3,
  },
}))

export const tableStyles = {
  ...globalTableStyles,
}
