import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  navBar: {
    width: '100%',
    height: '60px',
    zIndex: 1200,
    '& + *': {
      flexGrow: 1,
      overflow: 'auto',
    },
  },
  appBar: {
    height: '100%',
    boxShadow: 'unset',
    borderBottom: '1px solid #EBEDF8',
  },
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '110px 1fr 50px',
    minHeight: 59,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '110px 1fr auto',
    },
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
    paddingLeft: 48,
    paddingRight: 48,
  },
  logo: {
    cursor: 'pointer',
  },
  profile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'solid 1px #CBD6E2',
    padding: '2px 1px',
    borderRadius: 8,
    cursor: 'pointer',
    width: '100%',
    height: 44,
    marginLeft: 30,
    flexWrap: 'no-wrap',
  },
  icon: {
    color: '#637280',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fontSize: 15,
  },
  iconFont: {
    fontFamily: '"Roboto", sans-serif',
    marginLeft: 15,
  },
  bell: {
    height: '23px',
  },
  badge: {
    '& .MuiBadge-badge': {
      padding: 0,
      fontSize: '0.70em',
    },
  },
  avatar: {
    fontSize: 11,
    backgroundColor: '#3A1852',
    width: 31,
    height: 31,
    margin: '0 15px',
  },
  role: {
    marginRight: '2vw'
  }
}))

export const DesktopContainer = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: flex;
  }
`

export const MobileContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 960px) {
    display: none;
  }
`
