//  hooks
import { useSelector } from 'react-redux'

const IsRoleAllowed = (role, component) => {
  const sessionRole = useSelector(state => state.session.data.role)

  if (sessionRole === role) return component
  return null
}

export default IsRoleAllowed
