import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    color: 'white',
    textTransform: 'none',
    width: '8rem',
    height: '2.5rem',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondaryButton: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: '14px',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    width: '8rem',
    height: '2.5rem',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.grey,
    },
  },
  spinner: {
    position: 'absolute',
    margin: 0,
  },
}))

export const Root = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: flex-start;
  min-height: 80px;
`

export const FiltersContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    & .searchBar {
      margin-top: 3;
      width: 20rem;
      & .MuiOutlinedInput-root {
        & fieldset {
          border-color: '#CBD6E2';
        }
      }
      & .MuiOutlinedInput-root:focus {
        border-color: red;
      }
    }
    & .date {
      width: 160px;
      & .MuiInputBase-root {
        font-size: 14px;
        color: ${theme.palette.common.second3};
        font-weight: 400;
        padding: 0;
        background: #FFFFFF;
      }
      & .MuiFormHelperText-root {
        font-size: 10px;
      }
    }
  `}
`

export const FilterElement = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
`
