import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => (
  {
    h3: {
      margin: '2rem 0 1rem 1rem',
      color: theme.palette.primary.main,
    },
    backArrowLink: {
      color: theme.palette.common.second3,
    },
    title: {
      color: theme.palette.primary.main,
    },
    hr: {
      width: '100%',
      height: '10px',
      backgroundColor: theme.palette.primary.main,
      marginBottom: 24,

    },
    backButton: {
      backgroundColor: '#cbd6e2',
      fontSize: 18,
      width: 180,
      height: 50,
      textTransform: 'none',
      boxShadow: '0 0 0 0',
    },
    createShipment: {
      fontSize: 18,
      width: 180,
      height: 50,
      textTransform: 'none',
    },
    saveQuote: {
      backgroundColor: theme.palette.common.orange, // orange
      fontSize: 18,
      width: 180,
      height: 50,
      textTransform: 'none',
    },
    spinner: {
      position: 'absolute',
      right: '40%',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }
))
