import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useStyles = makeStyles(theme => ({
  heading: {
    margin: '12px 20px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    lineHeight: 'normal',
  },
}))

export const Root = styled.div`
  width: 100%;
  padding-top: 60px;
  display: grid;
  justify-content: center;
  padding-bottom: 80px;
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
`

export const Content = styled.div`
  padding-top: 42px;
  max-width: 1200px;
  display: grid;
  row-gap: 55px;
`
