// libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
//  components
import ItemIcons from 'components/common/tableFragments/itemsIcons'
import { formatToCurrency } from 'tools'
// styles
import { useStyles } from './styles'

const getTransportType = type => ({
  'dry-van': 'Dry_Van',
  reefer: 'Reefer',
  flatbed: 'Flatbed',
})[type] || type



function Items ({ itemsData = {} }) {
  const classes = useStyles()
  const details = itemsData.items.slice()
  const { items: [item], shipping, transportType } = itemsData
  const { packageDimensions: { length = '...', width = '...', height = '...' } = {} } = item

  const columns = {
    Flatbed: ["Value","Number of Loads","Special Instructions","Dimensions"],
    Reefer: ["Value","Number of Loads","Special Instructions","Temp. Range"],
    DryVan:["Value","Number of Loads","Special Instructions"," "]
  }

  function formatWeight(rawWeight){
    if(rawWeight) return new Intl.NumberFormat('en-US').format(rawWeight)        
  }

  const renderTransportType = (itemData) => {
    const {transportType} = itemData;    
    if (transportType === 'reefer') {
    return columns.Reefer.map(item=> <th key={item}>{item}</th>
    )

    } else if (transportType === 'flatbed') {
      { 
      return columns.Flatbed.map(item => <th key={item}>{item}</th>
      )}
    } else if (transportType === 'dry-van') {
    return columns.DryVan.map(item=> <th key={item}>{item}</th>
      )
    }
  }

  const renderTransportData = () => {

    if (getTransportType(transportType) === 'Reefer') {
      return (
    <> 
    <Typography component='td'>{`${formatToCurrency(item.value)}` || '...'}</Typography>
    <Typography component='td'>{` ${ parseInt(shipping.loads) === 1 ? formatWeight(shipping.loads) : formatWeight(shipping.loads) + ' ' + shipping.loadRange }` || '...'}</Typography>
    <Typography component='td'>{shipping.instructionsShipper || '...'}</Typography>
    <Typography component='td'>{`${item.tempMin + ' to ' +  item.tempMax + '°F'}`}  </Typography>        
    </>
      )      
    } else if (getTransportType(transportType) === 'Flatbed') {
      return (
        <>
        <Typography component='td'>{`${formatToCurrency(item.value)}` || '...'}</Typography>
        <Typography component='td'>{` ${ parseInt(shipping.loads) === 1 ? formatWeight(shipping.loads) : formatWeight(shipping.loads) + ' ' + shipping.loadRange }` || '...'}</Typography>
        <Typography component='td'>{shipping.instructionsShipper || '...'}</Typography>
        { shipping.instructionsShipper.length > 30 ?  
        <Typography component='td' >
          {`${width.toFixed(2)} x ${height.toFixed(2)} x ${length.toFixed(2)} inches`}
        </Typography>              
        :
        <Typography component='span' className={classes.miniSpan}>
          {`${width} x ${height} x ${length} inches`}
        </Typography>              
        }
       </>
      )
    } else if(getTransportType(transportType) === 'Dry_Van') {
      return (
        <>        
        <Typography component='td'>{`${formatToCurrency(item.value)}` || '...'}</Typography>
        <Typography component='td'>{` ${ parseInt(shipping.loads) === 1 ? formatWeight(shipping.loads) : formatWeight(shipping.loads) + ' ' + shipping.loadRange }` || '...'}</Typography>        
        <Typography component='td'>{shipping.instructionsShipper || '...'}</Typography>
        
       </>
      )
    }    
  }

  return (
    <CardContent className={classes.container}>
      <table>
        <thead className={classes.header}>
          <tr>
            <th>Units Number</th>
            <th>Weight</th>
            <th>Details</th>
            <th>Commodity</th>
          </tr>
        </thead>
        <tbody>
          {details.map((item, ind) => {
            const details = (({
              hazmat = false,
              isNewItem = false,
              isHighValue = false,
              isOverSize = false,
              isExpedited = false,
              isOverWeight = false,
              isTwentyFourHours = false,
              isTarping = false,
            }) => ({
              hazmat,
              isNewItem,
              isHighValue,
              isOverSize,
              isExpedited,
              isOverWeight,
              isTwentyFourHours,
              isTarping,
            }))(item)
            
            return (
              <tr className={classes.row} key={ind}>
                <Typography component='td'>{ `${formatWeight(item.totalPieces)}` || '...'}</Typography>
                <Typography component='td'>{item.totalWeight ? `${formatWeight(item.totalWeight)} lbs` : '...'}</Typography>
                <Typography component='td'>
                  <ItemIcons details={details} tarpingValue={item.tarpingValue || ''} hightValue={item.value}/>
                </Typography>
                <Typography component='td' className={classes.commodityCell}>{item.commodity || '...'}</Typography>
              </tr>
            )
          })}
        </tbody>
      </table>
      <br/>
      <table>
        <thead className={classes.header}>
          <tr>
            {renderTransportType(itemsData)}           
          </tr>
        </thead>
        <tbody>
        <tr className={classes.row}>
           {renderTransportData()}
       </tr>
        </tbody>
      </table>
    </CardContent>
  )
}

export default Items
