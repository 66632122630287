//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import Comments from 'components/quote/tl/salesRep/tlDetailsSalesRep/comments'

//  hooks
import { useSelector } from 'react-redux'

//  redux
import { ShipmentDetailsSetMessageRequest } from 'redux/ducks/shipmentDetails'

//  styles
import { documentIcon } from 'assets'
import { useStyles, Root, HeadingContainer, Content } from './styles'

export default function CommentsCard () {
  const classes = useStyles()
  const { comments = {}, refId = '' } = useSelector(state => state.entities.shipmentDetails.data || {})

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={documentIcon} width='25px' />
        <Typography variant='h2' className={classes.heading}>
          Comments
        </Typography>
      </HeadingContainer>
      <Content>
        <Comments
          comments={comments}
          quoteId={refId}
          loading={comments.loading || false}
          setMessageRequestAction={ShipmentDetailsSetMessageRequest}
        />
      </Content>
    </Root>
  )
}
