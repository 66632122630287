//  libraries
import React from 'react'
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar'
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton'
import ToolbarText from '@material-ui/pickers/_shared/ToolbarText'

//  styles
import { useStyles } from './styles'

const CustomToolbar = function (props) {
  const {
    date,
    isLandscape,
    openView,
    setOpenView,
    title,
  } = props
  const classes = useStyles()
  const stringDate = date._d.toString()
  const timeZone = stringDate.slice(stringDate.indexOf('(') + 1, stringDate.indexOf(')'))
  const timeZoneCode = stringDate.slice(stringDate.indexOf('(') - 9, stringDate.indexOf('(') - 1)

  const handleChangeViewClick = (view) => (e) => {
    setOpenView(view)
  }

  return (
    <PickerToolbar title={title} isLandscape={isLandscape} className={classes.toolbar}>
      <ToolbarButton
        onClick={handleChangeViewClick('hours')}
        variant='h2'
        selected={openView === 'hours'}
        label={date.format('HH')}
      />
      <ToolbarText
        label=':'
        variant='h2'
      />
      <ToolbarButton
        onClick={handleChangeViewClick('minutes')}
        variant='h2'
        selected={openView === 'minutes'}
        label={date.format('mm')}
      />
      {/* <Box display='flex' flexDirection='column' mx='1rem'>
        <ToolbarButton
          disableRipple
          onClick={() => setAm(true)}
          selected={am === true}
          label='AM'
          variant='h4'
        />
        <ToolbarButton
          disableRipple
          onClick={() => setAm(false)}
          // onClick={handleChangeViewClick2}
          selected={am === false}
          label='PM'
          variant='h4'
        />
      </Box> */}
      <ToolbarText
        className={classes.toolbarText}
        label={timeZone}
      />
      <ToolbarText
        className={classes.toolbarText}
        label={timeZoneCode}
      />
    </PickerToolbar>
  )
}

export default CustomToolbar
