import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: 12,
    color: theme.palette.common.second3,
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  button: {
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'none',
    width: 140,
    height: 50,
    color: theme.palette.common.second8,
    backgroundColor: '#290c3d',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  spinner: {
    position: 'absolute',
    margin: 0,
  },
}))

export const Form = styled.form`
  display: grid;
  justify-items: center;
  grid-template-rows: auto 1fr 50px;
  margin-top: 60px;
  flex-grow: 0.5;
`

export const Element = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 10px;
  padding-top: 20px;
`}
`
