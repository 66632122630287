//  libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Typography, Switch, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

//  hooks
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

//  redux
import { fetchPost } from 'redux/ducks/postData'
import { UserListUpdateStatus } from 'redux/ducks/userList'
import { resendActivationEmailRequest } from 'redux/ducks/user'
import { deleteItemList } from 'redux/ducks/userList/actions'

//  tools
import { convertToFormatData } from 'tools'

//  styles
import { useStyles } from './styles'
import { FaRegTrashAlt } from 'react-icons/fa'

export default function CustomSwitch ({ id, status, expiredActivationToken = false, firstActivation = false }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addToast, removeToast } = useToasts()
  const [resendLoading, setResendLoading] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    return () => {
      removeToast('feedback-resend')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeSwitch = (event) => {
    const checked = event.target.checked
    dispatch(fetchPost('adminService.editUser', convertToFormatData({
      isActive: checked,
      userId: id,
    }), null, () => dispatch(UserListUpdateStatus({ id, value: checked }))))
  }

  const handleResendButton = () => {
    setResendLoading(true)
    dispatch(resendActivationEmailRequest({ userId: id }, (appearance = 'error', message = 'Fail: The email was not sent') => {
      addToast(message, {
        appearance,
        autoDismiss: true,
        id: 'feedback-resend',
      })
      setResendLoading(false)
    }))
  }

  const handleDeleteButton = (event) => {
    event.stopPropagation()
    setDeleteModalOpen(true)
    // dispatch(deleteItemList(id))
    // setOpen(true)
  }

  const confirmDeleteUer = (event) => {
    event.stopPropagation()
    setDeleteModalOpen(false)
    dispatch(deleteItemList(id))
  }

  const deleteModalClose = (event) => {
    event.stopPropagation()
    setDeleteModalOpen(false)
  }

  return (
    <div className={classes.container}>
      {firstActivation && (
        <Switch
          key={id}
          name='statusSwitch'
          checked={status}
          color='primary'
          onChange={handleChangeSwitch}
        />
      )}
      {!status && expiredActivationToken && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='body1' color='error' gutterBottom>
            The activation email has expired.
          </Typography>
          <button
            onClick={handleResendButton}
            className={classes.resendButton}
            id='resendButton'
            disabled={resendLoading}
          >
            Resend Email
            {resendLoading && <CircularProgress color='primary' size={20} className={classes.loading} />}
          </button>
        </div>
      )}
      <>
        <IconButton
          id={`delete-${id}`}
          onClick={handleDeleteButton}
          style={{ zIndex: 10 }}
        >
          <FaRegTrashAlt />
        </IconButton>

        <Dialog open={deleteModalOpen} onClick={deleteModalClose} onClose={deleteModalClose}>
          <DialogTitle className={classes.dialogTitle}>Confirm delete user</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogContent}>
              Are you sure you want to delete this user? this action is irreversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.dialogCancel} onClick={deleteModalClose}>Cancel</Button>
            <Button className={classes.dialogConfirm} onClick={confirmDeleteUer}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  )
}

CustomSwitch.propTypes = {
  id: PropTypes.string,
  status: PropTypes.bool,
}
