//  libraries
import React from 'react'

//  components
import FormLogin from 'components/auth/formLogin'
import MessageModal from 'components/common/messageModal'

function Login () {
  return (
    <>
      <FormLogin />
      <MessageModal hideTitle title='catony' path='session.errors' />
    </>
  )
}

export default Login
