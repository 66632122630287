//  libraries
import React, { useState } from 'react'
import MaterialTable from 'material-table'
import PropTypes from 'prop-types'

//  hooks
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

// redux
import { QuoteDetailsMarketRatesRequest } from 'redux/ducks/quoteDetails'

//  tools
import { dateFormat, formatToCurrency } from 'tools'

// styles
import { useStyles, tableStyles, IconContainer } from './styles'
import { globalTableOptions } from 'styles/globalTableStyles'
import { tableIcons } from 'styles/tableIcons'
import { GrUpdate } from 'react-icons/gr'

// components
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'
import ContainerTable from 'components/common/tableFragments/containerTable'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
const MESSAGE = 'In this moment this function is not available. Please try later or contact us.'

function Marketplace (level, dat, truckstop, mcleod) {
  this.level = level
  this.dat = dat
  this.truckstop = truckstop
  this.mcleod = mcleod
}

function MarketRates ({ marketRates, refId, ratesHistory }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const emptyValue = 'N/A'
  let [dat1, dat2, dat3, datOD, truckstop1, truckstop2, truckstop3] = Array(6).fill(emptyValue)
  if (marketRates.length > 0) {
    let ind = {
      "DAT": undefined,
      "Truckstop": undefined,
      "McLeod": undefined,
    } 
    marketRates.map((rate, ind2)=>(
      ind[rate.type] = ind2
    ))
    try {
      if (marketRates[ind.DAT].error === false && ind.DAT !== undefined) {
        dat1 = marketRates[ind.DAT].data[2].value
        dat2 = marketRates[ind.DAT].data[0].value
        dat3 = marketRates[ind.DAT].data[1].value
        datOD = {...marketRates[ind.DAT].optionalData, miles: marketRates[ind.DAT].miles}
      }
    } catch { console.log('Error in DAT response.') }
    try {
      if (marketRates[ind.Truckstop].error === false && ind.Truckstop !== undefined) {
        truckstop1 = marketRates[ind.Truckstop].data[0].value === 0 ? emptyValue : marketRates[ind.Truckstop].data[0].value
        truckstop2 = marketRates[ind.Truckstop].data[2].value === 0 ? emptyValue : marketRates[ind.Truckstop].data[2].value
        truckstop3 = marketRates[ind.Truckstop].data[1].value === 0 ? emptyValue : marketRates[ind.Truckstop].data[1].value
      }
    } catch { console.log('Error in Truscktop response') }
  }

  const data = [
    new Marketplace('Market High', dat1, truckstop1, { ...(ratesHistory?.[0] || {}) }),
    new Marketplace('Market Average', dat2, truckstop2, { ...(ratesHistory?.[1] || {}) }),
    new Marketplace('Market Low', dat3, truckstop3, { ...(ratesHistory?.[2] || {}) }),
  ]

  const handleUpdateMarketRates = () => {
    setLoading(true)
    dispatch(QuoteDetailsMarketRatesRequest({ quoteId: refId },
      () => { setLoading(false) },
      () => {
        setLoading(false)
        addToast(MESSAGE, {
          appearance: 'error',
          autoDismiss: false,
          id: 'error-toast-sale',
        })
      }))
  }

  return (
    <>
      <IconContainer disabled={loading}>
        <GrUpdate display={loading?"none": "block"} onClick={handleUpdateMarketRates} className='icon' />
      </IconContainer>
      <MaterialTable
        isLoading={loading}
        data={data}
        icons={tableIcons}
        columns={[
          {
            title: (
              <Tooltip dir="top" title={
              <div>
                <div>Origin: <span>{datOD?.originGeography ?? emptyValue}</span></div>
                <div>Destination: <span>{datOD?.destinationGeography ?? emptyValue}</span></div>
                <div>Contributor: <span>{datOD?.contributors ?? emptyValue}</span></div>
                <div>Miles: <span>{datOD?.miles ?? emptyValue}</span></div>
                <div>Moves: <span>{datOD?.moves ?? emptyValue}</span></div>
                <div>Daysback: <span>{datOD?.daysback ?? emptyValue}</span></div>
              </div>}>
                <div>
                  <Typography variant='h5' className={classes.title}> DAT </Typography>
                  <Typography variant='h6' className={classes.text}> Previous 7 days  </Typography>
                </div>
              </Tooltip>
            ),
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: (rowData) => {
              return <QuoteDateTD date={rowData.dat === emptyValue ? emptyValue : `${formatToCurrency(rowData.dat)} USD`} time={rowData.level} />},
          },
          {
            title: (
              <>
                <Typography variant='h5' className={classes.title}> Truckstop </Typography>
                <Typography variant='h6' className={classes.text}> Previous 7 days  </Typography>
              </>
            ),
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: (rowData) => <QuoteDateTD date={rowData.truckstop === emptyValue ? emptyValue : `${formatToCurrency(rowData.truckstop)} USD`} time={rowData.level} />,
          },
          {
            title: 'McLeod',
            headerStyle: tableStyles.textAlignCenter,
            cellStyle: tableStyles.cellStyle,
            render: (rowData) => (
              <>
                <Typography> {rowData.mcleod.rate ? `${formatToCurrency(rowData.mcleod.rate)} USD | ${rowData.mcleod.weight} lb` : emptyValue} </Typography>
                <Typography variant='h6' className={classes.text}> {rowData.mcleod.customer}  </Typography>
                <Typography variant='h6' className={classes.text}> {rowData.mcleod.date && dateFormat(rowData.mcleod.date)}  </Typography>
              </>
            ),
          },
        ]}
        components={{
          Container: props => (
            <ContainerTable>
              {props.children}
            </ContainerTable>
          ),
        }}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          paging: false,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No Market rates to display',
          },
        }}
      />
    </>
  )
}

MarketRates.propTypes = {
  marketRates: PropTypes.array,
  refId: PropTypes.string,
  ratesHistory: PropTypes.array,
}

export default MarketRates
