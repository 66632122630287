import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { truckIconOrigin, truckIconDestination } from 'assets'

// styles
import { useStyles } from './styles'
import ImageNotDraggable from 'components/common/imageNotDraggable'
import { IoIosArrowRoundForward } from 'react-icons/io'

const Location = ({ state, city, zipCode, origin = true }) => {
  const classes = useStyles()
  const icon = origin ? truckIconOrigin : truckIconDestination
  return (
    <Box display='flex' flexDirection='row' alignItems='flex-start'>
      <ImageNotDraggable externalImage={icon} width='23px' />
      <Box display='flex' flexDirection='column' alignItems='center' mt={1} ml='1rem'>
        <Typography className={classes.city}>{state}</Typography>
        <Typography className={classes.state}>{city}</Typography>
        <Typography className={classes.zipCode}>{zipCode}</Typography>
      </Box>
    </Box>
  )
}

Location.propTypes = {
  state: PropTypes.string,
  city: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  origin: PropTypes.bool,
}

function Lane ({ from, to }) {
  const classes = useStyles()
  return (
    <Box display='flex' justifyContent='space-around' m={2} width='17rem'>
      <Location state={from.state} city={from.city} zipCode={from.zip} />
      <IoIosArrowRoundForward className={classes.icon} />
      <Location origin={false} state={to.state} city={to.city} zipCode={to.zip} />
    </Box>
  )
}

Lane.propTypes = {
  from: PropTypes.shape({
    state: PropTypes.string,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
  to: PropTypes.shape({
    state: PropTypes.string,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
}

export default Lane
