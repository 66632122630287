//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import AddModal from '../addModal'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  styles
import { accessorialsIcon, accessorialsIconActive } from 'assets'
import { useStyles, Root, HeadingContainer, Content, Item, Optional, ElementImage, ElementText, ElementButton, Total } from './styles'
import { MdAdd } from 'react-icons/md'
import { FaTrashAlt } from 'react-icons/fa'

export default function Desktop () {
  const { setValue, register, unregister } = useFormContext()
  const accessorials = useSelector(state => state.newForm.accessorials || [])
  const [selected, setSelected] = useState(accessorials)
  const [open, setOpen] = useState(false)
  const disabled = useSelector(state => state.newForm.disabled)
  const classes = useStyles()

  useEffect(() => {
    register('accessorials')
    setValue('accessorials', accessorials)
    return () => unregister('accessorials')
    //  eslint-disable-next-line
  }, [])

  const handleClick = () => {
    setOpen(!open)
  }

  const handleDelete = index => () => {
    const newSelected = [...selected]
    newSelected.splice(index, 1)
    setSelected(newSelected)
    setValue('accessorials', newSelected)
  }

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={accessorialsIconActive} width='52px' />
        <Typography variant='h1' className={classes.heading}>
          Accessorials
        </Typography>
      </HeadingContainer>
      {
        selected.length > 0 ? (
          <Content>
            {
              selected.map((item, index) => (
                <Item key={index}>
                  <Typography variant='h3' className={classes.item}>
                    {item.ref} {item.value > 1 && ` (${item.value})`}
                  </Typography>
                  <IconButton disabled={disabled} onClick={handleDelete(index)}>
                    <FaTrashAlt className='icon' />
                  </IconButton>
                </Item>))
            }
          </Content>
        ) : (
          <Optional>
            <ElementImage>
              <ImageNotDraggable externalImage={accessorialsIcon} width='290px' />
            </ElementImage>
            <ElementText>
              <Typography variant='h3' className={classes.text}>
                You can add accessorials for your load
              </Typography>
            </ElementText>
          </Optional>
        )
      }
      <ElementButton>
        {
          selected.length > 0 && (
            <Total>
              <Typography variant='h4' className={classes.total}> Total Accessorials added </Typography>
              <Typography variant='h2' className={classes.value}> {selected.length} </Typography>
            </Total>
          )
        }
        <Fab className={classes.fab} onClick={handleClick} disabled={disabled}>
          <MdAdd className='icon' />
        </Fab>
      </ElementButton>
      <AddModal
        open={open}
        handleClick={handleClick}
        selected={selected}
        setSelected={setSelected}
      />
    </Root>
  )
}
