//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { createSelector } from 'reselect'
import PropTypes from 'prop-types'

//  components
import ZipCodeSelector from './zipCodeSelector'
import FormInputs from 'components/common/formInputs'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { useStyles, Item } from './styles'

export default function LocationForm ({ location }) {
  const { register, errors } = useFormContext()
  const fromTo = createSelector(
    state => state.newForm[location],
    (info) => (info || {}),
  )
  const data = useSelector(fromTo)
  const classes = useStyles()

  return (
    <>
      <Item>
        <Typography variant='h4' className={classes.title} component='label'> ZipCode </Typography>
        <ZipCodeSelector key={location} location={location} value={data.zip || ''} />
      </Item>
      <Item>
        <Typography variant='h4' className={classes.title} component='label'> City </Typography>
        <FormInputs.Input
          key={`${location}.city`}
          name={`${location}.city`}
          inputRef={register(requiredValidator)}
          showError
          error={errors?.[location]?.city}
          disabled
          defaultValue={data?.city}
        />
      </Item>
      <Item>
        <Typography variant='h4' className={classes.title} component='label'> State </Typography>
        <FormInputs.Input
          key={`${location}.state`}
          name={`${location}.state`}
          inputRef={register(requiredValidator)}
          showError
          error={errors?.[location]?.state}
          disabled
          defaultValue={data?.state}
        />
      </Item>
      <Item>
        <Typography variant='h4' className={classes.title} component='label'> Country </Typography>
        <FormInputs.Input
          key={`${location}.country`}
          name={`${location}.country`}
          inputRef={register(requiredValidator)}
          showError
          error={errors?.[location]?.country}
          disabled
          defaultValue={data?.country}
        />
      </Item>
    </>
  )
}

LocationForm.propTypes = {
  location: PropTypes.string.isRequired,
}
