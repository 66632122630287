import { errorMessages } from 'mocks/errorMessages'

export default {
  error: (state, data = {}) => {
    return {
      ...state,
      loading: false,
      success: false,
      errors: [...state.errors, errorMessages[data.error] || data.error],
    }
  },

  loading: (state) => {
    return {
      ...state,
      loading: true,
      errors: [],
    }
  },

  capturedResponse: (state, data = {}) => {
    return {
      ...state,
      ...data,
      loading: false,
      errors: [],
    }
  },

  clean: state => {
    return {
      ...state,
      errors: [],
    }
  },
}
