//  libraries
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'

//  components
import FormInputs from 'components/common/formInputs'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

//  redux
import { McLeodCompaniesRequest } from 'redux/ducks/mcLeodCompanies'

//  tools
import { requiredValidator } from 'tools'

//  styles
import { useStyles, Root } from './styles'
import { AiFillCheckCircle } from 'react-icons/ai'

const message = 'Check the availability of the code'

export default function McLeodInput () {
  const { register, unregister, setValue, errors, clearErrors } = useFormContext()
  const dispatch = useDispatch()
  const mlCompanyId = useSelector(state => state.newForm.mlCompanyId || '')
  const { action } = useParams()
  const { addToast, removeToast } = useToasts()
  const [code, setCode] = useState(mlCompanyId)
  const [check, setCheck] = useState(false)
  const [timeoutID, setTimeoutID] = useState('')
  const [loading, SetLoading] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (action === 'new') {
      register({ name: 'mlCompanyId' }, requiredValidator)
      setValue('mlCompanyId', mlCompanyId)
    }
    return () => {
      unregister('mlCompanyId')
      removeToast('warning-toast-mcleod')
    }
    //  eslint-disable-next-line
  }, [])

  const handleCode = (event) => {
    const value = event.target.value
    setCode(value)
    setValue('mlCompanyId', '')
    setCheck(false)
    SetLoading(true)
    clearTimeout(timeoutID)
    if (value !== '') setTimeoutID(setTimeout(handleCheck(value), 3000))
    else SetLoading(false)
  }

  const handleCheck = (value) => (event) => {
    dispatch(McLeodCompaniesRequest({ code: value || code, action }, ({ data: { companyInfo } }) => {
      setValue('mlCompanyId', value || code)
      setValue('general.country', companyInfo.country)
      setValue('general.state', companyInfo.state)
      setValue('general.zip', companyInfo.zip)
      setValue('general.city', companyInfo.city)
      setValue('general.address', companyInfo.address)
      setValue('general.name', companyInfo.label)
      clearErrors(['general.name', 'mlCompanyId', 'general.country', 'general.state', 'general.zip', 'general.city', 'general.address'])
      setCheck(true)
      SetLoading(false)
    },
    () => {
      addToast('Error connecting to McLeodMcLead error connection', {
        appearance: 'error',
        autoDismiss: true,
        id: 'warning-toast-mcleod',
      })
      SetLoading(false)
    }))
  }

  return (
    <Root>
      <Typography variant='h4' className={classes.text} component='label'> McLeod Company </Typography>
      <FormInputs.Input
        showError
        error={errors.mlCompanyId}
        InputProps={{
          endAdornment: (
            <Tooltip title={message}>
              <InputAdornment position='end'>
                {
                  loading ? <CircularProgress size={20} /> : (
                    <AiFillCheckCircle
                      onClick={handleCheck()}
                      color={check ? '#D57800' : '#EEEEEE'}
                      className={classes.check}
                    />
                  )
                }

              </InputAdornment>
            </Tooltip>
          ),
        }}
        value={code}
        onChange={handleCode}
        disabled={action !== 'new'}
      />
    </Root>
  )
}
