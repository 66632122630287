// libraries
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

// Components
import ImageNotDraggable from 'components/common/imageNotDraggable'

// styles
import { tuckerLogo } from 'assets'
import { useStyles, ImageContainer, Content } from './styles'

function LayoutPrelogin ({ children }) {
  const classes = useStyles()
  return (
    <Typography component='div' className={classes.container}>
      <ImageContainer className={classes.image}>
        <ImageNotDraggable externalImage={tuckerLogo} width={190} />
      </ImageContainer>
      <Content>
        {children}
      </Content>
    </Typography>
  )
}

export default LayoutPrelogin

LayoutPrelogin.propTypes = {
  children: PropTypes.node.isRequired,
}
