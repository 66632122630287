//  libraries
import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import classNames from 'classnames'
import PropTypes from 'prop-types'

//  hooks
import { useHistory } from 'react-router-dom'

//  styles
import { useStyles } from './styles'

export default function Buttons ({ tab }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box display='flex' justifySelf='flex-end' mb='1rem'>
      {
        tab === 'form'
          ? (
            <Button
              variant='contained'
              type='submit'
              classes={{ root: classes.button, disabled: classes.disabled }}
            >
              Next
            </Button>
          ) : (
            <>
              <Button
                variant='contained'
                classes={{ root: classNames(classes.button, classes.margin), disabled: classes.disabled }}
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                type='submit'
                classes={{ root: classes.button, disabled: classes.disabled }}
              >
                Save
              </Button>
            </>
          )
      }
    </Box>
  )
}

Buttons.propTypes = {
  tab: PropTypes.string,
}
