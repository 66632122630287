//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import PropTypes from 'prop-types'

//  tools
import { nameAbbreviation } from 'tools'

//  hooks
import { useRouteMatch } from 'react-router-dom'

//  styles
import { useStyles, Root, Content, SubContent, Info } from './styles'

export default function CompanyInfo ({ company = {}, userInfo = {} }) {
  const classes = useStyles()
  const isSalesDetail = useRouteMatch('/sales/list/:id')
  const { image, name, email, contactName, phoneNumber } = company
  const { email: userEmail, userName } = userInfo

  const renderContactData = (name, email, secondLabel = 'email') => (
    <SubContent>
      <Typography variant='h2' className={classes.subtitle}>Requestor Contact</Typography>
      <Info>
        <Typography variant='h4' className={classes.text1}>Name</Typography>
        <Typography variant='h4' className={classes.text2}>{name || '...'}</Typography>
        <Typography variant='h4' className={classes.text1}>{secondLabel}</Typography>
        <Typography variant='h4' className={classes.text2}>{email || '...'} </Typography>
      </Info>
    </SubContent>
  )

  return (
    <Root>
      <Typography variant='h3' className={classes.title} align='center'>Contact Information</Typography>
      <Content>
        <Avatar className={classes.avatar} src={`${image}?${Math.random()}`}>
          {nameAbbreviation(name)}
        </Avatar>
        <Typography variant='h2' className={classes.companyName} align='center'>{name || 'Company Name'}</Typography>
        <Typography variant='h2' className={classes.companyEmail} align='center'>{email || '...'}</Typography>

        {renderContactData(contactName, phoneNumber, 'Phone')}
        {isSalesDetail && renderContactData(userName, userEmail)}

      </Content>
    </Root>
  )
}

CompanyInfo.propTypes = {
  company: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    contactName: PropTypes.string,
  }),
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    userName: PropTypes.string,
  }),
}
