//  libraries
import React from 'react'
import ReactGA from 'react-ga'

//  components
import NavStepper from 'components/common/navStepper'
import Loader from 'components/common/loader'
import {
  OriginDestinationQuote,
  TransportType,
  PackageInformation,
  ShippingInformationQuote,
} from 'components/common/forms'
import MessageModal from 'components/common/messageModal'

//  redux
import { fetchPost } from 'redux/ducks/postData'
import { modalUpdate } from 'redux/ducks/modal'
import { stepperUpdate } from 'redux/ducks/stepper'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

//  redux
import { newFormDelete, newFormUpdate } from 'redux/ducks/newForm'

//  styles
import { Content } from './styles'

export default function TlForm () {
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector(state => state.entities.postData.loading)
  const companies = useSelector(state => state.ui.companySelector)
  const role = useSelector(state => state.session.data.role)
  const quoteAction = useParams().action

  const getStepContent = {
    0: <OriginDestinationQuote />,
    1: <TransportType />,
    2: <PackageInformation />,
    3: <ShippingInformationQuote type='tl' />,
  }

  const clearEditData = (payload) => {
    const { transport = null, products = [], shipping = {} } = payload
    const updatedItems = {}
    if (transport === 'dry-van') {
      const junkValues = ['tempMin', 'tempMax', 'length', 'width', 'height']
      junkValues.forEach(val => delete products[0][val])
      updatedItems.products = products
    }
    if (shipping.loads <= 1) {
      delete shipping.loadRange
      updatedItems.shipping = shipping
    }
    if (Object.keys(updatedItems) > 0) {
      dispatch(newFormUpdate({ ...updatedItems }))
    }
  }

  const handleSent = (payload) => {
    clearEditData(payload) // quote validation
    if (quoteAction === 'edit') {
      ReactGA.event({
        category: 'Update',
        action: 'Edit TL Quote',
      })
      dispatch(fetchPost('quoteService.putTl', null, 'newForm', () => {
        history.goBack()
      }, companies))
    } else {
      ReactGA.event({
        category: 'Create',
        action: 'New TL Quote',
      })
      dispatch(fetchPost('quoteService.createTl', null, 'newForm', () => {
        dispatch(modalUpdate(true))
      }, companies))
    }
  }

  const handleCancel = () => {
    const path = role === 'sales' ? '/sales/list' : '/quote/list/tl'
    history.push(path)
  }

  const handleSubmit = () => {
    dispatch(newFormDelete())
    dispatch(stepperUpdate({
      index: 0,
      complete: [],
    }))
  }

  return (
    <Content>
      <NavStepper getStepContent={getStepContent} handleSent={handleSent} po companySelector />
      <MessageModal
        path='entities.postData.errors'
        title='SUCCESSFUL QUOTE REQUEST'
        body={'You will receive an email with the information you submitted, and a Tucker representative will review your quote and provide a rate shortly. If there\'s anything more we can do to earn your business, please feel free to reach out to us directly.'}
        submitText='Create another quote'
        cancelText='View quote list'
        handleCancelButton={handleCancel}
        handleSubmitButton={handleSubmit}
      />
      <Loader open={loading} />
    </Content>
  )
}
