//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STEPPER.UPDATE:
      return model.update(state, action.payload)
    case TYPES.STEPPER.CLEAR:
      return { ...initialState }
    default:
      return state
  }
}
