import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import { productsSucceeded, productsFailed } from './actions'
import API from 'API'

function * fetchProducts (action) {
  try {
    const { data } = yield call(API.quoteService.getProducts, action.payload)
    yield put(productsSucceeded(data))
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(productsFailed(newError))
  }
}

export default function * rootProducts () {
  yield takeEvery(TYPES.PRODUCTS.FETCH.REQUEST, fetchProducts)
}
