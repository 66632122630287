import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '22rem',
    marginBottom: '1rem',
    marginRight: '1rem',
  },
  dropdown: {
    position: 'absolute',
    top: 40,
    right: 0,
    left: 0,
    zIndex: 20,
    border: '1px solid #CBD6E2',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  fieldText: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD6E2',
      },
    },
  },
}))
