//  libraries
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

//  components
import NavStepper from 'components/common/navStepper'
import Loader from 'components/common/loader'
import {
  OriginDestinationQuote,
  ProductInformation,
  VehicleAccessorials,
  ShippingInformationQuote,
} from 'components/common/forms'
import MessageModal from 'components/common/messageModal'

//  redux
import { productsRequest } from 'redux/ducks/products'
import { carrierListQuoteRequest } from 'redux/ducks/carrierList'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

//  styles
import { Content } from './styles'

export default function LtlForm () {
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector(state => state.entities.carrierList.loading)

  useEffect(() => {
    dispatch(productsRequest())
  }, [dispatch])

  const getStepContent = {
    0: <OriginDestinationQuote />,
    1: <ProductInformation />,
    2: <VehicleAccessorials />,
    3: <ShippingInformationQuote type='ltl' />,
  }

  const handleSent = () => {
    dispatch(carrierListQuoteRequest(() => {
      ReactGA.event({
        category: 'Detail',
        action: '/quote/rates/list',
      })
      history.push('/quote/rates/list')
    }))
  }

  return (
    <Content>
      <NavStepper getStepContent={getStepContent} handleSent={handleSent} po companySelector />
      <MessageModal path='entities.carrierList.errors' />
      <Loader open={loading} />
    </Content>
  )
}
