import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import LayoutPrelogin from 'components/layouts/layout-prelogin'

//  tools
import { validateRoutes } from 'tools'

const PublicRoute = ({ component: Component, path }) => {
  const { finalRoute, isRender } = validateRoutes()

  const _renderComponent = (props) => {
    return (
      <LayoutPrelogin>
        <Component {...props} />
      </LayoutPrelogin>
    )
  }

  return (
    <Route
      render={
        props => !isRender ? _renderComponent(props)
          : <Redirect to={finalRoute} />
      }
      path={path}
    />)
}

export default PublicRoute
