//  libraries
import React from 'react'
import ReactDOM from 'react-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

//  redux
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persist } from 'redux/store'

//Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

//  App
import App from 'App'
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Provider store={store}>
      <PersistGate persistor={persist} loading={<h1>loading</h1>}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>
  , document.getElementById('root'))
