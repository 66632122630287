//  libraries
import React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

function TabPanel ({ children, value, index, ...rest }) {
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      {...rest}
    >
      {value === index && children}
    </Typography>
  )
}

export default TabPanel

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}
