//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import SelectDateTime from './selectDateTime'
import InfoTl from './infoTl'
import InfoLtl from './infoLtl'

//  styles
import { listIcon } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'

export default function ShippingInformationQuote ({ type }) {
  const classes = useStyles()

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={listIcon} width='35px' />
        <Typography variant='h1' className={classes.heading}>
          Pick-Up and Special Instructions
        </Typography>
      </HeadingContainer>
      <SelectDateTime name='pickup' tbd={type === 'tl'} />
      {type === 'ltl' ? <InfoLtl /> : <InfoTl />}
    </Root>
  )
}

ShippingInformationQuote.propTypes = {
  type: PropTypes.string.isRequired,
}
