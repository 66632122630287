import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from "react-redux"
import Layout from 'components/layouts/layout'

//  tools
import { userPrivileges } from 'tools'

const PrivateRoute = ({ component: Component, path, location }) => {
  const role = useSelector(state => state.session.data.role);
  const { finalRoute, isRender } = userPrivileges(path, location.pathname, role)
  const _renderComponent = (props) => {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    )
  }

  return (
    <Route
      render={
        props => isRender ? _renderComponent(props)
          : <Redirect to={finalRoute} />
      }
      path={path}
    />)
}

export default PrivateRoute
