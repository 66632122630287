import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    marginLeft: '8px',
    fontSize: '20px',
    color: 'grey',
  },
})
