import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'wrap',
    gridColumn: '1 / -1',
  },
  locationButton: {
    height: 40,
    fontSize: 18,
    color: theme.palette.common.second8,
    width: '11rem',
    textTransform: 'none',
    margin: '0 2rem',
    marginBottom: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  linearProgress: {
    width: '100%',
    marginBottom: 5,
  },
}))
