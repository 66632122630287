//  libraries
import React from 'react'

//  components
import NavStepper from 'components/common/navStepper'
import Loader from 'components/common/loader'
import MessageModal from 'components/common/messageModal'
import {
  OriginDestinationShipment,
  TransportType,
  PackageInformation,
  ShippingInformationShipment,
} from 'components/common/forms'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

//  redux
import { fetchPost } from 'redux/ducks/postData'
import { bolUpdate } from 'redux/ducks/bol'

//  styles
import { Content } from './styles'

export default function TlForm () {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()
  const loading = useSelector(state => state.entities.postData.loading)
  const companies = useSelector(state => state.ui.companySelector)
  const quoteAction = useParams().action

  const getStepContent = {
    0: {
      0: <OriginDestinationShipment location='from' position={0} />,
      1: <OriginDestinationShipment location='from' position={1} />,
      isComplex: true,
    },
    1: {
      0: <OriginDestinationShipment location='to' position={0} />,
      1: <OriginDestinationShipment location='to' position={1} />,
      isComplex: true,
    },
    2: <TransportType />,
    3: <PackageInformation />,
    4: <ShippingInformationShipment type='tl' />,
  }

  const handleSent = () => {
    if (quoteAction === 'edit') {
      dispatch(fetchPost('shipmentService.putTl', null, 'newForm', () => {
        addToast('Shipment updated successfully', {
          appearance: 'success',
          autoDismiss: true,
          id: 'success-update-shipment',
        })
        history.goBack()
      }, companies))
    } else {
      dispatch(bolUpdate({
        save: false,
        id: '',
      }))
      history.push('/shipment/bol/tl')
    }
  }

  return (
    <Content>
      <NavStepper getStepContent={getStepContent} handleSent={handleSent} companySelector />
      <MessageModal path='entities.postData.errors' />
      <Loader open={loading} />
    </Content>
  )
}
