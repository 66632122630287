import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    '& table': {
      width: '100%',
    },
  },

  commodityCell: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  header: {
    boxShadow: '0px 1px 2px rgba(0,0,0,.1)',
    '& tr': {
      textAlign: 'center',
    },
    '& th': {
      paddingBottom: '0.4rem',
    },
  },

  row: {
    textAlign: 'center',
    fontSize: '12pt',
    fontWeight: 500,
    padding: '1em 0',
    boxShadow: '0 0 0 rgba(0,0,0,.1)',
    '&:hover': {
      border: '1px solid blue',
    },
  },
}))
