//  libraries
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import Map from 'components/common/map'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { trackingIcon } from 'assets'
import { useStyles, Root, HeadingContainer } from './styles'



export default function TrackingCard () {
  const trackingInfo = useSelector(state => state.entities.shipmentDetails.data.trackingInfo || [])
  const classes = useStyles() 

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={trackingIcon} width='40px' />
        <Typography variant='h2' className={classes.heading}>
          Tracking Information
        </Typography>
      </HeadingContainer>
      {
        trackingInfo.length
          ?  (
              <Map
                theme='pedestrian.day'
                trackingInfo={trackingInfo}
              />)          
          : (
            <Typography variant='h4' className={classes.text} align='center'>
              No Tracking Info Yet
            </Typography>
          )
      }
    </Root>
  )
}
