//  libraries
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

//  components
import ButtonStepper from './buttonStepper'
import TopBar from './topBar'
import CustomIcon from './customIcon'
import TermsAndConditionsLink from 'components/common/termsAndConditionsLink'

// hooks
import { useForm, FormProvider } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'

//  redux
import { stepperUpdate, stepperClear } from 'redux/ducks/stepper'
import { newFormUpdate } from 'redux/ducks/newForm'

//  styles
import { useStyles, Container, Content, Form, CustomStepperConnector } from './styles'

export default function NavStepper ({ getStepContent, handleSent, po = false, companySelector = false }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const pathname = useLocation().pathname
  const { type, action } = useParams()
  const [internalStep, setInternalStep] = useState(0)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const methods = useForm({
    submitFocusError: false,
  })
  const disabledInitialSteppers = ['new', 'convert'].includes(action)
  const totalSteps = Object.keys(getStepContent).length
  const steps = Array(totalSteps).fill('')
  const { index: activeStep, complete: completed, activeSaveButton } = useSelector(state => state.ui.stepper)
  const completedSteppers = useSelector(state => state.ui.stepper.complete)
  let payload = useSelector(state => state.newForm) // just initial values
  const isLastStep = totalSteps - 1 === activeStep
  const isLastStepOfConvert = isLastStep && pathname.includes('/shipment/convert/')
  const activateBlocker = (completedSteppers.slice(0, totalSteps - 2).includes(true)) &&
    (completedSteppers.length < totalSteps) &&
    (totalSteps - 1 > activeStep)

  const unblockCallback = activateBlocker
    ? history.block(({ pathname }) => { // history.block returns unblock function
      const modality = pathname.includes('/quote/list/') ? 'quote' : 'shipment'
      return `This ${modality} has not been submitted. Are you sure you want to leave?`
    })
    : () => {}

  const unblock = useCallback(
    unblockCallback,
    [unblockCallback],
  )

  useEffect(() => {
    return unblock
  }, [unblock])

  useEffect(() => {
    dispatch(stepperUpdate({
      index: 0,
      complete: disabledInitialSteppers ? [] : Array(totalSteps).fill(true),
    }))
    return () => {
      dispatch(stepperClear())
    }
  //  eslint-disable-next-line
  }, [type])

  const transportTypeValidation = (dataSteps) => { // only to quote form
    payload = { ...payload, ...dataSteps }
    const allowedPaths = /(\/quote\/new\/tl)|(\/sales\/edit\/tl)/g
    const isQuoteForm = allowedPaths.test(pathname)
    if (!isQuoteForm) return true
    const { transport = null, products = [] } = payload
    const incompleteReefer = transport === 'reefer' && !products[0].tempMin && !products[0].tempMax
    const incompleteFlatbed = transport === 'flatbed' && !products[0].length && !products[0].width && !products[0].height
    if (incompleteReefer || incompleteFlatbed) {
      dispatch(stepperUpdate({ index: 2 }))
      return false
    }
    return true
  }

  const handleStep = step => () => { // step click when action is new
    setInternalStep(0)
    dispatch(stepperUpdate({ index: step, complete: Array(step + 1).fill(true) }))
  }

  const handleEdit = step => (values) => { // step click when action is edit'
    dispatch(newFormUpdate(values))
    setInternalStep(0)
    dispatch(stepperUpdate({ index: step }))
  }

  const nextComplete = () => { // next ordinary step
    const newCompleted = completed
    newCompleted[activeStep] = true
    dispatch(stepperUpdate({ complete: newCompleted }))
    if (totalSteps > activeStep + 1) {
      dispatch(stepperUpdate({ index: activeStep + 1 }))
    }
  }

  const onSubmit = (dataSteps) => { // Entry point => next/finish (when action === new)
    if (isLastStepOfConvert) {
      unblockCallback()
    }
    dispatch(newFormUpdate(dataSteps))

    // final step
    if (totalSteps - 1 === activeStep) {
      unblockCallback()
      if (!acceptedTerms && pathname.includes('/shipment/convert/')) return
      if (!transportTypeValidation(dataSteps)) return
      handleSent(payload)
    }

    // complex steppers
    if (getStepContent[activeStep].isComplex &&
      Object.keys(getStepContent[activeStep]).length > internalStep + 2) {
      setInternalStep(internalStep + 1)
    } else { // ordinary steppers
      if (activeStep < 2) setInternalStep(0)
      nextComplete()
    }
  }

  const handleSave = (dataSteps) => { // Entry point => save (when action === edit)
    dispatch(newFormUpdate(dataSteps))
    if (!transportTypeValidation(dataSteps)) return
    handleSent(payload)
  }

  const handleChangeForm = () => {
    dispatch(stepperUpdate({ activeSaveButton: true }))
  }

  return (
    <Container>
      <TopBar po={po} companySelector={companySelector} />
      <Stepper
        connector={(<CustomStepperConnector />)}
        activeStep={activeStep}
        orientation='vertical'
        className={classes.stepper}
        style={{ minHeight: totalSteps <= 2 ? 300 : 486 }}
      >
        {steps.map((label, index) => (
          <Step key={index} disabled={action === 'edit' ? false : !completed[index]}>
            <StepButton
              className={classes.stepButton}
              onClick={action === 'edit' ? methods.handleSubmit(handleEdit(index)) : handleStep(index)}
              completed={completed[index]}
            >
              <StepLabel
                classes={{
                  root: classes.stepLabel,
                  iconContainer: classes.stepLabelIconContainer,
                }}
                StepIconComponent={CustomIcon}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Content>
        <Typography variant='h2' className={classes.position}>Step {activeStep + 1}</Typography>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)} onChangeCapture={!activeSaveButton && action === 'edit' ? handleChangeForm : null}>
            {
              getStepContent[activeStep].isComplex
                ? getStepContent[activeStep][internalStep]
                : getStepContent[activeStep]
            }
            {isLastStepOfConvert && (
              <FormControlLabel
                className={classes.terms}
                control={
                  <Checkbox
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms((acceptedTerms) => !acceptedTerms)}
                  />
                }
                label={<TermsAndConditionsLink />}
              />
            )}
            <ButtonStepper
              complex={getStepContent[activeStep].isComplex}
              activeStep={activeStep}
              internalStep={internalStep}
              setInternalStep={setInternalStep}
              isLastStep={isLastStep}
              action={action}
              handleSave={methods.handleSubmit(handleSave)}
              disabledNextButton={isLastStepOfConvert && !acceptedTerms}
              disabledSaveButton={!activeSaveButton}
            />
          </Form>
        </FormProvider>
      </Content>
    </Container>
  )
}

NavStepper.propTypes = {
  getStepContent: PropTypes.object.isRequired,
  handleSent: PropTypes.func.isRequired,
  po: PropTypes.bool,
  companySelector: PropTypes.bool,
}
