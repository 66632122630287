import { makeStyles, withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import StepConnector from '@material-ui/core/StepConnector'

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 400,
    color: theme.palette.common.second3,
  },
  text: {
    fontWeight: 400,
    fontSize: 18,
    color: theme.palette.common.second1,
  },
  subtitle: {
    color: theme.palette.common.second3,
    fontWeight: 400,
  },
  location: {
    color: theme.palette.common.second3,
    fontWeight: 'normal',
  },
  date: {
    fontWeight: 400,
    color: theme.palette.common.second3,
  },
}))

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .image {
    margin-top: 30px;
  }
`

export const StepperContainer = styled.div`
  width: 800px;
  height: 250px;
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
`

export const CustomStepperConnector = withStyles({
  root: {
    top: 29,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  line: {
    height: 3,
    borderColor: (props) => props.delivered ? '#D57800' : '#bdbdbd',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)
