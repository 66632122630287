export const formatToCurrency = (value) => {
  if (typeof value !== 'number') return value
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const formatToLocalString = (value) => {
  if (!Number(value)) return value
  return value.toLocaleString()
}

export const formatToMassUnit = (value) => {
  return new Intl.NumberFormat('en-US').format(value)        
}
