//  libraries
import React, { useState } from 'react'
import PropTypes, { object } from 'prop-types'

//  hooks
import { useWindowEvent } from 'hooks/useWindowEvent'

//  components
import Item from './item'

//  styles
import { Container, Header, Content, SubContent } from './styles'
import { FaCaretDown } from 'react-icons/fa'

export default function CustomSelector ({ content, selected, setSelected }) {
  const [show, setShow] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [options, setOptions] = useState([])
  const [position, setPosition] = useState(0)

  useWindowEvent('click', function (e) {
    if (!(document.getElementById('selector') && document.getElementById('selector').contains(e.target))) {
      setShowOptions(false)
      setShow(false)
    }
  })

  const handleClick = () => {
    if (showOptions === true) {
      setShowOptions(false)
    }
    setShow(!show)
  }

  const handleOptions = (value, index) => {
    setPosition(index)
    setOptions(value)
    handleShowOptions()
  }

  const handleShowOptions = () => {
    setShowOptions(!showOptions)
    setShow(!show)
  }

  const handleSelected = (item, option = false) => {
    let add = ''
    if (option) {
      add = { ...content[position], option: item.value }
      handleShowOptions()
    } else {
      add = { ...item }
    }
    const newSelected = [...selected, add]
    setSelected(newSelected)
  }

  return (
    <Container id='selector'>
      <Header onClick={handleClick}>
        <div>
          Select
        </div>
        <FaCaretDown />
      </Header>
      <Content show={show}>
        {
          content.filter(item => selected.map(sel => sel.value).indexOf(item.value) === -1).map((item, index) => {
            return (
              <Item key={index} handleClick={item.options ? () => handleOptions(item.options, index) : () => handleSelected(item)}>
                {item}
              </Item>
            )
          })
        }
      </Content>
      <SubContent show={showOptions}>
        {
          options.map((item, index) => {
            return (
              <Item key={index} handleClick={() => handleSelected(item, true)}>
                {item}
              </Item>
            )
          })
        }
      </SubContent>
    </Container>
  )
}

CustomSelector.propTypes = {
  content: PropTypes.arrayOf(object).isRequired,
  selected: PropTypes.arrayOf(object).isRequired,
  setSelected: PropTypes.func.isRequired,
}
