// libraries
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable, { MTableBody } from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'

//  hooks
import { useSelector, useDispatch } from 'react-redux'

//  redux
import { quoteListFetch, cleanState } from 'redux/ducks/quoteList'

// tools
import { FETCH } from 'API/fetch'
import { quoteRoutes } from 'API/routes/quote'
import { dateFormat, formatToCurrency } from 'tools'

// styles
import { useStyles, tableStyles } from './styles'
import { tableIcons } from 'styles/tableIcons'
import { globalTableOptions } from 'styles/globalTableStyles'
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
// components
import FormattedCell from 'components/common/tableFragments/cells/formattedCell'
import StatusTD from 'components/common/tableFragments/cells/statusTD'
import TlDetail from 'components/quote/common/tlDetail'
import FilterBar from 'components/common/filterBar'
import ContainerTable from 'components/common/tableFragments/containerTable'
import QuoteDateTD from 'components/common/tableFragments/cells/quoteDateTD'
import Pagination from 'components/common/tableFragments/pagination'
import TableLoading from 'components/common/tableFragments/tableLoading'
import CellRepresentatives from 'components/common/tableFragments/cellRepresentatives'
import SortingHeader from 'components/common/tableFragments/sortingHeader'

export default function TlQuoteList () {
  const classes = useStyles()
  const tableRef = useRef(null)
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(globalTableOptions.pageSize)
  const { data = [], page = 0, totalCount = 0, loading = true } = useSelector(state => state.entities.quoteList.tl)
  const filters = useSelector(state => state.ui.filters)
  const setPage = page - 1 < 0 ? 0 : page - 1 // Avoid to assign negative value.

  useEffect(() => {
    dispatch(quoteListFetch('Tl', { params: { limit, page: 1, ...filters } }))
    return () => {
      dispatch(cleanState())
    }
    //  eslint-disable-next-line
  }, [])

  return (
    <>
      <FilterBar
        filters={['searchBar', 'status', 'creationDate', 'priceRange', 'transportType']}
        module='quote'
        thunkAction={(data, callback) => quoteListFetch('Tl', data, null, callback)}
        addTypeFilter
      />
      <Pagination
        count={totalCount}
        rowsPerPage={limit}
        page={setPage}
        onChangePage={(evt, page) => {
          dispatch(quoteListFetch('Tl', { params: { ...filters, limit, page: page + 1 } }))
        }}
        onChangeRowsPerPage={(evt, arg) => {
          setLimit(evt.target.value)
          tableRef.current.onChangeRowsPerPage(evt)
          dispatch(quoteListFetch('Tl', { params: { ...filters, limit: evt.target.value, page } }))
        }}
      />
      <MaterialTable
        isLoading={loading}
        data={data}
        tableRef={tableRef}
        icons={tableIcons}
        columns={[
          {
            title: 'Reference',
            sortKeyValue: 'reference',
            render: rowData => (
              <>
                {rowData.mlQuoteId && <Typography className={classes.reference}>{rowData.mlQuoteId}</Typography>}
                <Typography variant='body2' className={classes.reference}> #{rowData.refId} </Typography>
              </>
            ),
          },
          {
            title: 'Status',
            sortKeyValue: 'status',
            render: rowData => <StatusTD status={rowData.status} expirationDate={rowData.rate?.expirationDate} />,
          },
          {
            title: 'Creation Date',
            sortKeyValue: 'creation',
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.quoteDate?.date)}
                time={dateFormat(rowData.quoteDate?.date, 'HH:mm')}
              />
            ),
          },
          {
            title: 'Origin',
            sortKeyValue: 'origin',
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundUp className={classes.originIcon} />}
                mainText={rowData.origin?.city}
                secondMainText={rowData.origin?.state}
                secondaryText={rowData.origin?.postalCode}
              />
            ),
          },
          {
            title: 'Destination',
            sortKeyValue: 'destination',
            render: rowData => (
              <FormattedCell
                icon={<IoIosArrowRoundDown className={classes.destinationIcon} />}
                mainText={rowData.destination?.city}
                secondMainText={rowData.destination?.state}
                secondaryText={rowData.destination?.postalCode}
              />
            ),
          },
          {
            title: 'Pickup Date',
            sortKeyValue: 'pickup',
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.pickup?.startDate)}
                time={rowData.shipping?.pickup?.startTime}
              />
            ),
          },
          {
            title: 'Delivery Date',
            sortKeyValue: 'delivery',
            render: rowData => (
              <QuoteDateTD
                date={dateFormat(rowData.shipping?.delivery?.startDate)}
                time={rowData.shipping?.delivery?.startTime}
              />
            ),
          },
          {
            title: 'Customer Code',
            sorting: false,
            render: rowData => (
              <Typography align='center' className={classes.primaryColor}>
                {rowData.company.mlCompanyId || '...'}
              </Typography>
            ),
          },
          {
            title: 'Quoted By',
            sortKeyValue: 'quoted',
            sorting: true,
            render: rowData =>
              <Typography align='center' className={classes.primaryColor}>{rowData.userInfo?.userName || '...'}</Typography>,
          },
          {
            title: 'Representatives',
            sortKeyValue: 'representatives',
            sorting: true,
            render: rowData => <CellRepresentatives representatives={rowData.company?.representatives} />,
          },
          {
            title: 'Quoted Rate',
            sortKeyValue: 'rate',
            render: rowData => (
              <Typography align='center' className={classes.primaryColor} noWrap>
                {rowData.rate?.customerPrice ? `${formatToCurrency(rowData.rate.customerPrice)} USD` : '...'}
              </Typography>
            ),
          },
        ]}
        detailPanel={[{
          icon: () => <MdKeyboardArrowDown fontSize='1em' />,
          openIcon: () => <MdKeyboardArrowUp fontSize='1em' />,
          render: rowData => <TlDetail rowData={rowData} />,
        }]}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              FETCH('DELETE', quoteRoutes.delete.replace(':quoteId', oldData.refId))
                .then(() => {
                  resolve(dispatch(quoteListFetch('Tl', { params: { limit, page, ...filters } })))
                })
                .catch(error => {
                  console.log(error)
                  reject(error)
                })
            }),
        }}
        components={{
          Pagination: (props) => {
            return (
              <TablePagination
                {...props}
                count={totalCount}
                page={setPage}
                rowsPerPage={limit}
                onChangePage={(evt, page) => {
                  dispatch(quoteListFetch('Tl', { params: { ...filters, limit, page: page + 1 } }))
                }}
                onChangeRowsPerPage={(evt, arg) => {
                  setLimit(evt.target.value)
                  props.onChangeRowsPerPage(evt)
                  dispatch(quoteListFetch('Tl', { params: { ...filters, limit: evt.target.value, page } }))
                }}
              />
            )
          },
          Body: (props) => (
            <>
              {loading && totalCount > 0 && <TableLoading />}
              <MTableBody {...props} />
            </>
          ),
          Container: props => (
            <ContainerTable>
              {props.children}
            </ContainerTable>
          ),
          Header: (props) => <SortingHeader tableProps={props} limit={limit} listOf='quote' />,
        }}
        options={{
          ...globalTableOptions,
          headerStyle: tableStyles.tHeader,
          rowStyle: tableStyles.successRow,
          sorting: true,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: loading ? 'Loading' : 'No quotes to display',
          },
          pagination: {
            labelRowsSelect: 'Items',
          }
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </>
  )
}
