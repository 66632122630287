//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { MdKeyboardReturn } from 'react-icons/md'

// styles
import { useStyles } from './styles'

const FormattedCell = ({ icon, mainText, secondMainText, secondaryText }) => {
  const classes = useStyles()
  return (
    <div className={classes.rowContainer}>
      {mainText
        ? (
          <div className={classes.rowContent}>
            <div className={classes.mainText}>
              {icon || null}
              <Typography className={classes.content}>
                {mainText}{secondMainText ? `, ${secondMainText}` : null}
              </Typography>
            </div>
            <Typography component='div' className={classes.secondaryText}>
              <MdKeyboardReturn style={{ fontSize: '12pt', transform: 'scaleX(-1)' }} />
              {secondaryText || 'unknown'}
            </Typography>
          </div>
        ) : <Typography align='center'>...</Typography>}
    </div>
  )
}

export default FormattedCell

FormattedCell.propTypes = {
  icon: PropTypes.element,
  secondMainText: PropTypes.string,
  mainText: PropTypes.string,
  secondaryText: PropTypes.string,
}
