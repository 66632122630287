import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
  return ({
    container: {
      padding: '0 3.3rem',
      paddingTop: 29,
      paddingBottom: 10,
      display: 'grid',
      gridTemplateColumns: 'minmax(90%, 1500px)',
      gridAutoRows: 'min-content',
      justifyContent: 'center',
    },
    h1: {
      marginTop: 12,
      fontSize: 21,
      color: theme.palette.common.second1,
      marginRight: 5,
    },
    newQuoteShipment: {
      backgroundColor: theme.palette.common.orange,
      fontSize: '14px',
      textTransform: 'none',
      width: '180px',
      height: '42px',
      '&:hover': {
        backgroundColor: theme.palette.common.deepOrange,
      },
    },
    icon: {
      fontSize: 30,
    },
  })
})
