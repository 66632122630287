import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  AccountingListSucceeded,
  AccountingListFailed,
} from './actions'
import API from 'API'

function * fetchAccountingList (action) {
  const { payload, companyId, callback } = action
  try {
    const { data } = yield call(API.accountingService.getInvoice, companyId, payload)
    yield put(AccountingListSucceeded(data))
    callback()
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(AccountingListFailed(newError))
    callback()
  }
}

export default function * rootAccountingList () {
  yield takeEvery(TYPES.ACCOUNTING_LIST.FETCH.REQUEST, fetchAccountingList)
}
