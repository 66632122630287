//  libraries
import React from 'react'
import MobileStepper from '@material-ui/core/MobileStepper'
import PropTypes from 'prop-types'

//  components
import Step1 from './step1'
import Step2 from './step2'

//  styles
import { useStyles } from './styles'

export default function OriginDestinationFormShipment ({ position, location }) {
  const classes = useStyles()

  const steps = {
    0: <Step1 location={location} title={location === 'from' ? 'Origin' : 'Destination'} />,
    1: <Step2 name={location === 'from' ? 'pickup' : 'delivery'} />,
  }

  return (
    <>
      {steps[position]}
      <MobileStepper
        variant='dots'
        steps={2}
        position='static'
        activeStep={position}
        classes={{
          root: classes.root,
          dots: classes.dots,
        }}
      />
    </>
  )
}

OriginDestinationFormShipment.propTypes = {
  position: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
}
