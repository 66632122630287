//  libraries
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'

//  components
import Loader from 'components/common/loader'
import NotFound from 'components/common/notFound'
import TlDetailSalesRep from 'components/quote/tl/salesRep/tlDetailsSalesRep'

//  redux
import { QuoteDetailsRequest, cleanState } from 'redux/ducks/quoteDetails'

//  hooks
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

//  styles
import { useStyles } from './styles'

export default function QuoteDetails () {
  const { id: quoteId } = useParams()
  const dispatch = useDispatch()
  const { loading, success } = useSelector(state => state.entities.quoteDetails)
  const classes = useStyles()

  useEffect(() => {
    dispatch(QuoteDetailsRequest({ quoteId }))
    return () => {
      dispatch(cleanState())
    }
    //  eslint-disable-next-line
  }, [])

  if (loading) return <Loader />

  if (!success) return <NotFound />

  return (
    <Grid container justify='center'>
      <Grid item zeroMinWidth className={classes.container}>
        <TlDetailSalesRep />
      </Grid>
    </Grid>
  )
}
