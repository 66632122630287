//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.NEW_FORM.UPDATE:
      return model.update(state, action.payload)
    case TYPES.NEW_FORM.DELETE:
      return model.delete(initialState)
    case TYPES.NEW_FORM.LOAD.QUOTE.LTL:
      return model.loadFromQuoteLtl(action.payload)
    case TYPES.NEW_FORM.LOAD.QUOTE.TL:
      return model.loadFromQuoteTl(action.payload)
    case TYPES.NEW_FORM.LOAD.SHIPMENT.LTL:
      return model.loadFromShipmentLtl(action.payload)
    case TYPES.NEW_FORM.LOAD.SHIPMENT.TL:
      return model.loadFromShipmentTl(action.payload)
    case TYPES.NEW_FORM.LOAD.RATE_LIST:
      return model.loadFromRatesList(action.payload)
    case TYPES.NEW_FORM.LOAD.USER_LIST:
      return model.loadUser(action.payload)
    case TYPES.NEW_FORM.LOAD.COMPANY_LIST:
      return model.loadCompany(action.payload)
    case TYPES.NEW_FORM.LOAD.SALES_DETAILS:
      return model.loadSales(action.payload)
    default:
      return state
  }
}
