//  libraries
import React from 'react'
import ReactGA from 'react-ga'
import Typography from '@material-ui/core/Typography'

//  components
import NavStepper from 'components/common/navStepper'
import { Company, Notifications } from '../forms'
import MessageModal from 'components/common/messageModal'

//  hooks
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//  redux
import { fetchPost } from 'redux/ducks/postData'
import { modalUpdate } from 'redux/ducks/modal'
import { cleanState, loginSucceeded } from 'redux/ducks/user'

//  tools
import { convertToFormatData, clearSession, clearStorage } from 'tools'
import API from 'API'

//  styles
import { useStyles, Content, Title } from './styles'

export default function CompanyForm () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { action } = useParams()
  const newForm = useSelector(state => state.newForm)

  const getStepContent = {
    0: <Company />,
    1: <Notifications />,
  }

  const updateSession = () => { // to update company selector
    API.authService.checkUser()
      .then((res) => {
        dispatch(loginSucceeded(res.data))
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          clearSession()
          clearStorage()
          dispatch(cleanState())
        }
      })
  }

  const handleSent = (values) => {
    const form = convertToFormatData({ ...newForm, ...values })
    if (action === 'new') {
      ReactGA.event({
        category: 'Create',
        action: 'New Company',
      })
      dispatch(fetchPost('adminService.createCompanies', form, '', () => {
        updateSession()
        dispatch(modalUpdate(true))
      }))
    } else {
      ReactGA.event({
        category: 'Update',
        action: 'Edit Company',
      })
      dispatch(fetchPost('adminService.editCompany', form, '', () => {
        updateSession()
        dispatch(modalUpdate(true))
      }))
    }
  }

  return (
    <Content>
      <Title>
        <Typography variant='subtitle1' className={classes.title}>
          {action === 'new' ? 'Company Information' : 'Edit Company Information'}
        </Typography>
      </Title>
      <NavStepper getStepContent={getStepContent} handleSent={handleSent} />
      <MessageModal
        title={action === 'new' ? 'SUCCESSFUL CREATION!' : 'SUCCESSFULLY UPDATED!'}
        path='entities.postData.errors'
        body={action === 'new' ? 'The company was successfully created.' : 'The company was updated correctly.'}
        handleCancelButton={() => { history.goBack() }}
        handleSubmitButton={() => { history.goBack() }}
        submitText='OK'
      />
    </Content>
  )
}
