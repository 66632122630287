//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { useStyles, Root, Content } from './styles'

export default function Accessorials () {
  const accessorials = useSelector(state => state.entities.shipmentDetails.data.accessorials || [])
  const classes = useStyles()

  return (
    <Root>
      {
        accessorials.map((item, index) => (
          <Content key={index}>
            <Typography variant='h3' className={classes.item}>
              {item.ref} {item.value > 1 && ` (${item.value})`}
            </Typography>
          </Content>
        ))
      }
    </Root>
  )
}
