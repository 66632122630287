export default {
  update: (state, data) => ({
    ...state,
    open: data,
  }),
  creatingShipment: (state, data) => ({
    ...state,
    creatingShipmentDialog: data,
  }),
}
