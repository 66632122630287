//  libraries
import React, { useEffect } from 'react'

//  components
import BackButton from 'components/common/backButton'
import CompanyForm from 'components/admin/companies/companyForm'

//  redux
import { AccountsRequest } from 'redux/ducks/accounts'
import { McLeodCompaniesRequest, cleanState } from 'redux/ducks/mcLeodCompanies'
import { TuckerUsersRequest } from 'redux/ducks/userList'

//  hooks
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

//  styles
import { Container, Content } from './styles'

export default function CreateEditCompany () {
  const dispatch = useDispatch()
  const { action } = useParams()
  const mlCompanyId = useSelector(state => state.newForm.mlCompanyId || '')

  useEffect(() => {
    dispatch(AccountsRequest())
    dispatch(TuckerUsersRequest())
    mlCompanyId && dispatch(McLeodCompaniesRequest({ code: mlCompanyId, action }))
    return () => {
      dispatch(cleanState())
    }
    //  eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Content>
        <BackButton />
        <CompanyForm />
      </Content>
    </Container>
  )
}
