import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorText: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.common.second1,
  },
  search: {
    marginTop: '35px',
  },
  cancelButton: {
    margin: '0 17px',
    width: 180,
    height: 50,
    backgroundColor: theme.palette.common.second6,
    '&:hover': {
      backgroundColor: '#637280',
    },
    color: theme.palette.common.second8,
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  addButton: {
    margin: '0 17px',
    width: 180,
    backgroundColor: '#290c3d',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
    background: theme.palette.primary.main,
    color: theme.palette.common.second8,
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    height: 50,
  },
}))

export const Container = styled.div`
  width: 80%;
  height: 80%;
  border: 2px solid #CBD6E2;
  background-color: #FFFFFF;
  border-radius: 6px;
  margin: 90px auto;
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 83px;
  border-bottom: 2px solid #CBD6E2;
`

export const Content = styled.div`
  margin: 0 72px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-grow: 1;
  overflow: hidden;
`

export const CardContainer = styled.div`
  display: grid;
  row-gap: 20px;
  margin-top: 50px;
  flex-grow: 1;
  overflow-y: scroll;
  scrollbar-color: #CBD6E2 #FFFFFF;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb  {
    background: #CBD6E2;
    border-radius: 4px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 58px;
  margin-bottom: 44px;
`
