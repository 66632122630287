//  libraries
import React, { useEffect } from 'react'

//  tools
import { floatNumberValidator } from 'tools'

//  components
import CustomInput from '../customInput'

//  hooks
import { useFormContext } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

const message = 'The size of your shipment requires a different equipment type. In order to quote your shipment, a member of our team will reach out to you for more details.'

export default function Dimensions ({ length = '', width = '', height = '' }) {
  const { addToast, removeToast } = useToasts()
  const { errors, control, setValue, getValues } = useFormContext()
  const [{ length: l, width: w, height: h }] = getValues({ nest: true }).products || [{}]
  const rules = {
    ...floatNumberValidator,
    max: { value: 200, message: 'Max value must be 200' },
  }

  useEffect(() => {
    if (l > 48.0 || h > 102.0 || w > 102.0) {
      setValue('products[0].overSize', true)
      addToast(message, {
        appearance: 'warning',
        autoDismiss: false,
        id: 'warning-oversize-ltl',
      })
    } else {
      removeToast('warning-oversize-ltl')
      setValue('products[0].overSize', false)
    }
    //  eslint-disable-next-line
  }, [l, h, w])

  return (
    <>
      <CustomInput
        title='Length (inches)'
        name='products[0].length'
        error={errors.products?.[0]?.length}
        control={control}
        defaultValue={length}
        rules={rules}
      />
      <CustomInput
        title='Width (inches)'
        name='products[0].width'
        error={errors.products?.[0]?.width}
        control={control}
        defaultValue={width}
        rules={rules}
      />
      <CustomInput
        title='Height (inches)'
        name='products[0].height'
        error={errors.products?.[0]?.height}
        control={control}
        defaultValue={height}
        rules={rules}
      />
    </>
  )
}
