//  libraries
import React, { useRef, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import PropTypes from 'prop-types'

//  tools
import { dateFormat } from 'tools'

//  styles
import { useStyles } from './styles'

export default function CommentsList ({ list = [] }) {
  const classes = useStyles()
  const container = useRef(null)
  const orderedList = list.slice()

  useEffect(() => {
    container.current.scrollTop = container.current.scrollHeight
  }, [list.length])

  return (
    <div className={classes.container} ref={container}>
      {orderedList.map((commentObj, index) => (
        <Box key={index} p={2} pt={0} className={classes.commentBlock}>
          <Typography className={classes.metaData}>
            {dateFormat(commentObj.createdAt, 'MM/DD/YYYY')}
          </Typography>
          <Typography className={classes.metaData}>
            User: {commentObj.owner.email}
          </Typography>
          <Typography className={classes.message}>
            {commentObj.message}
          </Typography>
          <Divider className={classes.divider} />
        </Box>
      ))}
    </div>
  )
}

CommentsList.propTypes = {
  list: PropTypes.array,
}
