import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  hr: {
    width: '100%',
    height: '10px',
    backgroundColor: theme.palette.primary.main,
    margin: '0.5rem 2rem 2rem 2rem',
  },
  subtitle: {
    width: '100%',
    marginTop: '1rem',
    marginLeft: '2rem',
  },
  section: {
    display: 'flex',
    alignContent: 'start',
    flexWrap: 'wrap',
    maxWidth: '40rem',
    '&:empty': {
      display: 'none',
    },
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '16rem',
    border: `solid 1px ${theme.palette.common.second6}`,
    padding: '1rem 1.5rem',
    margin: '1rem 2rem',
    height: 'max-content',
    borderRadius: 4,
  },
  hoverEffect: {
    '&:hover': {
      backgroundColor: theme.palette.common.second5,
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: theme.palette.common.second4,
    },
  },
  valueText: {
    marginLeft: 'calc(1rem + 26px)',
    width: '100%',
  },
  dollarLabel: {
    width: '100%',
    fontSize: 14,
  },
  maxWidth: {
    width: '100%',
  },
}))
