//  libraries
import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Select, { components } from 'react-select'

// components
import DateRange from '../components/dateRange'
import TransportType from '../components/transportType'
import PriceRange from '../components/priceRange'

// mocks
import status from 'mocks/status.json'
import roles from 'mocks/roles.json'

// hooks
import { useLocation } from 'react-router-dom'

//  styles
import { customSelectStyles } from './styles'
import { FaAngleDown } from 'react-icons/fa'
import { GoSearch } from 'react-icons/go'

const DropdownIndicator = props => { // Icon of react select box => status filter
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown style={{ color: '#637280', width: 18, height: 18 }} />
    </components.DropdownIndicator>
  )
}

/**
 * Filters dictionary
 */
export const filters = {
  searchBar: {
    name: 'searchTerm',
    Component: ({ filtersValues, value, cbHandler = () => {}, onSearchEvent, ...props }) => {
      return (
        <TextField
          className='searchBar'
          variant='outlined'
          value={value || ''}
          onChange={function ({ target }) { return cbHandler({ key: 'searchTerm', value: target.value }, ...arguments) }}
          onKeyDown={(e) => { if (e.keyCode === 13) onSearchEvent() }} // enter event handler
          placeholder='Search'
          InputProps={{
            startAdornment: <InputAdornment position='start'><GoSearch color='#CBD6E2' /></InputAdornment>,
          }}
          {...props}
        />
      )
    },
  },

  status: {
    name: 'status',
    Component: ({ value = [], cbHandler = () => {}, ...props }) => {
      const { pathname } = useLocation()
      const isQuote = pathname.includes('quote') || pathname.includes('sales')
      const options = isQuote ? status.quote : status.shipment
      return (
        <Select
          id='status'
          classNamePrefix='select'
          name='status'
          placeholder='Status'
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isSearchable
          isMulti
          components={{ DropdownIndicator }}
          value={isQuote
            ? status.quote.filter(option => value.includes(option.value))
            : status.shipment.filter(option => value.includes(option.value))}
          onChange={function (data) { return cbHandler({ key: 'status', value: (data || []).map(({ value }) => value) }, ...arguments) }}
          options={options}
          styles={customSelectStyles}
          {...props}
        />
      )
    },
  },

  invoiceDate: {
    name: 'invoiceDate',
    Component: ({ filtersValues, cbHandler = () => {} }) => {
      return (
        <DateRange
          filtersValues={filtersValues}
          cbHandler={cbHandler}
          gteKey='invoiceCreatedAt[gte]'
          lteKey='invoiceCreatedAt[lte]'
          placeholder='Invoice Date'
        />
      )
    },
  },

  dueDate: {
    name: 'dueDate',
    Component: ({ filtersValues, cbHandler = () => {} }) => {
      return (
        <DateRange
          filtersValues={filtersValues}
          cbHandler={cbHandler}
          gteKey='dueDate[gte]'
          lteKey='dueDate[lte]'
          placeholder='Due Date'
        />
      )
    },
  },

  statusUsers: {
    name: 'statusUsers',
    Component: ({ filtersValues, value = [], cbHandler = () => {}, ...props }) => (
      <Select
        classNamePrefix='select'
        name='status'
        placeholder='Status'
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable
        isMulti
        components={{ DropdownIndicator }}
        options={status.user}
        styles={customSelectStyles}
        value={status.user.filter(option => value.includes(option.value))}
        onChange={function (data) { return cbHandler({ key: 'statusUsers', value: (data || []).map(({ value }) => value) }, ...arguments) }}
        {...props}
      />
    ),
  },

  rolesUsers: {
    name: 'rolesUsers',
    Component: ({ filtersValues, value = [], cbHandler = () => {}, ...props }) => (
      <Select
        id='roles'
        classNamePrefix='select'
        name='roles'
        placeholder='roles'
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable
        isMulti
        components={{ DropdownIndicator }}
        options={roles}
        styles={customSelectStyles}
        value={roles.filter(option => value.includes(option.value))}
        onChange={function (data) { return cbHandler({ key: 'rolesUsers', value: (data || []).map(({ value }) => value) }, ...arguments) }}
        {...props}
      />
    ),
  },
}

export const advancedFilters = {
  priceRange: {
    name: 'priceRange',
    Component: ({ filtersValues, cbHandler }) => {
      return <PriceRange value={filtersValues.priceRange} cbHandler={cbHandler} />
    },
  },

  transportType: {
    name: 'transportType',
    Component: ({ filtersValues, cbHandler }) => {
      return <TransportType value={filtersValues.transportType} cbHandler={cbHandler} />
    },
  },

  creationDate: {
    name: 'creationDate',
    Component: ({ filtersValues, cbHandler }) => {
      return (
        <DateRange
          filtersValues={filtersValues}
          cbHandler={cbHandler}
          gteKey='createdAt[gte]'
          lteKey='createdAt[lte]'
          labelText='Creation Date'
        />)
    },
  },

  pickupDate: {
    name: 'pickupDate',
    Component: ({ filtersValues, cbHandler }) => {
      return (
        <DateRange
          filtersValues={filtersValues}
          cbHandler={cbHandler}
          gteKey='pickupWindow.startDate[gte]'
          lteKey='pickupWindow.startDate[lte]'
          labelText='Pickup Date'
        />
      )
    },
  },

  deliveryDate: {
    name: 'deliveryDate',
    Component: ({ filtersValues, cbHandler }) => {
      return (
        <DateRange
          filtersValues={filtersValues}
          cbHandler={cbHandler}
          gteKey='deliveryWindow.startDate[gte]'
          lteKey='deliveryWindow.startDate[lte]'
          labelText='Delivery Date'
        />
      )
    },
  },
}
