import makeStyles from '@material-ui/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: '15rem',
    overflow: 'auto',
    margin: '1rem 0',
    scrollbarColor: theme.palette.common.second3,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb ': {
      background: '#CBD6E2',
      borderRadius: 4,
    },

  },
  commentBlock: {
    color: '#a1a1a1',
    fontSize: 10,
  },
  metaData: {
    fontStyle: 'italic',
    fontSize: 12,
  },
  message: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    lineBreak: 'anywhere',
  },
  divider: {
    marginBottom: '1rem',
  },
}))
