//  libraries
import React from 'react'

//  components
import GeneralTabs from 'components/common/generalTabs'
import BackButton from 'components/common/backButton'
import LtlForm from 'components/quote/ltl/ltlForm'
import TlForm from 'components/quote/tl/tlForm'

//  styles
import { Container, Content } from './styles'

export default function NewQuote () {
  const ltlForm = <LtlForm />
  const tlForm = <TlForm />

  return (
    <Container>
      <Content>
        <BackButton />
        <GeneralTabs content1={ltlForm} content2={tlForm} title='Quote' />
      </Content>
    </Container>
  )
}
