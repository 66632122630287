import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    border: solid 1px ${theme.palette.common.second6};
    color: ${theme.palette.common.second3};
    max-height: 364px;
  `}
`

export const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  border-radius: 5px;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  align-items: center;
`

export const Content = styled.div`
  ${({ theme, show }) => css`
    display: ${show ? 'block' : 'none'}
    max-height: 326px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      border-radius: 3.5px;
      background-color: ${theme.palette.common.second5};
    }
    &::-webkit-scrollbar {
      width: 12px;
      background-color: ${theme.palette.common.second5};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3.5px;
      background-color: ${theme.palette.common.second6};
    }
  `}

`

export const SubContent = styled.div`
  ${({ show }) => css`
    display: ${show ? 'block' : 'none'}
  `}
`
