//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.MODAL.UPDATE:
      return model.update(state, action.payload)
    case TYPES.MODAL.CREATING_SHIPMENT:
      return model.creatingShipment(state, action.payload)
    default:
      return state
  }
}
