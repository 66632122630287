//  libraries
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

//  styles
import { Root } from './styles'
import { FaWeight, FaRunning, FaRegClock } from 'react-icons/fa'
import { GiResize, GiCardboardBox, GiFlatbedCovered } from 'react-icons/gi'
import { MdMonetizationOn } from 'react-icons/md'
import { hazmat } from 'assets'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'
import {formatToCurrency} from "../../../../tools";
const icons = {
  hazmat: () => <ImageNotDraggable externalImage={hazmat} width='16px' />,
  isOverSize: GiResize,
  isOverWeight: FaWeight,
  isNewItem: GiCardboardBox,
  isHighValue: MdMonetizationOn,
  isExpedited: FaRunning,
  isTwentyFourHours: FaRegClock,
  isTarping: GiFlatbedCovered,
}

const text = {
  hazmat: 'Hazmat',
  isOverSize: 'Over Size',
  isOverWeight: 'Over Weight',
  isNewItem: 'Used',
  isHighValue: 'High Value',
  isExpedited: 'Expedited',
  isTwentyFourHours: 'Within 24 Hours',
  isTarping: 'Required tarping',
}

export default function ItemIcons ({ details, tarpingValue = '', hightValue = '-' }) {
  return (
    <Root>
      {Object.keys(details).map((key, index) => {
        const TempIco = icons[key]
        const value = details[key]
        if (!value) return null
       
        return (
          <Tooltip title={key === 'isTarping' ? `${text[key]}: ${tarpingValue} ft.` : key === 'isHighValue' ? `${text[key]}: ${formatToCurrency(hightValue)}` : text[key]} key={index}>
            <span><TempIco style={{ color: value ? '#4B2367' : 'grey', margin: '0 .1em' }} /></span>
          </Tooltip>
        )
      })}
    </Root>

  )
}

ItemIcons.propTypes = {
  details: PropTypes.objectOf(PropTypes.bool),
}
