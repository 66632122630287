//  actions
import TYPES from './types'

//  initialState
import initialState from './initialState'

// Model
import model from './model'

export default (state = initialState, action) => {
  switch (action.type) {
    /**
     * LIST
     */
    case TYPES.QUOTE_LIST.FETCH.REQUEST:
      return model.customLoading(state, action.table)
    case TYPES.QUOTE_LIST.FETCH.SUCCEEDED:
      return model.saveData(state, action.payload, action.table)
    case TYPES.QUOTE_LIST.FETCH.FAILED:
      return model.customError(state, action.payload)
    /**
     * REQUOTE
     */
    case TYPES.QUOTE_LIST.REQUOTE.FETCH.REQUEST:
      return model.rowLoading(state, action.quoteId)
    case TYPES.QUOTE_LIST.REQUOTE.FETCH.FAILED:
      return model.requoteError(state, action.quoteId)
    /**
     * SURVEY
     */
    case TYPES.QUOTE_LIST.SURVEY.FETCH.REQUEST:
      return model.customLoading(state, 'tl')
    case TYPES.QUOTE_LIST.SURVEY.FETCH.SUCCEEDED:
      return model.updateTlStatus(state, action.quoteId, action.newStatus)
    case TYPES.QUOTE_LIST.SURVEY.FETCH.FAILED:
      return model.updateTlRjectError(state, action.quoteId)
    /**
     * UPDATES
     */
    case TYPES.QUOTE_LIST.UPDATE.ASSIGN:
      return model.updateTlQuoteByRefId(state, action.quoteId, action.newData)
    case TYPES.QUOTE_LIST.UPDATE.SELECTED_RATE:
      return model.updateLtlSelectedRate(state, action.refId, action.newSelectedRate)
    case TYPES.QUOTE_LIST.UPDATE.CARRIERS_RATES:
      return model.updateRates(state, action.ratesList, action.customerPrice, action.carrierPrice, action.selectedRate)

    /**
     * GENERAL
     */
    case TYPES.QUOTE_LIST.CLEAN.ERRORS:
      return model.clean(state)
    case TYPES.QUOTE_LIST.CLEAN.STATE:
      return model.clean(initialState)
    default:
      return state
  }
}
