export const tableStyles = {
  cellStyle: {
    fontSize: 14,
    color: '#b4b4b4',
    textAlign: 'center',
  },
  column: {
    fontSize: 14,
    textAlign: 'center',
  },
  nameColumn: {
    fontSize: 14,
    textAlign: 'center',
  },
  rowStyle: {
    borderBottom: 'solid 1px #eaeff4',
  },
  detailContainer: {
    border: 'solid 4px #eaeff4',
    borderTop: 'solid 1px white',
    width: '100%',
    marginBottom: 40,
  },
}
