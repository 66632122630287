import keyMirror from 'keymirror-nested'

export default keyMirror({
  USER_LIST: {
    FETCH: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    UPDATE: {
      STATUS: null,
    },
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
    DELETE: 'delete'
  },
  TUCKER_USERS: {
    FETCH: {
      REQUEST: null,
      SUCCEEDED: null,
      FAILED: null,
    },
    UPDATE: {
      STATUS: null,
    },
    CLEAN: {
      ERRORS: null,
      STATE: null,
    },
  },
})
