// BaseModel
import baseModel from 'redux/baseModel'
import { errorMessages } from 'mocks/errorMessages'

export default {
  ...baseModel,
  setMessage: (state, newMessage, commentType) => {
    const newDetails = state.data[0]
    newDetails.comments[commentType] = [
      ...(newDetails.comments[commentType] || []),
      newMessage,
    ]
    newDetails.comments.loading = false
    return {
      ...state,
      loading: false,
      errors: [],
      data: [newDetails],
    }
  },

  customLoadingMessage: (state) => {
    const newDetails = state.data[0]
    newDetails.comments = {
      ...newDetails.comments,
      loading: true,
    }
    return {
      ...state,
      data: [newDetails],
    }
  },

  customErrorComments: (state, data = {}) => {
    const newDetails = state.data[0]
    newDetails.comments = {
      ...newDetails.comments,
      loading: false,
    }
    return {
      ...state,
      data: [newDetails],
      errors: [...state.errors, errorMessages[data.error] || data.error],
    }
  },

  updateMarketRates: (state, data) => {
    const newDetails = state.data[0]
    newDetails.marketRates = data.marketRates
    newDetails.ratesHistory = data.ratesHistory
    newDetails.marketRates.loading = false
    return {
      ...state,
      loading: false,
      errors: [],
      data: [newDetails],
    }
  },

  updateStatus: (state, { status }) => {
    const data = state.data[0]
    data.status = status
    return {
      ...state,
      loading: false,
      errorDecline: null,
      data: [data],
    }
  },

  declineError: (state, data) => {
    return {
      ...state,
      loading: false,
      errorDecline: data.error,
    }
  },

  customLoadingMarketRates: (state) => {
    const newDetails = state.data[0]
    newDetails.marketRates = []
    newDetails.ratesHistory = []
    return {
      ...state,
      data: [newDetails],
    }
  },

  customErrorMarketRates: (state, data = {}) => {
    const newDetails = state.data[0]
    newDetails.marketRates = [
      ...newDetails.marketRates,
    ]
    return {
      ...state,
      data: [newDetails],
      errors: [...state.errors, errorMessages[data.error] || data.error],
    }
  },
}
