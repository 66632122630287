//  libraries
import React from 'react'
import ReactGA from 'react-ga'
import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as ThemeStyled } from 'styled-components'
import { ToastProvider } from 'react-toast-notifications'

// components
import { StyledToast } from 'components/common/styledToast'

//  Routes
import Routes from 'routes'

//  styles
import { GlobalStyles } from 'styles/GlobalStyles'
import { theme } from 'styles/theme'

//  Google Analytics
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ThemeStyled theme={theme}>
        <GlobalStyles />
        <ToastProvider placement='bottom-left' components={{ ToastContainer: StyledToast }} autoDismissTimeout={10000}>
          <Routes />
        </ToastProvider>
      </ThemeStyled>
    </ThemeProvider>
  )
}

export default App
