import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    margin: 'auto 5px',
    color: theme.palette.common.second3,
    fontWeight: '500',
  },
  error: {
    fontSize: '10px',
  },
}))

export const Root = styled.div`
  margin-bottom: 2rem;
`

export const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 150px 300px 150px;
  justify-content: center;
  column-gap: 10px;
  background-color: #CBD6E233;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px ${({ theme }) => theme.palette.common.second6};
  border-bottom: none;
`

export const Content = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 150px 300px 150px;
  justify-content: center;
  column-gap: 10px;
  border: solid 1px ${({ theme }) => theme.palette.common.second6};
  height: 90px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 30px;
`

export const Item = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
`
