//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'

//  hooks
import { useFormContext, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  styles
import { useStyles, Content, Card } from './styles'
import { BsListUl } from 'react-icons/bs'
import { GoFileSymlinkFile } from 'react-icons/go'
import { FiFileText } from 'react-icons/fi'

export default function Permissions () {
  const { control } = useFormContext()
  const permissions = useSelector(state => state.newForm.permissions || {})
  const classes = useStyles()

  return (
    <div>
      <Typography variant='h4' className={classes.title}> This user has the following permissions for the  application </Typography>
      <Content>
        <Card>
          <Box display='flex' alignItems='center' width='220px'>
            <BsListUl color='#979797' className={classes.icon} />
            <Typography variant='h4' className={classes.text} component='label'> Full Access Shipment </Typography>
          </Box>
          <Controller
            render={props => (
              <Checkbox
                onChange={e => props.onChange(e.target.checked)}
                checked={props.value}
                color='default'
                classes={{
                  checked: classes.checked,
                }}
              />
            )}
            defaultValue={!!permissions.shipment}
            name='permissions.shipment'
            control={control}
          />
        </Card>
        <Card>
          <Box display='flex' alignItems='center' width='220px'>
            <GoFileSymlinkFile color='#979797' className={classes.icon} />
            <Typography variant='h4' className={classes.text} component='label'> Full Access Quote </Typography>
          </Box>
          <Controller
            render={props => (
              <Checkbox
                onChange={e => props.onChange(e.target.checked)}
                checked={props.value}
                color='default'
                classes={{
                  checked: classes.checked,
                }}
              />
            )}
            defaultValue={!!permissions.quote}
            name='permissions.quote'
            control={control}
          />
        </Card>
        <Card>
          <Box display='flex' alignItems='center' width='220px'>
            <FiFileText color='#979797' className={classes.icon} />
            <Typography variant='h4' className={classes.text} component='label'> Full Access Accounting </Typography>
          </Box>
          <Controller
            render={props => (
              <Checkbox
                onChange={e => props.onChange(e.target.checked)}
                checked={props.value}
                color='default'
                classes={{
                  checked: classes.checked,
                }}
              />
            )}
            defaultValue={!!permissions.accounting}
            name='permissions.accounting'
            control={control}
          />
        </Card>
      </Content>
    </div>
  )
}
