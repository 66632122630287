import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  text2: {
    color: theme.palette.primary.main,
  },
  text1: {
    color: theme.palette.common.second1,
  },
}))

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 250px;
  grid-area: info;
`
