import { call, takeEvery, put } from 'redux-saga/effects'
import TYPES from './types'
import {
  McLeodCompaniesSucceeded,
  McLeodCompaniesFailed,
} from './actions'
import API from 'API'

function * fetchMcLeodCompanies (action) {
  try {
    const { data } = yield call(API.adminService.mcLeodCompanies, action.payload)
    yield put(McLeodCompaniesSucceeded(data))
    action.callback(data)
  } catch (error) {
    const newError = error.response?.data || { error: error.message }
    yield put(McLeodCompaniesFailed(newError))
    action.errorCallback(newError.error)
  }
}

export default function * rootMcLeodCompanies () {
  yield takeEvery(TYPES.MCLEOD_COMPANIES.FETCH.REQUEST, fetchMcLeodCompanies)
}
