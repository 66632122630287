//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'

//  components
import ImageNotDraggable from 'components/common/imageNotDraggable'

//  hooks
import { useSelector } from 'react-redux'

//  styles
import { documentIcon } from 'assets'
import { useStyles, Root, HeadingContainer, Content } from './styles'

export default function ReferenceCard () {
  const references = useSelector(state => state.entities.shipmentDetails.data.references || {})
  const classes = useStyles()

  return (
    <Root>
      <HeadingContainer>
        <ImageNotDraggable externalImage={documentIcon} width='25px' />
        <Typography variant='h2' className={classes.heading}>
          Reference List
        </Typography>
      </HeadingContainer>
      <Content>
        {
          references.map(({ ref, value }, index) => (
            <div key={index}>
              <Typography variant='h4' className={classes.title} align='center'> {ref || '...'} </Typography>
              <Typography variant='h4' className={classes.text} align='center'> {value || '...'} </Typography>
            </div>
          ))
        }
      </Content>
    </Root>
  )
}
