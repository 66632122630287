//  libraries
import React from 'react'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

//  hooks
import { useFormContext, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

//  styles
import { useStyles } from './styles'

export default function CheckBoxes () {
  const { register, watch } = useFormContext()
  const {
    isAppointmentRequired = false,
    isAppointmentScheduledAndConfirmed = false,
  } = useSelector(state => state.newForm.shipping.pickup)
  const appointmentRequired = watch('shipping.pickup.isAppointmentRequired')
  const appointmentScheduledAndConfirmed = watch('shipping.pickup.isAppointmentScheduledAndConfirmed')
  const classes = useStyles({ displayValue: appointmentRequired })

  return (
    <div className={classes.secondRow}>
      <div className={classes.labelAndItem}>
        <Typography>
          Is an Appointment Required?
        </Typography>
        <Controller
          render={props => (
            <FormControlLabel
              className={classes.radio}
              onChange={e => props.onChange(e.target.checked)}
              checked={props.value}
              label={appointmentRequired ? 'Yes' : 'No'}
              control={
                <Switch color='primary' />
              }
            />
          )}
          defaultValue={isAppointmentRequired}
          name='shipping.pickup.isAppointmentRequired'
          inputRef={register}
        />
      </div>
      <div className={`${classes.labelAndItem} ${classes.conditionalDisplay}`}>
        <Typography>
          Is an Appointment Scheduled and Confirmed?
        </Typography>
        <Controller
          render={props => (
            <FormControlLabel
              className={classes.radio}
              onChange={e => props.onChange(e.target.checked)}
              checked={props.value}
              label={appointmentScheduledAndConfirmed ? 'Yes' : 'No'}
              control={
                <Switch color='primary' />
              }
            />
          )}
          defaultValue={isAppointmentScheduledAndConfirmed}
          name='shipping.pickup.isAppointmentScheduledAndConfirmed'
          inputRef={register}
        />
      </div>
    </div>
  )
}
