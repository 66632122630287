//  libraries
import React, { useState, useRef } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import PropTypes from 'prop-types'

//  styles
import { useStyles } from './styles'
import { MdMoreVert, MdVisibility } from 'react-icons/md'

export default function Menu ({ id, request }) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const classes = useStyles()

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handlePOD = () => {
    request('accountingService.getPdfPOD', id, 'modal')
  }

  const handleOpen = () => {
    request('accountingService.getPdfInvoice', id, 'modal')
  }

  return (
    <div>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
      >
        <MdMoreVert className={classes.dots} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={false} onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleOpen} className={classes.menuItem}>
                    View Invoice<MdVisibility className={classes.icon} />
                  </MenuItem>
                  <MenuItem onClick={handlePOD} className={classes.menuItem}>
                    View POD<MdVisibility className={classes.icon} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

Menu.propTypes = {
  id: PropTypes.string,
  request: PropTypes.func,
}
