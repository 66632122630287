import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '35px',
    fontWeight: 900,
    color: theme.palette.primary.main,
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    width: '50%',
    height: 76,
  },
  button: {
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'none',
    width: 140,
    height: 50,
    color: theme.palette.common.second8,
    backgroundColor: '#290c3d',
    '&:hover': {
      backgroundColor: '#3A1852',
    },
  },
  disabled: {
    color: '#FFFFFF !important',
  },
}))

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`

export const Content = styled.div`
  max-width: 1200px;
  margin: 0 10px;
  width: 100%;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
`

export const Title = styled.div`
  border-bottom: 2px solid #CBD6E2;
  width: 100%;
  height: 76px;
  margin-top: 20px;
`
