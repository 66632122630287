//  libraries
import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'

//  components
import TabPanel from 'components/common/tabPanel'

//  hooks
import { useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'

//  redux
import { newFormDelete } from 'redux/ducks/newForm'
import { locationButtonsDelete } from 'redux/ducks/locationButtons'

//  styles
import { useStyles, Container } from './styles'

export default function GeneralTabs ({ content1, content2, title }) {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { type, action } = useParams()
  const [value, setValue] = useState(type)
  const textAction = action === 'edit' ? action : 'New'
  const [, path] = location.pathname.split('/')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    dispatch(newFormDelete())
    dispatch(locationButtonsDelete())
    history.push(`/${path}/new/${newValue}`)
  }

  const renderTabs = () => {
    /* add to hide ltl module */
    return <Tab value='tl' label={`${textAction} ${type.toUpperCase()} ${title}`} classes={{ root: classes.tab, selected: classes.activeTab }} />

    // complete functionality
    // if (action !== 'new') {
    //   return (<Tab value={type} label={`New ${title} ${type.toUpperCase()}`} classes={{ root: classes.tab, selected: classes.activeTab }} />)
    // }
    // return [
    //   <Tab key='ltl' value='ltl' label={`New ${title} LTL`} classes={{ root: classes.tab, selected: classes.activeTab }} />,
    //   <Tab key='tl' value='tl' label={`New ${title} TL`} classes={{ root: classes.tab, selected: classes.activeTab }} />,
    // ]
  }

  return (
    <Container>
      <AppBar position='static' color='secondary' className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          classes={{
            indicator: classes.indicator,
          }}
        >
          {renderTabs()}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index='ltl'>
        {content1}
      </TabPanel>
      <TabPanel value={value} index='tl'>
        {content2}
      </TabPanel>
    </Container>
  )
}

GeneralTabs.propTypes = {
  content1: PropTypes.node.isRequired,
  content2: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}
