// libraries
import React from 'react'

// hooks
import { DefaultToastContainer } from 'react-toast-notifications'

// styles
import { useStyles } from './styles'
import classNames from 'classnames'

export const StyledToast = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <DefaultToastContainer className={classNames(classes.styledToast, className)} {...props}>
      {children}
    </DefaultToastContainer>
  )
}
