import baseModel from 'redux/baseModel'

export default {
  ...baseModel,

  setData: (state, data = {}) => {
    return {
      ...state,
      loading: false,
      data: {
        ...data,
        results: [...state.data.results, ...data.results],
      },
    }
  },

  addNewNotification: (state, data = {}) => {
    return {
      ...state,
      data: {
        ...state.data,
        totalCount: state.data.totalCount + 1,
        unread: state.data.unread + 1,
        results: [...state.data.results, data],
      },
    }
  },

  updateReadNotifications: (state, readNotifications = []) => {
    const { results, unread } = state.data
    const readIndex = readNotifications.map(id => results.findIndex(notification => notification._id === id))
    readIndex.forEach(notificationIndex => {
      results[notificationIndex].read = true
    })
    const setUnread = unread - readIndex.length

    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        unread: setUnread < 0 ? 0 : setUnread,
        results,
      },
    }
  },

  deleteNotification: (state, idNotification) => {
    const toDelete = state.data.results.find(notify => notify._id === idNotification)
    const currentUnread = state.data.unread
    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        results: state.data.results.filter(notification => notification._id !== idNotification),
        unread: toDelete.read === false ? currentUnread - 1 : currentUnread,
      },
    }
  },
}
